import "../../App.css";
import {fetchEmail, fetchProfile} from './fetch'
import { useEffect, useState, useRef } from "react";
import Cookies from 'js-cookie';
import headshot from "./headshot.svg";
import hint from "./hint.svg";
import styles from "./logedhome.module.css";
import Result from "../Result/Result";
import IntelliProIcon from "../IntelliProIcon/IntelliProIcon";
import Filters from "../Filters/Filters";
import { useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import {Link} from "react-router-dom";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as CopyIcon } from "./copy.svg";
import { ReactComponent as SuccessIcon} from "./Success.svg";
import { ReactComponent as HoverCopyIcon }from './copyHover.svg'; // Hover state copy icon
import { ReactComponent as ActiveCopyIcon}from './copy_pressed.svg'; // Active (pressed) state copy icon
import Select from "react-select";
import placeholderImg from './language_icon.svg';
import Result_test from "../Result/result_test";
import Result_fake from "../Result/result_fake";
import DownloadPdf from "../Download/PDFDownload";
import Footer from "../utils/footer";

// import ResumePage from './component/ResumePage/ResumePage'
// import GeneratePdf from './component/Result/GeneratePdf';
// import ResumeWrapper from './component/ResumePage/ResumeWrapper';
// import data from './component/fake_data12.json'

function Home (props) {
    const navigate = useNavigate();
    const [general, setGeneral] = useState({
      general_filter: {
        location: [],
        job_title: [],
        employer: [],
        total_publications: { min: null, max: null },
        published_on: [],
        only_with: { email: false, phone: false, ORCID: false, gs_link: false },
      },
    });
    const [subject, setSubject] = useState({
      subject_filter: {
        type: "any",
        subject: [],
        topic: [],
        onlyshown: [],
      },
    });
    const [name, setName] = useState('');
    const [initial,setInitial] = useState('')
    const [isSearching, setIsSearching] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true); // Add this state to track login status
    const [showRes, setShowRes] = useState(false);
    const [resumeData, setResumeData] = useState([]);
    const [test, setTest] = useState(true);
    const [totalScholars, setTotalScholars] = useState(-1);
    const [isModalOpen, setIsModalOpen] = useState(false); // Add this state to track modal status
    const [isOutModalOpen, setIsOutModalOpen] = useState(false); // Add this state to track modal status
    const [isHintModalOpen, setIsHintModalOpen] = useState(false); // Add this state to track modal status
    const [linkCopied, setLinkCopied] = useState(false);
    const [isHovered, setIsHovered] = useState(false);//for Copy Icon
    const [isActive, setIsActive] = useState(false);//for Copy Icon
    const [referralLink, setReferralLink] = useState("");
    const [email, setEmail] = useState("");
    const [resultEmpty, setResultEmpty] = useState(false);
    const [scholarPopup, setScholarPopup] = useState(false);
    const [searchBy, setSearchBy] = useState("filter");
    const [hasDetail, setHasDetail] = useState(true);
    const [searchParams] = useSearchParams();
    const filter = searchParams.get('k');

    // function getCookie(name) {
    //   let cookieValue = null;
    //   if (document.cookie && document.cookie !== '') {
    //       const cookies = document.cookie.split(';');
    //       for (let i = 0; i < cookies.length; i++) {
    //           const cookie = cookies[i].trim();
    //           if (cookie.substring(0, name.length + 1) === (name + '=')) {
    //               cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
    //               break;
    //           }
    //       }
    //   }
    //   return cookieValue;
    // }   

    // async function fetchLoginStatus() {
    //   console.log("fetching...");
    //   console.log(getCookie('csrftoken'));
    //   console.log("cookies:", document.cookie);
    //   try {
    //     const response = await fetch("http://127.0.0.1:8001/test2", {
    //       method: "GET",
    //       credentials: 'include',  // Important for sending cookies
    //     });

    //     if (!response.ok) {
    //       throw new Error(`Request failed with status: ${response.status}`);
    //     }

    //     const data = await response.json();
    //     console.log("resp data:", data);
    //     return data ? true : false;
    //   } catch (error) {
    //     console.error("Error fetching login status:", error.message);
    //     return false;
    //   }
    // }

    // (async () => {
    //   const isLoggedIn = await fetchLoginStatus();
    //   console.log(`Is logged in: ${isLoggedIn}`);
    // })();
    const fetchReferralLink = async (email) => {
      try {
        const response = await fetch(`https://intelliprozenithscholars.com/auth/get_referral_link/?email=${email}`);
        if (response.ok) {
          const data = await response.json();
          if (data.status === 'success') {
            setReferralLink('https://paradx.net/dkdh_referral?'+data.referral_link);
          } else {
            console.error('Error fetching referral link:', data.status);
          }
        } else {
          console.error('Error fetching referral link:', response.status);
        }
      } catch (error) {
        console.error('Error fetching referral link:', error);
      }
    };    
    useEffect(()=>{
      fetchProfile().then((data) => {
        // console.log("login status", data);
        if (data.email){
          setEmail(data.email);
          const initials = data.name.split(' ').map((n) => n[0]).join('').toUpperCase();
          setInitial(initials);
          setIsLoggedIn(true);
          fetchReferralLink(data.email);
        }
        else{
          setIsLoggedIn(true);
          setReferralLink(""); 
        }
    })
    .catch((error) => {
        console.error("Promise rejected:", error);
    });
    },[]);
    // useEffect(() => {
      
    //   if (props.email){
    //     setIsLoggedIn(true);
    //     fetchReferralLink();
    //   }
    //   else{
    //     setIsLoggedIn(false);
    //     setReferralLink("");  
    //   }
    // }, [props.email]); 

    // useEffect(() => {
    //   if (!isLoggedIn){
    //     props.setEmail("");
    //   }
    // }, [isLoggedIn]); 

    // const referralLink = "https://www.refersdfadfadfasdfSdfafsdfasdfas.com"; // Replace with the actual referral link


    function handleShowRes(){
      setShowRes(true);
    }
    // Function to handle login
    const handleLogin = () => {
      // Logic to authenticate user goes here
     
      setIsLoggedIn(!isLoggedIn); // Set login status to true
      console.log("User logged in successfully");
        // Navigate to the new URL
      // navigate('/logedHome');
     
    };


    // Function to toggle the modal
    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };

     const toggleoutModal = () => {
      setIsOutModalOpen(!isOutModalOpen);
    };


    // Create a reference to the modal content to close the reffreal link modal if clicked outside
  const modalContentRef = useRef(null);
  const modalOutContentRef = useRef(null);

  // Function to close the modal if clicked outside
  const handleClickOutside = (e) => {
    if (isModalOpen && modalContentRef.current && !modalContentRef.current.contains(e.target)) {
      toggleModal();
      setLinkCopied(false);
      setIsHovered(false);
      setIsActive(false);
    }

    if (isOutModalOpen && modalOutContentRef.current && !modalOutContentRef.current.contains(e.target)) {
      toggleoutModal();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleClick = () => {
    copyLink();
    setIsActive(true);
  };


    // Function to handle logout
    const handleLogout = () => {
      // Logic to logout user goes here   
      setIsLoggedIn(false); // Set login status to false
      Cookies.remove('user_id');
      Cookies.remove('sessionid');
      Cookies.remove('csrftoken');
      localStorage.removeItem('resumeData');

      toggleoutModal();

      console.log("User logged out successfully");
      // Redirect to the home page
      navigate('/');
    };

    const toggleLoggout = () => {
      if (isLoggedIn) {
        handleLogout();
      } else {
        handleLogin();
      }
    };


     // Function to toggle the modal
    const toggleHintModal = () => {
      setIsHintModalOpen(!isHintModalOpen);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(referralLink);
        console.log("Referral link copied to clipboard");
        setLinkCopied(true); // Set linkCopied to true when the link is copied
    };


    function setTotal(data){
      setTotalScholars(data['total_scholars']);
      console.log("total scholars received");
    }
    function setData(data){
      console.log("600 resumes received");
      props.set(data);
      setResumeData([...data['data']]);
      setShowRes(true);
      // setIsSearching(false);
    }
    useEffect(() => {
      // console.log('result',resumeData);
      setTest(!test);
    }, [resumeData]); 

    useEffect(() => {
      console.log('searching 状态',isSearching)
      if(!hasDetail){
        // const timer = setTimeout(() => {
        //   setIsSearching(false);
        // }, 3500);
  
        // // 如果你的组件在6秒之前被卸载，你需要清除定时器，防止尝试更新已卸载组件的状态
        // return () => clearTimeout(timer);
      }else if (isSearching && resumeData.length > 0 ) {
        const timer = setTimeout(() => {
          setIsSearching(false);
        }, 34000);
  
        // 如果你的组件在6秒之前被卸载，你需要清除定时器，防止尝试更新已卸载组件的状态
        return () => clearTimeout(timer);
      }
    }, [isSearching]);

    useEffect(() => {
      if (isModalOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      // Cleanup function to remove the event listener when the component is unmounted
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isModalOpen]);
  
    // Add an event listener to the document when the modal is open
    useEffect(() => {
      if (isOutModalOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      // Cleanup function to remove the event listener when the component is unmounted
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOutModalOpen]);

//  const translations = {
//     EN: {
//       SignUp: 'Sign Up',
//       SignIn: 'Sign In',

//     },
//     CN: {
//       SignUp: '注册',
//       SignIn: '登录',
//     },
//     // You can add more languages here
//   };

//   const t = (key) => {
//     return translations[props.selectedLanguage][key] || key;
//   };

/*
    const options = [
    { value: 'sign-up', label: 'Sign Up' },
    { value: 'sign-in', label: 'Sign In'},
];

    const handleSelectChange = (selectedOption) => {
      if (selectedOption.value === 'sign-in') {
        //<Link to="/login" className="signIn" onClick={handleLogin}>Sign In/</Link>
        navigate("/login")
      } else if (selectedOption.value === 'sign-up') {
        //<Link to="/signUp" className="signUp">Sign Up</Link>
        navigate("/signUp")
      }
    }

    const customSelect = {
      control: (provided, state) => ({
          ...provided,
          fontFamily: 'Sofia Pro',
          fontSize: 16,
          color: '#377DED',
          border: 'none',
          background: 'transparent',
          outline: 'none',
          border: 'none', 
          boxShadow: 'none', 
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        display:'none',
      }),
      placeholder: (provided, state) => ({
        ...provided,
        transition: 'color 0.2s',
        color: state.isFocused ? '#2149B1' : '#377DED',
        fontSize: state.isFocused ? '18px' : '16px',
        position: 'relative',
        "&:hover": {
          color: "#2149B1",
          fontSize: 18,
          borderBottom: "2px solid #2149B1",
        },
      }),
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: state.isSelected ? '#FFFFFF' : '#377DED',
        cursor: 'pointer',
        "&:active": {
          color: '#FFFFFF',
          background: '#377DED',
        },
        "&:focus": {
          outline: 'none',
        },
        // borderRadius
      }),
      menu: (provided) => ({
        ...provided,
        border: 'none',
        borderRadius: '10px',
        boxShadow: 'none',
        marginTop: 0,
      }),
    };
*/

    const SelectedOptionWithSVG = ({ label }) => (
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
        <img src={placeholderImg} alt="Placeholder" style={{  height: '25px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px',paddingTop:"7px"}} />
        {label==='English' ? (
          <span className="home-anguage" style={{fontSize:'22px',marginTop:'5px'}}>EN</span>
        ) : (
          <span className="home-anguage"style={{fontSize:'22px',marginTop:'5px'}}>CN</span>
        )}
      </div>
    );

    const options2 = [
      { value: 'EN', label: 'English' },
      { value: 'CN', label: '中文(简体)' },
    ];
    const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

    const handleSelectChange2 = (selectedOption) => {
      setSelectedLanguage(selectedOption.value); // Update
      props.setSelectedLanguage(selectedOption.value); 
      if (selectedOption.value === 'EN') {
        // Language is set to English
      } else if (selectedOption.value === 'CN') {
        // Language is set to Chinese
      }
    }

    const customSelect2 = {
      control: (provided, state) => ({
          ...provided,
          fontFamily: 'Sofia Pro',
          fontSize: 16,
          color: '#377DED',
          border: 'none',
          background: 'transparent',
          outline: 'none',
          border: 'none', 
          boxShadow: 'none', 
          minWidth: '130px',
          // marginTop:"10px",
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        display:'none',
      }),
      placeholder: (provided, state) => ({
        ...provided,
        transition: 'color 0.2s',
        color: '#377DED',
        fontSize: state.isFocused ? '18px' : '16px',
        position: 'relative',
        "&:hover": {
          color: "#1052BC",
          fontSize: 18,
          //borderBottom: "2px solid #2149B1",
        },
      }),
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: state.isSelected ? '#FFFFFF' : '#377DED',
        cursor: 'pointer',
        textAlign: 'left',
        "&:active": {
          color: '#FFFFFF',
          background: '#377DED',
        },
        "&:focus": {
          outline: 'none',
        },
        display: 'flex',
        marginTop:'8px',
        marginBottom:'8px'
      }),
      menu: (provided) => ({
        ...provided,
        border: 'none',
        borderRadius: '10px',
        boxShadow: 'none',
        marginTop: 5,
        position: 'absolute',
      }),
    };

    const PlaceholderImageEN = () => (
      <div style={{display: 'flex', alignItems: 'center', color: "black", fontSize:"22px", marginLeft:"2px"}}>
        <img src={placeholderImg} alt="Placeholder" style={{  height: '25px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px'}} />
        <span className={styles.langp1}>EN</span>
      </div>
    );

    const PlaceholderImageCN = () => (
      <div style={{display: 'flex', alignItems: 'center', color: "black", fontSize:"22px", marginLeft:"2px"}}>
        <img src={placeholderImg} alt="Placeholder" style={{  height: '25px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px'}} />
        <span className={styles.langp1}>CN</span>
      </div>
    );

    const toProfile = () => {
      toggleoutModal();
      console.log("User logged out successfully");
      console.log('~~~~~~')
      // navigate('/profile')
    }

    const scholarInputPopup = e => {
      clearTimeout(e.target.close)
      e.target.open = setTimeout(() => {
        setScholarPopup(true)
      }, 800);
    }
    const closeScholarPopup = e => {
      clearTimeout(e.target.open)
      e.target.close = setTimeout(() => {
        setScholarPopup(false)
      }, 1000);
    }

  // const translations = {
  //   EN: {
  //     SignUp: 'Sign Up',
  //     SignIn: 'Sign In',
  //     placeholder: 'Sign Up/Log In',

  //   },
  //   CN: {
  //     SignUp: '注册',
  //     SignIn: '登录',
  //     placeholder: '注册/登录',
  //   },
    // You can add more languages here
  // };

  // const t = (key) => {
  //   return translations[props.selectedLanguage][key] || key;
  // };
    

    return(
      <div >
        <div className={styles.topbar}>
          {/* <button onClick={handleLogin}>Login</button> */}

          {props.selectedLanguage =='EN' && <div className={styles.area}>
              {/* LogoutModal */}
              {isOutModalOpen && (
                  <div className={styles.profileMenu} ref={modalOutContentRef}>
                      <div  className={styles.settingmodal} onClick={e=>navigate('/profile')}>
                        <h3>Settings</h3>
                      </div>

                      <div className={`${styles.Logoutmodal} ${isHovered ? styles.logoutModalHover : ''} ${isActive ? styles.logoutModalClick : ''}`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={toggleLoggout}
                      >
                        <h3>Log out</h3>
                      </div>
                  </div>
              )} 
              <div className={styles.hint_modal}>
                  {isHintModalOpen && (
                      <div className={styles.Hintmodal}>
                        
                        “Click to obtain your exclusive referral link！”
                        
                        <h4>
                        For inquiries regarding the collection and utilization of personal data, please refer to our Privacy Policy and Terms of Use.
                        </h4>
                      </div>
                  )}
              </div>
              {/* <referralLinkModal/> */}
              <div className={styles.refer_modal}>
                      {isModalOpen && (
                        <div className={linkCopied ? styles.modalSmall : styles.modalNormal}>
                            <div className={styles.modalContent} ref={modalContentRef}>
                            <div className={styles.linkContainer}>
                                {linkCopied ? (
                                <>
                                    <div className={styles.successMessageContainer}>
                                      <div className={styles.successMessage}>Link Copied Successfully!</div>
                                      <SuccessIcon className={styles.successIcon} /> {/* Replace with the actual success icon */}
                                    </div>
                                    
                                </>
                                ) : (
                                <>
                                  <a href={referralLink} target="_blank" rel="noopener noreferrer" className={styles.referralLink}>{referralLink}</a>
                                    <div
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                      >
                                        {isHovered ? <HoverCopyIcon /> : isActive ? <ActiveCopyIcon /> : <CopyIcon />}
                                        
                                      </div>
                                </>
                                )}
                            </div>
                            </div>
                        </div>
                    )}
                
              </div>
          
          </div>}
          {props.selectedLanguage =='CN' && <div className={styles.area}>
              {/* LogoutModal */}
              {isOutModalOpen && (
                  <div className={styles.profileMenu} ref={modalOutContentRef}>
                      <div  className={styles.settingmodal} onClick={e=>navigate('/profile')}>
                        <h3>设置</h3>
                      </div>

                      <div className={`${styles.Logoutmodal} ${isHovered ? styles.logoutModalHover : ''} ${isActive ? styles.logoutModalClick : ''}`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={toggleLoggout}
                      >
                        <h3>退出登录</h3>
                      </div>
                  </div>
              )} 
              <div className={styles.hint_modal}>
                  {isHintModalOpen && (
                      <div className={styles.Hintmodal}>
                        
                        “点击获取您的专属的推广注册链接！”
                        
                        <h4>
                        关于个人数据的收集和使用等疑问，请参考privacy policy和term of use。
                        </h4>
                      </div>
                  )}
              </div>
              {/* <referralLinkModal/> */}
              <div className={styles.refer_modal}>
                      {isModalOpen && (
                        <div className={linkCopied ? styles.modalSmall : styles.modalNormal}>
                            <div className={styles.modalContent} ref={modalContentRef}>
                            <div className={styles.linkContainer}>
                                {linkCopied ? (
                                <>
                                    <div className={styles.successMessageContainer}>
                                      <div className={styles.successMessage}>成功复制链接!</div>
                                      <SuccessIcon className={styles.successIcon} /> {/* Replace with the actual success icon */}
                                    </div>
                                    
                                </>
                                ) : (
                                <>
                                  <a href={referralLink} target="_blank" rel="noopener noreferrer" className={styles.referralLink}>{referralLink}</a>
                                    <div
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                      >
                                        {isHovered ? <HoverCopyIcon /> : isActive ? <ActiveCopyIcon /> : <CopyIcon />}
                                        
                                      </div>
                                </>
                                )}
                            </div>
                            </div>
                        </div>
                    )}
                
              </div>
          
          </div>}

          
          {!isLoggedIn && <div className="navBar">        
            <button className={styles.buttonSign} onClick={() => navigate('/login')}>
              Sign Up/Log in
            </button>

            <div className={styles.langDrop}>
              <Select
                options={options2}
                onChange={handleSelectChange2}
                isSearchable={false} // This will disable user input
                placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                components={{
                  SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                }}
                styles={customSelect2}
              />
            </div>
          </div>}

          {isLoggedIn && <div className="navBar" style={{width:'94%'}}>
            {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />
}

            <img src={hint} alt="Hint" className= {styles.hintIcon} onMouseEnter={() => setIsHintModalOpen(true)}
          onMouseLeave={() => setIsHintModalOpen(false)}/>
           { props.selectedLanguage=='EN' && <div className={styles.getRefer} onClick={toggleModal}>Get My Referral Link!</div>} 
           { props.selectedLanguage=='CN' && <div className={styles.getRefer} onClick={toggleModal}>获取我的推荐链接</div>} 

            {/* <img src={headshot} alt="User Headshot" className={styles.userHeadshot} onClick={toggleoutModal} /> */}
            <div className={styles.avatar}  onClick={toggleoutModal}>
                           <span className="initials"  >{initial}</span>
                       </div>
            <div className={styles.langDrop2}>
              <Select
                options={options2}
                onChange={handleSelectChange2}
                isSearchable={false} // This will disable user input
                placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                components={{
                  SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                }}
                styles={customSelect2}
              />
            </div> 
          </div>
          }

          
        </div>


        <div className={styles.home}>
          
          {/*{selectedLanguage === "EN" ? (<p>Test Sentense</p>) : (<p>测试句子</p>)}*/}

          {/* <IntelliProIcon className={styles.IntelliProIcon}/> */}
          {/* <DownloadPdf /> */}
          <div className={styles.searchByBar}>
            <div className={searchBy==="filter"?styles.searchBySelected:styles.searchByOption} onClick={e=>{setSearchBy("filter")}}>  {props.selectedLanguage==="EN"?"Search by Filter":"以特定条件筛选"}</div>
            <div className={searchBy==="scholar"?styles.searchBySelected:styles.searchByOption} onClick={e=>{setSearchBy("scholar")}}>   {props.selectedLanguage==="EN"?"Search by Scholar's Name":"以学者名称检索"}</div>
          </div>
          
            <Filters setGeneral = {setGeneral} setSubject = {setSubject} setName={setName} selectedLanguage={props.selectedLanguage} showRes={handleShowRes} set={setData} setTotal={setTotal} isSearching = {isSearching} setIsSearching = {setIsSearching} searchBy={searchBy} isLoggedIn={isLoggedIn} filter={filter}/>
            {/* : <div className={styles.scholarFilterContainer}>
                <div className={styles.scholarNameFilter}>
                  <div className={ `${selectedLanguage==="EN"?styles.instructBox:styles.instructBoxCN}` }>
                     {selectedLanguage==="EN"?"Enter Scholars' Name":"请输入学者名称"}
                    <img src="/info.svg" className={styles.infoIcon} alt="infoIcon" onMouseEnter={scholarInputPopup} onMouseLeave={closeScholarPopup}/>
                    {scholarPopup && (<div className={  `${selectedLanguage==="EN"?styles.schPopup:styles.schPopupCN } `   }>
                      {selectedLanguage==="EN"?"Please enter only one scholar's name at a time. If you input more than one name, our system will automatically search for the first name you entered."
                      :"请仅输入一个学者名称. 如输入多个名称, 系统将自动以第一个进行检索"}
                      </div>)}
                  </div>
                  <input className={styles.scholarInput} />
                </div>
                <button className={styles.searchNameBtn} onClick={e=>{ console.log(`${selectedLanguage==="EN"?"styles.instructBox":""}` ) }}>{selectedLanguage==="EN"?"Search":"开始搜索"}</button>
                
              </div> */}
          <div className={styles.resultWrap}>
            {showRes && hasDetail &&email !='shirley.tang@intelliprogroup.com' && <Result_test general = {general} subject = {subject} name={name} selectedLanguage={props.selectedLanguage} state={resumeData} test= {test} totalScholars={totalScholars} setIsSearching = {setIsSearching} searchBy={searchBy} isLoggedIn={hasDetail} authLevel={'user'} filter={filter} />}  
            {/* {showRes && ! hasDetail&&<Result_fake general = {general} subject = {subject} name={name} selectedLanguage={props.selectedLanguage} state={resumeData} test= {test} totalScholars={totalScholars} setIsSearching = {setIsSearching} searchBy={searchBy} isLoggedIn={hasDetail} set={setData} setHasDetail={setHasDetail}/>}  */}
            {showRes && email=='shirley.tang@intelliprogroup.com' && email && <Result_test general = {general} subject = {subject} name={name} selectedLanguage={props.selectedLanguage} state={resumeData} test= {test} totalScholars={totalScholars} setIsSearching = {setIsSearching} searchBy={searchBy} isLoggedIn={hasDetail}  authLevel={'admin'}/> }
          </div>

          <Footer selectedLanguage={props.selectedLanguage}/>

        </div>

      </div>
    );
}

export default Home;