import "../../App.css";
import { useEffect, useState,useRef } from "react";
import headshot from "./headshot.svg";
import hint from "./hint.svg";
import styles from "./logedhome.module.css";

import Result from "../Result/Result";
import IntelliProIcon from "../IntelliProIcon/IntelliProIcon";
import Filters from "../Filters/Filters";
import { useNavigate } from "react-router-dom";
import React from "react";
import {Link} from "react-router-dom";
import { ReactComponent as CopyIcon } from "./copy.svg";
import { ReactComponent as SuccessIcon} from "./Success.svg";
import { ReactComponent as HoverCopyIcon }from './copyHover.svg'; // Hover state copy icon
import { ReactComponent as ActiveCopyIcon}from './copy_pressed.svg'; // Active (pressed) state copy icon


// import ResumePage from './component/ResumePage/ResumePage'
// import GeneratePdf from './component/Result/GeneratePdf';
// import ResumeWrapper from './component/ResumePage/ResumeWrapper';
// import data from './component/fake_data12.json'

function LogedHome (props) {
    const navigate = useNavigate();
    const [isSearching, setIsSearching] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Add this state to track login status
    const [showRes, setShowRes] = useState(false);
    const [resumeData, setResumeData] = useState([]);
    const [test, setTest] = useState(true);
    const [totalScholars, setTotalScholars] = useState(-1);
    const [isModalOpen, setIsModalOpen] = useState(false); // Add this state to track modal status
    const [isOutModalOpen, setIsOutModalOpen] = useState(false); // Add this state to track modal status
    const [isHintModalOpen, setIsHintModalOpen] = useState(false); // Add this state to track modal status
    const [linkCopied, setLinkCopied] = useState(false);
    const [isHovered, setIsHovered] = useState(false);//for Copy Icon
    const [isActive, setIsActive] = useState(false);//for Copy Icon

    
    const referralLink = "https://www.refersdfadfadfasdfSdfafsdfasdfas.com"; // Replace with the actual referral link

    function handleShowRes(){
      setShowRes(true);
    }
    // Function to handle login
    const handleLogin = () => {
      // Logic to authenticate user goes here
      setIsLoggedIn(true); // Set login status to true
      console.log("User logged in successfully");
    };

    // Function to toggle the modal
    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };

     const toggleoutModal = () => {
      setIsOutModalOpen(!isOutModalOpen);
    };


    // Create a reference to the modal content to close the reffreal link modal if clicked outside
  const modalContentRef = useRef(null);
  const modalOutContentRef = useRef(null);

  // Function to close the modal if clicked outside
  const handleClickOutside = (e) => {
    if (isModalOpen && modalContentRef.current && !modalContentRef.current.contains(e.target)) {
      toggleModal();
      setLinkCopied(false);
      setIsHovered(false);
      setIsActive(false);
    }

    if (isOutModalOpen && modalOutContentRef.current && !modalOutContentRef.current.contains(e.target)) {
      toggleoutModal();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    copyLink();
    setIsActive(true);
  };


    // Function to handle logout
    const handleLogout = () => {
      // Logic to logout user goes here   
      setIsLoggedIn(false); // Set login status to false

      console.log("User logged out successfully");
      // Redirect to the home page
      navigate('/');
    };

    const toggleLoggout = () => {
      if (isLoggedIn) {
        handleLogout();
      } else {
        handleLogin();
      }
    };


     // Function to toggle the modal
    const toggleHintModal = () => {
      setIsHintModalOpen(!isHintModalOpen);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(referralLink);
        console.log("Referral link copied to clipboard");
        setLinkCopied(true); // Set linkCopied to true when the link is copied
    };

    
    function setTotal(data){
      setTotalScholars(data['total_scholars']);
      console.log("total scholars received");
    }
    function setData(data){
      console.log("600 resumes received");
      props.set(data);
      setResumeData([...data['resumes']]);
      setShowRes(true);
      setIsSearching(false);
    }
    useEffect(() => {
      // console.log(resumeData);
      setTest(!test);
    }, [resumeData]); 
    useEffect(() => {
      if (isSearching) {
        const timer = setTimeout(() => {
          setIsSearching(false);
        }, 24000);
  
        // 如果你的组件在6秒之前被卸载，你需要清除定时器，防止尝试更新已卸载组件的状态
        return () => clearTimeout(timer);
      }
    }, [isSearching]);

    // Add an event listener to the document when the modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  // Add an event listener to the document when the modal is open
  useEffect(() => {
    if (isOutModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOutModalOpen]);


    return(
        <div>
          <div className="navBar">
            <img src={hint} alt="Hint" className= {styles.hintIcon} onMouseEnter={() => setIsHintModalOpen(true)}
          onMouseLeave={() => setIsHintModalOpen(false)}/>
            <div className={styles.getRefer} onClick={toggleModal}> {props.selectedLanguage==="EN"?"Get My Referral Link!":"获取我的推荐链接"}</div>
            {/* <img src={headshot} alt="User Headshot" className="userHeadshot" onClick={toggleoutModal} />  */}
            <div className="avatar">
                <span className="initials">YT</span>
              </div>
            
          </div>

          <IntelliProIcon/>
          <Filters showRes={handleShowRes} set={setData} setTotal={setTotal} isSearching = {isSearching} setIsSearching = {setIsSearching}/>
          {showRes && <Result state={resumeData} test= {test} totalScholars={totalScholars}/>}

           {/* Modal */}
        {isModalOpen && (
        <div className={linkCopied ? styles.modalSmall : styles.modalNormal}>
            <div className={styles.modalContent} ref={modalContentRef}>
            <div className={styles.linkContainer}>
                {linkCopied ? (
                <>
                    <div className={styles.successMessageContainer}>
                      <div className={styles.successMessage}>Link Copied Successfully!</div>
                      <SuccessIcon className={styles.successIcon} /> {/* Replace with the actual success icon */}
                    </div>
                    
                </>
                ) : (
                <>
                    
                   <a href={referralLink} target="_blank" rel="noopener noreferrer" className={styles.referralLink}>{referralLink}</a>
                    <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleClick}
                      >
                        {isHovered ? <HoverCopyIcon /> : isActive ? <ActiveCopyIcon /> : <CopyIcon />}
                        
                      </div>

                </>
                )}
            </div>
            </div>
        </div>
    )}

    {/* LogoutModal */}
    {isOutModalOpen && (
        <div className={`${styles.Logoutmodal} ${isHovered ? styles.logoutModalHover : ''} ${isActive ? styles.logoutModalClick : ''}`}
          ref={modalOutContentRef} 
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={toggleLoggout}
          >
          <h3>Log out</h3>
        </div>
    )}

  
    <div className={styles.Container}>
      {isHintModalOpen && (
        <div className={styles.Hintmodal}>
          <h3>
            “Invite Friends, Share Rewards!”
          </h3>
          <h4>
            Click the button to get your exclusive invitation link. Share it with your friends, and when they join our platform through your link, both you and your friends will receive special referral rewards.
          </h4>
        </div>
      )}
    </div>



  </div>

      
    );
}

export default LogedHome;