import React, { useState, useEffect} from "react";
import { useNavigate,useParams } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';

import styles from "./signUp.module.css"


const SignUpPage = (props) => {
  const navigate = useNavigate();
  const [nameError, setNameError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  console.log("log in Language:", props.selectedLanguage);
  const [titleError, setTitleError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [cpasswordError, setCPasswordError] = useState(false);
  const [TOUError, setTOUError] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidComp, setInvalidComp] = useState(false);
  const [invalidLengthComp, setInvalidLengthComp] = useState(false);
  const [invalidLengthJob, setInvalidLengthJob] = useState(false);
  const [invalidJob, setInvalidJob] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidLengthPW, setInvalidLengthPW] = useState(false);
  const [invalidCPassword, setInvalidCPassword] = useState(false);
  const [emailEmptyError, setEmailEmptyError] = useState(false);
  const [notWorkEmail, setNotWorkEmail] = useState(false);
  const params = useParams();


  const [csrfToken, setCsrfToken] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    title: '',
    company: '',
    password: '',
    confirm_password: '',
    agree_to_terms: false,
    referral_by:'',
    port: '3001',
    language:''
  });
  const [message, setMessage] = useState('');

  // useEffect(() => {
  //   fetch("http://127.0.0.1:8001/register", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //   })
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error("Request failed with status: " + response.status);
  //     }
  //     return response.json();
  //   })
  //   .then(data => {
  //     console.log(data)
  //     setCsrfToken(data.csrf_token);
  //   })
  //   .catch(error => {
  //     console.error("Error fetching CSRF token:", error);
  //   });
  // }, []); 
  function encodeFormData(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");

    setNameError(!formData.name.trim());
    setCompanyError(!formData.company.trim());
    setTitleError(!formData.title.trim());
    setEmailError(!formData.email.trim());
    setEmailEmptyError(!formData.email.trim());
    setPasswordError(!formData.password.trim());
    setCPasswordError(!formData.confirm_password.trim());
    setTOUError(!formData.agree_to_terms);
    setNotWorkEmail(false)
    setInvalidLengthPW(false)
    

    setInvalidName(/[^a-zA-Z\s]/.test(formData.name));
    setInvalidComp(/[^a-zA-Z\s0-9\-.,]/.test(formData.company));
    setInvalidLengthComp(formData.company.length > 40);
    setInvalidLengthJob(formData.title.length > 25);
    setInvalidJob(/[^a-zA-Z\s0-9\-.,]/.test(formData.title));
    setInvalidEmail((formData.email.trim()) && (!formData.email.includes("@") || formData.email.length <= 3 || 
    formData.email.split("@")[0].length === 0 || formData.email.split("@")[1].length === 0));
    setInvalidLengthPW((formData.password.trim()) && (formData.password.length < 8));
    setInvalidCPassword((formData.password.trim()) && (formData.confirm_password.trim()) && (!invalidLengthPW) && (formData.password !== formData.confirm_password));

    if(formData.email.includes('@gmail') || formData.email.includes('@yahoo') || formData.email.includes('@qq')){
      console.log('email',formData.email)
      setNotWorkEmail(true);
      setEmailEmptyError(false)
      setInvalidEmail(false);
      setMessage("Please user work email");
      return;
    }
      if (!formData.name.trim()) {
      setNameError(true);
      setMessage("Fill in the blank");
      return;
    } else if (!formData.company.trim()) {
      setCompanyError(true);
      setMessage("Fill in the blank");
      return;
    } else if (!formData.title.trim()) {
      setTitleError(true);
      setMessage("Fill in the blank");
      return;
    } else if (!formData.email.trim()) {
      setEmailEmptyError(true);
      setMessage("Fill in the blank");
      return;
    } else if (!formData.password.trim()) {
      setPasswordError(true);
      setMessage("Fill in the blank");
      return;
    } else if (!formData.confirm_password.trim()) {
      setCPasswordError(true);
      setMessage("Fill in the blank");
      return;
    // The judging conditions of valid name may be modified later
    } else if (!formData.agree_to_terms) {
      // console.log("Please agree to the terms of use.");
      setTOUError(true);
      setMessage("You must agree with terms");  // Display feedback to the user
      return;
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      setInvalidName(true);
      setNameError(false);
      setMessage("Invaild name");
      return;
    } else if (formData.company.length > 40) {
      setInvalidLengthComp(true);
      setCompanyError(false)
      setMessage("The company's name must not exceed 40 characters");
      return;
    // The judging conditions of valid company name may be modified later
    } else if (/[^a-zA-Z\s0-9\-.,]/.test(formData.company)) {
      setInvalidComp(true);
      setCompanyError(false);
      setMessage("Invalid company name");
      return;
    } else if (formData.title.length > 25) {
      setInvalidLengthJob(true);
      setTitleError(false)
      setMessage("The job title's name must not exceed 25 characters");
      return;
    // The judging conditions of valid job title may be modified later
    } else if (/[^a-zA-Z\s0-9\-.,]/.test(formData.title)) {
      setInvalidJob(true);
      setMessage("Invalid job title");
      return;
    // The judging conditions of valid email may be modified later
    } else if (!formData.email.includes("@") || formData.email.length <= 3 || 
        formData.email.split("@")[0].length === 0 || formData.email.split("@")[1].length === 0) {
      setInvalidEmail(true);
      setEmailEmptyError(false)
      setEmailError(false)
      setMessage("Invalid email address");
      return;
    } else if (formData.password.length < 8) {
      setInvalidLengthPW(true);
      setMessage("Password must be at least 8 characters");
      return;
    } else if (formData.password !== formData.confirm_password) {
      setInvalidCPassword(true);
      // console.log("Password does not match.");
      setMessage("Password does not match");  // Display feedback to the user
      return;  // Don't proceed with registration
    } ;
    if(params.sourcing){
      formData.referral_by=params.sourcing
    }
    formData.language = props.selectedLanguage
    console.log(JSON.stringify(formData))
    // Send the registration data to your backend
    fetch("https://intelliprozenithscholars.com/auth/register", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("Request failed with status: " + response.status);
      }
      return response.json();
    })
    .then(csrfdata => {
      console.log(csrfdata)
      setCsrfToken(csrfdata.csrf_token);
      const formBody = encodeFormData(formData);
      console.log("Data we POST:", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfdata.csrf_token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: formBody
      })
      fetch("https://intelliprozenithscholars.com/auth/register/", {
        method: "POST",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: formBody
      })
      .then(response => {
        if (!response.ok) {
          throw new Error("Registration request failed with status: " + response.status);
        }
        return response.json();
      })
      .then(data => {
        console.log("data: ", data);
        if (data.success) {
          console.log(data.message);
          setMessage(data.message);  // Display success message to the user
          if(params.sourcing){
            referralUpdate()
          }else{
            console.log('no referral')
          }
          navigate('/linkSent', { state: { formData: formData } });
          // Redirect to another page or show a success message, etc.
        } else {
          // Handle error messages sent by the server
          if (data.message.email) {
            setMessage(data.message.email[0]);
            setEmailError(true)
          } else {
            setMessage(data.message);
          }
        }
      })
      .catch(error => {
        console.error("Error during registration:", error);
        setMessage("Error during registration.");  // Display feedback to the user
      });
    })
    .catch(error => {
      console.error("Error fetching CSRF token:", error);
    });
  };

// Function to make the fetch call
async function referralUpdate() {
  const inputData = {
    source_info: params.sourcing,
    email: formData.email,
    name: formData.name,
    company: formData.company,
    job_title: formData.title,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
        body: JSON.stringify(inputData),
  };

  console.log(inputData);

  try {
    const response = await fetch(' http://23.84.177.165:9200/referral', requestOptions);
    const data = await response.json();
    console.log('Response:', data);
    // Handle the response data here
  } catch (error) {
    console.error('Error:', error);
    // Handle any errors here
  }
}




  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    e.preventDefault()
    const checked = e.target.checked;
    console.log('agree_to_terms', checked)
    setFormData(prevData => ({
      ...prevData,
      agree_to_terms: checked,
    }));
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setFormData((prevData) => ({
      ...prevData,
      showPassword: !prevData.showPassword,
      showConfirmPassword: !prevData.showConfirmPassword,
    }));
  };

  const translations = {
    EN: {
     Sign_Up: "Sign Up",
     Full_Name: "Full Name *",
      Company: "Company*",
      Job_Title: "Job Title *",
      Email_Address: "Email Address *",
      Password: "Password *",
      Confirm_Password: "Confirm Password *",
      Agree_to_terms: "I agree to the terms of use",
      Already_have_an_account: "Already have an account?",
      Sign_In: "Create Account",
      Log_In: "Log In",
      agree_to_terms: "By selecting 'Create Account, you are",
      terms: "term of use.",

    },
    CN: {
      Sign_Up: "注册",
      Full_Name: "姓名 *",
      Company: "公司 *",
      Job_Title: "职位 *",
      Email_Address: "邮箱地址 *",
      Password: "\t\t\t密码 *",
      Confirm_Password: " 确认密码 *",
      Agree_to_terms: "我已阅读并同意",
      Already_have_an_account: "已有账号？",
      Sign_In: "创建账号",
      Log_In: "直接登录",
      agree_to_terms: "我已阅读并同意",
      terms: "使用条款.",

    },
    // You can add more languages here
  };

  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };
  
  const SelectedOptionWithSVG = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px',paddingTop:'7px'}} />
      {label==='English' ? (
        <span style={{ paddingTop: '4px' }}>EN</span>
      ) : (
        <span style={{ paddingTop: '4px' }}>CN</span>
      )}
    </div>
  );

  const options2 = [
    { value: 'EN', label: 'English' },
    { value: 'CN', label: '中文(简体)' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

  const handleSelectChange2 = (selectedOption) => {
    setSelectedLanguage(selectedOption.value); // Update
    props.setSelectedLanguage(selectedOption.value); 
    if (selectedOption.value === 'EN') {
      // Language is set to English
    } else if (selectedOption.value === 'CN') {
      // Language is set to Chinese
    }
  }

  const customSelect2 = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: '#377DED',
        border: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none', 
        boxShadow: 'none', 
        minWidth: '130px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display:'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      transition: 'color 0.2s',
      color: '#377DED',
      fontSize: state.isFocused ? '18px' : '16px',
      position: 'relative',
      "&:hover": {
        color: "#2149B1",
        fontSize: 18,
        //borderBottom: "2px solid #2149B1",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Sofia Pro',
      fontSize: 16,
      color: state.isSelected ? '#FFFFFF' : '#377DED',
      cursor: 'pointer',
      textAlign: 'left',
      "&:active": {
        color: '#FFFFFF',
        background: '#377DED',
      },
      "&:focus": {
        outline: 'none',
      },
      display: 'flex',
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: 0,
      position: 'absolute',
    }),
  };

  const PlaceholderImageEN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px'}} />
      <span>EN</span>
    </div>
  );

  const PlaceholderImageCN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px'}} />
      <span>CN</span>
    </div>
  );
  const handleSignUpClick = () => {
      if(params.sourcing){
        console.log(params.sourcing)
        navigate(`/login/${params.sourcing}`);
      }else{
        navigate('/login')
      } 
  };
  const handleTermsClick = () => {
    localStorage.setItem('selectedLanguage', props.selectedLanguage)
    window.open('/TermOfUse')
  };


const handlePrivacyClick = () => {
  localStorage.setItem('selectedLanguage', props.selectedLanguage)
  window.open('/privacy')
};

const handleLanding = () => {
  if(params.sourcing){
    console.log(params.sourcing)
    navigate(`/landing/${params.sourcing}`);
  }else{
    navigate('/')
  }  };
  
  const handleSentLinkClick = () => {
    navigate('/linkSent');
  };

  
  return (
    <div>
      <div className={styles.LoginPage}>
        <div className={styles.LeftContainer}>
          <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
          {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
          {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
        </div>
        <div className= {styles.backhome}>
          <img src="/backhome.svg" alt="back" className= {styles.backtohome}  onClick={handleLanding}/>
        </div>

        <div className={styles.langDrop}>
            <Select
              options={options2}
              onChange={handleSelectChange2}
              isSearchable={false} // This will disable user input
              placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
              components={{
                SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
              }}
              styles={customSelect2}
            />
        </div>
        
        <div className={styles.RightContainer}>
          <div className={styles.start}>
          <h2 className={props.selectedLanguage==="CN"?styles.signUpWordCN:styles.signUpWord}>{t('Sign_Up')}</h2>
          <form onSubmit={handleSubmit} className={styles.subList} noValidate>
            
            {/* Full Name */}
            <div className={`${styles.formGroup} 
            ${(nameError) || (invalidName) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l1CN:styles.l1}>{t('Full_Name')}</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            {(nameError) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidName) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Invaild name</p>}
            {(nameError) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>请输入字段</p>}
            {(invalidName) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>姓名格式不正确</p>}

            {/* Company */}
            <div className={`${styles.formGroup} 
            ${(companyError) || (invalidLengthComp) || (invalidComp) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l2CN:styles.l2}>{t('Company')}</label>
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
            </div>
            {(companyError) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidLengthComp) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>The company's name must not exceed 40 characters</p>}
            {(invalidComp) &&  props.selectedLanguage=='EN'&& <p className={styles.errorMessagePW}>Invalid company name</p>}

            {(companyError) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>请输入字段</p>}
            {(invalidLengthComp) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>公司名称字数不能超过40个字符</p>}
            {(invalidComp) &&  props.selectedLanguage=='CN'&& <p className={styles.errorMessagePW}>公司名称格式不正确</p>}
          
            {/* Job Title */}
            <div className={`${styles.formGroup} 
            ${(titleError) || (invalidLengthJob) || (invalidJob) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l3CN:styles.l3}>{t('Job_Title')}</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            {(titleError) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidLengthJob) && props.selectedLanguage=='EN'&& <p className={styles.errorMessagePW}>The job title's name must not exceed 25 characters</p>}
            {(invalidJob) && props.selectedLanguage=='EN'&& <p className={styles.errorMessagePW}>Invalid job title</p>}

            {(titleError) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>请输入字段</p>}
            {(invalidLengthJob) && props.selectedLanguage=='CN'&& <p className={styles.errorMessagePW}>职位名称字数不能超过25个字符</p>}
            {(invalidJob) && props.selectedLanguage=='CN'&& <p className={styles.errorMessagePW}>职位名称格式不正确</p>}
        
            {/* Work Email Address */}
            <div className={`${styles.formGroup} 
            ${(emailError) || (invalidEmail) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l4CN:styles.l4}>{t('Email_Address')}</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            {(emailError) && message =='This email is already in use.'&& props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>{message}</p>}
            {(emailEmptyError) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidEmail) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Invalid email address</p>}
            {(notWorkEmail) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Please use work email</p>}


            {(emailError) && props.selectedLanguage=='CN'&& message =='This email is already in use.' && <p className={styles.errorMessagePW}>此邮箱已被使用</p>}
            {/* {(emailError) && props.selectedLanguage=='CN'&& message == 'Fill in the blank' && <p className={styles.errorMessagePW}>请填写此字段</p>} */}
            {(emailEmptyError) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>请输入字段</p>}
            {(invalidEmail) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>邮箱地址格式不正确</p>}
            {(notWorkEmail) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>请使用工作邮箱</p>}


            {/* Password */}
            <div className={`${styles.formGroup} 
            ${(passwordError) || (invalidLengthPW) || (invalidCPassword) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l5CN:styles.l5}>{t('Password')}</label>
              <div className={props.selectedLanguage==="CN"?styles.inputContainerCN:styles.inputContainer}>
                <input
                  type={formData.showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={props.selectedLanguage==="CN"?styles.inputCN:styles.inputEN}

                  // className={styles.inputField}
                />
                <button
                  className={styles.toggleButton}
                  onClick={togglePasswordVisibility}
                >
                  {formData.showPassword  ? (
                    <img src="/openEye.svg" alt="Show" className={props.selectedLanguage==="CN"?styles.eyesCN:styles.eyes} />
                    
                  ) : (
                    <img src="/closeEye.svg" alt="Hide"  className={props.selectedLanguage==="CN"?styles.eyesCN:styles.eyes} />
                  )}

                </button>
              </div>
            </div>
            {(passwordError) &&props.selectedLanguage=='EN'&& <p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidLengthPW) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Password must be at least 8 characters</p>}
            {(invalidCPassword) &&  (!invalidLengthPW) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Password does not match</p>}

            {(passwordError) &&props.selectedLanguage=='CN'&& <p className={styles.errorMessagePW}>请输入字段</p>}
            {(invalidLengthPW) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>密码必须至少8个字符</p>}
            {(invalidCPassword) && (!invalidLengthPW) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>密码不一致</p>}
        
            {/* Confirm Password */}
            <div className={`${styles.formGroup} 
            ${(cpasswordError) || (invalidCPassword) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l6CN:styles.l6}>{t('Confirm_Password')}</label>
              <div className={styles.inputContainer}>
                <input
                  type={formData.showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                />
                <button
                  className={props.selectedLanguage==="CN"?styles.toggleButton2CN:styles.toggleButton}
                  onClick={togglePasswordVisibility}
                >
                  {formData.showConfirmPassword ? (
                    <img src="/openEye.svg" alt="Show" className={styles.eyes} />
                  ) : (
                    <img src="/closeEye.svg" alt="Hide" className={styles.eyes} />
                  )}
                </button>
              </div>
            </div>
            {(cpasswordError) &&props.selectedLanguage=='EN'&& <p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidCPassword) && (!invalidLengthPW) && props.selectedLanguage=='EN'&&<p className={styles.errorMessagePW}>Password does not match</p>}
            {(cpasswordError) &&props.selectedLanguage=='CN'&& <p className={styles.errorMessagePW}>请输入字段</p>}
            {(invalidCPassword) && (!invalidLengthPW) && props.selectedLanguage=='CN'&&<p className={styles.errorMessagePW}>密码不一致</p>}

            {props.selectedLanguage=="EN" && <div className={props.selectedLanguage==="CN" ?styles.formGroup2CN:styles.formGroup2}>
              <label className={props.selectedLanguage==="CN"?styles.termlabelCN:styles.termlabel}>
              <input
                type="checkbox"
                name = "agree_to_terms"
                checked={formData.agree_to_terms}
                onChange={handleCheckboxChange}
              />
              I confirm that I have read and agree<br/>to the <span className={styles.terms} onClick={handleTermsClick}>term of use</span> and <span className={styles.privacy} onClick={handlePrivacyClick}>privacy policy</span>.
              </label>
            </div>
            }


            
           {props.selectedLanguage=="CN" && <div className={props.selectedLanguage==="CN" ?styles.formGroup2CN:styles.formGroup2}>
              <label className={props.selectedLanguage==="CN"?styles.termlabelCN:styles.termlabel}>
              <input
                type="checkbox"
                name = "agree_to_terms"
                checked={formData.agree_to_terms}
                onChange={handleCheckboxChange}
              />
              我已阅读并同意<span className={styles.terms} onClick={handleTermsClick}>用户须知</span>和 <span className={styles.privacy} onClick={handlePrivacyClick}>隐私政策</span>。
              </label>
            </div>
            }
{/* <<<<<<< HEAD
            {message==="Please agree to the terms of use" && <p className={styles.errorMessagePW}>{message}</p>}
        
            <button
            type="submit" className={props.selectedLanguage==="CN"?styles.ccbuttonCN:styles.ccbutton}>{t("Sign_In")}</button>
======= */}
            {(TOUError) && props.selectedLanguage=='EN'&& <p className={styles.errorMessagePW}>You must agree with terms</p>}
            {/* && message==="You must agree with terms" */}

            <button type="buttom" onClick={handleSubmit} className={props.selectedLanguage==="CN"?styles.ccbuttonCN:styles.ccbutton}>{t("Sign_In")}</button>
{/* >>>>>>> main */}

            <div className={props.selectedLanguage==="CN"?styles.signInLinkCN:styles.signInLink}>
              {t('Already_have_an_account')} <a onClick={handleSignUpClick}>{t('Log_In')}</a>
            </div>
      
          </form>
        </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;