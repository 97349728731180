import React from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import styles from "./WeChat.module.css";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';


function WeChat_login(props){
    const navigate = useNavigate();
    
return(
    <div className={styles.container}>
        <div className={styles.LeftContainer}>
            <img src="/hero.png" alt="bgi" className={styles.BackgroundImg} />
        </div>
        <div className={styles.RightContainer}>
        <h2>Welcome</h2>
        <div className={styles.buttonContainer}>
            <img src="/Wechat.png" alt="bgi" className={styles.WeChatIcon}  />
            <button className={styles.button} onClick={e=>{navigate('/wechatQR')}}>Continue with WeChat</button>

        </div>
        </div>
    </div>
)
}
export default WeChat_login;