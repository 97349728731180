import React, { useState } from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import { ReactComponent as BackImg } from "./assets/back.svg";
import styles from "./newPW.module.css";
import {  useNavigate , useParams } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';

const NewPWPage = (props) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordEmpty, setPasswordEmpty] = useState(false);
    const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [samePassword,setSamePassword] = useState(false)
    const [invaldLength,setInvaldLength] = useState(false)
    const [message, setMessage] = useState("");
    const { id } = useParams();
    const { token } = useParams();
    const navigate = useNavigate();

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirmPasswordToggle = () => {
        setShowPassword(!showPassword); // Synchronize the showPassword state for both fields
    };

    const handleResetPassword = () => {
        setPasswordsMatch(true); // Reset the passwords match state
        setPasswordEmpty(false);
        setConfirmPasswordEmpty(false);
        setSamePassword(false)
        setInvaldLength(false)
    
        if ((!password) && (!confirmPassword)) {
            setPasswordEmpty(true);
            setConfirmPasswordEmpty(true);
            return;
        } else if (!password) {
            setPasswordEmpty(true);
            return;
        } else if (!confirmPassword) {
            setConfirmPasswordEmpty(true);
            return;
        }

        if(password.length<8){
            setInvaldLength(true);
            return
        }
    
        if (!password || !confirmPassword) {
            return; // Don't proceed if either field is empty
        }
    
        if (password !== confirmPassword) {
            setPasswordsMatch(false);
            return; // Don't proceed if passwords don't match
        }
        fetch("https://intelliprozenithscholars.com/auth/register", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            })
            .then(response => {
            if (!response.ok) {
                throw new Error("Request failed with status: " + response.status);
            }
            return response.json();
            })
            .then(csrfdata => {

                const csrftoken = csrfdata.csrfToken; 
                
                fetch(`https://intelliprozenithscholars.com/auth/resetpwd/`, {
                    method: 'POST',
                    headers: { 
                    'X-CSRFToken': csrftoken,
                    'Content-Type': 'application/x-www-form-urlencoded' 
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        "id":id,
                        "password": password,
                        "token": token,
                        "port":"3001"
                    })
                })
                .then(response => {
                    if (!response.ok) {
                    throw new Error("Reset password request failed with status: " + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    if (data.message ==="successfully reset password") {
                        console.log(data.message);
                        navigate('/successPW');
                    }
                    else{
                        setMessage(data.message); 
                        setSamePassword(true)
                    }
                })
                .catch(error =>{
                    console.error("Error during resend:", error);
                    setMessage("Error during resend.");  // Display feedback to the user
                });
            })
            .catch(error => {
                console.error("Error fetching CSRF token:", error);
            })
    };
   
    const translations = {
        EN: {
            newPW: "New Password",
            reset: "Please reset your password",
            newPW2: "New Password ",
            confirmPW: "Confirm Password",
            resetPW: "Reset Password",
            newUser: "New user?",
            signIn: "Sign in here",
        },
        CN: {
            newPW: "重置密码",
            reset: "请重置您的密码",
            newPW2: "新密码",
            confirmPW: "确认密码",
            resetPW: "重置",
            newUser: "新用户?",
            signIn: "点击注册",
        },
        // You can add more languages here
      };
    
      const t = (key) => {
        return translations[props.selectedLanguage][key] || key;
      };
    
      const SelectedOptionWithSVG = ({ label }) => (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-20px' }}>
          <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px'}} />
          {label==='English' ? (
            <span style={{ paddingTop: '2px' }}>EN</span>
          ) : (
            <span style={{ paddingTop: '2px' }}>CN</span>
          )}
        </div>
      );
    
      const options2 = [
        { value: 'EN', label: 'English' },
        { value: 'CN', label: '中文(简体)' },
      ];
    
      const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language
    
      const handleSelectChange2 = (selectedOption) => {
        setSelectedLanguage(selectedOption.value); // Update
        props.setSelectedLanguage(selectedOption.value); 
        if (selectedOption.value === 'EN') {
          // Language is set to English
        } else if (selectedOption.value === 'CN') {
          // Language is set to Chinese
        }
      }
    
      const customSelect2 = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: 'Sofia Pro',
            fontSize: 16,
            color: '#377DED',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            border: 'none', 
            boxShadow: 'none', 
            minWidth: '130px',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display:'none',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          transition: 'color 0.2s',
          color: '#377DED',
          fontSize: state.isFocused ? '18px' : '16px',
          position: 'relative',
          "&:hover": {
            color: "#2149B1",
            fontSize: 18,
            //borderBottom: "2px solid #2149B1",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          fontFamily: 'Sofia Pro',
          fontSize: 16,
          color: state.isSelected ? '#FFFFFF' : '#377DED',
          cursor: 'pointer',
          textAlign: 'left',
          "&:active": {
            color: '#FFFFFF',
            background: '#377DED',
          },
          "&:focus": {
            outline: 'none',
          },
          display: 'flex',
          marginTop:'8px',
          marginBottom:'8px'
        }),
        menu: (provided) => ({
          ...provided,
          border: 'none',
          borderRadius: '10px',
          boxShadow: 'none',
          marginTop: 0,
          position: 'absolute',
          width:'150px',
          marginLeft:'10px'
        }),
      };
    
// <<<<<<< HEAD
//         const PlaceholderImageEN = () => (
//             <div style={{display: 'flex', alignItems: 'center' }}>
//             <span style={{ marginRight: '5px' }}>English</span>
//             <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '5px' }} />
//             </div>
//         );

//         const PlaceholderImageCN = () => (
//             <div style={{display: 'flex', alignItems: 'center' }}>
//             <span style={{ marginRight: '10px' }}>Chinese (中文)</span>
//             <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '0px' }} />
//             </div>
//         );
//         const handleSignUpClick = () => {
//             navigate('/signup');
//         };
// =======
      const PlaceholderImageEN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>EN</span>
        </div>
      );
    
      const PlaceholderImageCN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>CN</span>
        </div>
      );

    return (
        <div className={props.selectedLanguage==="CN"?styles.newPWFormCN:styles.newPWForm}>
            {/* <div className={styles.LeftContainer}>
                    <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
            </div> */}

            <div className={styles.logo}>
              {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
              {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
              {/* <img src="/backlogin.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/> */}
              {props.selectedLanguage=='EN' &&  <img src="/backlogin.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/>}
            {props.selectedLanguage=='CN' &&  <img src="/backcn.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/>}
            </div>
            
            <div className={styles.langDrop}>
                    <Select
                        options={options2}
                        onChange={handleSelectChange2}
                        isSearchable={false} // This will disable user input
                        placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                        components={{
                            SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                        }}
                        styles={customSelect2}
                    />
            </div>

            <div className={styles.Form}>
                {/* <a href="/forgetPW">
                    <BackImg className={styles.backImg} />
                </a> */}
                <h2>{t('newPW')}</h2>                 
                <h4>{t('reset')}</h4>                
                <div className={props.selectedLanguage ==="CN"?styles.passwordinputFieldCN:styles.passwordinputField}>  
                    <h3>{t('newPW2')}</h3>                              
                    <div className={`${styles.inputContainer} 
                    ${(passwordEmpty) || (!passwordsMatch) ? styles.errorGroup : ""}`}>
                        <input
                            className={styles.input}
                            // className={props.selectedLanguage ==="CN"?styles.inputCN:styles.input}
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            className={styles.toggleButton}
                            onClick={handlePasswordToggle}
                        >
                            {showPassword ? (
                                <img src="/openEye.svg" alt="Show" className={styles.eyes} />
                            ) : (
                                <img src="/closeEye.svg" alt="Hide" className={styles.eyes} />
                            )}
                        </button>
                    </div>
                    {passwordEmpty && props.selectedLanguage== 'EN' && <p className={styles.errorMessagePW}>Fill in the blank</p>}
                    {!passwordsMatch && props.selectedLanguage== 'EN' &&<p className={styles.errorMessagePW4}>Passwords do not match</p>}
                    {samePassword && props.selectedLanguage== 'EN' && <p className={styles.errorMessagePW5}>You use the same password</p>}
                    {invaldLength && props.selectedLanguage== 'EN' &&<p className={styles.errorMessagePW12}>Password must be at least 8 characters</p>}

                    {passwordEmpty && props.selectedLanguage== 'CN' && <p className={styles.errorMessagePW11}>请输入字段</p>}
                    {!passwordsMatch && props.selectedLanguage== 'CN' &&<p className={styles.errorMessagePW9}>密码不一致</p>}
                    {samePassword && props.selectedLanguage== 'CN' && <p className={styles.errorMessagePW7}>与旧密码相同</p>}
                    {invaldLength && props.selectedLanguage== 'CN' &&<p className={styles.errorMessagePW13}>密码必须至少8个字符</p>}

                </div>
                <div className={`${props.selectedLanguage ==="CN"?styles.confirminputFieldCN:styles.confirminputField} 
                    ${(confirmPasswordEmpty) || (!passwordsMatch) ? styles.errorGroup : ""}`}>
                    <h3>{t('confirmPW')}</h3>
                    <div className={styles.inputContainer}>
                        <input
                            className={styles.input}
                            type={showPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button
                            className={styles.toggleButton2}
                            onClick={handleConfirmPasswordToggle}
                        >
                            {showPassword ? (
                                <img src="/openEye.svg" alt="Show" className={styles.eyes} />
                            ) : (
                                <img src="/closeEye.svg" alt="Hide" className={styles.eyes} />
                            )}
                        </button>
                    </div>
                    {confirmPasswordEmpty && props.selectedLanguage== 'EN' &&<p className={styles.errorMessagePW2}>Fill in the blank</p>}
                    {!passwordsMatch && props.selectedLanguage== 'EN' &&<p className={styles.errorMessagePW3}>Passwords do not match</p>}
                    {samePassword && props.selectedLanguage== 'EN' &&<p className={styles.errorMessagePW6}>You use the same password</p>}

                    {passwordEmpty && props.selectedLanguage== 'CN' && <p className={styles.errorMessagePW}>请输入字段</p>}
                    {!passwordsMatch && props.selectedLanguage== 'CN' &&<p className={styles.errorMessagePW10}>密码不一致</p>}
                    {samePassword && props.selectedLanguage== 'CN' && <p className={styles.errorMessagePW8}>与旧密码相同</p>}
                    


                </div>
                <button className={props.selectedLanguage ==="CN"?styles.sendButtonCN:styles.sendButton} onClick={handleResetPassword}>{t('resetPW')}</button>
{/* <<<<<<< HEAD
                <div className={styles.newUserContainer}>
                    <div className={styles.newUser}>{t('newUser')}</div>
                    <div className={props.selectedLanguage === "CN" ?styles.signupHereCN:styles.signupHere} onClick={handleSignUpClick}>
                        {t('signIn')}
                </div>
        </div>
======= */}
                {/* <div className={styles.newUserContainer}> */}
                    <div className={props.selectedLanguage ==="CN"?styles.newUserCN:styles.newUser}>{t('newUser')}</div>
                    <div className={props.selectedLanguage ==="CN"?styles.signupHereCN:styles.signupHere}>
                        <a href="/signUp">{t('signIn')}</a>
                    </div>
                {/* </div> */}
                

            </div>
        </div>
    );
}

export default NewPWPage;