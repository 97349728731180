import React, { useState } from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import { ReactComponent as BackImg } from "./assets/back.svg";
import styles from "./forgetPW.module.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';

const ForgetPWPage = (props) => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate(); // Initialize useNavigate
    const [emailEmpty, setEmailEmpty] = useState(false);
    const formData = new URLSearchParams();
    formData.append("port","3001");
    const [message, setMessage] = useState("");

    const translations = {
    EN: {
        Forget: "Forget Password",
        enter: "Enter the work email address associated with your account",
        email: "Work Email Address",
        sent:"Send",
        newUser:"New User?",
        signUp:"Sign up here",
        error1: 'Invalid email address',
        blank: 'Fill in the blank',
        notExist: 'This account does not exist',
    },
    CN: {
        Forget: "忘记密码",
        enter: "输入与您的帐户关联的邮箱地址",
        email: "邮箱",
        sent:"发送",
        newUser: "新用户？",
        signUp:"点击注册",
        error1:"此邮件地址无效",
        blank: '请填写此字段',
        notExist: '此账号不存在',
    },
  };

    const submit = async (e) => {
        console.log('submit', email);
        if(e){ e.preventDefault(); }

        if (!email) {
            setEmailEmpty(true);
            setMessage('Fill in the blank');
            return;
        } else if (!email.includes("@") || email.length <= 3 || 
        email.split("@")[0].length === 0 || email.split("@")[1].length === 0) {
            setMessage('Invalid email address');
            return;
        } else {
            setMessage("");
            setEmailEmpty(false);
        }
        
        fetch("https://intelliprozenithscholars.com/auth/register", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
        })
        .then(response => {
        if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
        }
        return response.json();
        })
        .then(csrfdata => {

            const token = csrfdata.csrfToken; 
            fetch(`https://intelliprozenithscholars.com/auth/verifyEmail/${email}`, {
                method: 'POST',
                headers: { 
                'X-CSRFToken': token,
                'Content-Type': 'application/x-www-form-urlencoded' 
                },
                credentials: 'include',
                body: formData.toString()
            })
            .then(response => {
                if (!response.ok) {
                  throw new Error("Verify email request failed with status: " + response.status);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (data.success) {
                    console.log(data.message);
                    setMessage(data.message);  // Display success message to the user
                    navigate('/linkSentPW', {state: {email: email}});
                }
                else{
                    setMessage(data.message); 
                    if (data.message==="the state of the user is:not exist") {
                        setMessage('This account does not exist');
                    }
                    else{
                        setMessage(data.message);
                    }
                }
            })
            .catch(error =>{
                console.error("Error during resend:", error);
                setMessage("Error during resend.");  // Display feedback to the user
            });
        })
        .catch(error => {
            console.error("Error fetching CSRF token:", error);
        })
    };

    const handleBackClick = () => {
        navigate("/login"); // Navigate to /login
    };


  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };
  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const SelectedOptionWithSVG = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-100px' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '452px'}} />
      {label==='English' ? (
        <span style={{ paddingTop: '2px' }}>EN</span>
      ) : (
        <span style={{ paddingTop: '2px' }}>CN</span>
      )}
    </div>
  );

  const options2 = [
    { value: 'EN', label: 'English' },
    { value: 'CN', label: '中文(简体)' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

  const handleSelectChange2 = (selectedOption) => {
    setSelectedLanguage(selectedOption.value); // Update
    props.setSelectedLanguage(selectedOption.value); 
    if (selectedOption.value === 'EN') {
      // Language is set to English
    } else if (selectedOption.value === 'CN') {
      // Language is set to Chinese
    }
  }

  const customSelect2 = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: '#377DED',
        border: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none', 
        boxShadow: 'none', 
        minWidth: '30px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display:'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      transition: 'color 0.2s',
      color: '#377DED',
      fontSize: state.isFocused ? '18px' : '16px',
      position: 'relative',
      "&:hover": {
        color: "#2149B1",
        fontSize: 18,
        //borderBottom: "2px solid #2149B1",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Sofia Pro',
      fontSize: 16,
      color: state.isSelected ? '#FFFFFF' : '#377DED',
      cursor: 'pointer',
      textAlign: 'left',
      "&:active": {
        color: '#FFFFFF',
        background: '#377DED',
      },
      "&:focus": {
        outline: 'none',
      },
      display: 'flex',
      marginTop:'8px',
      marginBottom:'8px'
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: "-20px",
      position: 'absolute',
      width:'150px',
      marginLeft:'450px'
    }),
  };
  

  const PlaceholderImageEN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
        <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '452px' }} />
        <span>EN</span>
    </div>
  );

  const PlaceholderImageCN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
        <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '452px' }} />
        <span>CN</span>
    </div>
  );

    return(
        <div className={props.selectedLanguage==="CN"?styles.forgetPWFormCN:styles.forgetPWForm}>
            {/* <div className={styles.LeftContainer}>
                <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
            </div> */}

            <div className={styles.logo}>
              {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
              {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage=='EN' &&  <img src="/backlogin.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/>}
            {props.selectedLanguage=='CN' &&  <img src="/backcn.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/>}
            </div>
            <div className={styles.langDrop}>
                    <Select
                        options={options2}
                        onChange={handleSelectChange2}
                        isSearchable={false} // This will disable user input
                        placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                        components={{
                            SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                        }}
                        styles={customSelect2}
                    />
            </div>

            <div className={`${styles.Form} 
            ${(emailEmpty) || (message===t('error1')) || (message===t('notExist')) ? styles.errorGroup : ""}`}>

                {/* <div className={styles.backButton} onClick={handleBackClick}>
                  <BackImg className={styles.backImg} />
                </div>            */}

                <h2>{t('Forget')}</h2>
                <h4>{t('enter')}</h4>

                <div className={styles.EmailinputField}>
                    <h3>{t('email')}</h3>
                    <input
                        className={styles.input}
                        type="email" // Changed to "email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={ e => e.key ==='Enter'? submit():''}
                    />
                    {console.log('message',message)}
                    {message==='Fill in the blank' && props.selectedLanguage=='EN' && <p className={styles.errorMessagePW}>{message}</p>}
                    {message==='Invalid email address' &&  props.selectedLanguage=='EN' && <p className={styles.errorMessagePW}>{message}</p>}
                    {message==='This account does not exist' &&  props.selectedLanguage=='EN' && <p className={styles.errorMessagePW}>{message}</p>}

                    {message==='Fill in the blank' && props.selectedLanguage=='CN' &&  <p className={styles.errorMessagePW}>请输入字段</p>}
                    {message==='Invalid email address' &&  props.selectedLanguage=='CN' && <p className={styles.errorMessagePW}>此邮箱地址无效</p>}
                    {message==='This account does not exist' &&  props.selectedLanguage=='CN' && <p className={styles.errorMessagePW}>此账号不存在</p>}
                </div>

                <button onClick={submit} className={styles.sendButton}>{t('sent')}</button>
                <div className={styles.newUserContainer}>
                    <div className={styles.newUser}>{t('newUser')}</div>
                    <div className={props.selectedLanguage === "CN" ?styles.signupHereCN:styles.signupHere} onClick={handleSignUpClick}>
                        {t('signUp')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPWPage;
