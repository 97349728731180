// npm install react-router-dom
import ReactDOM from 'react-dom';
import styles from './Result.module.css'
import { useState, useEffect } from "react"
// npm i --save react-select
// npm install react-select
import React from "react";
import Select from "react-select";
// Scholar PDFs
// import { Link } from "react-router-dom";

// Data imported
// import data from '../fake_data12.json'


// Pagination
import { Link, Routes } from "react-router-dom";
import { BrowserRouter, Route} from 'react-router-dom';
import GeneratePdf from './GeneratePdf';
import ResumePage from '../ResumePage/ResumePage';
import ResumeDetailPage from '../ResumePage/ResumeDetail';
// import data from '../fake_data12.json'

//import request from '../request.json';
import request from '../sampleResponse.json'
import PieGenerator from '../ResumePage/chart/PieGenerator';
import BarGenerator from '../ResumePage/chart/BarGenerator';




function Result(props) {
    // State to store the selected sorting option
    const [selectedOption, setSelectedOption,selectedLanguage] = useState({
        label: "Recommendation",
        value: "Option5",
    });

    // State declarations for data and originalData
    const [data, setData] = useState(props.state);
    const [originalData, setOriginalData] = useState(props.state);

    const [currentPage, setCurrentPage] = useState(1); // Current page number

    // Function to handle the sorting based on the selected option
    const sortData = (option) => {
        const selectedValue = option.value;
        let sortedData;

        if (selectedValue === "Option5") {
            // Use the original data when "Recommendation" is selected
            sortedData = [...originalData];
        } else {
            sortedData = [...data];
            switch (selectedValue) {
                case "Option1": // Number of Citations (High to Low)
                    sortedData = sortedData.sort((a, b) => b.total_citation - a.total_citation);
                    break;
                case "Option2": // Number of Citations (Low to High)
                    sortedData = sortedData.sort((a, b) => a.total_citation - b.total_citation);
                    break;
                case "Option3": // Number of Publications (High to Low)
                    sortedData = sortedData.sort((a, b) => b.total_pub - a.total_pub);
                    break;
                case "Option4": // Number of Publications (Low to High)
                    sortedData = sortedData.sort((a, b) => a.total_pub - b.total_pub);
                    break;
                default:
                    break;
            }
        }
        setData([...sortedData]);
        setCurrentPage(1); // Reset to the first page after sorting
    };

    // Update the state when props.state changes
    useEffect(() => {
        setData(props.state);
        setOriginalData(props.state);
        // localStorage.setItem('resumeAll', JSON.stringify(props.state));
    }, [props.state]);

    // Data imported
    const nameList = data.map(item => item.name);
    const jobTitle = data.map(item => item.title);
    const company = data.map(item => item.employer);

    const getAfterLastComma = (str) => {
        const lastCommaIndex = str.lastIndexOf(', ');
        if (lastCommaIndex !== -1) {
            return str.substring(lastCommaIndex + 2);
        }
        return str;
    };

    function getJobComp(nameList, jobTitle, company, currentPage, cardsPerPage) {
        const startIndex = (currentPage - 1) * cardsPerPage;
        const endIndex = startIndex + cardsPerPage;
        const JobComp = nameList.slice(startIndex, endIndex).map((name, index) => {
            const currentJob = jobTitle[index + startIndex];
            const currentComp = company[index + startIndex];
            const JobCompList = [currentJob, currentComp];
            return JobCompList;
        });
        return JobComp;
    }

    function truncateName(name, maxChars) {
        if (name.length > maxChars) {
          return name.slice(0, maxChars) + '...';
        } else {
          return name;
        }
    }

    // paper
    const subject = data.map(item => item.subject);
    const paper = subject.map(item => item.paper);
    {/* const biochem = Object.keys(paper[0])[0]; */}

    // Calculate the Top6 Topics for Scholars
    function calculatePaperValues(nameList, subject, currentPage, cardsPerPage) {
        // Calculate the start and end indexes for the current page
        const startIndex = (currentPage - 1) * cardsPerPage;
        const endIndex = startIndex + cardsPerPage;
        const PaperValues = nameList.slice(startIndex, endIndex).map((name, index) => {
          const currentSubject = subject[index + startIndex];
          const currentPaper = Object.keys(currentSubject)[0];
          const subjectData = currentSubject[currentPaper];
          const paperSums = Object.entries(subjectData).map(([paper, data]) => {
            const { Q1 = 0, Q2 = 0, "Q3&4": Q34 = 0 } = data;
            return {
              paper,
              sum: Q1 + Q2 + Q34,
            };
          });
          paperSums.sort((a, b) => b.sum - a.sum);
          const top4Papers = paperSums.slice(0, 6);
          const firstPaper = paperSums.length > 0 ? paperSums[0].paper : '';
          const secondPaper = top4Papers.length > 1 ? top4Papers[1].paper : '';
          const thirdPaper = top4Papers.length > 2 ? top4Papers[2].paper : '';
          const fourthPaper = top4Papers.length > 3 ? top4Papers[3].paper : '';
          const fifthPaper = top4Papers.length > 4 ? top4Papers[4].paper : '';
          const sixthPaper = top4Papers.length > 5 ? top4Papers[5].paper : '';
          const PaperList = [firstPaper, secondPaper, thirdPaper, fourthPaper, fifthPaper, sixthPaper];
          return PaperList;
        });
        return PaperValues;
      }

    // LineLength * 2 + 2 char means #s of chars in one line inside Topics
    const LineLength = 15;
    // 5 Functions to control which topics to show in each line
    const PrintTopic = (str1, str2) => {
        if (str1.length <= LineLength && str2.length <= LineLength) {
            return [str1, str2];
        } else {
            return [str1, ''];
        }
    };
    const PrintedOrNot = (str1, str2) => {
        if (str1.length <= LineLength && str2.length <= LineLength) {
            return true;
        } else {
            return false;
        }
    };
    const Print2Line = (str1, str2, str3, str4) => {
        if (PrintedOrNot(str1, str2)) {
            return PrintTopic(str3, str4);
        } else {
            return PrintTopic(str2, str3);
        }
    };
    const PrintedOrNot2 = ([str1, str2]) => {
        if (str2=='') {
            return false;
        } else {
            return true;
        }
    };
    const Print3Line = (str1, str2, str3, str4, str5, str6) => {
        if (PrintedOrNot(str1, str2)) {
            if (PrintedOrNot(str3, str4)) {
                return PrintTopic(str5, str6);
            } else {
                return PrintTopic(str4, str5);
            }
        } else {
            if (PrintTopic(str2, str3)) {
                return PrintTopic(str4, str5);
            } else {
                return PrintTopic(str3, str4);
            }
        }
    };

    function hasChar(str) {
        return str.trim() !== '';
    }


    // console.log(dataList);
    // Pagination
    // Initialize the state variables for pagination
    //const [currentPage, setCurrentPage] = useState(1); // Current page number
    const cardsPerPage = 15; // Number of cards per page
    // Calculate the `PaperValues` array for the current page
    const PaperValues = calculatePaperValues(nameList, subject, currentPage, cardsPerPage);
    const JobComp = getJobComp(nameList, jobTitle, company, currentPage, cardsPerPage);
    // Calculate the index range for the current page
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = nameList.slice(indexOfFirstCard, indexOfLastCard);
    // function to handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= Math.ceil(nameList.length / cardsPerPage)) {
            setCurrentPage(pageNumber); 
        }
    };
    const totalPages = Math.ceil(nameList.length / cardsPerPage);
    console.log('total pages',totalPages)
    const visiblePages = Array.from({ length: totalPages - 2 }, (_, index) => index + 2);
    
    // 3 Conditions if totalPages >= 6
    const renderPagination = () => {
        const currentPageIndex = currentPage - 1;
        const paginationLinks = [];
        
        // Conditions Split
        if (currentPage < 5) {
            // "1 2 3 4 ... N"
            const cond1Pages = Array.from({length:3}, (_, index) => index + 2);
            cond1Pages.map((cond1Pages) => (
                paginationLinks.push(
                    <Link
                        to="#"
                        key={cond1Pages}
                        onClick={() => handlePageChange(cond1Pages)}
                        className={currentPage === cond1Pages ? styles.activePage : ""}
                        >
                        {cond1Pages}
                    </Link>
            )))
            paginationLinks.push(
                <button 
                    className={styles.pageButton}
                    key="next5" onClick={() => handlePageChange(5)}>...</button>
            )
        } else if (currentPage >= (totalPages-3)) {
            // "1 ... N-3 N-2 N-1 N"
            paginationLinks.push(
                <button
                    className={styles.pageButton}
                    key="beforeN-3" onClick={() => handlePageChange(totalPages-4)}>...</button>
            )
            const cond3Pages = [totalPages-3, totalPages-2, totalPages-1];
            cond3Pages.map((cond3Pages) => (
                paginationLinks.push(
                    <Link
                        to="#"
                        key={cond3Pages}
                        onClick={() => handlePageChange(cond3Pages)}
                        className={currentPage === cond3Pages ? styles.activePage : ""}
                        >
                        {cond3Pages}
                    </Link>
            )))
        } else {
            // "1 ... n-1 n n+1 ... N"
            paginationLinks.push(
                <button 
                    className={styles.pageButton}
                    key="beforen-1" onClick={() => handlePageChange(currentPage-2)}>...</button>
            )
            const cond2Pages = [currentPage-1, currentPage, currentPage+1];
            cond2Pages.map((cond2Pages) => (
                paginationLinks.push(
                    <Link
                        to="#"
                        key={cond2Pages}
                        onClick={() => handlePageChange(cond2Pages)}
                        className={currentPage === cond2Pages ? styles.activePage : ""}
                        >
                        {cond2Pages}
                    </Link>
            )))
            paginationLinks.push(
                <button 
                    className={styles.pageButton}
                    key="aftern+1" onClick={() => handlePageChange(currentPage+2)}>...</button>
            )
        }
    return paginationLinks;
  };

    const cardsPerRow = 5;  // Number of cards per row

    // Add state variables and functions to handle the "GoToPage" functionality
    const [goToPageValue, setGoToPageValue] = useState(""); // State to store the value entered in the "Go To Page" input field
    const handleGoToPage = () => {
        const pageNumber = parseInt(goToPageValue);
        if (pageNumber >= 1 && pageNumber <= Math.ceil(nameList.length / cardsPerPage)) {
            setCurrentPage(pageNumber);
        }
    };

    const [count, setCount] = useState(0);

    const navigateToNewPage = (index) => {
      // 使用window.open打开新标签页，并将状态传递为URL参数
      const resumeInfo = {
        language: props.selectedLanguage,
        // data: data[index]
      };
      localStorage.setItem('resumeData', JSON.stringify(resumeInfo));
      console.log(localStorage.getItem('resumeData'))
      {console.log('language',props.selectedLanguage)}
      window.open(`/resume/${index}`, '_blank');
    };


    // function openResumePage(index) {
    //     const newTab = window.open(window.location.origin + '/' + index, '_blank');

    //     if (newTab) {
    //         // Write an empty root node to the new tab
    //         newTab.document.write('<div id="root"></div>');

    //         // Render the React component inside the new tab's root node with the provided data
    //         ReactDOM.render(<ResumePage state = {data[index]} />, newTab.document.getElementById('root'));
    //     }
    // }

    // Select Options
    const options = [
        { label: "Recommendation", value: "Option5" },
        { label: "Number of Citations (High to Low)", value: "Option1" },
        { label: "Number of Citations (Low to High)", value: "Option2" },
        { label: "Number of Publications (High to Low)", value: "Option3" },
        { label: "Number of Publications (Low to High)", value: "Option4" }
    ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: 5, 
            width: 370, 
            height: 40, 
            fontFamily: 'Sofia Pro Light Az',
            fontSize: 20,
            color: '#5E7594',
            border: '3px solid #ccc', 
            padding: '0px 0px', 
        })
    };

    //const CustomDropdownIndicator = () => (
    //    <img
    //        src="../Polygon 23.png"
    //        alt="Dropdown Indicator"
    //        style={{
    //            width: 20,
    //            height: 20
    //        }}
    //    />
    //);



    return (

            <div className={styles.total} id="Search_result">
                <div className={styles.lineContainer}>
                    
                    {props.totalScholars <= 600 ? <h1 className={styles.selected}>{props.totalScholars} Results</h1> : <h1 className={styles.selected}>600 Out of {props.totalScholars} Results</h1>}
                    {/* <h1 className={styles.split} style={{ marginLeft: "10px" }}>|</h1>
                        <button className={`${styles.export} ${styles.customButton}`} style={{ marginLeft: "10px" }}>Export</button>
                        <button className={`${styles.export} ${styles.customButton}`} style={{ marginLeft: "20px" }}>Add to a list</button>
                        <button className={`${styles.export} ${styles.customButton}`} style={{ marginLeft: "20px" }}>Remove from a list</button> */}
                    <div className={styles.sortContainer}>
                        <h1 className={styles.sort}>'Sort by'</h1>
                        <div className={styles.sort_rect}>
                            <Select
                                value={selectedOption}
                                options={options}
                                onChange={(option) => {
                                    setSelectedOption(option);
                                    sortData(option);
                                }}
                                styles={customStyles}
                                //components={{
                                //    DropdownIndicator: CustomDropdownIndicator
                                //}}
                            />
                        </div>
                    </div>
                </div>

                <hr className={styles.line}/>
                {/* <PieGenerator />
                <BarGenerator /> */}

                <section className={styles.cardGroup}>
                    <div className={styles.rowCard}>
                        {/* Pagination */}
                        {/* {nameList.map((name, index) => ( */}
                        {currentCards.map((name, index) => (
                            <div
                                className={styles.card}
                                key={index}
                                style={{ marginLeft: index % cardsPerRow !== 0 ? "25px" : "25px" }}
                            >

                                <div className={styles.cardImg} 
                                style={{ zoom: '15%' }}
                                onClick={()=>{navigateToNewPage(index+(currentPage-1)*15)}}

                                ><ResumePage state = {data[index+(currentPage-1)*15]} selectedLanguage = {props.selectedLanguage}/></div>

                                <div className={styles.paraP}>
                                    <h2 className={styles.fnln}>{truncateName(name.split("(")[0].trim(), 19)}</h2>
                                    <h2 className={styles.jic}>{JobComp[index][0]}</h2>
                                    <h2 className={styles.jic}>{truncateName(getAfterLastComma(JobComp[index][1]), 28)}</h2>
                                </div>
                                <div className={styles.TopicContainer}>
                                    {/* <div className={styles.flsj}>
                                        <h3 className={styles.subject}>{PrintTopic(PaperValues[index][0], PaperValues[index][1])}</h3>
                                    </div> */}
                                    {PrintedOrNot(PaperValues[index][0], PaperValues[index][1]) ? <div className={styles.flsj}>
                                        <h3 className={hasChar(PrintTopic(PaperValues[index][0], PaperValues[index][1])[0]) ? styles.subject : styles.noBackground}>
                                            {PrintTopic(PaperValues[index][0], PaperValues[index][1])[0]}</h3>
                                        <h3 className={hasChar(PrintTopic(PaperValues[index][0], PaperValues[index][1])[1]) ? styles.subject : styles.noBackground}>
                                            {PrintTopic(PaperValues[index][0], PaperValues[index][1])[1]}</h3>
                                        </div> : <div className={styles.flsj}>
                                            <h3 className={styles.subject}>{PrintTopic(PaperValues[index][0], PaperValues[index][1])[0]}</h3>
                                            </div>}                           
                                    {/* <div className={styles.flsj}>
                                        <h3 className={styles.subject}>{Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])}</h3>
                                    </div> */}
                                    {PrintedOrNot2(Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])) ? <div className={styles.flsj}>
                                        <h3 className={styles.subject}>{Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])[0]}</h3>
                                        <h3 className={styles.subject}>{Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])[1]}</h3>
                                        </div> : <div className={styles.flsj}>
                                            <h3 className={hasChar(Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])[0]) ? styles.subject : styles.noBackground}>
                                                {Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])[0]}</h3>
                                            </div>}
                                    {/* <div className={styles.flsj}>
                                        <h3 className={styles.subject}>{Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])}</h3>          
                                    </div> */}
                                    {PrintedOrNot2(Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])) ? <div className={styles.flsj}>
                                        <h3 className={styles.subject}>{Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[0]}</h3>
                                        <h3 className={styles.subject}>{Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[1]}</h3>
                                        </div> : <div className={styles.flsj}>
                                            {/* <h3 className={styles.subject}>{Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[0]}</h3> */}
                                            <h3 className={hasChar(Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[0]) ? styles.subject : styles.noBackground}>
                                                {Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[0]}</h3>
                                            </div>}
                                </div>
                            </div>
                        ))}
                    </div>
                </section>   

                <hr className={styles.line}/>

                {/* Pagination */} 
                <div className={styles.pagination}>
                    {/* <h1 className={styles.results}>{nameList.length} Results</h1> 
                    <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        &lt;
                    </button>
                    {Array.from({ length: Math.ceil(nameList.length / cardsPerPage) }, (_, index) => (
                        <Link
                            to="#"
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? styles.activePage : ""}
                        >
                            {index + 1}
                        </Link>
                    ))}                 
                    <button
                        disabled={currentPage === Math.ceil(nameList.length / cardsPerPage)}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        &gt;
                    </button>
                    */} 

                    {/* Page 1 Always */}
                    <Link
                        to="#"
                        key={1}
                        onClick={() => handlePageChange(1)}
                        className={currentPage === 1 ? styles.activePage : ""}
                        >
                        {1}
                    </Link>

                    {/* Page 2 to Page N-1 */}
                    {totalPages < 6 ? (
                        visiblePages.map((pageNumber) => (
                            <Link
                                to="#"
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                className={currentPage === pageNumber ? styles.activePage : ""}
                                >
                                {pageNumber}
                            </Link>
                        )) 
                    ) : (
                        <>{renderPagination()}</>
                    )}
                    
                    {/* Page N Always */}
                    <Link
                        to="#"
                        key={totalPages}
                        onClick={() => handlePageChange(totalPages)}
                        className={currentPage === totalPages ? styles.activePage : ""}
                        >
                        {totalPages}
                    </Link>

                    <div className={styles.goToPage}>
                        <b className={styles.pw}>Page</b>
                        <input
                            type="text"
                            value={goToPageValue}
                            onChange={(event) => setGoToPageValue(event.target.value)}
                        />
                        <button className={styles.gtpw} onClick={handleGoToPage}>
                            <b className={styles.pw}>Go</b></button>
                    </div>
    
                </div>
        </div>

    );
}

export default Result;