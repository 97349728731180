import React, { useState, useEffect } from "react";
import { BarChart, Bar, YAxis, LabelList } from "recharts";

export default function SubjectBarGenerator(props) {
  const [data1, setData1] = useState([props.data.citation]);
  const [data2, setData2] = useState([props.data.paper]);
  const [updatedData1, setUpdatedData1] = useState([{
    name: props.data.citation['name'],
    Q1: props.data.citation['Q1'] === 0 ? 0.8 : props.data.citation['Q1'],
    Q2: props.data.citation['Q2'] === 0 ? 0.8: props.data.citation['Q2'],
    'Q3&4': props.data.citation['Q3&4'] === 0 ? 0.8 : props.data.citation['Q3&4'],
  }]);
  const [updatedData2, setUpdatedData2] = useState([{
    name: props.data.paper['name'],
    Q1: props.data.paper['Q1'] === 0 ? -0.8 : props.data.paper['Q1'],
    Q2: props.data.paper['Q2'] === 0 ? -0.8: props.data.paper['Q2'],
    'Q3&4': props.data.paper['Q3&4'] === 0 ? -0.8 : props.data.paper['Q3&4'],
  }]);

  return (
    <div>
      <BarChart
        width={190}
        height={150}
        data={updatedData1}
        margin={{
          top: -5,
          right: 0,
          left: 0,
          bottom: 6,
        }}
      >
        <YAxis
          domain={[0, props.maxCite * 1.2]} // Set the YAxis domain to [0, maxValue]
          hide // Hide the Y-axis
        />
         <Bar dataKey="Q1" fill="#377DED" barSize={50}>
          {/* Using LabelList component to render the top labels */}
          <LabelList
            dataKey="Q1"
            position="top"
            formatter={(value) => value === 0.8 ? 0 : Math.abs(value)}
            fontSize={18}
          />
        </Bar>
       
        <Bar dataKey="Q2" fill="#94D8FF" barSize={50}>
          <LabelList
            dataKey="Q2"
            position="top"
            formatter={(value) => value === 0.8 ? 0 : Math.abs(value)}
            fontSize={18}
          />
        </Bar>
        <Bar dataKey="Q3&4" fill="#DDF6FD" barSize={50}>
          <LabelList
            dataKey="Q3&4"
            position="top"
            formatter={(value) => value === 0.8 ? 0 : Math.abs(value)}
            fontSize={18}
          />
        </Bar>
       
      </BarChart>
      <BarChart
        width={190}
        height={90}
        data={updatedData2}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <YAxis
          domain={[props.maxPap * 5, 0]} 
          hide 
        />
        <Bar dataKey="Q1" fill="#5C5D5F" barSize={50}>
          <LabelList
            dataKey="Q1"
            position="top"
            formatter={(value) => value === -0.8 ? 0 : Math.abs(value)}
            fontSize={18}
          />
        </Bar>
       
        <Bar dataKey="Q2" fill="#A2A3A7" barSize={50}>
          <LabelList
            dataKey="Q2"
            position="top"
            formatter={(value) => value === -0.8 ? 0 : Math.abs(value)}
            fontSize={18}
          />
        </Bar>
        <Bar dataKey="Q3&4" fill="#D9D9D9" barSize={50} >
          <LabelList
              dataKey="Q3&4"
              position="top"
              formatter={(value) => value === -0.8 ? 0 : Math.abs(value)}
              fontSize={18}
          />

        </Bar>
        
      </BarChart>
    </div>
  );
}
