import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./helperPage.module.css";
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';
import helperimg1 from '../Home/helperImg/helper1.JPEG'
import helperimg2 from '../Home/helperImg/helper2.JPEG'
import helperimg3 from '../Home/helperImg/helper8.JPEG'
import helperimg4 from '../Home/helperImg/helper3.JPEG'
import helperimg5 from '../Home/helperImg/helper4.jpg'
import helperimg6 from '../Home/helperImg/helper5.jpg'
import helperimg7 from '../Home/helperImg/helper6.jpg'
import helperimg8 from '../Home/helperImg/helper7.jpg'
import helperimg1cn from '../Home/helperImg/helper1cn.jpeg'
import helperimg2cn from '../Home/helperImg/helper2cn.jpeg'
import helperimg3cn from '../Home/helperImg/helper3cn.jpeg'
import helperimg4cn from '../Home/helperImg/helper4cn.jpg'
import helperimg5cn from '../Home/helperImg/helper5cn.jpg'
import helperimg6cn from '../Home/helperImg/helper6cn.jpg'
import helperimg7cn from '../Home/helperImg/helper7cn.jpg'
import helperimg8cn from '../Home/helperImg/helper8cn.jpg'

function HelperPage(props) {
    // console.log("log in Language:", props.selectedLanguage);
    const defaultLanguage = localStorage.getItem('selectedLanguage')
    
    const translations = {
        EN: {
            FLtitle: "Platform Introduction",
            welcome: "Purpose",
            firstP1: "Welcome to the ZenithScholars! ZenithScholars is a comprehensive platform that covers four major academic fields, including 136 disciplines, and gathers data from 800,000 scholars worldwide in the STEM field. Here, you can easily access the scholar information you need through precise filtering or one-click name searches. Our platform is committed to providing professional and efficient support by precisely matching academic elites, helping businesses rapidly move towards success. Thank you for choosing ZenithScholars. Let's embark on an exciting journey together, integrating academia and businesses!",
            firstP2: "We use your personal data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. ",
            pread: "Platform Function Introduction ",
            secondP: "While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:",
            tele: "Children’s Privacy",
            teleP1: "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.",
            teleP2:"If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
            cr: "Links to Other Websites",
            crP1: "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
            crP2:"We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.",
            tm: "Changes to This Privacy Policy",
            tmP1: "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.",
            tmP2: "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the \"Last updated\" date at the top of this Privacy Policy.",
            tmP3: "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
            LAA: "Interpretation and Definitions",
            LAAP: "Subject to compliance with these Conditions of Use and any Terms of Service, and payment of any applicable fees, Zenith Scholars or its content providers grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal non-commercial use of Zenith Scholars services. This license does not include resale or commercial use of any Zenith Scholars service or its content; collection and use of any academic publications, salary data, or any other content; use of any third party downloading, copying or other use of your account information for the benefit of a third party; or using data mining, robots or similar data gathering and extraction tools. All rights not expressly granted to you in these Conditions of Use or any Terms of Service are reserved and reserved by Zenith Scholars or its licensors, suppliers, publishers, rights holders or other content providers. None of the Zenith Scholars Services, or any portion thereof, may be copied, duplicated, duplicated, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written permission of Zenith Scholars. You may not use any meta tags or any other (hidden text) utilizing Zenith Scholars's name or trademarks without the express written permission of Zenith Scholars (hidden text). You may not abuse the Zenith Scholars service, and may only use the Zenith Scholars service in a manner permitted by law. The license granted by Zenith Scholars will terminate if you fail to comply with these Conditions of Use or any of the Terms of Service.",
            
        },
        CN: {
            FLtitle: "平台介绍",
            welcome: "目的",
            firstP1: "这份隐私政策描述了我们在您使用本服务时关于收集、使用和披露您的信息的政策和程序，并告知您有关您的隐私权利以及法律如何保护您。我们使用您的个人数据来提供和改进本服务。通过使用本服务，您同意根据本隐私政策收集和使用信息。",
            pread: "平台功能介绍",
            secondP: "在使用我们的服务时，我们可能会要求您提供某些可用于联系或识别您的个人身份信息。这些可识别个人信息可能包括但不限于：",
            tele: "儿童隐私",
            teleP1: "我们的服务不针对13岁以下的任何人。我们不会故意收集13岁以下任何人的可识别个人信息。如果您是父母或监护人，并且您知道您的孩子向我们提供了个人数据，请与我们联系。如果我们得知我们在未经父母同意的情况下收集了13岁以下任何人的个人数据，我们会采取步骤将该信息从我们的服务器中删除。",
            teleP2:"如果我们需要以同意作为处理您的信息的法律依据，",
            cr: "到其他网站的链接",
            crP1: "我们的服务可能包含指向非我们运营的其他网站的链接。如果您点击第三方链接，您将被重定向到该第三方的网站。我们强烈建议您查看您访问的每个网站的隐私政策。",
            crP2:"我们无法控制第三方网站或服务的内容、隐私政策或做法，也不承担对其内容或实践的责任。",
            tm: "对于本隐私政策的更改",
            tmP1: "我们可能会不时更新我们的隐私政策。我们将通过在此页面上发布新的隐私政策来通知您。",
            tmP2:"在更改生效之前，我们将通过电子邮件和/或在我们的服务上显著的通知来告知您，并更新本隐私政策顶部的“最后更新”日期。",
            tmP3:"建议您定期查看本隐私政策以了解任何更改。本隐私政策的更改在发布在此页面时生效。",
            LAA: "解释和定义",
            LAAP: "首字母大写的单词在以下情况下具有明确定义。以下定义无论是以单数还是复数形式出现，都具有相同的含义。",
    
        },
    };
    

    const t = (key) => {
        return translations[selectedLanguage][key] || key;
    };

    const SelectedOptionWithSVG = ({ label }) => (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
          <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px',paddingTop:'6px'}} />
          {label==='English' ? (
            <span style={{ paddingTop: '2px' }}>EN</span>
          ) : (
            <span style={{ paddingTop: '2px' }}>CN</span>
          )}
        </div>
    );
    
    const options2 = [
        { value: 'EN', label: 'English' },
        { value: 'CN', label: '中文(简体)' },
    ];

    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage); // Set default language


    
    
    const handleSelectChange2 = (selectedOption) => {
        setSelectedLanguage(selectedOption.value); // Update
        
        props.setSelectedLanguage(selectedOption.value); 
        if (selectedOption.value === 'EN') {
          
        } else if (selectedOption.value === 'CN') {
          
        }
    };

    
    const customSelect2 = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: 'Sofia Pro',
            fontSize: 16,
            color: '#377DED',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            border: 'none', 
            boxShadow: 'none', 
            minWidth: '130px',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display:'none',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          transition: 'color 0.2s',
          color: '#377DED',
          fontSize: state.isFocused ? '18px' : '16px',
          position: 'relative',
          "&:hover": {
            color: "#2149B1",
            fontSize: 18,
            //borderBottom: "2px solid #2149B1",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          fontFamily: 'Sofia Pro',
          fontSize: 16,
          color: state.isSelected ? '#FFFFFF' : '#377DED',
          cursor: 'pointer',
          textAlign: 'left',
          "&:active": {
            color: '#FFFFFF',
            background: '#377DED',
          },
          "&:focus": {
            outline: 'none',
          },
          display: 'flex',
          marginTop:'8px',
          marginBottom:'8px'
        }),
        menu: (provided) => ({
          ...provided,
          border: 'none',
          borderRadius: '10px',
          boxShadow: 'none',
          marginTop: 0,
          position: 'absolute',
        }),
    };
    
    const PlaceholderImageEN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>EN</span>
        </div>
    );
    
    const PlaceholderImageCN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>CN</span>
        </div>
    );

    return (
        <>
         <div className={styles.termOfUseContainer}>
        {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
        {selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
            <div className={styles.langDrop}>
                <Select
                    options={options2}
                    onChange={handleSelectChange2}
                    isSearchable={false} // This will disable user input

                    placeholder={defaultLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                    components={{
                        SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                    }}
                    styles={customSelect2}
                />
            </div>

        {
            selectedLanguage == 'EN' &&
        
        <> 
            <div className={styles.textContainer}>
                <div className={styles.FLtitle}>
                    <p>{t('FLtitle')}</p>
                </div>
{/* 
                <div className={styles.welcome}>
                    <p>{t('welcome')}</p>
                </div> */}
                <div className={styles.firstP}>
                    <p className={styles.normal}>{t('firstP1')}</p>
                    <img src={helperimg1} alt="" className={styles.helperimg}/>
                </div>
                <div className={styles.secondP}>
                    <p className={styles.title}>{t('pread')}</p>
                    <p className={styles.subtitle1}>1. How do I search for scholars I'm interested in? </p>
                    

                    <p className={styles.normal}>On this platform, you have two ways to find scholars of interest.</p>
                    <p className={styles.normal}>1. Filtering with specific conditions: ① Select 'Search by Filter'; ② Set different filtering conditions, such as filtering by scholars' personal information (General Filter) or scholars' research fields (Academic Filter), or a combination of both; ③ After setting, click the 'Save' button to save the filtering information; ④ Click 'Apply Filter' to obtain all scholars that matches the filtering conditions you've set.</p>
                    <img src={helperimg2} alt="" className={styles.helperimg}/>
                    <p  className={styles.normal}>2. Searching by scholar name: By directly searching for the scholar's name, you can obtain their detailed information. After entering the name, click the 'Search' button to obtain all scholar information that matches the filtering criteria you've set.</p>
                    <img src={helperimg3} alt="" className={styles.helperimg}/>
                    <p className={styles.normal}>Please note that you can only input one scholar's name at a time. If you input multiple names simultaneously, the system will only automatically return the information for the first name you searched for.</p>
                </div>

                <div className={styles.telecommunication}>
                    <p className={styles.subtitle1}>2. How to recruit scholars of interest? </p>
                    <p className={styles.normal}>If you successfully match scholars that meet your requirements on this platform, please follow these steps: </p>
                    <p className={styles.normal}>1. After completing the search through the filters or by searching a name, you'll find all scholar resumes that match the search criteria at the bottom of the homepage;</p>
                    <img src={helperimg4} alt="" className={styles.helperimg}></img>

                    <p className={styles.normal}>2. Browse through the scholar resumes, confirm the scholar information you need, and click to enter the scholar's resume details page;</p>
                    <img src={helperimg5} alt=""className={styles.helperimg}></img>

                    <p className={styles.normal}>3. On this scholar's resume details page, you will see a 'Contact Us' button;</p>
                    <img src={helperimg6} alt=""className={styles.helperimg}></img>

                    <p className={styles.normal}>4. After clicking the button, click  'Contact' and we will receive your request immediately and contact you via the email address you used to register on this platform.</p>
                    <img src={helperimg7} alt="" className={styles.helperimg}></img>
                </div>
                <div className={styles.copyright}>
                    <p className={styles.title}>Other</p>
                    <p className={styles.subtitle1}>3. How to use the promotional registration link? </p>
                    <p className={styles.normal}>Once you've logged in, you can find the option 'Get My Exclusive Promotional Registration Link' at the top right corner of the homepage. After logging in, at the top right corner of the homepage, you'll see the option 'Get My Referral Link.' Clicking on it will generate your unique promotional link. When you copy and share this link with your friends or family, if they open and register on ZenithScholars through your link, both you and the referred friend or family member will receive rewards.</p>
                    <img src={helperimg8} alt="" className={styles.helperimg}></img>

                    <p className={styles.normal}>Please note that as our platform is currently in the testing phase, and  the reward mechanism is not yet active. However, if you are interested in our platform and wish to support its rapid development, we still encourage you to share it. Additionally, we will track the number of referrals you make. Thank you for actively promoting ZenithScholars and contributing to the successful development of the platform!</p>

                </div>
                


            </div>
        </>}

        {
            selectedLanguage == 'CN' &&
        
        <> 
            <div className={styles.textContainer}>
            <div className={styles.FLtitle}>
                    <p>{t('FLtitle')}</p>
                </div>

                <div className={styles.welcome}>
                    <p>欢迎使用智汇才猎平台！</p>
                </div>
                <div className={styles.firstP}>
                <p className={styles.normal}>智汇才猎是一个涵盖四大学术领域、包括136个学科专业，并集结了全球STEM领域内80万学者数据的综合平台。在这里，您可以通过精密的筛选或一键搜索姓名，轻松获取您所需的学者信息。我们的平台致力于通过精准匹配学术精英，助力企业快速迈向成功而提供专业而高效的支持。</p>

                    <p className={styles.normal}>感谢您选择智汇才猎，让我们共同开启学术与企业的精彩融合之旅！</p>
                    <img src={helperimg1cn} alt="" className={styles.helperimg}/>
                </div>
                <div className={styles.secondP}>
                    <p className={styles.title}>{t('pread')}</p>
                    <p className={styles.subtitle1}>如何搜索我感兴趣的学者？ </p>
                    

                    <p className={styles.normal}>在本平台中，您有两种方式可以找到感兴趣的学者。</p>
                    <p className={styles.normal}>1. 以特定条件筛选 ：① 选定“以特定条件筛选”；② 设置不同的过滤条件，例如根据学者的个人信息（基础信息筛选）或学者的研究领域（学术领域筛选），或两者兼并；③ 设置完成后，点击“保存”按钮保存筛选条件；④ 点击“应用筛选”，即可获得所有符合您设置的筛选条件的学者信息。</p>
                    <img src={helperimg2cn} alt="" className={styles.helperimg}/>
                    <p  className={styles.normal}>2. 以学者名称检索：通过直接搜索学者姓名，直接获得该学者的详细信息。输入名称后，点击“开始搜索”按钮，即可获得所有符合您设置的筛选条件的学者信息。</p>
                    <img src={helperimg3cn} alt="" className={styles.helperimg}/>
                    <p className={styles.normal}>请注意，您一次只能输入一个学者的名称。若您同时输入多个，系统将只能自动返回您第一个搜索的姓名。</p>
                </div>

                <div className={styles.telecommunication}>
                    <p className={styles.subtitle1}>如何招聘我感兴趣的学者？ </p>
                    <p className={styles.normal}>若您在本平台成功匹配到符合您需求的学者，请按以下步骤操作：</p>
                    <p className={styles.normal}>1. 第一步：通过过滤器或搜索姓名完成检索后，您将在首页底部找到所有符合搜索条件的学者简历；</p>
                    <img src={helperimg4cn} alt="" className={styles.helperimg}></img>

                    <p className={styles.normal}>2. 第二步：浏览学者简历，确认您所需的学者信息，并点击进入学者简历详情页；</p>
                    <img src={helperimg5cn} alt="" className={styles.helperimg}></img>

                    <p className={styles.normal}>3. 第三步：在这位学者的简历详情页，您会看到“联系我们”按钮;</p>
                    <img src={helperimg6cn} alt="" className={styles.helperimg}></img>

                    <p className={styles.normal}>4. 第四步：点击按钮后，选择“立即联系”，我们将及时收到您的请求，并通过您注册本平台时使用的邮件地址与您取得联系。</p>
                    <img src={helperimg7cn} alt="" className={styles.helperimg}></img>
                </div>
                <div className={styles.copyright}>
                    <p className={styles.title}>其它</p>
                    <p className={styles.subtitle1}>如何使用推广注册链接？</p>
                    <p className={styles.normal}>在您完成登录后，可看到首页的右上角处有“获取我的推荐链接”的选项。点击后，系统将生成您专属的推广链接。当您将此链接复制并分享给您的朋友或家人后，只要他们通过您的链接打开智汇才猎并在平台注册，您和被推荐的朋友或家人都将获得奖励。</p>
                    <img src={helperimg8cn} alt="" className={styles.helperimg}></img>

                    <p className={styles.normal}>请注意，由于我们的平台目前处于测试阶段，奖励机制暂未开放。然而，如果您喜欢我们的平台并愿意支持其快速发展，我们仍然鼓励您分享。同时，我们仍然会记录您的推荐次数。</p>
                    <p className={styles.normal}>欢迎您积极推广智汇才猎，共同助力平台的成功发展！</p>

                </div>
            </div>
        </>}
        </div>
        
        </>
    );
}

export default HelperPage;