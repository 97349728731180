import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./privacy.module.css";
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';

function Privacy(props) {
    // console.log("log in Language:", props.selectedLanguage);
    const defaultLanguage = localStorage.getItem('selectedLanguage')
    
    const translations = {
        EN: {
            FLtitle: "ZenithScholars General Privacy Policy",
            welcome: "Purpose",
            firstP1: "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.",
            firstP2: "We use your personal data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. ",
            pread: "Collecting and Using Personal Data",
            secondP: "While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:",
            tele: "Children’s Privacy",
            teleP1: "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.",
            teleP2:"If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
            cr: "Links to Other Websites",
            crP1: "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
            crP2:"We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.",
            tm: "Changes to This Privacy Policy",
            tmP1: "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.",
            tmP2: "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the \"Last updated\" date at the top of this Privacy Policy.",
            tmP3: "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
            LAA: "Interpretation and Definitions",
            LAAP: "Subject to compliance with these Conditions of Use and any Terms of Service, and payment of any applicable fees, Zenith Scholars or its content providers grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal non-commercial use of Zenith Scholars services. This license does not include resale or commercial use of any Zenith Scholars service or its content; collection and use of any academic publications, salary data, or any other content; use of any third party downloading, copying or other use of your account information for the benefit of a third party; or using data mining, robots or similar data gathering and extraction tools. All rights not expressly granted to you in these Conditions of Use or any Terms of Service are reserved and reserved by Zenith Scholars or its licensors, suppliers, publishers, rights holders or other content providers. None of the Zenith Scholars Services, or any portion thereof, may be copied, duplicated, duplicated, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written permission of Zenith Scholars. You may not use any meta tags or any other (hidden text) utilizing Zenith Scholars's name or trademarks without the express written permission of Zenith Scholars (hidden text). You may not abuse the Zenith Scholars service, and may only use the Zenith Scholars service in a manner permitted by law. The license granted by Zenith Scholars will terminate if you fail to comply with these Conditions of Use or any of the Terms of Service.",
            
        },
        CN: {
            FLtitle: "智汇才猎隐私政策",
            welcome: "目的",
            firstP1: "这份隐私政策描述了我们在您使用本服务时关于收集、使用和披露您的信息的政策和程序，并告知您有关您的隐私权利以及法律如何保护您。我们使用您的个人数据来提供和改进本服务。通过使用本服务，您同意根据本隐私政策收集和使用信息。",
            pread: "收集和使用个人数据",
            secondP: "在使用我们的服务时，我们可能会要求您提供某些可用于联系或识别您的个人身份信息。这些可识别个人信息可能包括但不限于：",
            tele: "儿童隐私",
            teleP1: "我们的服务不针对13岁以下的任何人。我们不会故意收集13岁以下任何人的可识别个人信息。如果您是父母或监护人，并且您知道您的孩子向我们提供了个人数据，请与我们联系。如果我们得知我们在未经父母同意的情况下收集了13岁以下任何人的个人数据，我们会采取步骤将该信息从我们的服务器中删除。",
            teleP2:"如果我们需要以同意作为处理您的信息的法律依据，",
            cr: "到其他网站的链接",
            crP1: "我们的服务可能包含指向非我们运营的其他网站的链接。如果您点击第三方链接，您将被重定向到该第三方的网站。我们强烈建议您查看您访问的每个网站的隐私政策。",
            crP2:"我们无法控制第三方网站或服务的内容、隐私政策或做法，也不承担对其内容或实践的责任。",
            tm: "对于本隐私政策的更改",
            tmP1: "我们可能会不时更新我们的隐私政策。我们将通过在此页面上发布新的隐私政策来通知您。",
            tmP2:"在更改生效之前，我们将通过电子邮件和/或在我们的服务上显著的通知来告知您，并更新本隐私政策顶部的“最后更新”日期。",
            tmP3:"建议您定期查看本隐私政策以了解任何更改。本隐私政策的更改在发布在此页面时生效。",
            LAA: "解释和定义",
            LAAP: "首字母大写的单词在以下情况下具有明确定义。以下定义无论是以单数还是复数形式出现，都具有相同的含义。",
    
        },
    };
    

    const t = (key) => {
        return translations[selectedLanguage][key] || key;
    };

    const SelectedOptionWithSVG = ({ label }) => (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
          <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px',paddingTop:'6px'}} />
          {label==='English' ? (
            <span style={{ paddingTop: '2px' }}>EN</span>
          ) : (
            <span style={{ paddingTop: '2px' }}>CN</span>
          )}
        </div>
    );
    
    const options2 = [
        { value: 'EN', label: 'English' },
        { value: 'CN', label: '中文(简体)' },
    ];

    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage); // Set default language


    
    
    const handleSelectChange2 = (selectedOption) => {
        setSelectedLanguage(selectedOption.value); // Update
        
        props.setSelectedLanguage(selectedOption.value); 
        if (selectedOption.value === 'EN') {
          
        } else if (selectedOption.value === 'CN') {
          
        }
    };

    
    const customSelect2 = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: 'Sofia Pro',
            fontSize: 16,
            color: '#377DED',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            border: 'none', 
            boxShadow: 'none', 
            minWidth: '130px',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display:'none',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          transition: 'color 0.2s',
          color: '#377DED',
          fontSize: state.isFocused ? '18px' : '16px',
          position: 'relative',
          "&:hover": {
            color: "#2149B1",
            fontSize: 18,
            //borderBottom: "2px solid #2149B1",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          fontFamily: 'Sofia Pro',
          fontSize: 16,
          color: state.isSelected ? '#FFFFFF' : '#377DED',
          cursor: 'pointer',
          textAlign: 'left',
          "&:active": {
            color: '#FFFFFF',
            background: '#377DED',
          },
          "&:focus": {
            outline: 'none',
          },
          display: 'flex',
          marginTop:'8px',
          marginBottom:'8px'
        }),
        menu: (provided) => ({
          ...provided,
          border: 'none',
          borderRadius: '10px',
          boxShadow: 'none',
          marginTop: 0,
          position: 'absolute',
        }),
    };
    
    const PlaceholderImageEN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>EN</span>
        </div>
    );
    
    const PlaceholderImageCN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>CN</span>
        </div>
    );

    return (
        <>
         <div className={styles.termOfUseContainer}>
        {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
        {selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
            <div className={styles.langDrop}>
                <Select
                    options={options2}
                    onChange={handleSelectChange2}
                    isSearchable={false} // This will disable user input

                    placeholder={defaultLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                    components={{
                        SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                    }}
                    styles={customSelect2}
                />
            </div>

        {
            selectedLanguage == 'EN' &&
        
        <> 
            <div className={styles.textContainer}>
                <div className={styles.FLtitle}>
                    <p>{t('FLtitle')}</p>
                </div>

                <div className={styles.welcome}>
                    <p>{t('welcome')}</p>
                </div>
                <div className={styles.firstP}>
                    <p className={styles.normal}>{t('firstP1')}</p>
                    <p className={styles.normal}>{t('firstP2')}</p>

                </div>
                <div className={styles.secondP}>
                    <p className={styles.title}>{t('pread')}</p>
                    <p className={styles.subtitle1}>Types of Data Collected</p>
                    <p className={styles.subtitle2}>Personal Data</p>
                    <p className={styles.normal}>{t('secondP')}</p>
                    <ul className={styles.listp}>
                        <li>Full Name</li>
                        <li>Work Email Address </li>
                        <li>Job Title</li>
                        <li>Company</li>
                        <li>Usage Data</li>
                    </ul>
                    <p className={styles.subtitle2}>Usage Data</p>

                    <p className={styles.normal}>Usage Data is collected automatically when using the Service.</p>
                    <p className={styles.normal}>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                    <p className={styles.normal}>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>

                    <p className={styles.subtitle2}>Tracking Technologies and Cookies</p>
                    <p className={styles.normal}>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                    <ul className={styles.listp}>
                        <li><span className={styles.highlight}>Cookies or Browser Cookies</span> – a cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, you may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                        <li><span className={styles.highlight}>Web Beacons</span> – Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                       
                    </ul>
                    <p className={styles.normal}>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/">Free Privacy Policy website</a> article.</p>
                    <p className={styles.normal}>We use both Session and Persistent Cookies for the purposes set out below:</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Necessary / Essential Cookies</span></p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type: Session Cookies</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administered by: Us</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>

                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Cookies Policy / Notice Acceptance Cookies</span></p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type: Persistent Cookies</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administered by: Us</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>

                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Functionality Cookies</span></p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type: Persistent Cookies</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Administered by: Us</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                    <p className={styles.normal}>For more information about the cookies we use and your choices regarding cookies, please contact us directly.</p>

                    <p className={styles.subtitle1}>Use of Your Personal Data</p>
                    <p className={styles.normal}>The Company may use Personal Data for the following purposes:</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>To provide and maintain our Service,</span> including to monitor the usage of our Service.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>To manage Your Account: </span>to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>For the performance of a contract:</span> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>To contact You:</span> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>To provide You</span> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>To manage Your requests:</span> To attend and manage Your requests to Us.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>For other purposes:</span> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
                    <p className={styles.normal}>We may share Your personal information in the following situations:</p>

                    <ul className={styles.listp}>
                        <li><span className={styles.highlight}>With Service Providers:</span> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                        <li><span className={styles.highlight}>For business transfers:</span> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                        <li><span className={styles.highlight}>With Affiliates:</span> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                        <li><span className={styles.highlight}>With business partners:</span> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                        <li><span className={styles.highlight}>With other users:</span> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
                        <li><span className={styles.highlight}>With Your consent:</span> We may disclose Your personal information for any other purpose with Your consent.</li>
                    </ul>

                    <p className={styles.subtitle1}>Retention of Your Personal Data</p>
                    <p className={styles.normal}>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                    <p className={styles.normal}>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

                    <p className={styles.subtitle1}>Transfer Your Personal Data</p>
                    <p className={styles.normal}>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                    <p className={styles.normal}>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                    <p className={styles.normal}>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

                    <p className={styles.subtitle1}>Disclosure of Your Personal Data </p>
                    <p className={styles.subtitle2}>Business Transactions</p>
                    <p className={styles.normal}>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                    <p className={styles.subtitle2}>Law Enforcement</p>
                    <p className={styles.normal}>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

                    <p className={styles.subtitle2}>Other Legal Requirements</p>
                    <p className={styles.normal}>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                    <ul className={styles.listp}>
                        <li>Comply with a legal obligation</li>
                        <li>Protect and defend the rights or property of the Company</li>
                        <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                        <li>Protect the personal safety of Users of the Service or the public</li>
                        <li>Protect against legal liability</li>
                    </ul>

                    <p className={styles.subtitle1}>Security of Your Personal Data</p>
                    <p className={styles.normal}>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>




                </div>

                <div className={styles.telecommunication}>
                    <p className={styles.title}>{t('tele')}</p>
                    <p className={styles.normal}>{t('teleP1')}</p>
                    <p className={styles.normal}>{t('teleP2')}</p>

                </div>
                <div className={styles.copyright}>
                    <p className={styles.title}>{t('cr')}</p>
                    <p className={styles.normal}>{t('crP1')}</p>
                    <p className={styles.normal}>{t('crP2')}</p>

                </div>
                <div className={styles.trademark}>
                    <p className={styles.title}>{t('tm')}</p>
                    <p className={styles.normal}>{t('tmP1')}</p>
                    <p className={styles.normal}>{t('tmP2')}</p>
                    <p className={styles.normal}>{t('tmP3')}</p>
                </div>

                <div className={styles.trademark}>
                    <p className={styles.title}>Interpretation and Definitions</p>
                    <p className={styles.subtitle2}>Interpretation</p>
                    <p className={styles.normal}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                    <p className={styles.subtitle2}>Definitions</p>
                    <p className={styles.normal}>For the purposes of this Privacy Policy:</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Account</span> means a unique account created for You to access our Service or parts of our Service.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Company</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ZenithScholars.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Cookie</span>  are small files that are placed on Your computer, mobile device, or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Country</span>  refers to: California, United States</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Device</span> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Personal Data</span> is any information that relates to an identified or identifiable individual.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Service</span> refers to the Website.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Service Provider</span> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Usage Data</span> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Website</span> refers to ZenithScholar, accessible from <a href="https://intelliprozenithscholars.com/">https://intelliprozenithscholars.com/</a></p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>You</span> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>

                    
                </div>
                <div className={styles.trademark}>
                    <p className={styles.title}>California Privacy Policy</p>
                    <p className={styles.normal}>California Consumer Privacy Act (CCPA) Policy</p>
                    <p className={styles.normal}>Notification for California Consumers</p>
                    <p className={styles.normal}>Last Updated: 11/9/2023</p>
                    <br></br>

                    <span className={styles.highlight}>1. Introduction</span> 
                    <p className={styles.normal}>This California Consumer Privacy Act (CCPA) policy outlines how we collect, use, disclose, and protects the personal information of California consumers as required by the CCPA.</p>
                    <br></br>

                    <span className={styles.highlight}>2. Information We Collect</span> 
                    <p className={styles.normal}> We collect various categories of personal information from our users, as detailed in our General Privacy Policy. This information may include but is not limited to:</p>
                    <ul className={styles.listp}>
                        <li> Identifiers (e.g., name, email address, IP address)</li>
                        <li> Personal information categories listed in the California Customer Records statute (e.g., address, phone number)</li>
                        <li> Commercial information (e.g., purchase history, payment information)</li>
                        <li> Internet or other electronic network activity information (e.g., browsing history)</li>
                    </ul>
                    <br></br>

                    <span className={styles.highlight}>3. Purpose of Collection</span> 
                    <p className={styles.normal}>We collect personal information for the following purposes: providing and improving our services and personalizing user experience </p>
                    <br></br>

                    <span className={styles.highlight}>4. User Rights under CCPA</span> 
                    <p className={styles.normal}> As a California consumer, you have the following rights:</p>
                    <ul className={styles.listp}>
                        <li> The right to know what personal information we have collected, used, disclosed, and sold about you.</li>
                        <li> The right to request the deletion of your personal information.</li>
                        <li> The right to opt-out of the sale of your personal information.</li>
                        <li> The right to non-discrimination for exercising your CCPA rights.</li>
                        <li> For details on how to exercise these rights, please contact us.  </li>
                    </ul>
                    <br></br>

                    <span className={styles.highlight}>5. Information Security</span> 
                    <p className={styles.normal}>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we can not guarantee absolute security. </p>
                    <br></br>
                    
                    <span className={styles.highlight}>6. Changes to this CCPA Policy</span> 
                    <p className={styles.normal}>We reserve the right to update this CCPA policy periodically. Any changes will be reflected on this page with a revised "Last Updated" date. We encourage you to review this policy regularly.</p>
                    <br></br>

                    <p className={styles.normal}>For more information about how we handle personal information, please refer to our comprehensive General Privacy Policy. </p>
                    
                    
                </div>
                <div className={styles.LAA}>
                    <p className={styles.title}>Contact Us</p>
                    <p className={styles.normal}>If you have any questions about this Privacy Policy, you can contact us:</p>
                    <ul className={styles.listp}>
                        <li>By visiting this page on our website: <a href="https://intelliprozenithscholars.com/">https://intelliprozenithscholars.com/</a></li>
                        </ul>
                </div>
            </div>
        </>}

        {
            selectedLanguage == 'CN' &&
        
        <> 
            <div className={styles.textContainer}>
                <div className={styles.FLtitle}>
                    <p>{t('FLtitle')}</p>
                </div>

                <div className={styles.welcome}>
                    <p>{t('welcome')}</p>
                </div>
                <div className={styles.firstP}>
                    <p className={styles.normal}>{t('firstP1')}</p>

                </div>
                <div className={styles.secondP}>
                    <p className={styles.title}>{t('pread')}</p>
                    <p className={styles.subtitle1}>收集的数据类型</p>
                    <p className={styles.subtitle2}>个人数据</p>
                    <p className={styles.normal}>{t('secondP')}</p>
                    <ul className={styles.listp}>
                        <li>真实姓名</li>
                        <li>工作电子邮件地址</li>
                        <li>职位名称</li>
                        <li>公司名称</li>
                        <li>使用数据</li>
                    </ul>
                    <p className={styles.subtitle2}>使用数据</p>

                    <p className={styles.normal}>使用数据是在使用服务时自动收集的。使用数据可能包括诸如您的设备的互联网协议地址（例如 IP 地址）、浏览器类型、浏览器版本、您访问的我们服务的页面、访问的时间和日期、在这些页面上花费的时间、唯一设备标识符和其他诊断数据等信息。我们还可能在您访问我们的服务或通过移动设备访问服务时，收集您的浏览器发送的信息。</p>
                   

                    <p className={styles.subtitle2}>跟踪技术和Cookie</p>
                    <p className={styles.normal}>我们使用Cookie和类似的跟踪技术来跟踪我们服务的活动并存储特定信息。我们使用的跟踪技术包括信标、标签和脚本，用于收集和跟踪信息以改进和分析我们的服务。我们使用的技术可能包括：</p>
                    <ul className={styles.listp}>
                        <li><span className={styles.highlight}>Cookie或浏览器Cookie</span>– Cookie是一个放置在您的设备上的小文件。您可以指示您的浏览器拒绝所有Cookie或在Cookie被发送时进行指示。然而，如果您不接受Cookie，您可能无法使用我们服务的某些部分。除非您已调整了您的浏览器设置以拒绝Cookie，否则我们的服务可能会使用Cookie。</li>
                        <li><span className={styles.highlight}>网页信标</span>– 我们服务的某些部分以及我们的电子邮件可能包含称为网页信标（也称为透明图像、像素标签和单像素图像）的小型电子文件，它们允许公司，例如，计算访问这些页面或打开电子邮件的用户数量，以及用于其他相关的网站统计信息（例如，记录某个部分的受欢迎程度以及验证系统和服务器的完整性）。</li>
                       
                    </ul>
                    <p className={styles.normal}>Cookie可以是"持久性"或"会话"Cookie。持久性Cookie在您离线时保留在您的个人计算机或移动设备上，而会话Cookie在您关闭网络浏览器后立即被删除。请在<a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/">Free Privacy Policy</a>网站的文章中了解更多关于Cookie的信息。 </p>
                    <p className={styles.normal}>我们使用会话和持久性Cookie来实现以下目的：</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>必要/基本Cookie</span></p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类型：会话Cookie</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;管理者：我们</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目的：这些Cookie是提供通过网站可用的服务以及使您能够使用其中一些功能所必不可少的。它们有助于验证用户身份并防止用户帐户的欺诈使用。如果没有这些Cookie，您所要求的服务将无法提供，我们只使用这些Cookie来为您提供这些服务。</p>

                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Cookie政策/通知接受Cookie</span></p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类型：持久性Cookie</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;管理者：我们</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目的：这些Cookie用于识别用户是否已经同意在网站上使用Cookie。</p>

                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>功能性Cookie</span></p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类型：持久性Cookie</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;管理者：我们</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目的：这些Cookie允许我们记住您在使用网站时所做的选择，如记住您的登录详细信息或语言首选项。这些Cookie的目的是为您提供更个性化的体验，避免您在每次使用网站时都需要重新输入您的首选项。</p>
                    <p className={styles.normal}>有关我们使用的Cookie以及关于Cookie的选择，请直接联系我们获取更多信息。</p>

                    <p className={styles.subtitle1}>使用您的个人数据</p>
                    <p className={styles.normal}>公司可能出于以下目的使用个人数据：</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. 为提供和维护我们的服务，包括监控我们服务的使用情况。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 管理您的帐户：管理您作为服务用户的注册。您提供的个人数据可以让您作为注册用户访问不同的服务功能。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 履行合同：开发、合规和执行您通过服务购买的产品、物品或服务的购买合同，或履行与我们通过服务订立的任何其他合同。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 联系您：通过电子邮件、电话呼叫、短信或其他等效的电子通信方式，如移动应用程序的推送通知，联系您，以提供与功能、产品或已签约服务相关的更新或信息通信，包括必要或合理的安全更新。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. 提供新闻、特别优惠和有关我们提供的与您已购买或咨询过的类似商品、服务和活动的一般信息，除非您选择不接收此类信息。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. 处理您的请求：回应和管理您对我们的请求。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. 用于其他目的：我们可能出于其他目的使用您的信息，如数据分析、识别使用趋势、确定促销活动的有效性以及评估和改进我们的服务、产品、营销和您的体验。</p>
                    <p className={styles.normal}>我们可能会在以下情况下分享您的个人信息：</p>

                    <ul className={styles.listp}>
                        <li><span className={styles.highlight}>与服务提供商:</span> 我们可能与服务提供商分享您的个人信息，以监控和分析我们服务的使用，以及与您联系。</li>
                        <li><span className={styles.highlight}>用于业务转让:</span> 我们可能在与公司资产的出售、融资、并购或与其他公司的全部或部分业务收购的谈判过程中分享或转移您的个人信息。</li>
                        <li><span className={styles.highlight}>与关联公司:</span> 我们可能与我们的关联公司分享您的信息，届时我们将要求这些关联公司遵守本隐私政策。关联公司包括我们的母公司以及任何其他子公司、合资伙伴或与我们共同控制或受到我们共同控制的公司。</li>
                        <li><span className={styles.highlight}>与业务合作伙伴:</span> 我们可能与业务合作伙伴分享您的信息，以向您提供特定的产品、服务或促销活动。</li>
                        <li><span className={styles.highlight}>与其他用户:</span> 当您在公共领域分享个人信息或以其他方式与其他用户互动时，这些信息可能被所有用户查看，并可能被公开分发给外部人士。</li>
                        <li><span className={styles.highlight}>在您同意的情况下:</span> 我们可能在获得您的同意的情况下出于任何其他目的披露您的个人信息。</li>
                    </ul>

                    <p className={styles.subtitle1}>保留您的个人数据</p>
                    <p className={styles.normal}>公司将仅在本隐私政策中规定的目的所需的时间内保留您的个人数据。我们将保留并使用您的个人数据，以便遵守我们的法律义务（例如，如果我们需要保留您的数据以符合适用法律的规定），解决争议，并执行我们的法律协议和政策。</p>
                    <p className={styles.normal}>公司还将保留使用数据以进行内部分析。使用数据通常保留的时间较短，除非这些数据用于加强安全性或改进我们服务的功能，或者我们有法律义务保留这些数据更长的时间。</p>

                    <p className={styles.subtitle1}>转移您的个人数据</p>
                    <p className={styles.normal}>您的信息，包括个人数据，将在公司的运营办公室和处理方所在的任何其他地方进行处理。这意味着这些信息可能被转移到位于您所在的州、省、国家或其他政府管辖区之外的计算机上，而数据保护法律可能与您所在地的法律不同。</p>
                    <p className={styles.normal}>您对本隐私政策的同意，以及您提交此类信息，表示您同意进行这种转移。</p>
                    <p className={styles.normal}>公司将采取一切合理必要的措施，确保您的数据得到安全处理，并符合本隐私政策的规定，不会将您的个人数据转移给组织或国家，除非存在适当的控制措施，包括数据的安全性和其他个人信息的保护。</p>
                    <p className={styles.subtitle1}>披露您的个人数据</p>
                    <p className={styles.subtitle2}>业务交易</p>
                    <p className={styles.normal}>如果公司参与合并、收购或资产出售，您的个人数据可能会被转移。在您的个人数据被转移并受到不同隐私政策约束之前，我们将提前通知您。</p>
                    <p className={styles.subtitle2}>执法机关</p>
                    <p className={styles.normal}>在某些情况下，如果根据法律要求或应对公共当局的有效请求（例如法院或政府机构）而需要，公司可能会被要求披露您的个人数据。</p>
                    <p className={styles.subtitle2}>其他法律要求</p>
                    <p className={styles.normal}>公司可能出于善意信念认为有必要采取以下行动来披露您的个人数据:</p>
                    <ul className={styles.listp}>
                        <li>遵守法律义务</li>
                        <li>保护和捍卫公司的权利或财产</li>
                        <li>防止或调查与服务相关的可能不当行为</li>
                        <li>保护服务用户或公众的个人安全</li>
                        <li>防止法律责任</li>
                    </ul>

                    <p className={styles.subtitle1}>您的个人数据的安全性</p>
                    <p className={styles.normal}>您的个人数据的安全对我们非常重要，但请记住，互联网传输或电子存储的任何方法都不是100%安全的。尽管我们努力采用商业上可接受的手段来保护您的个人数据，但我们无法保证其绝对安全。</p>

                </div>

                <div className={styles.telecommunication}>
                    <p className={styles.title}>{t('tele')}</p>
                    <p className={styles.normal}>{t('teleP1')}</p>
                    <p className={styles.normal}>{t('teleP2')}</p>

                </div>
                <div className={styles.copyright}>
                    <p className={styles.title}>{t('cr')}</p>
                    <p className={styles.normal}>{t('crP1')}</p>
                    <p className={styles.normal}>{t('crP2')}</p>

                </div>
                <div className={styles.trademark}>
                    <p className={styles.title}>{t('tm')}</p>
                    <p className={styles.normal}>{t('tmP1')}</p>
                    <p className={styles.normal}>{t('tmP2')}</p>
                    <p className={styles.normal}>{t('tmP3')}</p>
                </div>

                <div className={styles.trademark}>
                    <p className={styles.title}>解释和定义</p>
                    <p className={styles.subtitle2}>解释</p>
                    <p className={styles.normal}>首字母大写的单词在以下情况下具有明确定义。以下定义无论是以单数还是复数形式出现，都具有相同的含义。</p>
                    <p className={styles.subtitle2}>定义</p>
                    <p className={styles.normal}>根据本隐私政策的目的:</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>账户</span>指的是为您创建的唯一帐户，用于访问我们的服务或我们服务的部分。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>公司</span>（在本协议中称为“公司”、“我们”或“我们的”）指的是 ZenithScholars。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>Cookie</span>是指由网站放置在您的计算机、移动设备或任何其他设备上的小文件，其中包含有关您在该网站上的浏览历史的详细信息，除此之外还有许多其他用途。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>国家</span>指的是美国加利福尼亚州。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>设备</span>指的是可以访问该服务的任何设备，例如计算机、手机或数字平板电脑。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>个人数据</span>是指与已知或可识别的个体有关的任何信息。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>服务</span>指的是该网站。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>服务提供商</span>是指代表公司处理数据的任何自然人或法人。它指的是公司雇佣的第三方公司或个人，以便协助提供服务，代表公司提供服务，执行与服务相关的服务，或协助公司分析服务的使用方式。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>使用数据</span>指的是自动收集的数据，要么是通过使用服务产生的，要么是从服务基础设施本身产生的（例如，页面访问的持续时间）。</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>网站</span>是指 ZenithScholars，可从<a href="https://intelliprozenithscholars.com/">https://intelliprozenithscholars.com/</a>访问</p>
                    <p className={styles.normal}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={styles.highlight}>您</span>是指访问或使用服务的个体，或在该个体访问或使用服务的情况下，是适用的公司或其他法律实体。</p>

                    
                </div>
                <div className={styles.trademark}>
                    <p className={styles.title}>加州消费者隐私权政策</p>
                    <ul className={styles.listp}>
                        <li className={styles.normal}>加州消费者隐私权法（CCPA）政策</li>
                        <li className={styles.normal}>针对加州消费者的通知</li>
                        <li className={styles.normal}>最近更新日期：2023年11月9日</li>
                    </ul>
                    <br></br>

                    <span className={styles.highlight}>1. 介绍</span> 
                    <p className={styles.normal}>这份加州消费者隐私权法（CCPA）政策概述了我们如何根据CCPA要求收集、使用、披露和保护加州消费者的个人信息。</p>
                    <br></br>

                    <span className={styles.highlight}>2. 我们收集的信息</span> 
                    <p className={styles.normal}> 我们从用户处收集各种类别的个人信息，详细信息请参阅我们的《通用隐私政策》。这些信息可能包括但不限于：</p>
                    <ul className={styles.listp}>
                        <li> 标识符（例如姓名、电子邮件地址、IP地址）</li>
                        <li> 加州客户记录法列出的个人信息类别（例如地址、电话号码）</li>
                        <li> 商业信息（例如购买记录、支付信息）</li>
                        <li> 互联网或其他电子网络活动信息（例如浏览历史）</li>
                    </ul>
                    <br></br>
                    
                    <span className={styles.highlight}>3. 收集目的</span> 
                    <p className={styles.normal}>我们收集个人信息的目的包括：提供和改进我们的服务以及个性化用户体验。</p>
                    <br></br>

                    <span className={styles.highlight}>4. CCPA下的用户权利</span> 
                    <p className={styles.normal}> 作为加州消费者，您拥有以下权利：</p>
                    <ul className={styles.listp}>
                        <li> 知晓我们收集、使用、披露和出售有关您的个人信息的权利。</li>
                        <li> 请求删除您的个人信息的权利。</li>
                        <li> 拒绝出售您的个人信息的权利。</li>
                        <li> 行使CCPA权利而不受歧视的权利。</li>
                        <li> 要了解如何行使这些权利的详细信息，请与我们联系。</li>
                    </ul>
                    <br></br>

                    <span className={styles.highlight}>5. 信息安全</span> 
                    <p className={styles.normal}>我们采取合理的安全措施，保护您的个人信息免受未经授权的访问、披露、更改和破坏。然而，互联网传输或电子存储的任何方法均不是完全安全的，我们无法保证绝对安全。 </p>
                    <br></br>
                    
                    <span className={styles.highlight}>6. 本CCPA政策的更改</span> 
                    <p className={styles.normal}>我们保留定期更新此CCPA政策的权利。任何变更都将反映在本页面，并标注修订的“最近更新日期”。我们鼓励您定期查阅此政策。</p>
                    <br></br>

                    <p className={styles.normal}>欲了解更多关于我们如何处理个人信息的信息，请参阅我们全面的《通用隐私政策》。</p>
                    
                    
                </div>

                <div className={styles.LAA}>
                    <p className={styles.title}>联系我们</p>
                    <p className={styles.normal}>如果您对本隐私政策有任何问题，您可以通过以下方式与我们联系：</p>
                    <ul className={styles.listp}>
                        <li>通过访问我们网站上的此页面：<a href="https://intelliprozenithscholars.com/">https://intelliprozenithscholars.com/</a></li>
                        </ul>
                </div>
            </div>
        </>}
        </div>
        
        </>
    );
}

export default Privacy;