// import { useEffect, useState } from "react";
import styles from "../GeneralFilter/GeneralFIlter.module.css";
// import { useNavigate } from "react-router-dom";


function NameCard (props) {
    return (
    <>
    <div className={styles.cardContainer}>
        {props.selectedLanguage == 'EN' &&
          <div className={styles.inputCard}>
          Name 
          {props.show?( 
              <input name={props.name} onChange={props.change} defaultValue={props.val} className={`${props.nameCount>1?styles.scholarInput:styles.scholarInput1}`} placeholder="search..."/>
          ):(<div className={styles.scholarInput2}>
              {props.val? <div>{props.val}</div>: <div className={styles.searching}>search...</div>}
              </div>
          )}
          {props.nameCount!==1 && (
              <img onClick={e => props.delete(props.name)} src="/bluecross.svg" className={styles.blueCross} alt="crossIcon" />
          )}
      </div>}

      {props.selectedLanguage == 'CN' &&
          <div className={styles.inputCard}>
          姓名 
          {props.show?( 
              <input name={props.name} onChange={props.change} defaultValue={props.val} className={`${props.nameCount>1?styles.scholarInput:styles.scholarInput1}`} placeholder="搜索..."/>
          ):(<div className={styles.scholarInput2}>
              {props.val? <div>{props.val}</div>: <div className={styles.searching}>搜索...</div>}
              </div>
          )}
          {props.nameCount!==1 && (
              <img onClick={e => props.delete(props.name)} src="/bluecross.svg" className={styles.blueCross} alt="crossIcon" />
          )}
      </div>}
      
    </div>
    </>
    )
}

export default NameCard;