import styles from "../ResumePage/ResumeDetail.module.css";
import AcademicMatrics from "./component/AcademicMatrics";
import {  useState,useEffect } from "react";
import { fetchEmail,fetchProfile,getToken } from "../Home/fetch";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import translateCN from '../SubjectFilter/mapping_dict.json'
import OpenAI from 'openai';


const ResumeDetail = (props) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [unlockPop, setUnlockPop] = useState(false);
  const [contactRemind1, setContactRemind1] = useState(props.selectedLanguage === 'EN' ? "Looking to hire" : "想要招聘");
  const [contactRemind2, setContactRemind2] = useState(props.selectedLanguage === 'EN' ? `${props.state.name}?`: `${props.state.name}?`);
  const [contactRemind3, setContactRemind3] = useState(props.selectedLanguage === 'EN' ? "ZenithScholar bridges the gap between" : "ZenithScholar 架起了");
  const [contactRemind4, setContactRemind4] = useState(props.selectedLanguage === 'EN' ? "employers and top scholars worldwide." : "全球雇主和顶尖学者之间的桥梁。");
  const [contactRemind5, setContactRemind5] = useState(props.selectedLanguage === 'EN' ? "Contact us to get started." : "联系我们开始招聘。");

  const [iconSrc, setIconSrc] = useState("/contact.svg");
  const [btnName, setBtnName] = useState(props.selectedLanguage === 'EN' ?"Contact":"联系");
  const [clicks, setClicks] = useState(0);
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('')
  const [jobTitle,setJobTitle] = useState('')

  const [unlock, setUnlock] = useState();
  const [errMsg,setErrMsg] = useState('')
  const [cnBio, setCnBio] = useState('')
  const [cnEdu, setCnEdu] = useState('')
  const [cnWorkExp, setCnWorkExp] =useState([])
  const [enBio, setEnBio] = useState('')
  const [enEdu, setEnEdu] = useState('')
  const [enWorkExp, setEnWorkExp] =useState([])

  const generateText = async (text,lang) => {
    const apiKey = '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'

    try {

        const openai = new OpenAI({
            apiKey, dangerouslyAllowBrowser:true// This is also the default, can be omitted
          }); 
          let prompt = ''  
          if(lang == 'cn'){
            prompt = `Translate the following English text to simplified Chinese: "${text}"`;
          }else{
            prompt = `Translate the following English text to English: "${text}"`;
          }
     
          const completion = await openai.completions.create({
            model: "gpt-3.5-turbo-instruct",
            prompt: prompt,
            max_tokens: 500,
          });
          return completion.choices[0].text;
          
      } catch (error) {
        console.error('Error:', error);
      }
  };

  const processStr = (bio,lang) => {
    let combinedStr = bio.join("\n");
    let words = []
  //   if (props.selectedLanguage === 'CN') {
  //     try {
  //         const translated = await generateText(combinedStr);
  //         return translated;
  //     } catch (error) {
  //         console.error('Error translating:', error);
  //         // Handle error if translation fails
  //         return combinedStr; // Return the original string in case of an error
  //     }
  // }

      if(lang =='en'){
        words = combinedStr.split(/ /).filter(word => word);

      }
      
    if (words.length > 130 && lang=='en') {
      return words.slice(0, 130).join(" ") + "...";
    }
    
    if (combinedStr.length > 400 && lang=='cn') {
      return combinedStr.slice(0, 400)+ "...";
    }
    return combinedStr;
  };
  let bioArray = [];
  let displayedBio = "";
  let displayedBioCn = "";

  if (props.state && props.state.bio && props.state.bio.trim() !== "") {
    bioArray = props.state.bio.split("<br>");
    bioArray = bioArray.filter((item) => item.trim() !== "");
    displayedBio = processStr(bioArray,'en');
    // console.log('displaybio',displayedBio)
    // processStr(bioArray).then((result) => {
    //   displayedBio = result;
    // });
  }

  
  if (props.state && props.state.bio_zh && props.state.bio_zh.trim() !== "") {
    bioArray = props.state.bio_zh.split("<br>");
    bioArray = bioArray.filter((item) => item.trim() !== "");
    displayedBioCn = processStr(bioArray,'cn');
    // console.log('displaybio',displayedBio)
    // processStr(bioArray).then((result) => {
    //   displayedBio = result;
    // });
  }


  const splitToLines = (str) => {
    const words = str.split(/\t|\n| |<br>/);

    let totalLines = [];
    let currentLine = [];
    let lencnt = 0;
    words.forEach((word) => {
      if (currentLine.length < 5 && lencnt + word.length < 38) {
        currentLine.push(word);
        lencnt += word.length;
      } else {
        totalLines.push(currentLine.join(" "));
        currentLine = [word];
        lencnt = 0;
      }
    });
    if (currentLine.length > 0) {
      totalLines.push(currentLine.join(" "));
    }
    return totalLines;
  };

  const experienceFormat = (arr, maxLineNum) => {
    let lines = [];
    let currentLine = [];
    arr.forEach((exp) => {
      currentLine = splitToLines(exp);
      if (currentLine.length) lines = [...lines, ...currentLine];
    });
    if (lines.length > maxLineNum) {
      lines = lines.slice(0, maxLineNum);
      const lastLine = lines[lines.length - 1];
      lines[lines.length - 1] = lastLine + "...";
    }
    return lines;
  };
  let eduArray = [];
  let eduFormatted = [];
  let eduFormattedCn = [];
  if (
    props.state &&
    props.state.education_experience &&
    props.state.education_experience.trim() !== ""
  ) {
    eduArray = props.state.education_experience.split(/<br>|\n/);
    eduArray = eduArray.filter((item) => item.trim() !== "");
    eduFormatted = experienceFormat(eduArray.slice(0, 2), 5);
  }

  if (
    props.state &&
    props.state.education_experience_zh &&
    props.state.education_experience_zh.trim() !== ""
  ) {
    eduArray = props.state.education_experience_zh.split(/<br>|\n/);
    eduArray = eduArray.filter((item) => item.trim() !== "");
    eduFormattedCn = experienceFormat(eduArray.slice(0, 2), 5);
  }

  let workArray = [];
  let workFormatted = [];
  let workFormattedCn = [];
  if (
    props.state &&
    props.state.work_experience &&
    props.state.work_experience.length
  ) {
    workArray = props.state.work_experience;
    workArray = workArray.filter((item) => item.trim() !== "");
    workFormatted = experienceFormat(workArray.slice(0, 2), 5);
  }

  if (
    props.state &&
    props.state.work_experience_zh &&
    props.state.work_experience_zh.length
  ) {
    workArray = props.state.work_experience_zh;

    workArray = workArray.filter((item) => item.trim() !== "");
    workFormattedCn = experienceFormat(workArray.slice(0, 2), 5);
  }

  const processTranslate=(combinedStr,lang)=>{
    let words=[]
    
    if(lang =='en'){
      words = combinedStr.split(/ /).filter(word => word);
   }
   
    
   if (words.length > 200 && lang=='en') {
     return words.slice(0, 140).join(' ') + "...";
   }

   if (combinedStr.length> 400 && lang=='cn') {
     return combinedStr.slice(0, 400)+ "...";
   }
    
   return combinedStr
  }
  
  const processLangBio = async(bio,lang)=>{
    let combinedStr = bio.join("\n");
        try {
          if(lang == 'cn'){
            const translated = await generateText(combinedStr,lang);
            const displaytranslate = processTranslate(translated,lang)
            setCnBio(displaytranslate)
          }else if (lang=='en'){
            const translated = await generateText(combinedStr,lang);
            const displaytranslate = processTranslate(translated,lang)
            setEnBio(displaytranslate)
          }
        } catch (error) {
            console.error('Error translating:', error);
            // Handle error if translation fails
            setCnBio('N/A'); // Return the original string in case of an error
            setEnBio('N/A');
        }
  }

  const processLangWorkExp = async (workArr,lang) => {
    try {
      if(workArr.length == 1){
        const words = workArr[0].split(/<br>/);
        if(words.length == 1 && words[0].length >200){
          const newExp = words[0].slice(0,200) + '...'
          words[0] = newExp
        }
        workArr =words
      }
      if(lang == 'cn'){
      const translatedList = await Promise.all(
        workArr.map(async (work) => {
          const translated = await generateText(work,lang);
          return translated;
        })
      );
      setCnWorkExp(translatedList.slice(0,2));}
      else if(lang == 'en'){
        const translatedList = await Promise.all(
          workArr.map(async (work) => {
            const translated = await generateText(work,lang);
            return translated;
          })
        );
        setEnWorkExp(translatedList.slice(0,2));}
    } catch (error) {
      console.error('Error translating:', error);
      // Handle error if translation fails
      setCnWorkExp([]); // Return the original string in case of an error
    }
  };

  const processLangEdu = async (eduArr,lang) => {
    try {
      if(lang == 'cn'){
      const translatedList = await Promise.all(
        eduArr.map(async (work) => {
          const translated = await generateText(work,lang);
          return translated;
        })
      );
      setCnEdu(translatedList.slice(0,2));}
      else if(lang == 'en'){
        const translatedList = await Promise.all(
          eduArr.map(async (work) => {
            const translated = await generateText(work,lang);
            return translated;
          })
        );
        setEnEdu(translatedList.slice(0,2));}

    } catch (error) {
      console.error('Error translating:', error);
      // Handle error if translation fails
      setCnEdu([]); // Return the original string in case of an error
    }
  };
  useEffect(()=>{
    if (props.state && props.state.bio && props.state.bio.trim() !== "" && props.selectedLanguage == 'CN') {
      bioArray = props.state.bio.split("<br>");
      bioArray = bioArray.filter((item) => item.trim() !== "");
      processLangBio(bioArray,'cn')
    }
    if (
      props.state &&
      props.state.work_experience &&
      props.state.work_experience.length &&
      props.selectedLanguage == 'CN' 
    ) {
      workArray = props.state.work_experience;
      workArray = workArray.filter((item) => item.trim() !== "");
      //workFormatted = experienceFormat(workArray.slice(0, 2), 5);
      processLangWorkExp(workArray,'cn')
    }

    if (
      props.state &&
      props.state.education_experience &&
      props.state.education_experience.trim() !== "" &&
      props.selectedLanguage == 'CN'
    ) {
      eduArray = props.state.education_experience.split(/<br>|\n/);
      eduArray = eduArray.filter((item) => item.trim() !== "");
      eduFormatted = experienceFormat(eduArray.slice(0, 2), 3);
      processLangEdu(eduArray,'cn')
    }

    if (props.state && props.state.bio_zh && props.state.bio_zh.trim() !== "" && props.selectedLanguage == 'EN') {
      bioArray = props.state.bio_zh.split("<br>");
      bioArray = bioArray.filter((item) => item.trim() !== "");
      processLangBio(bioArray,'en')
    }
    if (
      props.state &&
      props.state.work_experience_zh &&
      props.state.work_experience_zh.length &&
      props.selectedLanguage == 'EN' 
    ) {
      workArray = props.state.work_experience_zh;
      workArray = workArray.filter((item) => item.trim() !== "");
      //workFormatted = experienceFormat(workArray.slice(0, 2), 5);
      processLangWorkExp(workArray,'en')
    }

    if (
      props.state &&
      props.state.education_experience_zh &&
      props.state.education_experience_zh.trim() !== "" &&
      props.selectedLanguage == 'EN'
    ) {
      eduArray = props.state.education_experience_zh.split(/<br>|\n/);
      eduArray = eduArray.filter((item) => item.trim() !== "");
      eduFormatted = experienceFormat(eduArray.slice(0, 2), 3);
      processLangEdu(eduArray,'en')
    }
  
  },[])

  const subjects = Object.entries(props.state.subject.paper).map(
    ([subjectName, quartiles]) => {
      const totalPapers = Object.values(quartiles).reduce(
        (sum, value) => sum + value,
        0
      );
      return {
        name: subjectName,
        value: totalPapers,
      };
    }
  );
  const topSubjects = subjects.sort((a, b) => b.value - a.value);

  const barSubjectData = topSubjects.slice(0, 3).map((subject) => ({
    paper: {
      name: subject.name,
      Q1: -props.state.subject.paper[subject.name].Q1,
      Q2: -props.state.subject.paper[subject.name].Q2,
      "Q3&4": -props.state.subject.paper[subject.name]["Q3&4"],
    },
    citation: {
      name: subject.name,
      Q1: props.state.subject.citation[subject.name].Q1,
      Q2: props.state.subject.citation[subject.name].Q2,
      "Q3&4": props.state.subject.citation[subject.name]["Q3&4"],
    },
  }));
  while (topSubjects.length < 3) {
    topSubjects.push({
      name: "",
      value: 0,
    });
  }
  while (barSubjectData.length < 3) {
    barSubjectData.push({
      paper: {
        name: "",
        Q1: 0,
        Q2: 0,
        "Q3&4": 0,
      },
      citation: {
        name: "",
        Q1: 0,
        Q2: 0,
        "Q3&4": 0,
      },
    });
  }

  const topics = Object.entries(props.state["top_research_topics"].paper).map(
    ([subjectName, quartiles]) => {
      const totalPapers = Object.values(quartiles).reduce(
        (sum, value) => sum + value,
        0
      );
      return {
        name: subjectName,
        value: totalPapers,
      };
    }
  );

  const topTopics = topics.sort((a, b) => b.value - a.value);

  const barTopicData = topTopics.slice(0, 7).map((subject) => ({
    paper: {
      name: subject.name,
      Q1: -props.state["top_research_topics"].paper[subject.name].Q1,
      Q2: -props.state["top_research_topics"].paper[subject.name].Q2,
      "Q3&4": -props.state["top_research_topics"].paper[subject.name]["Q3&4"],
    },
    citation: {
      name: subject.name,
      Q1: props.state["top_research_topics"].citation[subject.name].Q1,
      Q2: props.state["top_research_topics"].citation[subject.name].Q2,
      "Q3&4": props.state["top_research_topics"].citation[subject.name]["Q3&4"],
    },
  }));
  while (topTopics.length < 7) {
    topTopics.push({
      name: "",
      value: 0,
    });
  }
  while (barTopicData.length < 7) {
    barTopicData.push({
      paper: {
        name: "",
        Q1: 0,
        Q2: 0,
        Q3: 0,
      },
      citation: {
        name: "",
        Q1: 0,
        Q2: 0,
        Q3: 0,
      },
    });
  }

  const translations = {
    EN: {
     moreInfo1:"More Info 1",
      moreInfo2:"More Info 2",
      moreInfo3:"More Info 3",
      work_experience: "WORK EXPERIENCE",
      IntelliPro: 'IntelliPro | Global Scholar Database',
      Profile: 'Profile',
      EDUCATION_EXPERIENCE: 'EDUCATION EXPERIENCE',
      SCHOLAR_BIO: 'SCHOLAR BIO',
      NA: 'N/A',
      at: 'at ',
    },
    CN: {
      moreInfo1:"更多信息 1",
      moreInfo2:"更多信息 2",
      moreInfo3:"更多信息 3",
      work_experience: "工作经历",
      IntelliPro: 'IntelliPro | 全球学者数据库',
      Profile: '资料',
      EDUCATION_EXPERIENCE: '教育经历',
      SCHOLAR_BIO: '学者简介',
      NA: '无',
      at: '在',

      
    },
  // You can add more languages here
};

const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };

  useEffect(()=>{
    fetchProfile().then((data) => {
      console.log("login status", data);
      setEmail(data.email);
      setFullName(data.name);
      setCompany(data.company);
      setJobTitle(data.title)
      setUnlock(data.is_unlock);
      
  })
  .catch((error) => {
      console.error("Promise rejected:", error);
  });
  },[email]);

  const handlecontact = async () => {
  
    try {
      const tokenData = await getToken;
      const token = tokenData.csrfToken;

      const response = await fetch("https://intelliprozenithscholars.com/auth/user_unlock/", {
        method: "POST",
        headers: {
          "X-CSRFToken": token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        credentials: "include",
        // body: formData.toString()
        body: JSON.stringify({
          name:fullName,
          email: email,
          company:company,
          job_title:jobTitle,
          port: '3001'
        }),
      });
      const data = await response.json();

      if (data.message == "Successfully change lock status") {
        console.log("Successfully change lock status")
        setUnlock(true)
      //   const response2 = await fetch("https://www.larksuite.com/flow/api/trigger-webhook/4a460387df490113f3876b0a9db34a53", {
      //   method: "POST",
      //   mode:'no-cors',
      //   body: JSON.stringify({
      //         name:fullName,
      //         email:email,
      //         company:company,
      //         job_title:jobTitle,
      //   }),
      // });

      // const data2 = await response2.json();
      // console.log(data2)
      // } else {
      //   // setError( data.message ||'Registration failed.');
       }else{
        setErrMsg('fail to unlock')
       }
    } catch (error) {
      setErrMsg("An error occurred. Please try again later.");
    }
  };

  const contactClicked = e => {
    if (clicks===0 && unlock){
      if(props.selectedLanguage == 'EN'){
        setContactRemind1("Stay tuned! A member of our team will be")
        setContactRemind3("reaching out to you shortly.")
        setContactRemind2("")
        setContactRemind4("")
        setContactRemind5("")

        setBtnName("Done")
      }else if(props.selectedLanguage == 'CN'){
        setContactRemind1("敬请期待！我们团队的成员")
        setContactRemind3("将很快与您联系。")
        setContactRemind2("")
        setContactRemind4("")
        setContactRemind5("")
        setBtnName("完成")
      }
     
      setIconSrc("/letter.svg")
    } else if (clicks===0 && !unlock){
      setIconSrc("/contact.svg")
      if(props.selectedLanguage == 'EN'){

      setContactRemind1("We've received your request! Someone from our ")
      setContactRemind3("team will contact you soon.")
      setContactRemind2("")
      setContactRemind4("")
      setContactRemind5("")
      setBtnName("Done")
    }else if(props.selectedLanguage == 'CN'){
      
      setContactRemind1("我们已收到您的请求！我们团队的某位成员")
      setContactRemind3("将很快与您联系。")
      setContactRemind2("")
      setContactRemind4("")
      setContactRemind5("")
      setBtnName("完成")
    }


      handlecontact()
    } else {
      setUnlockPop(false)
      setClicks(0)
      if(props.selectedLanguage == 'EN'){

      setContactRemind1("Looking to hire")
      setContactRemind2(`${props.state.name}?`)
      setContactRemind3("ZenithScholar bridges the gap between")
      setContactRemind4("employers and top scholars worldwide.")
      setContactRemind5("Contact us to get started.")
      setBtnName("Contact")
    }else if(props.selectedLanguage == 'CN'){
      setContactRemind1("想要招聘")
      setContactRemind2(`${props.state.name}?`)
      setContactRemind3("ZenithScholar架起了")
      setContactRemind4("全球雇主和顶尖学者之间的桥梁。")
      setContactRemind5("联系我们开始招聘。")
      setBtnName("联系")
    }
      return
    }
    setClicks(clicks+1)
  }

  const closePop = e => {
    setUnlockPop(false);
    setClicks(0)
    setIconSrc("/contact.svg")
    if(props.selectedLanguage == 'EN'){

      setContactRemind1("Looking to hire")
      setContactRemind2(`${props.state.name}?`)
      setContactRemind3("ZenithScholar bridges the gap between")
      setContactRemind4("employers and top scholars worldwide.")
      setContactRemind5("Contact us to get started.")
      setBtnName("Contact")
    }else if(props.selectedLanguage == 'CN'){
      setContactRemind1("想要招聘")
      setContactRemind2(`${props.state.name}?`)
      setContactRemind3("ZenithScholar架起了")
      setContactRemind4("全球雇主和顶尖学者之间的桥梁。")
      setContactRemind5("联系我们开始招聘。")
      setBtnName("联系")
    }
  }

  const handleOpenTab = () => {
  
  };

let employers =  '';

if(props.state.employer == undefined || props.state.employer.length==0){
    console.log('no employee')
}else{
  employers= props.state.employer.split(",")

}



// const lastEmployer = employers.slice(-1)[0];
console.log('employer',props.state.employer)
console.log('employers',employers)
const filteredEmployer = employers? employers
  .reverse()
  .find(employer =>
    ["university", "college", "institution","institute",'center','lab','laboratory'].some(keyword =>
      employer.toLowerCase().includes(keyword)
    )
  ):''

  const getAfterLastComma = (str) => {
    const lastCommaIndex = str.lastIndexOf(', ');
    console.log(lastCommaIndex)
    if (lastCommaIndex !== -1) {
        return str.substring(lastCommaIndex + 2);
    }
    return str;
  };

  

  const employResult = filteredEmployer || getAfterLastComma(props.state.employer)




  let confidentialInof;
  if (!props.authed) {
    confidentialInof = (
      <div>
        <div className={styles.informationGroup_detail}>
          {/* <img
            className={styles.informationGroup_detail_icon}
            alt=""
            src="/image-73@2x.png"
          />
          <div className={styles.informationGroup_text}>
          {props.state.phone && props.state.phone.length > 0 ? props.state.phone : "N/A"}
          </div> */}
          <img
            className={styles.informationGroup_detail_icon}
            alt=""
            src="/image-74@2x.png"
          />
          <div className={styles.informationGroup_text}>
          {props.state.location && props.state.location.length > 0 ? props.state.location : "N/A"}
          </div>
          <img
            className={styles.informationGroup_detail_icon}
            alt=""
            src="/image-75@2x.png"
          />
          {/* <div className={styles.informationGroup_text}><a href={props.state.links[0]}>{t('moreInfo1')}</a></div>
          <div className={styles.informationGroup_text}><a href={props.state.links[1]}>{t('moreInfo2')}</a></div>
          <div className={styles.informationGroup_text}><a href={props.state.links[2]}>{t('moreInfo3')}</a></div> */}
          {props.state.links && props.state.links.length > 0 ? (
                props.state.links.map((link, index) => (
                  <div key={index} className={styles.informationGroup_text}>
                    <a href={link} target="_blank">{t(`moreInfo${index + 1}`)}</a>
                  </div>
                ))
              ) : (
                <div className={styles.informationGroup_text}>N/A</div>
              )}
        </div>
        <div className={styles.informationGroup_email}>
          {/* <img
            className={styles.informationGroup_detail_icon}
            alt=""
            src="/image-76@2x.png"
          /> */}
            {/* {props.state.emails && props.state.emails.length >0 ? <>
              <div className={styles.informationGroup_text}>{props.state.emails[0]}</div>
            <div className={styles.informationGroup_text}>{props.state.emails[1]}</div>
      </>:<b className={styles.dsilvercsucluk}>N/A</b>} */}
      
          {/* <div className={styles.informationGroup_text}>{props.state.emails[0]}</div>
            <div className={styles.informationGroup_text}>{props.state.emails[1]}</div>   */}
        </div>
      </div>
    );
  } else {
    confidentialInof = (
      <div>
        <div className={styles.informationGroup_detail}>
          {/* <img
            className={styles.informationGroup_detail_icon}
            alt=""
            src="/image-73@2x.png"
          />
          <div className={styles.confidentialInof_box}>
            <div className={styles.informationGroup_text_confidential}>
              this-is-testNumber
            </div>
          </div> */}

          <img
            className={styles.informationGroup_detail_icon}
            alt=""
            src="/image-74@2x.png"
          />
          <div className={styles.informationGroup_text}>
          {props.state.location && props.state.location.length > 0 ? props.state.location : "N/A"}
          </div>
          {/* <img
            className={styles.informationGroup_detail_icon}
            alt=""
            src="/image-75@2x.png"
          />
          <div className={styles.confidentialInof_box}>
            <div className={styles.informationGroup_text_confidential}>
             {t('moreInfo1')}
            </div>
          </div>
          <div className={styles.confidentialInof_box}>
            <div className={styles.informationGroup_text_confidential}>
               {t('moreInfo1')}
            </div>
          </div> */}
          {/* <div className={styles.confidentialInof_box}>
            <div className={styles.informationGroup_text_confidential}>
              {t('moreInfo1')}
            </div>
          </div> */}
          

        </div>
        <div className={styles.informationGroup_email}>
          {/* <img
            className={styles.informationGroup_detail_icon}
            alt=""
            src="/image-76@2x.png"
          />
          <div className={styles.confidentialInof_box}>
            <div className={styles.informationGroup_text_confidential}>
              
            </div>
          </div>
          <div className={styles.confidentialInof_box}>
            <div className={styles.informationGroup_text_confidential}>
              testDouble124@test.com
            </div>
          </div> */}
          

        </div>
        
      </div>
    );
  }
  // console.log(!props.state.title);

  const handleBack =()=>{

  }
  return (
    <div className={styles.frame}>
      {/* <div className={styles.logo}>
        {props.selectedLanguage=='EN' &&  <img src="/backlogin.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/home")}}/>}
            {props.selectedLanguage=='CN' &&  <img src="/backcn.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/home")}}/>}
      </div> */}

      <div className={styles.intelliproGlobal} onClick={handleOpenTab}>
        {/* <img className={styles.startIcon} alt="" src="/resume_logo.svg" /> */}
        <img className={styles.logoresume} alt="" src="/new_logo.svg" />
        {/* {`${props.state.name} Profile`} */}

      </div> 

      <div className={styles.informationGroup}>
      {/* <div className={styles.logo}>
            {props.selectedLanguage=='EN' &&  <img src="/backlogin.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/home")}}/>}
            {props.selectedLanguage=='CN' &&  <img src="/backcn.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/home")}}/>}
            {/* { <img src="/backcn.svg" alt="logo" className= {styles.backlogin}  onClick={()=>handleBack('https://github.com/IFTteam/Scholar_FrontEnd_v2')}/>}  
      </div> */}
      
        <div className={styles.informationGroup2}>
        <div className={styles.informationGroup3}>
        <img
          className={styles.informationGroup_avatar}
          src={props.state.photolink? `${props.state.photolink}`:'/avatar.svg'}
          alt=""
        />

        <div className={styles.informationGroup_content}>
         
          <div className={styles.informationGroup_title}>
          
            <h1 className={styles.informationGroup_name}>{props.state.name}</h1>
            {!props.state.title && !props.state.title_zh &&(
              <p className={styles.informationGroup_job}>
                {props.state.employer.length>0 ?
                <span className={styles.informationGroup_job_current}>
                {/* {props.state.employer.split(",").slice(-1)[0]} */}
                {employResult}
                {console.log(props.state.employer)}
              </span>:
              <span className={styles.informationGroup_job_current}>
            </span>
                }
                
              </p>
            ) 
               }
               { props.selectedLanguage == 'EN' && props.state.title && props.state.title_zh && (
              <p className={styles.informationGroup_job}>
                <span className={styles.informationGroup_job_title}>
                  {props.state.title}{" "}
                </span>
                {props.state.employer.length>0 ?
                <span className={styles.informationGroup_job_current}>
                  {t('at')}
                  {/* {props.state.employer.split(",").slice(-1)[0]} */}
                  {employResult}
                  {console.log(props.state.employer)}

                </span>:
                <span className={styles.informationGroup_job_current}>
                </span>
                }
              </p>
            )}

          { props.selectedLanguage == 'CN' && props.state.title_zh && props.state.title && (
              <p className={styles.informationGroup_job}>
                <span className={styles.informationGroup_job_title}>
                  {props.state.title_zh}{" "}
                </span>
                {props.state.employer.length>0 ?
                <span className={styles.informationGroup_job_current}>
                  {t('at')}
                  {/* {props.state.employer.split(",").slice(-1)[0]} */}
                  {employResult}
                  {console.log(props.state.employer)}

                </span>:
                <span className={styles.informationGroup_job_current}>
                </span>
                }
              </p>
            )}

            {props.state.title && !props.state.title_zh && (
              <p className={styles.informationGroup_job}>
                <span className={styles.informationGroup_job_title}>
                  {props.state.title}{" "}
                </span>
                {props.state.employer.length>0 ?
                <span className={styles.informationGroup_job_current}>
                  {t('at')}
                  {/* {props.state.employer.split(",").slice(-1)[0]} */}
                  {employResult}
                  {console.log(props.state.employer)}

                </span>:
                <span className={styles.informationGroup_job_current}>
                </span>
                }
              </p>
            )}

            { !props.state.title && props.state.title_zh && (
              <p className={styles.informationGroup_job}>
                <span className={styles.informationGroup_job_title}>
                  {props.state.title_zh}{" "}
                </span>
                {props.state.employer.length>0 ?
                <span className={styles.informationGroup_job_current}>
                  {t('at')}
                  {/* {props.state.employer.split(",").slice(-1)[0]} */}
                  {employResult}
                  {console.log(props.state.employer)}

                </span>:
                <span className={styles.informationGroup_job_current}>
                </span>
                }
              </p>
            )}
          </div>
          {confidentialInof}
        </div>
        </div>
        <div className={styles.unlockWrap}>
          <div className={styles.unlockContact}>
             {props.selectedLanguage=='EN' && <>Looking to hire?<br/>We’re here to help!</>}
             {props.selectedLanguage=='CN' && <>需要招聘吗？<br/>我们可以提供帮助！</>}

             <button className={styles.unlockBtn} onClick={e=>{setUnlockPop(true)}}>
             {props.selectedLanguage=='EN' && <>Contact Us</>}
             {props.selectedLanguage=='CN' && <>联系我们</>}


              </button>
          </div>
        </div>

      </div>
      </div>

      {/* second  */}
      <div className={styles.informationGroup}>
        <div className={styles.experience}>
          <div className={styles.experience_work}>
            <div className={styles.experience_title}>
              {t('work_experience')}
              <div className={styles.line}></div>
            </div>

            <div className={styles.experience_work_detail}>
              <div className={styles.experience_work_title}>
                {workFormatted.length !== 0 && props.selectedLanguage=='EN' &&
                  workFormatted.map((item) => (
                      <div className={styles.experience_work_item}>{item}</div>
                    ))}
                   {workFormattedCn.length !== 0 && props.selectedLanguage=='CN' &&
                  workFormattedCn.map((item) => (
                      <div className={styles.experience_work_item}>{item}</div>
                    ))}
                   
                   
                    {cnWorkExp.length !== 0 && workFormattedCn.length ==0 && props.selectedLanguage=='CN' &&
                  cnWorkExp.map((item) => (
                      <div className={styles.experience_work_item}>{item}</div>
                    ))}

                     
                {enWorkExp.length !== 0 && workFormatted.length ==0 && props.selectedLanguage=='EN' &&
                  enWorkExp.map((item) => (
                      <div className={styles.experience_work_item}>{item}</div>
                    ))}
                    {workFormatted.length == 0 && workFormattedCn ==0 && t('NA')}

              </div>
            </div>
          </div>

          <div className={styles.experience_work}>
            <div className={styles.experience_title}>
              {t('EDUCATION_EXPERIENCE')}
              <div className={styles.line}></div>
            </div>
            <div className={styles.experience_work_detail}>
              <div className={styles.experience_work_title}>
                {eduFormatted.length !== 0
                  && props.selectedLanguage == 'EN' && eduFormatted.map((item) => (
                      <div className={styles.experience_work_item}>{item}</div>
                    ))}
                  {eduFormattedCn.length !== 0
                  && props.selectedLanguage == 'CN' && eduFormattedCn.map((item) => (
                      <div className={styles.experience_work_item}>{item}</div>
                    ))}
                    {cnEdu.length !== 0 && eduFormattedCn.length==0
                  && props.selectedLanguage == 'CN' && cnEdu.map((item) => (
                      <div className={styles.experience_work_item}>{item}</div>
                    ))}
                      {enEdu.length !== 0 && eduFormatted.length==0
                  && props.selectedLanguage == 'EN' && enEdu.map((item) => (
                      <div className={styles.experience_work_item}>{item}</div>
                    ))}
                    {eduFormatted.length == 0 && eduFormattedCn.length ==0 && t('NA')}

              </div>
            </div>
          </div>
        </div>

        <div className={styles.bio}>
          <div className={styles.experience_title}>
            {t('SCHOLAR_BIO')}
            <div className={styles.line}></div>
          </div>

          <div className={styles.experience_bio_content}>
            {props.selectedLanguage == 'EN' && displayedBio.length !== 0 && displayedBio}
            {props.selectedLanguage == 'CN' && displayedBioCn.length !== 0 && displayedBioCn}

            {props.selectedLanguage == 'CN' && cnBio.length !== 0 && displayedBioCn.length==0 && cnBio}
            {props.selectedLanguage == 'EN' && enBio.length !== 0 && displayedBio.length==0 && enBio}

            {displayedBio.length == 0 && displayedBioCn.length ==0 && t('NA')}
          </div>
        </div>
      </div>
      <AcademicMatrics state={props.state} selectedLanguage = {props.selectedLanguage}/>
      <div>
        {props.selectedLanguage == 'EN' && <img className={styles.iftLogo} alt="" src="/new_logo.svg" />}
        {props.selectedLanguage == 'CN' && <img className={styles.iftLogo} alt="" src="/new_logo_cn.svg" />}

        
      </div>
      {unlockPop && (
          <div className={styles.layer}>
            <div className={styles.loginRemind}>
              <div className={styles.topbar}></div>
              <img src={iconSrc} className={styles.contactIcon} alt="contactIcon" />
              <img src="/cross.svg" className={styles.crossIcon} onClick={closePop}alt="crossIcon" />
              <div className={styles.remindwords}>{contactRemind1}</div>
              <div className={styles.remindwords2}>{contactRemind2}</div>
              <div className={styles.remindwords}>{contactRemind3}</div>
              <div className={styles.remindwords}>{contactRemind4}</div>
              <div className={styles.remindwords}>{contactRemind5}</div>


             { (btnName == 'Contact' || btnName == '联系') && <button className={styles.remindPopBtn1} onClick={contactClicked}>{btnName}</button>}
             { (btnName == 'Done' || btnName == '完成') && <button className={styles.remindPopBtn} onClick={contactClicked}>{btnName}</button>}

            </div>
            
          </div>

          
        )}

    </div>
  );
};

export default ResumeDetail;
