import React, { useEffect, useState } from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import styles from "./linkSent.module.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';

const SuccessPage = (props) => {
  const translations = {
    EN: {
      success: "Success!",
      text: "Your account has been successfully created!",
      back: "Back to Log In",

    },
    CN: {
      success: "验证成功!",
      text: "您的账号已经激活成功!",
      back: "回到登录",

    },
    // You can add more languages here
  };

  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };

  const SelectedOptionWithSVG = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px',paddingTop:'9px'}} />
      {label==='English' ? (
        <span style={{ paddingTop: '8px' }}>EN</span>
      ) : (
        <span style={{ paddingTop: '8px' }}>CN</span>
      )}
    </div>
  );

  const options2 = [
    { value: 'EN', label: 'English' },
    { value: 'CN', label: '中文(简体)' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

  const handleSelectChange2 = (selectedOption) => {
    setSelectedLanguage(selectedOption.value); // Update
    props.setSelectedLanguage(selectedOption.value); 
    if (selectedOption.value === 'EN') {
      // Language is set to English
    } else if (selectedOption.value === 'CN') {
      // Language is set to Chinese
    }
  }

  const customSelect2 = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: '#377DED',
        border: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none', 
        boxShadow: 'none', 
        minWidth: '130px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display:'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      transition: 'color 0.2s',
      color: '#377DED',
      fontSize: state.isFocused ? '18px' : '16px',
      position: 'relative',
      "&:hover": {
        color: "#2149B1",
        fontSize: 18,
        //borderBottom: "2px solid #2149B1",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Sofia Pro',
      fontSize: 16,
      color: state.isSelected ? '#FFFFFF' : '#377DED',
      cursor: 'pointer',
      textAlign: 'left',
      "&:active": {
        color: '#FFFFFF',
        background: '#377DED',
      },
      "&:focus": {
        outline: 'none',
      },
      display: 'flex',
      marginTop:'8px',
      marginBottom:'8px'
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: 0,
      position: 'absolute',
    }),
  };

  // const PlaceholderImageEN = () => (
  //   <div style={{display: 'flex', alignItems: 'center' }}>
  //     <span style={{ marginRight: '5px' }}>English</span>
  //     <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '5px' }} />
  //   </div>
  // );

  // const PlaceholderImageCN = () => (
  //   <div style={{display: 'flex', alignItems: 'center' }}>
  //     <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '0px' }} />
  //   </div>
  // );

  
  const PlaceholderImageEN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px'}} />
      <span>EN</span>
    </div>
  );

  const PlaceholderImageCN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px'}} />
      <span>CN</span>
    </div>
  );
  

return (
    <div className={styles.totalContainer}>
      <div className={styles.LeftContainer}>
        {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
        {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
        <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
      </div>

      <div className={styles.langDrop}>
            <Select
              options={options2}
              onChange={handleSelectChange2}
              isSearchable={false} // This will disable user input
              placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
              components={{
                SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
              }}
              styles={customSelect2}
            />
      </div>
      
      <div className={styles.rightContainer}>
        <img className={styles.sentImg} alt="" src="/success.png" />
        <h2 className={styles.sentWord}>{t('success')}</h2>
        <h3 className={styles.sentcontent}>{t('text')}</h3>

        <Link to="/login" className={styles.backButton}>
            {t('back')}
        </Link>

      </div>
    </div>
);
};

export default SuccessPage;