import React, { useState } from 'react';
import OpenAI from 'openai';
//openai.configure({ apiKey: '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' });

const GptTest = () => {
  const [text, setText] = useState('');
  const [response, setResponse] = useState('');



  const generateText = async () => {
    const apiKey = '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'

    try {

        const openai = new OpenAI({
            apiKey, dangerouslyAllowBrowser:true// This is also the default, can be omitted
          });   
          const prompt = `Translate the following English text to Chinese: "${text}"`;
     
          const completion = await openai.completions.create({
            model: "text-davinci-003",
            prompt: "This story begins",
            max_tokens: 30,
          });
          console.log(completion.choices[0].text);
          
      } catch (error) {
        console.error('Error:', error);
      }
  };

  return (
    <div>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter your text here..."
      ></textarea>
      <button onClick={generateText}>Generate</button>
      <h3>Generated Text:</h3>
      <p>{response}</p>
    </div>
  );
};

export default GptTest;
