import React from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import styles from "./WeChat.module.css";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';


function WeChat_logedIn(props){
    const navigate = useNavigate();
    
return(
    <div className={styles.container}>
        <div className={styles.LeftContainer}>
            <img src="/hero.png" alt="bgi" className={styles.BackgroundImg} />
        </div>
        <div className={styles.RightContainer2}>
        <h2>Welcome to ZenithScholars</h2>
        <h3>You will be redirected automatically.</h3>

        </div>
    </div>
)
}
export default WeChat_logedIn;