import React, {useEffect, useState} from "react";
import styles from "./cookieNotice.module.css";
// import CookieConsent, { Cookies } from "react-cookie-consent";
import Cookies from "js-cookie";




function CookieNotice(props) {

    const readCookie = () => {
        let result = [];
        const str = Cookies.get("cookieConsents");
        const list = str.split("&");

        list.forEach((cookie) => {
            const val = parseInt(cookie.split("=")[1]);
            result.push(val);
           
        })


        return result;
    }

    const [hideBanner, setHideBanner] = useState(Cookies.get("cookieSet") !== undefined);
    const [hideWindow, setHideWindow] = useState(true);
    const [cookieConsents, setCookieConsents] = useState(
        Cookies.get("cookieConsents") !== undefined ? readCookie() : [1]
    )

    useEffect(() => {
        writeCookie();
    }, []);

    // useEffect(() => {
    //     console.log(props.currentLocation)
    // //     for (let i = 1; i < cookieConsents.length; i++) {
    // //         console.log(cookieConsents[i])
    // //         if(cookieConsents[i]==0){
    // //             console.log('remove')
    // //             Cookies.remove('_ga');
    
    // //             // 删除所有以 "_ga_" 开头的 cookie
    // //             Object.keys(Cookies.get()).forEach(cookieName => {
    // //                 console.log(cookieName)
    // //                 if (cookieName.startsWith('_ga_')) {
    // //                 Cookies.remove(cookieName);
    // //                 }
    // //             });
    // //     }
    // // }
    // },
    // [props.currentLocation]);

    const translations = {
        EN: {
            banner: "By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.",
            acceptButton: "Accept All Cookies",
            rejectButton: "Reject All Cookies",
            settingsButton: "Cookies Settings",
            consentTitle: "Privacy Preference Center",
            consentContent: "When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.",
            cookiePolicy: "Cookie Policy",
            applyButton: "Apply Settings",
            settingsTitle: "Manage Consent Preferences",
            option1Title: "Strictly Necessary Cookies",
            option1Content: "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
            option1Status: "Always Active",
            option2Title: "Performance Cookies",
            option2Content: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.",
        },

        CN: {
            banner: "单击“接受所有Cookies”，即表示您同意在您的设备上存储Cookies，以增强网站导航、分析网站使用情况并协助我们的营销工作。",
            acceptButton: "接受所有Cookies",
            rejectButton: "拒绝",
            settingsButton: "Cookies设置",
            consentTitle: "隐私偏好中心",
            consentContent: "当您访问任何网站时，它可能会以Cookies的形式在您的浏览器上存储或检索信息。这些信息可能与您、您的偏好或您的设备有关，主要用于使网站按照您的预期运行。这些信息通常不会直接识别您的身份，但可以为您提供更加个性化的网络体验。由于我们尊重您的隐私权，因此您可以选择不允许某些类型的cookies。单击不同的类别标题以了解更多信息并更改我们的默认设置。但是阻止某些类型的 cookies可能会影响您对网站的体验以及我们能够提供的服务。",
            cookiePolicy: "Cookie政策",
            applyButton: "应用Cookie设置",
            settingsTitle: "管理偏好设置",
            option1Title: "必需的Cookies",
            option1Content: "这些Cookies是网站正常运行所必需的，并且无法在我们的系统中关闭。它们通常只是为了响应您所做的请求而设置，例如设置您的隐私首选项、登录或填写表格。您可以将浏览器设置为阻止或提醒您这些Cookies，但网站的某些部分将无法工作。这些Cookies不会存储任何个人身份信息。",
            option1Status: "始终开启",
            option2Title: "性能Cookies",
            option2Content: "这些Cookies使我们能够计算访问量和流量来源，以便我们能够衡量和改进我们网站的性能。它们帮助我们了解哪些页面最受欢迎和最不受欢迎，并了解访问者如何在网站上探索。这些Cookies收集的所有信息都是计算后的，因此是匿名的。如果您不允许这些Cookies，我们将不知道您何时访问了我们的网站，也无法监控其性能。",
        }
    };

    const t = (key) => {
        return translations[props.selectedLanguage][key] || key;
    };

    // const styles = {
    //     banner: {
    //         background: "white",
    //         color: "black",
    //         textAlign: "left",
    //     },
    //
    //     acceptButton: {
    //         background: "#377DED",
    //         color: "white",
    //     },
    //
    //     rejectButton: {
    //         background: "#377DED",
    //         color: "white",
    //     }
    // }

    // IMPORTANT: change the value to add more cookie options, do not forget to add the translation texts above
    const numberOfCookieOptions = 2;

    const cookieOptions =  [];

    for (let i = 1; i <= numberOfCookieOptions; i++) {
        cookieOptions.push(
            <CookieOption
                optionId={i}
                titleText={t(`option${i}Title`)}
                statusText={t(`option${i}Status`)}
                contentText={t(`option${i}Content`)}
                cookieConsents={cookieConsents}
                setCookieConsents={setCookieConsents}
            />
        )
    }

    const writeCookie = () => {
        let str = "cookie1=1";
        console.log('writeCookie')
        for (let i = 1; i < cookieConsents.length; i++) {
            str += `&cookie${i+1}=${cookieConsents[i]}`;
            if(cookieConsents[i]==0){
                Cookies.remove('_ga');
    
                // 删除所有以 "_ga_" 开头的 cookie
                Object.keys(Cookies.get()).forEach(cookieName => {
                    if (cookieName.startsWith('_ga_')) {
                    Cookies.remove(cookieName);
                    }
                });

        }
       

        } 

        Cookies.set("cookieConsents", str, { expires: 365 });
    }

    const acceptCookie = (isAccept) => {
        let result = [];

        result[0] = 1;
        for (let i = 1; i < numberOfCookieOptions; i++) {
            result[i] = isAccept;
        }

        setCookieConsents(result);
    }

    const writeAcceptCookie = (isAccept) => {
        let str = "cookie1=1";
        for (let i = 1; i < numberOfCookieOptions; i++) {
            str += `&cookie${i+1}=${isAccept}`;
        }

        Cookies.set("cookieConsents", str, { expires: 365 });
    }

    // useEffect(() => {
    //     let result = [];
    //     if (Cookies.get("cookieConsents") === undefined) {
    //         result = acceptCookie(0);
    //     } else {
    //         result = readCookie();
    //     }
    //
    //     setCookieConsents(result);
    // }, []);

    const handleOnAccept = () => {
        acceptCookie(1);
        writeAcceptCookie(1);
        Cookies.set("cookieSet", true, { expires: 365 });
        setHideWindow(true);
        setHideBanner(true);
        window.location.reload()

    };

    const handleOnReject = () => {
        acceptCookie(0);
        writeAcceptCookie(0);
        Cookies.set("cookieSet", true, { expires: 365 });
        setHideWindow(true);
        setHideBanner(true);
        window.location.reload()

    };

    const handleSettingsOnClick = () => {
        setHideWindow(false);
    };

    const handleOnCloseBanner = () => {
        setHideBanner(true);
    }

    const handleIconOnClick = () => {
        if (hideWindow) {
            setHideWindow(false);
        } else {
            setHideWindow(true);
        }
    }

    const handleOnCloseWindow = () => {
        setHideWindow(true);
    }

    const handleApplyOnClick = () => {
        writeCookie();
        Cookies.set("cookieSet", true, { expires: 365 });
        
        setHideWindow(true);
        setHideBanner(true);
        window.location.reload()

    }

    return (
        // <CookieConsent
        //     enableDeclineButton
        //     location="bottom"
        //     style={styles.banner}
        //     buttonStyle={styles.acceptButton}
        //     declineButtonStyle={styles.rejectButton}
        //     buttonText={t("acceptButton")}
        //     declineButtonText={t("rejectButton")}
        // >
        //     {t("banner")}
        // </CookieConsent>
        <div>
            <div className={`${styles.cookiesBanner} ${hideBanner ? styles.hidden : ""}`}>
                <div className={styles.bannerText}>
                    {/* <div>
                        <img src={"https://intelliprozenithscholars.com/logo_small.png"} alt={"Zenith Scholars Logo"}/>
                    </div> */}
                    <div>{t("banner")}</div>
                    <button
                        className={styles.button3}
                        onClick={handleOnCloseBanner}
                    >
                        <img src='/closeicon.png'/>
                    </button>
                </div>
                <div className={styles.buttons1}>
                <button
                        className={styles.button4}
                        onClick={handleSettingsOnClick}
                    >
                        {t("settingsButton")}
                    </button>
                    </div>

                <div className={styles.buttons}>
                    
                    <button
                        className={styles.button2}
                        onClick={handleOnReject}
                    >
                        {t("rejectButton")}
                    </button>
                    <button
                        className={styles.button1}
                        onClick={handleOnAccept}
                    >
                        {t("acceptButton")}
                    </button>
                   
                </div>
            </div>

            {/* <div
                className={styles.cookiesIcon}
                onClick={handleIconOnClick}
            >
                <img src={"https://intelliprozenithscholars.com/logo_small.png"} alt={"Cookies Icon"}/>
            </div> */}

            <div
                className={`${styles.overlay} ${hideWindow ? styles.hidden : ""}`}
                onClick={handleOnCloseWindow}
            ></div>

            <div className={`${styles.cookiesWindow} ${hideWindow ? styles.hidden : ""}`}>
                <div className={styles.windowHeader}>
                    <img src={`https://intelliprozenithscholars.com/new_logo${props.selectedLanguage === "CN" ? "_cn" : ""}.svg`} alt={"ZenithScholar Logo"}/>
                    <button
                        className={styles.button3}
                        onClick={handleOnCloseWindow}
                    >
                    <img src='/closeicon.png'/>

                    </button>
                </div>
                <div className={styles.windowContent}>
                    <h2>{t("consentTitle")}</h2>
                    <div>
                        {t("consentContent")}
                        <br/>
                        <a href={"/cookie"} target={"_blank"}>{t("cookiePolicy")}</a>
                    </div>

                    <br/>
                    <button
                        className={styles.button5}
                        onClick={handleOnAccept}
                    >
                        {t("acceptButton")}
                    </button>

                    <h3>{t("settingsTitle")}</h3>
                    {cookieOptions}
                </div>
                <div className={styles.windowFooter}>
                    <button
                        className={styles.button6}
                        onClick={handleOnReject}
                    >
                        {t("rejectButton")}
                    </button>
                    <button
                        className={styles.button5}
                        onClick={handleApplyOnClick}
                    >
                        {t("applyButton")}
                    </button>
                </div>
            </div>
        </div>
    );
}

function CookieOption(props) {

    const [hideOption, setHideOption] = useState(true);

    const setCookieConsentsElement = (val) => {
        let result = props.cookieConsents;

        result[props.optionId-1] = val;

        props.setCookieConsents(result);
    }

    useEffect(() => {
        if (props.cookieConsents && props.optionId !== 1) {
            document.getElementById(`option${props.optionId}Checkbox`).checked = props.cookieConsents[props.optionId-1] === 1
        }
    }, [props.cookieConsents])

    const handleOptionOnClick = () => {
        if (hideOption) {
            setHideOption(false);
        } else {
            setHideOption(true);
        }
    }

    const handleOptionOnChange = () => {
        if (document.getElementById(`option${props.optionId}Checkbox`).checked) {
            setCookieConsentsElement(1);
        } else {
            setCookieConsentsElement(0);
        }
    }

    return (
        <div>
            <div className={styles.cookieOptionTitle}>
                <h4 className={styles.cookieOptionTitleText} onClick={handleOptionOnClick}>
                    <span className={!hideOption ? styles.hidden : ""}><img src='/downicon.png' />&nbsp;&nbsp;</span>
                    <span className={hideOption ? styles.hidden : ""}><img src='/upicon.png'/>&nbsp;&nbsp;</span>
                    {props.titleText}
                </h4>
                {props.optionId === 1 ?
                    <h4 className={styles.cookieOption1Status}>{props.statusText}</h4>
                    :
                    <input
                        id={`option${props.optionId}Checkbox`}
                        type={"checkbox"}
                        onChange={handleOptionOnChange}
                    />}
            </div>
            <div className={`${styles.cookieOptionContent} ${hideOption ? styles.hidden : ""}`}
                 onClick={handleOptionOnClick}>
                {props.contentText}
            </div>
        </div>
    );
}

export default CookieNotice;