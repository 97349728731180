// npm install react-router-dom
import ReactDOM from 'react-dom';
import styles from './Result.module.css'
import { useState, useEffect } from "react"
// npm i --save react-select
// npm install react-select
import React from "react";
import {fetchResumes} from './fetch'
import { fetchTotalNum } from './fetch';
import { fetchTotalNameNum } from './fetch';
import Select from "react-select";
import translateCN from '../SubjectFilter/mapping_dict.json'
// Scholar PDFs
// import { Link } from "react-router-dom";

// Data imported
// import data from '../fake_data12.json'


// Pagination
import { Link, Routes } from "react-router-dom";
import { BrowserRouter, Route, useSearchParams} from 'react-router-dom';
import GeneratePdf from './GeneratePdf';
import ResumePage from '../ResumePage/ResumePage';
import ResumeDetailPage from '../ResumePage/ResumeDetail';
// import data from '../fake_data12.json'

//import request from '../request.json';
import request from '../sampleResponse.json'
import PieGenerator from '../ResumePage/chart/PieGenerator';
import BarGenerator from '../ResumePage/chart/BarGenerator';
import e from 'cors';
import fakedata from '../request.json'



function Result_test(props) {
    const [searchParams] = useSearchParams();
    const filter = searchParams.get('k');
 
    const translations = {
        EN: {
            outof: " Out of",
            Results: " Results",
            sort: "Sort by",
            recommend: "Recommendation",
            citHL: "Number of Citations (High to Low)",
            citLH: "Number of Citations (Low to High)",
            pubHL: "Number of Publications (High to Low)",
            pubLH: "Number of Publications (Low to High)",
        },
        CN: {
            outof: "个结果",
            Results: "个总结果",
            sort: "排序方式",
            recommend: "推荐排序",
            citHL: "论文引用次数（从高到低）",
            citLH: "论文引用次数（从低到高）",
            pubHL: "论文发布数量（从高到低）",
            pubLH: "论文发布数量（从低到高）",
        },
      // You can add more languages here
    };
    const t = (key) => {
        return translations[props.selectedLanguage][key] || key;
    };

    // State to store the selected sorting option
    let array = Array.from({ length: 41 }, () => false); 

    const options = [
        { label: t('recommend'), value: "Option5" },
        { label: t('citHL'), value: "Option1" },
        { label: t('citLH'), value: "Option2" },
        { label: t('pubHL'), value: "Option3" },
        { label: t('pubLH'), value: "Option4" }
    ];
    let latestSequence = 0;
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: 5,
            width: 370,
            height: 40,
            fontFamily: 'Sofia Pro Light Az',
            fontSize: 20,
            color: '#5E7594',
            border: '3px solid #ccc',
            padding: '0px 0px',
        }),
      };      
    const [selectedOption, setSelectedOption] = useState({
        label: t('recommend'),
        value: "Option5",
    });
    const [pageDict, setPageDict] = useState({});
    const cardsPerPage = 15; // Number of cards per page

    // State declarations for data and originalData
    const [data, setData] = useState(props.state);
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [originalData, setOriginalData] = useState([]);
    const [resumeData, setResumeData] = useState([]);
    const [page, setPage] = useState(1);
    const [getData, setGetData] = useState(false);
    const [total, setTotal] = useState(-1);
    // Function to handle the sorting based on the selected option
    const sortData = (option) => {
        latestSequence++; 
     //   console.log('sorting...')
        const selectedValue = option.value;
        let sortedData;

        if (selectedValue === "Option5") {
            // Use the original data when "Recommendation" is selected
            sortedData = [...originalData];
        } else {
            sortedData = [...originalData];
            switch (selectedValue) {
                case "Option1": // Number of Citations (High to Low)
                    sortedData = sortedData.sort((a, b) => b.total_citation - a.total_citation);
                    // console.log(sortedData.slice(1, 10));
                    break;
                case "Option2": // Number of Citations (Low to High)
                    sortedData = sortedData.sort((a, b) => a.total_citation - b.total_citation);
                    break;
                case "Option3": // Number of Publications (High to Low)
                    sortedData = sortedData.sort((a, b) => b.total_pub - a.total_pub);
                    break;
                case "Option4": // Number of Publications (Low to High)
                    sortedData = sortedData.sort((a, b) => a.total_pub - b.total_pub);
                    break;
                default:
                    break;
            }
        }
        setResumeData([...sortedData]);
        setCurrentPage(1); // Reset to the first page after sorting
    };
    // Update the state when props.state changes
    useEffect(() => {
        if (props.state){
            latestSequence++; //无法修复再次点击search会继续fetch之前未完成的部分
        }
        setData(props.state);
        setOriginalData(props.state);
        const resumeAll = {
            data: props.state
        }
        localStorage.setItem('resumeAll', JSON.stringify(resumeAll));
    }, [props.state]);

    const fetchPagex = async(page) => {
        const thisSequence = ++latestSequence;
        // console.log(thisSequence, latestSequence);
        if (page > Math.ceil(data.length / cardsPerPage) ) {
            setOriginalData(resumeData);
            setGetData(false);
            if(props.searchBy=='filter'){
            fetchTotalNum(props.general, props.subject).then((data) => {
                console.log("total:",data);
                setTotal(data['total_scholars']);
            })
            .catch((error) => {
                console.error("Promise rejected:", error);
            });
        }else{
            console.log('name total',props.name);
            fetchTotalNameNum(props.name).then((data) => {
                // console.log("total:",data);
                setTotal(data['total_num']);
            })
            .catch((error) => {
                console.error("Promise rejected:", error);
            });
        }

            return;
        }        
        const startIndex = (page - 1) * cardsPerPage;
        let endIndex = 0;
        if (page <= 5){
            endIndex = ((startIndex + cardsPerPage) < data.length) ? startIndex + cardsPerPage : data.length;
        }
        else{
            endIndex = ((startIndex + cardsPerPage * 5) < data.length) ? startIndex + cardsPerPage * 5 : data.length;
        }
        let ids = {"ids":[]};
        for (let i = startIndex; i < endIndex; i++) {
            if (Array.isArray(data[i]) && data[i].length > 0) {
                ids['ids'].push(data[i][0]);    
            }
        }
      //  console.log('page:', page, "resumeData:", resumeData);
        fetchResumes(ids).then((data) => {
            const newdata = data['data'];
            newdata.sort((a, b) => ids.ids.indexOf(a.id) - ids.ids.indexOf(b.id));
            if (thisSequence === latestSequence) {
                setResumeData(prevResumeData => [...prevResumeData, ...newdata]);
                (page <= 5) ? setPage(page + 1) : setPage(page + 5);
            }
        })
        .catch((error) => {
            console.error("Promise rejected:", error);
        });
    
    
}


    useEffect(() => {
            // console.log('page',page)
           fetchPagex(page);
    }, [page]);

    useEffect(() => {
        setResumeData([]);
        setTotal(-1);
       // console.log('datachanged,sequence:', latestSequence);
        // console.log("updating...")
        if (data.length>0) {
            setGetData(true);
            setPage(1);
            setCurrentPage(1);
            // console.log('data',data)
        }else{
            setPage(2); 
            setCurrentPage(0);
        }

    }, [data]);

    useEffect(() => {
        if (currentPage > Math.ceil(resumeData.length / cardsPerPage)) {
            props.setIsSearching(true);
            // console.log('currentPage:', currentPage);
            // console.log('改变searching：true1')
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentPage <= Math.ceil(resumeData.length / cardsPerPage) ) {
            props.setIsSearching(false);
         }
         
        else{
            props.setIsSearching(true);
            // console.log('改变searching:true2')
        }
        console.log(total);
    }, [resumeData]);

    // useEffect(() => {
    //     if(total==0 && total!=-1){
    //         props.setIsSearching(false);
    //      }
    // }, [total]);



    const nameList = resumeData.map(item => item.name);
   // console.log("nameList:", nameList);
    const jobTitle = resumeData.map(item => item.title);
    const company = resumeData.map(item => item.employer);
    
    const getAfterLastComma = (str) => {
        const lastCommaIndex = str.lastIndexOf(', ');
        if (lastCommaIndex !== -1) {
            return str.substring(lastCommaIndex + 2);
        }
        return str;
    };

    function truncateName(name, maxChars) {
        if (name.length > maxChars) {
          return name.slice(0, maxChars) + '...';
        } else {
          return name;
        }
    }
    function getJobComp(nameList, jobTitle, company, currentPage, cardsPerPage) {
        const startIndex = (currentPage - 1) * cardsPerPage;
        const endIndex = startIndex + cardsPerPage;
        const JobComp = nameList.slice(startIndex, endIndex).map((name, index) => {
            const currentJob = jobTitle[index + startIndex];
            const currentComp = company[index + startIndex];
            const JobCompList = [currentJob, currentComp];
            return JobCompList;
        });
        // console.log(JobComp);
        return JobComp;
    }
    const subject = resumeData.map(item => item.subject);
    // const paper = subject.map(item => item.paper);
    function calculatePaperValues(nameList, subject, currentPage, cardsPerPage) {
        // Calculate the start and end indexes for the current page
        const startIndex = (currentPage - 1) * cardsPerPage;
        const endIndex = startIndex + cardsPerPage;
        const PaperValues = nameList.slice(startIndex, endIndex).map((name, index) => {
          const currentSubject = subject[index + startIndex];
          let paperSums=[]
          if(currentSubject){
          const currentPaper = Object.keys(currentSubject)[0];
          const subjectData = currentSubject[currentPaper];
          paperSums = Object.entries(subjectData).map(([paper, data]) => {
            const { Q1 = 0, Q2 = 0, "Q3&4": Q34 = 0 } = data;
            return {
              paper,
              sum: Q1 + Q2 + Q34,
            };
          });
        }
          paperSums.sort((a, b) => b.sum - a.sum);
          const top4Papers = paperSums.slice(0, 6);
          const firstPaper = paperSums.length > 0 ? paperSums[0].paper : '';
          const secondPaper = top4Papers.length > 1 ? top4Papers[1].paper : '';
          const thirdPaper = top4Papers.length > 2 ? top4Papers[2].paper : '';
          const fourthPaper = top4Papers.length > 3 ? top4Papers[3].paper : '';
          const fifthPaper = top4Papers.length > 4 ? top4Papers[4].paper : '';
          const sixthPaper = top4Papers.length > 5 ? top4Papers[5].paper : '';
          const PaperList = [firstPaper, secondPaper, thirdPaper, fourthPaper, fifthPaper, sixthPaper];
          return PaperList;
        });
        return PaperValues;
    }
    
    const LineLength = 15;
    // 5 Functions to control which topics to show in each line
    const PrintTopic = (str1, str2) => {
        if(props.selectedLanguage == 'EN'){
            if (str1.length <= LineLength && str2.length <= LineLength ) {
                return [str1, str2];
            } else {
                return [str1, ''];
            }
        }else if(props.selectedLanguage == 'CN'){
            if (str1 && str2 && str1.length <= LineLength && str2.length <= LineLength ) {
                const tStr1 = translateCN[str1]? translateCN[str1]: str1;
                const tStr2 = translateCN[str2]? translateCN[str2]: str2;
                return [tStr1,tStr2];
            } else {
                const tStr1 = translateCN[str1]? translateCN[str1]: str1;
                if(! translateCN[str1]){
                    console.log(str1)
                }
                return [tStr1, ''];
            }
        }
      
    };
    const PrintedOrNot = (str1, str2) => {
        if (str1.length <= LineLength && str2.length <= LineLength) {
            return true;
        } else {
            return false;
        }
    };
    const Print2Line = (str1, str2, str3, str4) => {
        if (PrintedOrNot(str1, str2)) {
            return PrintTopic(str3, str4);
        } else {
            return PrintTopic(str2, str3);
        }
    };
    const PrintedOrNot2 = ([str1, str2]) => {
        if (str2=='') {
            return false;
        } else {
            return true;
        }
    };
    const Print3Line = (str1, str2, str3, str4, str5, str6) => {
        if (PrintedOrNot(str1, str2)) {
            if (PrintedOrNot(str3, str4)) {
                return PrintTopic(str5, str6);
            } else {
                return PrintTopic(str4, str5);
            }
        } else {
            if (PrintedOrNot(str2, str3)) {
                return PrintTopic(str4, str5);
            } else {
                return PrintTopic(str3, str4);
            }
        }
    };
      
    function hasChar(str) {
        if(str){
            return str.trim() !== '';
        }
    }
    // console.log("calculating paperValues", nameList, subject, currentPage, cardsPerPage)
    const PaperValues = calculatePaperValues(nameList, subject, currentPage, cardsPerPage);
    const JobComp = getJobComp(nameList, jobTitle, company, currentPage, cardsPerPage);
    // console.log("PaperValues", PaperValues);

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = nameList.slice(indexOfFirstCard, indexOfLastCard);
    // console.log("Current Cards:", currentCards);

    const scrollSmoothTo = (position) => {
        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({
                top: position,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo(0, position);
        }
    };
    
    // 用法示例
    

    const handlePageChange = (pageNumber) => {
        // console.log("handlePageChange");
        if (pageNumber >= 1 && pageNumber <= Math.ceil(data.length / cardsPerPage)) {
            setCurrentPage(pageNumber); 
            scrollSmoothTo(100); // 将页面滚动到位置500

        }
    };
    const totalPages = Math.ceil(data.length / cardsPerPage);
    
    // const visiblePages = Array.from({ length: totalPages - 2 }, (_, index) => index + 2);
    const visiblePages = totalPages <= 5
    ? Array.from({ length: totalPages -1}, (_, index) => index+2 )
    : Array.from({ length: totalPages - 2 }, (_, index) => index + 2);
    
    const renderPagination = () => {
        const currentPageIndex = currentPage - 1;
        const paginationLinks = [];
        
        // Conditions Split
        if (currentPage < 5) {
            // "1 2 3 4 ... N"
            const cond1Pages = Array.from({length:3}, (_, index) => index + 2);
            cond1Pages.map((cond1Pages) => (
                paginationLinks.push(
                    <Link
                    to={`/home/?k=${filter}#`}
                    key={cond1Pages}
                        onClick={() => handlePageChange(cond1Pages)}
                        className={currentPage === cond1Pages ? styles.activePage : ""}
                        >
                        {cond1Pages}
                    </Link>
            )))
            paginationLinks.push(
                <button 
                    className={styles.pageButton}
                    style={{ margin: 0, padding: '10px' }}
                    key="next5" onClick={() => handlePageChange(5)}>...</button>
            )
        } else if (currentPage >= (totalPages-2)) {
            // "1 ... N-3 N-2 N-1 N"
            paginationLinks.push(
                <button
                    className={styles.pageButton}
                    style={{ margin: 0, padding: '10px' }}
                    key="beforeN-3" onClick={() => handlePageChange(totalPages-3)}>...</button>
            )
            const cond3Pages = [totalPages-2, totalPages-1, totalPages];
            cond3Pages.map((cond3Pages) => (
                paginationLinks.push(
                    <Link
                        to={`/home/?k=${filter}#`}
                        key={cond3Pages}
                        onClick={() => handlePageChange(cond3Pages)}
                        className={currentPage === cond3Pages ? styles.activePage : ""}
                        >
                        {cond3Pages}
                    </Link>
            )))
        } else {
            // "1 ... n-1 n n+1 ... N"
            paginationLinks.push(
                <button 
                    className={styles.pageButton}
                    style={{ margin: 0, padding: '10px' }}
                    key="beforen-1" onClick={() => handlePageChange(currentPage-2)}>...</button>
            )
            const cond2Pages = [currentPage-1, currentPage, currentPage+1];
            cond2Pages.map((cond2Pages) => (
                paginationLinks.push(
                    <Link
                       to={`/home/?k=${filter}#`}
                        key={cond2Pages}
                        onClick={() => handlePageChange(cond2Pages)}
                        className={currentPage === cond2Pages ? styles.activePage : ""}
                        >
                        {cond2Pages}
                    </Link>
            )))
            paginationLinks.push(
                <button 
                    className={styles.pageButton}
                    style={{ margin: 0, padding: '10px' }}
                    key="aftern+1" onClick={() => handlePageChange(currentPage+2)}>...</button>
            )
        }
        return paginationLinks;
    };
    const cardsPerRow = 5;  // Number of cards per row

    // Add state variables and functions to handle the "GoToPage" functionality
    const [goToPageValue, setGoToPageValue] = useState(""); // State to store the value entered in the "Go To Page" input field
    const handleGoToPage = () => {
        const pageNumber = parseInt(goToPageValue);
        if (pageNumber >= 1 && pageNumber <= Math.ceil(data.length / cardsPerPage)) {
            setCurrentPage(pageNumber);
        }
    };
    const [count, setCount] = useState(0);

    const navigateToNewPage = (index) => {
      // 使用window.open打开新标签页，并将状态传递为URL参数
      const resumeInfo = {
        language: props.selectedLanguage,
        data: resumeData[index]
      };
      localStorage.setItem('resumeData', JSON.stringify(resumeInfo));
    //   console.log(localStorage.getItem('resumeData'));
    //   console.log(props.selectedLanguage)
    // if(props.authLevel === 'admin'){
    //     window.open(`/resume/download/${index}/${resumeData[index].id}`, '_blank');
    // }else{
    //     window.open(`/resume/${index}/${resumeData[index].id}`, '_blank');
    // }
        window.open(`/resume/${index}/${resumeData[index].id}`, '_blank');

    };

    const [isTopHalf, setIsTopHalf] = useState(true);

    // Function to check if the page is scrolled in the top half or bottom half
    function checkScrollPosition() {
        const scrollPosition = window.scrollY; // Current scroll position
        const viewportHeight = window.innerHeight; // Height of the viewport
    if (scrollPosition < viewportHeight / 2) {
        // Page is scrolled in the top half
        setIsTopHalf(true);
    } else {
        // Page is scrolled in the bottom half
        setIsTopHalf(false);
    }}

    // Attach a scroll event listener when the component mounts
    useEffect(() => {
        window.addEventListener("scroll", checkScrollPosition);
        return () => {
            window.removeEventListener("scroll", checkScrollPosition);
        };
    }, []);

    return (
        <div className={styles.total} id="Search_result">
        <div className={styles.lineContainer}>
            {/* <div className={styles.loading_dots}>
                <div className={styles.loading_dots__dot}></div>
                <div className={styles.loading_dots__dot}></div>
                <div className={styles.loading_dots__dot}></div>
            </div> */}
               {props.selectedLanguage === 'EN' && total < 0 && (
                <div>
                    <h1 className={styles.selected}>
                    {data.length} {t('outof')}
                    <div className={styles.loading_dots__dot}></div>
                    <div className={styles.loading_dots__dot}></div>
                    <div className={styles.loading_dots__dot}></div>
                    {t('Results')}
                    </h1>
                </div>
                )}


                {props.selectedLanguage === 'CN' && total < 0 && (
                <div>
                    <h1 className={styles.selected}>
                   共计
                    <div className={styles.loading_dots__dot}></div>
                    <div className={styles.loading_dots__dot}></div>
                    <div className={styles.loading_dots__dot}></div>
                    条结果，当前展示{data.length}条结果
                    </h1>
                </div>
                )}


            {/* {(total< 0) && <div>
                <h1 className={styles.selected}>{data.length + t('outof')} <div className={styles.loading_dots__dot}></div>
                <div className={styles.loading_dots__dot}></div>
                <div className={styles.loading_dots__dot}></div>
                {t('Results')}</h1> 
            </div>} */}
            {/* {props.selectedLanguage == 'EN' &&   {(total >=0) && ( (data.length < 600)     ? 
            <h1 className={styles.selected}>{data.length} {t('Results')}</h1>
            : <h1 className={styles.selected}>600 {t('outof')} {total}  {t('Results')}</h1>)}}
           {props.selectedLanguage == 'CN' &&   {(total >=0) && ( (data.length < 600)     ? 
            <h1 className={styles.selected}>{data.length} {t('Results')}</h1>
            : <h1 className={styles.selected}>共{total}结果，目前展示600条结果  </h1>)}} */}

        {props.selectedLanguage === 'EN' && total >= 0 && (
        data.length < 600 ? (
            <h1 className={styles.selected}>{data.length} {t('Results')}</h1>
        ) : (
            <h1 className={styles.selected}>600 {t('outof')} {total} {t('Results')}</h1>
        )
        )}

        {props.selectedLanguage === 'CN' && total >= 0 && (
        data.length < 600 ? (
            <h1 className={styles.selected}>共计{data.length}条结果</h1>
        ) : (
            <h1 className={styles.selected}>共计{total}条结果，目前展示600条结果</h1>
        )
        )}
          
            {/* <h1 className={styles.split} style={{ marginLeft: "10px" }}>|</h1>
                <button className={`${styles.export} ${styles.customButton}`} style={{ marginLeft: "10px" }}>Export</button>
                <button className={`${styles.export} ${styles.customButton}`} style={{ marginLeft: "20px" }}>Add to a list</button>
                <button className={`${styles.export} ${styles.customButton}`} style={{ marginLeft: "20px" }}>Remove from a list</button> */}
            <div className={styles.sortContainer}>
                <h1 className={styles.sort}>{t('sort')}</h1>
                <div className={styles.sort_rect}>
                    <Select
                        value={selectedOption}
                        options={options}
                        onChange={(option) => {
                            setSelectedOption(option);
                            sortData(option);
                        }}
                        isDisabled={getData}
                        styles={customStyles}
                        menuPlacement="bottom"
                        menuShouldScrollIntoView={false}
                        classNamePrefix="custom-dropdown-menu"
                        //components={{
                        //    DropdownIndicator: CustomDropdownIndicator
                        //}}
                    />
                </div>
            </div>
        </div>

        <hr className={styles.line}/>
        {/* <PieGenerator />
        <BarGenerator /> */}

        <section className={styles.cardGroup}>
            <div className={styles.rowCard}>
                {/* Pagination */}
                {/* {nameList.map((name, index) => ( */}
                {currentCards.map((name, index) => (
                    <div
                        className={styles.card}
                        key={index}
                        style={{ marginLeft: index % cardsPerRow !== 0 ? "25px" : "25px" }}
                    >

                        <div className={styles.cardImg} 
                    
                        onClick={()=>{navigateToNewPage(index+(currentPage-1)*15)}}>
                            <ResumePage state = {resumeData[index+(currentPage-1)*15]} selectedLanguage={props.selectedLanguage}/>
                        </div>

                        <div className={styles.paraP}>
                            <h2 className={styles.fnln}>{truncateName(name.split("(")[0].trim(), 19)}</h2>
                            <h2 className={styles.jic}>{JobComp[index][0]}</h2>
                            <h2 className={styles.jic}>{truncateName(getAfterLastComma(JobComp[index][1]), 28)}</h2>
                        </div>
                        <div className={styles.TopicContainer}>
                            {/* <div className={styles.flsj}>
                                <h3 className={styles.subject}>{PrintTopic(PaperValues[index][0], PaperValues[index][1])}</h3>
                            </div> */}
                            {PrintedOrNot(PaperValues[index][0], PaperValues[index][1]) ? <div className={styles.flsj}>
                                <h3 className={hasChar(PrintTopic(PaperValues[index][0], PaperValues[index][1])[0]) ? styles.subject : styles.noBackground}>
                                    {PrintTopic(PaperValues[index][0], PaperValues[index][1])[0]}</h3>
                                <h3 className={hasChar(PrintTopic(PaperValues[index][0], PaperValues[index][1])[1]) ? styles.subject : styles.noBackground}>
                                    {PrintTopic(PaperValues[index][0], PaperValues[index][1])[1]}</h3>
                                </div> : <div className={styles.flsj}>
                                    <h3 className={styles.subject}>{PrintTopic(PaperValues[index][0], PaperValues[index][1])[0]}</h3>
                                    </div>}                           
                            {/* <div className={styles.flsj}>
                                <h3 className={styles.subject}>{Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])}</h3>
                            </div> */}
                            {PrintedOrNot2(Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])) ? <div className={styles.flsj}>
                                <h3 className={styles.subject}>{Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])[0]}</h3>
                                <h3 className={styles.subject}>{Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])[1]}</h3>
                                </div> : <div className={styles.flsj}>
                                    <h3 className={hasChar(Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])[0]) ? styles.subject : styles.noBackground}>
                                        {Print2Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3])[0]}</h3>
                                    </div>}
                            {/* <div className={styles.flsj}>
                                <h3 className={styles.subject}>{Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])}</h3>          
                            </div> */}
                            {PrintedOrNot2(Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])) ? <div className={styles.flsj}>
                                <h3 className={styles.subject}>{Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[0]}</h3>
                                <h3 className={styles.subject}>{Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[1]}</h3>
                                </div> : <div className={styles.flsj}>
                                    {/* <h3 className={styles.subject}>{Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[0]}</h3> */}
                                    <h3 className={hasChar(Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[0]) ? styles.subject : styles.noBackground}>
                                        {Print3Line(PaperValues[index][0], PaperValues[index][1], PaperValues[index][2], PaperValues[index][3], PaperValues[index][4], PaperValues[index][5])[0]}</h3>
                                    </div>}
                        </div>
                    </div>
                ))}
            </div>
        </section>   

        <hr className={styles.line}/>

        {/* Pagination */} 
        <div className={styles.pagination}>
            {/* <h1 className={styles.results}>{nameList.length} Results</h1> 
            <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
            >
                &lt;
            </button>
            {Array.from({ length: Math.ceil(nameList.length / cardsPerPage) }, (_, index) => (
                <Link
                    to="#"
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? styles.activePage : ""}
                >
                    {index + 1}
                </Link>
            ))}                 
            <button
                disabled={currentPage === Math.ceil(nameList.length / cardsPerPage)}
                onClick={() => handlePageChange(currentPage + 1)}
            >
                &gt;
            </button>
            */} 

            {/* Page 1 Always */}
            <Link
                to={`/home/?k=${filter}#`}
                key={1}
                onClick={() => handlePageChange(1)}
                className={currentPage === 1 ? styles.activePage : ""}
                >
                {1}
            </Link>

            {/* Page 2 to Page N-1 */}
            {totalPages < 6 ? (
                visiblePages.map((pageNumber) => (
                    <Link
                        to={`/home/?k=${filter}#`}
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={currentPage === pageNumber ? styles.activePage : ""}
                        >
                        {pageNumber}
                    </Link>
                )) 
            ) : (
                <>{renderPagination()}</>
            )}
            
            {/* Page N Always 
            <Link
                to="#"
                key={totalPages}
                onClick={() => handlePageChange(totalPages)}
                className={currentPage === totalPages ? styles.activePage : ""}
                >
                {totalPages}
            </Link>
            */}
            
            
            {/* 
            <div className={styles.goToPage}>
                <b className={styles.pw}>Page</b>
                <input
                    type="text"
                    value={goToPageValue}
                    onChange={(event) => setGoToPageValue(event.target.value)}
                />
                <button className={styles.gtpw} onClick={handleGoToPage}>
                    <b className={styles.pw}>Go</b></button>
            </div>
            */}

        </div>
</div>
    );
}


export default Result_test;