import React, { useEffect, useState } from "react";
import styles from "./linkSent.module.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';
import {  useNavigate , useParams } from 'react-router-dom';

const FailPage = (props) => {
  const navigate = useNavigate();
  const { email } = useParams();
  const [message, setMessage] = useState('');
  console.log(email);
  const translations = {
    EN: {
      fail: "Failed!",
      text: "The verification link has become invalid.",
      resend: "Resend Link",

    },
    CN: {
      fail: "验证失败!",
      text: "验证链接已失效。",
      resend: "重新发送",

    },
  };

  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };

  const SelectedOptionWithSVG = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
      <span style={{ marginRight: '5px' }}>{label}</span>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '0px' }} />
    </div>
  );

  const options2 = [
    { value: 'EN', label: 'English' },
    { value: 'CN', label: '中文(简体)' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

  const handleSelectChange2 = (selectedOption) => {
    setSelectedLanguage(selectedOption.value); // Update
    props.setSelectedLanguage(selectedOption.value); 
    if (selectedOption.value === 'EN') {
      // Language is set to English
    } else if (selectedOption.value === 'CN') {
      // Language is set to Chinese
    }
  }

  const customSelect2 = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: '#377DED',
        border: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none', 
        boxShadow: 'none', 
        minWidth: '130px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display:'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      transition: 'color 0.2s',
      color: '#377DED',
      fontSize: state.isFocused ? '18px' : '16px',
      position: 'relative',
      "&:hover": {
        color: "#2149B1",
        fontSize: 18,
        //borderBottom: "2px solid #2149B1",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Sofia Pro',
      fontSize: 16,
      color: state.isSelected ? '#FFFFFF' : '#377DED',
      cursor: 'pointer',
      textAlign: 'left',
      "&:active": {
        color: '#FFFFFF',
        background: '#377DED',
      },
      "&:focus": {
        outline: 'none',
      },
      display: 'flex',
      marginTop:'8px',
      marginBottom:'8px'
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: 0,
      position: 'absolute',
    }),
  };

  const PlaceholderImageEN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '5px' }}>English</span>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '5px' }} />
    </div>
  );

  const PlaceholderImageCN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '5px' }}>Chinese (中文)</span>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '0px' }} />
    </div>
  );
  function encodeFormData(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
  }
  const handleResend = (e) =>{
    const formData = {
      "email": email,
      "port": 3001
    }
    console.log({
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      credentials: 'include',
      body: encodeFormData(formData)
    })
    fetch("https://intelliprozenithscholars.com/auth/resendActivate/", {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      credentials: 'include',
      body: encodeFormData(formData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("Resend request failed with status: " + response.status);
      }
      return response.json();
    })
    .then(data => {
      console.log("data:", data);
      if (data.success) {
        console.log(data.message);
        navigate('/linkSent', { state: { formData: formData } });
        // setMessage(data.message);  // Display success message to the user
        // Redirect to another page or show a success message, etc.
      } 
      else{
        if (data.message === 'User already activated'){
          setMessage('User already activated.')
          console.log(message);
        }
        else if (data.message === 'User not found'){
          setMessage('User not found.');
        }
        else if (data.message === 'Invalid or missing email'){
          setMessage('Invalid or missing email');
        }
        else{
          setMessage('Error during resend.');
          console.log(message);
        }
      }
    })
  }
  

return (
    <div className={styles.totalContainer}>
      <div className={styles.LeftContainer}>
          <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
      </div>
      
      <div className={styles.rightContainer}>
        <div className={styles.langDrop2}>
            <Select
              options={options2}
              onChange={handleSelectChange2}
              placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
              components={{
                SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
              }}
              styles={customSelect2}
            />
        </div>
        {/*<img className={styles.sentImg} alt="" src="/success.png" />*/}
        <img className={styles.failImg} alt="" src="/failed.svg" />
        <h2 className={styles.sentWord}>{t('fail')}</h2>
        <h3 className={styles.sentcontent}>{t('text')}</h3>

        {/* Need to be Modified*/}
        {/* <Link to="/" className={styles.backButton}>
            {t('resend')}
        </Link> */}
        <button onClick={handleResend} className={styles.backButton}>
          {t('resend')}
        </button>
      </div>
    </div>
);
};

export default FailPage;