import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./cookie.module.css";
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';
// import CookieConsent, { Cookies } from "react-cookie-consent";
import Cookies from "js-cookie";

function Cookie(props) {
    // console.log("log in Language:", props.selectedLanguage);
    const defaultLanguage = localStorage.getItem('selectedLanguage')
    const readCookie = () => {
        let result = [];
        const str = Cookies.get("cookieConsents");
        const list = str.split("&");

        list.forEach((cookie) => {
            const val = parseInt(cookie.split("=")[1]);
            result.push(val);
           
        })


        return result;
    }

    const [hideBanner, setHideBanner] = useState(Cookies.get("cookieSet") !== undefined);
    const [hideWindow, setHideWindow] = useState(true);
    const [cookieConsents, setCookieConsents] = useState(
        Cookies.get("cookieConsents") !== undefined ? readCookie() : [1]
    )

    const translations = {
        EN: {
            FLtitle: "ZenithScholars Cookie Policy",
            welcome: "Purpose",
            firstP1: "Last updated November 10, 2023",
            firstP2: "We use your personal data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. ",
            pread: "Collecting and Using Personal Data",
            secondP: "While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:",
            tele: "Children’s Privacy",
            teleP1: "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.",
            teleP2:"If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
            cr: "Links to Other Websites",
            crP1: "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
            crP2:"We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.",
            tm: "Changes to This Privacy Policy",
            tmP1: "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.",
            tmP2: "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the \"Last updated\" date at the top of this Privacy Policy.",
            tmP3: "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
            LAA: "Interpretation and Definitions",
            LAAP: "Subject to compliance with these Conditions of Use and any Terms of Service, and payment of any applicable fees, Zenith Scholars or its content providers grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal non-commercial use of Zenith Scholars services. This license does not include resale or commercial use of any Zenith Scholars service or its content; collection and use of any academic publications, salary data, or any other content; use of any third party downloading, copying or other use of your account information for the benefit of a third party; or using data mining, robots or similar data gathering and extraction tools. All rights not expressly granted to you in these Conditions of Use or any Terms of Service are reserved and reserved by Zenith Scholars or its licensors, suppliers, publishers, rights holders or other content providers. None of the Zenith Scholars Services, or any portion thereof, may be copied, duplicated, duplicated, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written permission of Zenith Scholars. You may not use any meta tags or any other (hidden text) utilizing Zenith Scholars's name or trademarks without the express written permission of Zenith Scholars (hidden text). You may not abuse the Zenith Scholars service, and may only use the Zenith Scholars service in a manner permitted by law. The license granted by Zenith Scholars will terminate if you fail to comply with these Conditions of Use or any of the Terms of Service.",
            banner: "By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.",
            acceptButton: "Accept All Cookies",
            rejectButton: "Reject All Cookies",
            settingsButton: "Cookies Settings",
            consentTitle: "Privacy Preference Center",
            consentContent: "When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.",
            cookiePolicy: "Cookie Policy",
            applyButton: "Apply Settings",
            settingsTitle: "Manage Consent Preferences",
            option1Title: "Strictly Necessary Cookies",
            option1Content: "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
            option1Status: "Always Active",
            option2Title: "Performance Cookies",
            option2Content: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.",
        },
        CN: {
            FLtitle: "智汇才猎 - Cookie 政策",
            welcome: "目的",
            firstP1: "最后更新于：2023年11月10日",
            pread: "收集和使用个人数据",
            secondP: "在使用我们的服务时，我们可能会要求您提供某些可用于联系或识别您的个人身份信息。这些可识别个人信息可能包括但不限于：",
            tele: "儿童隐私",
            teleP1: "我们的服务不针对13岁以下的任何人。我们不会故意收集13岁以下任何人的可识别个人信息。如果您是父母或监护人，并且您知道您的孩子向我们提供了个人数据，请与我们联系。如果我们得知我们在未经父母同意的情况下收集了13岁以下任何人的个人数据，我们会采取步骤将该信息从我们的服务器中删除。",
            teleP2:"如果我们需要以同意作为处理您的信息的法律依据，",
            cr: "到其他网站的链接",
            crP1: "我们的服务可能包含指向非我们运营的其他网站的链接。如果您点击第三方链接，您将被重定向到该第三方的网站。我们强烈建议您查看您访问的每个网站的隐私政策。",
            crP2:"我们无法控制第三方网站或服务的内容、隐私政策或做法，也不承担对其内容或实践的责任。",
            tm: "对于本隐私政策的更改",
            tmP1: "我们可能会不时更新我们的隐私政策。我们将通过在此页面上发布新的隐私政策来通知您。",
            tmP2:"在更改生效之前，我们将通过电子邮件和/或在我们的服务上显著的通知来告知您，并更新本隐私政策顶部的“最后更新”日期。",
            tmP3:"建议您定期查看本隐私政策以了解任何更改。本隐私政策的更改在发布在此页面时生效。",
            LAA: "解释和定义",
            LAAP: "首字母大写的单词在以下情况下具有明确定义。以下定义无论是以单数还是复数形式出现，都具有相同的含义。",
            banner: "单击“接受所有Cookies”，即表示您同意在您的设备上存储Cookies，以增强网站导航、分析网站使用情况并协助我们的营销工作。",
            acceptButton: "接受所有Cookies",
            rejectButton: "拒绝",
            settingsButton: "Cookies设置",
            consentTitle: "隐私偏好中心",
            consentContent: "当您访问任何网站时，它可能会以Cookies的形式在您的浏览器上存储或检索信息。这些信息可能与您、您的偏好或您的设备有关，主要用于使网站按照您的预期运行。这些信息通常不会直接识别您的身份，但可以为您提供更加个性化的网络体验。由于我们尊重您的隐私权，因此您可以选择不允许某些类型的cookies。单击不同的类别标题以了解更多信息并更改我们的默认设置。但是阻止某些类型的 cookies可能会影响您对网站的体验以及我们能够提供的服务。",
            cookiePolicy: "Cookie政策",
            applyButton: "应用Cookie设置",
            settingsTitle: "管理偏好设置",
            option1Title: "必需的Cookies",
            option1Content: "这些Cookies是网站正常运行所必需的，并且无法在我们的系统中关闭。它们通常只是为了响应您所做的请求而设置，例如设置您的隐私首选项、登录或填写表格。您可以将浏览器设置为阻止或提醒您这些Cookies，但网站的某些部分将无法工作。这些Cookies不会存储任何个人身份信息。",
            option1Status: "始终开启",
            option2Title: "性能Cookies",
            option2Content: "这些Cookies使我们能够计算访问量和流量来源，以便我们能够衡量和改进我们网站的性能。它们帮助我们了解哪些页面最受欢迎和最不受欢迎，并了解访问者如何在网站上探索。这些Cookies收集的所有信息都是计算后的，因此是匿名的。如果您不允许这些Cookies，我们将不知道您何时访问了我们的网站，也无法监控其性能。",
    
        },
    };
    
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage); // Set default language

    const t = (key) => {
        return translations[selectedLanguage][key] || key;
    };

    useEffect(() => {
        writeCookie();
    }, []);

    // useEffect(() => {
    //     console.log(props.currentLocation)
    // //     for (let i = 1; i < cookieConsents.length; i++) {
    // //         console.log(cookieConsents[i])
    // //         if(cookieConsents[i]==0){
    // //             console.log('remove')
    // //             Cookies.remove('_ga');
    
    // //             // 删除所有以 "_ga_" 开头的 cookie
    // //             Object.keys(Cookies.get()).forEach(cookieName => {
    // //                 console.log(cookieName)
    // //                 if (cookieName.startsWith('_ga_')) {
    // //                 Cookies.remove(cookieName);
    // //                 }
    // //             });
    // //     }
    // // }
    // },
    // [props.currentLocation]);

  


    // const styles = {
    //     banner: {
    //         background: "white",
    //         color: "black",
    //         textAlign: "left",
    //     },
    //
    //     acceptButton: {
    //         background: "#377DED",
    //         color: "white",
    //     },
    //
    //     rejectButton: {
    //         background: "#377DED",
    //         color: "white",
    //     }
    // }

    // IMPORTANT: change the value to add more cookie options, do not forget to add the translation texts above
    const numberOfCookieOptions = 2;

    const cookieOptions =  [];

    for (let i = 1; i <= numberOfCookieOptions; i++) {
        cookieOptions.push(
            <CookieOption
                optionId={i}
                titleText={t(`option${i}Title`)}
                statusText={t(`option${i}Status`)}
                contentText={t(`option${i}Content`)}
                cookieConsents={cookieConsents}
                setCookieConsents={setCookieConsents}
            />
        )
    }

    const writeCookie = () => {
        let str = "cookie1=1";
        console.log('writeCookie')
        for (let i = 1; i < cookieConsents.length; i++) {
            str += `&cookie${i+1}=${cookieConsents[i]}`;
            if(cookieConsents[i]==0){
                Cookies.remove('_ga');
    
                // 删除所有以 "_ga_" 开头的 cookie
                Object.keys(Cookies.get()).forEach(cookieName => {
                    if (cookieName.startsWith('_ga_')) {
                    Cookies.remove(cookieName);
                    }
                });

        }
       

        } 

        Cookies.set("cookieConsents", str, { expires: 365 });
    }

    const acceptCookie = (isAccept) => {
        let result = [];

        result[0] = 1;
        for (let i = 1; i < numberOfCookieOptions; i++) {
            result[i] = isAccept;
        }

        setCookieConsents(result);
    }

    const writeAcceptCookie = (isAccept) => {
        let str = "cookie1=1";
        for (let i = 1; i < numberOfCookieOptions; i++) {
            str += `&cookie${i+1}=${isAccept}`;
        }

        Cookies.set("cookieConsents", str, { expires: 365 });
    }

    // useEffect(() => {
    //     let result = [];
    //     if (Cookies.get("cookieConsents") === undefined) {
    //         result = acceptCookie(0);
    //     } else {
    //         result = readCookie();
    //     }
    //
    //     setCookieConsents(result);
    // }, []);

    const handleOnAccept = () => {
        acceptCookie(1);
        writeAcceptCookie(1);
        Cookies.set("cookieSet", true, { expires: 365 });
        setHideWindow(true);
        setHideBanner(true);
        window.location.reload()

    };

    const handleOnReject = () => {
        acceptCookie(0);
        writeAcceptCookie(0);
        Cookies.set("cookieSet", true, { expires: 365 });
        setHideWindow(true);
        setHideBanner(true);
        window.location.reload()

    };

    const handleSettingsOnClick = () => {
        setHideWindow(false);
    };

    const handleOnCloseBanner = () => {
        setHideBanner(true);
    }

    const handleIconOnClick = () => {
        if (hideWindow) {
            setHideWindow(false);
        } else {
            setHideWindow(true);
        }
    }

    const handleOnCloseWindow = () => {
        setHideWindow(true);
    }

    const handleApplyOnClick = () => {
        writeCookie();
        Cookies.set("cookieSet", true, { expires: 365 });
        
        setHideWindow(true);
        setHideBanner(true);
        window.location.reload()

    }

 
    
    

    const SelectedOptionWithSVG = ({ label }) => (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
          <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px',paddingTop:'6px'}} />
          {label==='English' ? (
            <span style={{ paddingTop: '2px' }}>EN</span>
          ) : (
            <span style={{ paddingTop: '2px' }}>CN</span>
          )}
        </div>
    );
    
    const options2 = [
        { value: 'EN', label: 'English' },
        { value: 'CN', label: '中文(简体)' },
    ];



    
    
    const handleSelectChange2 = (selectedOption) => {
        setSelectedLanguage(selectedOption.value); // Update
        
        props.setSelectedLanguage(selectedOption.value); 
        if (selectedOption.value === 'EN') {
          
        } else if (selectedOption.value === 'CN') {
          
        }
    };

    
    const customSelect2 = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: 'Sofia Pro',
            fontSize: 16,
            color: '#377DED',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            border: 'none', 
            boxShadow: 'none', 
            minWidth: '130px',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display:'none',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          transition: 'color 0.2s',
          color: '#377DED',
          fontSize: state.isFocused ? '18px' : '16px',
          position: 'relative',
          "&:hover": {
            color: "#2149B1",
            fontSize: 18,
            //borderBottom: "2px solid #2149B1",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          fontFamily: 'Sofia Pro',
          fontSize: 16,
          color: state.isSelected ? '#FFFFFF' : '#377DED',
          cursor: 'pointer',
          textAlign: 'left',
          "&:active": {
            color: '#FFFFFF',
            background: '#377DED',
          },
          "&:focus": {
            outline: 'none',
          },
          display: 'flex',
          marginTop:'8px',
          marginBottom:'8px'
        }),
        menu: (provided) => ({
          ...provided,
          border: 'none',
          borderRadius: '10px',
          boxShadow: 'none',
          marginTop: 0,
          position: 'absolute',
        }),
    };
    
    const PlaceholderImageEN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>EN</span>
        </div>
    );
    
    const PlaceholderImageCN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>CN</span>
        </div>
    );

    

    return (
        <>
         <div className={styles.termOfUseContainer}>
        {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
        {selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
            <div className={styles.langDrop}>
                <Select
                    options={options2}
                    onChange={handleSelectChange2}
                    isSearchable={false} // This will disable user input

                    placeholder={defaultLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                    components={{
                        SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                    }}
                    styles={customSelect2}
                />
            </div>

            <div>
        

            {/* <div
                className={styles.cookiesIcon}
                onClick={handleIconOnClick}
            >
                <img src={"https://intelliprozenithscholars.com/logo_small.png"} alt={"Cookies Icon"}/>
            </div> */}

            <div
                className={`${styles.overlay} ${hideWindow ? styles.hidden : ""}`}
                onClick={handleOnCloseWindow}
            ></div>

            <div className={`${styles.cookiesWindow} ${hideWindow ? styles.hidden : ""}`}>
                <div className={styles.windowHeader}>
                    <img src={`https://intelliprozenithscholars.com/new_logo${props.selectedLanguage === "CN" ? "_cn" : ""}.svg`} alt={"ZenithScholar Logo"}/>
                    <button
                        className={styles.button3}
                        onClick={handleOnCloseWindow}
                    >
                    <img src='/closeicon.png'/>

                    </button>
                </div>
                <div className={styles.windowContent}>
                    <h2>{t("consentTitle")}</h2>
                    <div>
                        {t("consentContent")}
                        <br/>
                        <a href={"/cookie"} target={"_blank"}>{t("cookiePolicy")}</a>
                    </div>

                    <br/>
                    <button
                        className={styles.button1}
                        onClick={handleOnAccept}
                    >
                        {t("acceptButton")}
                    </button>

                    <h3>{t("settingsTitle")}</h3>
                    {cookieOptions}
                </div>
                <div className={styles.windowFooter}>
                    <button
                        className={styles.button2}
                        onClick={handleOnReject}
                    >
                        {t("rejectButton")}
                    </button>
                    <button
                        className={styles.button1}
                        onClick={handleApplyOnClick}
                    >
                        {t("applyButton")}
                    </button>
                </div>
            </div>
        </div>

        {
            selectedLanguage == 'EN' &&
        
        <> 
            <div className={styles.textContainer}>
                <div className={styles.FLtitle}>
                    <p>{t('FLtitle')}</p>
                </div>

                {/* <div className={styles.welcome}>
                    <p>{t('welcome')}</p>
                </div> */}
                <div className={styles.firstP}>
                <p className={styles.normal}>{t('firstP1')}</p>

                    <p className={styles.normal}>
                    This Cookie Policy explains how _ ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at <a href="https://intelliprozenithscholars.com">https://intelliprozenithscholars.com</a> ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                    </p>
                    <p className={styles.normal}>In some cases, we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>

                </div>
                <div className={styles.secondP}>
                <p className={styles.title}>What are cookies?</p>
                <p className={styles.normal}>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>

                <p className={styles.normal}>Cookies set by the website owner (in this case, Intellipro ZenithScholars ) are called "first-party cookies" Cookies set by parties other than the website owners are called "third-party cookies." Third party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
                  
                  </div>

                  <div className={styles.secondP}>
                <p className={styles.title}>Why do we use cookies?</p>
                <p className={styles.normal}>We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictlynecessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.</p>

                  
                  </div>

                     <div className={styles.secondP}>
                <p className={styles.title}>Why do we use cookies?</p>
                <p className={styles.normal}>We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictlynecessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.</p>
                  
                  </div>
                  <div className={styles.secondP}>
                <p className={styles.title}>How can I control cookies?</p>
                <p className={styles.normal}>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies can not be rejected, as they are strictly necessary to provide you with services.</p>
                <p className={styles.normal}>The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website, though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies. You can exercise your cookie preferences specific to ZenithScholars Websites in the ZenithScholars Cookie Consent Manner by clicking <span className={styles.popwindow} onClick={handleSettingsOnClick}>here</span>. </p>
                <p className={styles.normal}>The specific types of first-party and third-party cookies served through our Website and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):</p>
                  
                  </div>


                  <div className={styles.secondP}>
                <p className={styles.title}>Cookie list:</p>
                <ul className={styles.listp}>
                    <li>Strictly necessary cookies:</li>
                    </ul>
                <p className={styles.normal}>These cookies are a category of cookies essential for the basic functionality of a website. </p>
                <table align="center"> 
                        <tr> 
                        <th></th> 
                            <th>COOKIE NAME</th> 
                            <th>DESCRIPTION</th> 
                            <th>DURATION</th> 

                        </tr> 
                        <tr> 
                        <td rowspan="5">first-party cookies</td> 

                            <td>userid</td> 
                            <td>This cookie is a session-specific identifier that uniquely recognizes a user during the course of their login session. Each time a user logs in, a new, encrypted user_id is generated to ensure secure and individualized interaction with the website.</td> 
                            <td>7 days</td> 


                        </tr> 
                        <tr> 
                            <td>sessionid</td> 
                            <td>This cookie is essential for the website to track the unique session of each user. It is used to maintain a logged-in status for a user between pages. It does not store any personal information and is deleted when the browser is closed.</td> 
                            <td>7 days</td> 

                        </tr> 

                        <tr> 
                            <td>csrftoken</td> 
                            <td>This cookie is associated with the Django web development platform for Python. It is designed to help protect a site against at particular type of software attack on web forms.</td> 
                            <td>7 days</td> 

                        </tr> 

                        
                        <tr> 
                            <td>cookieset</td> 
                            <td>Used to record a user's settings regarding cookie preferences on our website. It stores information about whether a user has accepted or refused the use of cookies.</td> 
                            <td>1 year</td> 

                        </tr> 
                        
                        <tr> 
                            <td>cookieconsent</td> 
                            <td>Used to record a user's consent specifically regarding the use of strictly necessary cookies and performance cookies. This cookie ensures that we comply with the user's preferences in terms of limiting or allowing certain types of cookie functionalities.</td> 
                            <td>1 year</td> 

                        </tr> 
                    </table> 
                    <ul className={styles.listp}>
                    <li>Performance cookies:</li>
                    </ul>
                <p className={styles.normal}>These cookies collect information that is used either in aggregate form to help us understand how our website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.</p>
                <table align="center"> 
                        <tr> 
                        <th></th> 
                            <th>COOKIE NAME</th> 
                            <th>DESCRIPTION</th> 
                            <th>DURATION</th> 

                        </tr> 
                        <tr> 
                        <td rowspan="2">first-party cookies</td> 

                            <td>_ga_#</td> 
                            <td>Used to distinguish individual users by means of designation of a randomly generated number as client identifier, which allows calculation of visits and sessions</td> 
                            <td>2 years</td> 


                        </tr> 
                        <tr> 
                            <td>_ga</td> 
                            <td>Records a particular ID used to come up with data about website usage by the user</td> 
                            <td>2 years</td> 

                        </tr> 

                    </table> 
                  
                  </div>
                  <div className={styles.secondP}>
                <p className={styles.title}>What about other tracking technologies, like web beacons?</p>
                <p className={styles.normal}>Cookies are not the only way to recognize or track visitors to a welbsite. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "cclear gifs"). These are tiny graphics files that contain a unique identifier that enables us to recognize when someone hasvisited our Website or opened an email including them. This allows us, for example, to monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of email marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</p>
                  
                  </div>

                <div className={styles.secondP}>
                <p className={styles.title}>How often will you update this Cookie Policy?</p>
                <p className={styles.normal}>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
                <p className={styles.normal}>The date at the top of this Cookie Policy indicates when it was last updated.</p>
                  </div>
                  


                <div className={styles.LAA}>
                    <p className={styles.title}>Where can I get further information?</p>
                    <p className={styles.normal}>If you have any questions about our use of cookies or other technologies, please contact us at: </p>
                    <ul className={styles.listp}>
                        <li><a href="blake.zhu@intelliprogroup.com ">blake.zhu@intelliprogroup.com </a></li>
                        </ul>
                </div>
            </div>
        </>}

        {
            selectedLanguage == 'CN' &&
        
            <> 
            <div className={styles.textContainer}>
                <div className={styles.FLtitle}>
                    <p>{t('FLtitle')}</p>
                </div>

                {/* <div className={styles.welcome}>
                    <p>{t('welcome')}</p>
                </div> */}
                <div className={styles.firstP}>
                <p className={styles.normal}>{t('firstP1')}</p>

                    <p className={styles.normal}>
                    本Cookie政策解释了_公司（以下简称“公司”，“我们”，“我们”，“我们的”）如何使用Cookie和类似技术在您访问我们的网站<a href="https://intelliprozenithscholars.com">https://intelliprozenithscholars.com</a>（以下简称“网站”）时识别您。该政策解释了这些技术是什么，为什么我们使用它们，以及您控制我们使用它们的权利。
                    </p>
                    <p className={styles.normal}>在某些情况下，我们可能使用Cookie收集个人信息，或者如果将其与其他信息结合使用，则会成为个人信息。</p>

                </div>
                <div className={styles.secondP}>
                <p className={styles.title}>什么是Cookie？</p>
                <p className={styles.normal}>Cookie是在您访问网站时放置在计算机或移动设备上的小型数据文件。网站所有者广泛使用Cookie，以使其网站正常工作或更有效地工作，同时提供报告信息。</p>

                <p className={styles.normal}>由网站所有者设置的Cookie（在本例中为Intellipro ZenithScholars）称为“第一方Cookie”；由网站所有者之外的其他方设置的Cookie称为“第三方Cookie”。第三方Cookie使第三方功能或功能能够通过网站提供（例如广告、互动内容和分析）。设置这些第三方Cookie的各方可以在涉及的网站访问时和访问某些其他网站时识别您的计算机。</p>
                  
                  </div>

                  <div className={styles.secondP}>
                <p className={styles.title}>为什么我们使用Cookie？</p>
                <p className={styles.normal}>我们出于几个原因使用第一方和第三方Cookie。一些Cookie出于技术原因是必需的，以使我们的网站运行，我们将这些称为“基本”或“严格必要”的Cookie。其他Cookie还使我们能够追踪和定位用户的兴趣，以增强我们在线属性的体验。第三方通过我们的网站提供广告、分析和其他目的的Cookie。下文详细描述了这一点。</p>
                  
                  </div>
                  <div className={styles.secondP}>
                <p className={styles.title}>我如何控制Cookie？</p>
                <p className={styles.normal}>您有权决定是否接受或拒绝Cookie。您可以通过在Cookie同意管理器中设置偏好来行使您的Cookie权利。Cookie同意管理器允许您选择接受或拒绝的Cookie类别。基本Cookie不能被拒绝，因为它们是提供服务所必需的。</p>
                <p className={styles.normal}>Cookie同意管理器可在通知横幅和我们的网站上找到。如果您选择拒绝Cookie，您仍然可以使用我们的网站，但您可能无法访问一些功能和网站的某些区域。您还可以设置或修改您的Web浏览器控件以接受或拒绝Cookie。您可以通过点击<span className={styles.popwindow} onClick={handleSettingsOnClick}>此处</span>在ZenithScholars网站的Cookie同意界面中设置您的Cookie偏好。</p>
                <p className={styles.normal}>通过我们的网站提供的特定类型的第一方和第三方Cookie以及它们执行的目的在下表中描述（请注意，提供的具体Cookie可能会根据您访问的具体在线属性而有所不同）：</p>
                  
                  </div>


                  <div className={styles.secondP}>
                <p className={styles.title}>Cookie 列表:</p>
                <ul className={styles.listp}>
                    <li>严格必要的Cookie：</li>
                    </ul>
                <p className={styles.normal}>这些Cookie是网站基本功能所必需的一类Cookie。</p>
                <table align="center"> 
                        <tr> 
                        <th></th> 
                            <th>COOKIE名称</th> 
                            <th>描述</th> 
                            <th>持续时间</th> 

                        </tr> 
                        <tr> 
                        <td rowspan="5">第一方cookies</td> 

                            <td>userid</td> 
                            <td>这个Cookie是一个与会话特定的标识符，它在用户登录会话期间唯一识别用户。每次用户登录时，都会生成一个新的加密的user_id，以确保与网站的安全和个性化交互。</td>

                            <td>7天</td> 


                        </tr> 
                        <tr> 
                            <td>sessionid</td> 
                            <td>这个Cookie对于网站跟踪每个用户的唯一会话至关重要。它用于在页面之间保持用户的登录状态。它不存储任何个人信息，并在浏览器关闭时被删除。</td>
                            <td>7天</td> 

                        </tr> 

                        <tr> 
                            <td>csrftoken</td> 
                            <td>这个Cookie与Django Python Web开发平台相关。它旨在帮助保护网站免受针对Web表单的特定类型的软件攻击。</td>
                            <td>7天</td> 

                        </tr> 

                        <tr> 
                            <td>cookieset</td> 
                            <td>用于记录用户在我们网站上有关 cookie 选项的设置。它存储有关用户是否接受或拒绝使用 cookie 的信息。</td> 
                            <td>1年</td> 

                        </tr> 
                        
                        <tr> 
                            <td>cookieconsent</td> 
                            <td>此cookie字段用于记录用户以下两种类别cookie的同意状态: (1) 必要且与网站基本功能相关 的cookie ; (2) 记录网站流量与表现 的cookie 。该 cookie 确保我们在限制或允许某些类型的 cookie 功能方面符合用户的偏好。</td> 
                            <td>1年</td> 

                        </tr> 
                    </table> 
                    <ul className={styles.listp}>
                    <li>性能cookies:</li>
                    </ul>
                <p className={styles.normal}>这些Cookie收集的信息要么以汇总形式使用，帮助我们了解我们的网站如何被使用或我们的营销活动的有效性，要么帮助我们为您定制我们的网站。</p>
                <table align="center"> 
                        <tr> 
                        <th></th> 
                        <th>COOKIE名称</th> 
                            <th>描述</th> 
                            <th>持续时间</th> 
                        </tr> 
                        <tr> 
                        <td rowspan="2">第一方cookies</td> 

                            <td>_ga_#</td> 
                            <td>用于通过指定一个随机生成的数字作为客户端标识来区分个体用户，从而允许计算访问和会话次数。</td> 
                            <td>2年</td> 


                        </tr> 
                        <tr> 
                            <td>_ga</td> 
                            <td>记录特定的标识符，用于生成有关用户对网站使用的数据。</td> 
                            <td>2年</td> 

                        </tr> 

                    </table> 
                  
                  </div>
                  <div className={styles.secondP}>
                <p className={styles.title}>关于其他追踪技术，比如像网络信标（web beacons）呢？</p>
                <p className={styles.normal}>Cookie并不是识别或追踪网站访问者的唯一方式。我们可能不时使用其他类似的技术，比如网络信标（有时称为“跟踪像素”或“clear gifs”）。这些是包含唯一标识符的微小图形文件，使我们能够识别某人何时访问了我们的网站或打开了包含它们的电子邮件。这使我们能够监视用户在网站内从一个页面到另一个页面的流量模式，传递或与Cookie通信，了解您是否是通过显示在第三方网站上的在线广告访问网站的，改进网站性能，并衡量电子邮件营销活动的成功。在许多情况下，这些技术依赖于Cookie的正常运作，因此拒绝Cookie将损害它们的功能。</p>
                  
                  </div>

                <div className={styles.secondP}>
                <p className={styles.title}>你会多久更新这个Cookie政策？</p>
                <p className={styles.normal}>我们可能会不时更新此Cookie政策，以反映我们使用的Cookie的更改或其他操作、法律或法规原因。因此，请定期查看此Cookie政策，以了解我们对Cookie和相关技术的使用。</p>
                <p className={styles.normal}>此Cookie政策顶部的日期表示上次更新的时间。</p>


                  </div>
                  


                <div className={styles.LAA}>
                <p className={styles.title}>我在哪里可以获得更多信息？</p>
                <p className={styles.normal}>如果您对我们使用的Cookie或其他技术有任何疑问，请通过以下方式与我们联系：</p>
                    <ul className={styles.listp}>
                        <li><a href="blake.zhu@intelliprogroup.com ">blake.zhu@intelliprogroup.com </a></li>
                        </ul>
                </div>
                </div>
            
        </>}
        </div>
        
        </>
    );
}

function CookieOption(props) {

    const [hideOption, setHideOption] = useState(true);

    const setCookieConsentsElement = (val) => {
        let result = props.cookieConsents;

        result[props.optionId-1] = val;

        props.setCookieConsents(result);
    }
    useEffect(() => {
        console.log(props.cookieConsents)
        console.log(props.optionId)
        if (props.cookieConsents && props.optionId !== 1) {
            console.log('need to be update',props.cookieConsents[props.optionId-1] === 1)
            document.getElementById(`optionCheckbox`).checked = props.cookieConsents[props.optionId-1] === 1
        }
    }, [props.cookieConsents])

    const handleOptionOnClick = () => {
        if (hideOption) {
            setHideOption(false);
        } else {
            setHideOption(true);
        }
    }

    const handleOptionOnChange = () => {
        if (document.getElementById(`optionCheckbox`).checked) {
            setCookieConsentsElement(1);
        } else {
            setCookieConsentsElement(0);
        }
    }

    return (
        <div>
            <div className={styles.cookieOptionTitle}>
                <h4 className={styles.cookieOptionTitleText} onClick={handleOptionOnClick}>
                    <span className={!hideOption ? styles.hidden : ""}><img src='/downicon.png' />&nbsp;&nbsp;</span>
                    <span className={hideOption ? styles.hidden : ""}><img src='/upicon.png'/>&nbsp;&nbsp;</span>
                    {props.titleText}
                </h4>
                {props.optionId === 1 ?
                    <h4 className={styles.cookieOption1Status}>{props.statusText}</h4>
                    :
                    <input
                        id={`optionCheckbox`}
                        type={"checkbox"}
                        onChange={handleOptionOnChange}
                    />}
            </div>
            <div className={`${styles.cookieOptionContent} ${hideOption ? styles.hidden : ""}`}
                 onClick={handleOptionOnClick}>
                {props.contentText}
            </div>
        </div>
    );
}

export default Cookie;