import React from 'react';
import styles from './GeneralFIlter.module.css';

const Modal = ({ isOpen, onClose, onOptionSelected, selectedLanguage}) => {
  if (!isOpen) {
    return null;
  }
  const handleActionAndClose = () => {
    onOptionSelected();
    onClose();
  };

  const translations = {
    EN: {
      QA:'Are you sure to clear all filters ?',
      no:'No',
      yes:'Yes'
    },
    CN: {
      QA:'确定要清空过滤器吗？',
      no:'否',
      yes:'是'
      
    },
  // You can add more languages here
};
const t = (key) => {
    return translations[selectedLanguage][key] || key;
  };

  return (
    <div className={styles.modalOverlay}>
  <div className={styles.modalWindow}>
    <div className={styles.modalIcon}>
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <path d="M50 26H38C36.9 26 36 26.9 36 28C36 29.1 36.9 30 38 30H50C51.1 30 52 29.1 52 28C52 26.9 51.1 26 50 26Z" fill="#0770DD"/>
        <path d="M50.3397 5.99998H13.6597C9.43973 5.99998 6 9.43998 6 13.6597C6 15.6997 6.8 17.6197 8.24 19.0795L24 34.8395V53.1797C24 55.8397 26.16 57.9997 28.82 57.9997C30.1 57.9997 31.34 57.4797 32.24 56.5797L38.24 50.5797C39.38 49.4397 40 47.9397 40 46.3397V37.9995C40 36.8995 39.1 35.9995 38 35.9995C36.9 35.9995 36 36.8995 36 37.9995V46.3397C36 46.8597 35.78 47.3797 35.42 47.7597L29.42 53.7597C28.94 54.2397 28 53.8397 28 53.1797V34.8395C28 33.7795 27.58 32.7595 26.82 32.0195L11.0803 16.2392C10.4003 15.5592 10.0003 14.6192 10.0003 13.6592C10.0003 11.6392 11.6403 9.99918 13.6603 9.99918H50.3403C52.3603 9.99918 54.0003 11.6392 54.0003 13.6592C54.0003 14.6192 53.6003 15.5592 52.9203 16.2392L50.5803 18.5792C49.8003 19.3592 49.8003 20.6192 50.5803 21.3992C51.3603 22.1792 52.6203 22.1792 53.4003 21.3992L55.7403 19.0592C57.2003 17.6192 58.0003 15.6992 58.0003 13.6592C58.0003 9.43918 54.5603 5.99945 50.3405 5.99945L50.3397 5.99998Z" fill="#0770DD"/>
      </svg>
    </div>
    <h2>{t('QA')}</h2>
    <div className={styles.buttonContainer}>
      <button className={styles.noButton} onClick={onClose}>{t('no')}</button>
      <button className={styles.yesButton} onClick={handleActionAndClose}>{t('yes')}</button>
      
    </div>
  </div>
</div>

  );
};

export default Modal;