import styles from "../GeneralFIlter.module.css";
import { useEffect, useState } from "react";
import hint from "../asset/hint-icon.png";

function OnlyShowComponent(props) {
  const [items, setItems] = useState(props.state);
  const [showMessage, setShowMessage] = useState(false);

  const handleMouseEnter = () => {
    setShowMessage(true);
  };

  const handleMouseLeave = () => {
    setShowMessage(false);
  };
  useEffect(() => {
    props.change(items);
  }, [items]);
  useEffect(() => {
    setItems(props.state);
  }, [props.state]);


const translations = {
    EN: {
      onlyshow: "Only Shown Profiles With",
       clear:"Clear",
       hint:"If two or more options are selected, the returned results will include all selected information types simultaneously.",
       email:"Email Address",
        phone:"Phone Number",
        orcid:"ORCID Profile Link",
        google:"Google Scholar Link",

    },
    CN: {
      onlyshow:"只显示具有以下资料的用户",
      clear:"删除",
      enter:"输入国家名称...",
      hint:"如果选择了两个或更多选项，返回的结果将同时包括所有已选择的信息类型。",
      email:"电子邮件地址",
      phone:"电话号码",
      orcid:"ORCID个人资料链接",
      google:"谷歌学术链接",
    },
    // You can add more languages here
  };

  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };
  
  const onlyShown = [
    t('email'),
    t('phone'),
    t('orcid'),
    t('google'),
  ];
  const addOnly = (only) => {
    if (items[only]) {
      setItems({ ...items, [only]: false });
    } else {
      setItems({ ...items, [only]: true });
    }
  };

  


  return (
    <div className={styles.wrapper} id="only_shown_profiles_with">
      <div className={styles.non_details_onlyShow}>
        <div className={styles.title_checkbox}>
          {/* <img src="tag.svg" alt="icon"> */}

          <h2>{t('onlyshow')}</h2>
          <div
            className={styles.hint_img}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={hint} alt="icon" />
            {showMessage && (
              <div className={styles.hint_msg}>
                {t('hint')}
              </div>
            )}
          </div>
        </div>
        <div className={styles.checkbox_block}>
          {onlyShown.map((only) => (
            <div className={styles.float_left}>
            <label>
              <input
                type="checkbox"
                id={only}
                name={only}
                onClick={() => addOnly(only)}
                checked={items[only]}
              />
              {only}
            </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OnlyShowComponent;
