import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { fetchEmail ,fetchProfile} from '../Home/fetch';

const PrivateRoute = () => {
  const [auth, setAuth] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const data = await fetchEmail();
        // console.log('login status 8000', data);
        const data2 = await fetchProfile();
        console.log('login status /auth', data2);
        if (data2.email) {
          setAuth(true);
          console.log('set true');
        } else {
          setAuth(false);
          console.log('set false');
        }
      } catch (error) {
        console.error('Promise rejected:', error);
        setAuth(false);

      }
    };
    fetchData();
  }, []);

  console.log('private route');
  console.log(auth);
  if (auth === undefined) return null; 

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
