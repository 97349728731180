import styles from "./ResumePage.module.css";
import PieGenerator from "../ResumePage/chart/PieGenerator";
import BarGenerator from "../ResumePage/chart/BarGenerator_RP";
import SubjectBarGenerator from "../ResumePage/chart/SubjectBarGenerator_RP";
import data from '../../DKDH_Filter.json'
import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
import {useEffect, useRef, useState} from "react";
import DownloadPdf from "./PDFDownload";

const getAfterLastComma = (str) => {
  const lastCommaIndex = str.lastIndexOf(', ');
  if (lastCommaIndex !== -1) {
      return str.substring(lastCommaIndex + 2);
  }
  return str;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase()+string.slice(1);
};

function ResumePage(props) {
  const processStr = (bio,lang) => {
    let combinedStr = bio.join('\n');
    let words=[]
    if(lang =='en'){
      words = combinedStr.split(/ /).filter(word => word);

    }
        
    if (words.length > 90 && lang=='en') {
      return words.slice(0, 90).join(' ') + "...";
    }

    if (combinedStr.length> 300 && lang=='cn') {
      return combinedStr.slice(0, 300)+ "...";
    }

    return combinedStr;
  };

  let bioArray = [];
  let displayedBio = "";
  let displayedBioCn = "";
  if (props.state && props.state.bio && props.state.bio.trim() !== ""){
    bioArray = props.state.bio.split('<br>');
    bioArray = bioArray.filter(item => item.trim() !== '');
    displayedBio = processStr(bioArray,'en');
  }

  if (props.state && props.state.bio_zh && props.state.bio_zh.trim() !== ""){
    bioArray = props.state.bio_zh.split('<br>');
    bioArray = bioArray.filter(item => item.trim() !== '');
    displayedBioCn = processStr(bioArray,'cn');
  }

  const splitToLines = (str) => {
    const words = str.split(/\t|\n| |<br>/);
    
    let totalLines = [];
    let currentLine = [];
    let lencnt = 0;
    words.forEach(word => {
        if (currentLine.length < 5 && lencnt + word.length < 38) {
            currentLine.push(word);
            lencnt += word.length;
        } else {
            totalLines.push(currentLine.join(' '));
            currentLine = [word];
            lencnt = 0;
        }
        // console.log(lencnt)
    });
    if (currentLine.length > 0 ){
      totalLines.push(currentLine.join(' '));
    }
    //console.log(totalLines);
    return totalLines;
    
  }

  
  const experienceFormat = (arr, maxLineNum) =>{
    let lines = [];
    let currentLine = [];
    arr.forEach(exp => {
      currentLine = splitToLines(exp);
      if (currentLine.length) lines=[...lines, ...currentLine];
    }
    );
    // console.log(lines);
    // Limit to 3 lines
    if (lines.length > maxLineNum) {
      lines = lines.slice(0, maxLineNum);
      const lastLine = lines[lines.length - 1];
      lines[lines.length - 1] = lastLine + '...';
    }
    return lines;
  }
  let eduArray = [];
  let eduFormatted = [];
  let eduFormattedCn =[];
  if (props.state && props.state.education_experience && props.state.education_experience.trim() !== ""){
    eduArray = props.state.education_experience.split(/<br>|\n/);
    eduArray = eduArray.filter(item => item.trim() !== '');
    eduFormatted = experienceFormat(eduArray.slice(0, 2), 3);
    // console.log(eduFormatted)
    // console.log(eduArray);
  }
  if (
    props.state &&
    props.state.education_experience_zh &&
    props.state.education_experience_zh.trim() !== ""
  ) {
    eduArray = props.state.education_experience_zh.split(/<br>|\n/);
    eduArray = eduArray.filter((item) => item.trim() !== "");
    eduFormattedCn = experienceFormat(eduArray.slice(0, 2), 3);
  }
  
  

  let workArray = [];
  let workFormatted = [];
  let workFormattedCn = [];
  if (props.state && props.state.work_experience  && props.state.work_experience.length){
    workArray = props.state.work_experience;
    // console.log(props.state.work_experience)
    workArray = workArray.filter(item => item.trim() !== '');
    workFormatted = experienceFormat(workArray.slice(0, 2), 4);
    // console.log(workFormatted);
  }

  if (props.state && props.state.work_experience_zh  && props.state.work_experience_zh.length){
    workArray = props.state.work_experience_zh;
    workArray = workArray.filter(item => item.trim() !== '');
    workFormattedCn = experienceFormat(workArray.slice(0, 2), 4);
    // console.log(workFormatted);
  }
  //Processing bars and pie
  let subjects = []
  if(props.state && props.state.subject){
  subjects = Object.entries(props.state.subject.paper).map(([subjectName, quartiles]) => {
    const totalPapers = Object.values(quartiles).reduce((sum, value) => sum + value, 0);
    return {
      name: subjectName,
      value: totalPapers,
    };
  });
}
  
  // const sumOfTotalPapers = subjects.reduce((sum, subject) => sum + subject.value, 0); 
  const topSubjects = subjects.sort((a, b) => b.value - a.value);
  const sumOfOtherPapers = topSubjects.slice(3).reduce((sum, subject) => sum + subject.value, 0); 
  const pieData = [...topSubjects.slice(0, 3), ...[{
                                        name: "Others",
                                        value: sumOfOtherPapers,
                                        }]];
  // console.log(pieData);
  // console.log(topSubjects);
  // console.log(props.state.subject.citation)
  //console.log("subject:",topSubjects);
  const barSubjectData = topSubjects.slice(0, 3).map(subject => ({
    paper: {
      name: subject.name,
      Q1: -props.state.subject.paper[subject.name].Q1,
      Q2: -props.state.subject.paper[subject.name].Q2,
      'Q3&4': -props.state.subject.paper[subject.name]['Q3&4'] 
    },
    citation: {
      name: subject.name,
      Q1: props.state.subject.citation[subject.name] ? props.state.subject.citation[subject.name].Q1 : 0,
      Q2: props.state.subject.citation[subject.name] ? props.state.subject.citation[subject.name].Q2 : 0,
      'Q3&4': props.state.subject.citation[subject.name] ? props.state.subject.citation[subject.name]['Q3&4'] : 0
    }
  }));

  // console.log("subject data pass:", barSubjectData);
  while (topSubjects.length < 3){
    topSubjects.push({
      name: "",
      value: 0,
      })
  };
  while (barSubjectData.length < 3) { 
    barSubjectData.push({
        paper: {
            name: "",
            Q1: 0,
            Q2: 0,
            'Q3&4' : 0
        },
        citation: {
            name: "",
            Q1: 0,
            Q2: 0,
            'Q3&4' : 0
        }
    });
  }
  // console.log(barSubjectData)
  let maxCitation = Math.max(...barSubjectData.map(subject => {
    return Math.max(subject.citation.Q1, subject.citation.Q2, subject.citation['Q3&4']);
  }));
  let maxPaper = Math.min(...barSubjectData.map(subject => {
    return Math.min(subject.paper.Q1, subject.paper.Q2, subject.paper['Q3&4']);
  }));
  // console.log("max:", maxCitation)
  
  let topics = []
  if(props.state['top_research_topics']){
  topics = Object.entries(props.state['top_research_topics'].paper).map(([subjectName, quartiles]) => {
    const totalPapers = Object.values(quartiles).reduce((sum, value) => sum + value, 0);
    return {
      name: subjectName,
      value: totalPapers,
    };
  });
}
  // console.log(topics)
  const sumOfTotalPapers = subjects.reduce((sum, subject) => sum + subject.value, 0); 
  const topTopics = topics.sort((a, b) => b.value - a.value);
  // console.log(topTopics);
  //console.log("topics:",topTopics);
  const barTopicData = topTopics.slice(0, 7).map(subject => ({
    paper: {
      name: subject.name,
      Q1: -props.state['top_research_topics'].paper[subject.name].Q1,
      Q2: -props.state['top_research_topics'].paper[subject.name].Q2,
      'Q3&4': -props.state['top_research_topics'].paper[subject.name]['Q3&4'] 
    },
    citation: {
      name: subject.name,
      Q1: props.state['top_research_topics'].citation[subject.name] ? props.state['top_research_topics'].citation[subject.name].Q1 : 0,
      Q2: props.state['top_research_topics'].citation[subject.name] ? props.state['top_research_topics'].citation[subject.name].Q2 : 0, 
      'Q3&4': props.state['top_research_topics'].citation[subject.name] ? props.state['top_research_topics'].citation[subject.name]['Q3&4'] : 0
    }
  }));
  // console.log("bar data pass:" , barTopicData);
  while (topTopics.length < 7){
    topTopics.push({
      name: "",
      value: 0,
      })
  };
  while (barTopicData.length < 7) { 
    barTopicData.push({
        paper: {
            name: "",
            Q1: 0,
            Q2: 0,
            'Q3&4': 0
        },
        citation: {
            name: "",
            Q1: 0,
            Q2: 0,
            'Q3&4': 0
        }
    });
  }
  let maxTopicCitation = Math.max(...barTopicData.map(subject => {
    return Math.max(subject.citation.Q1, subject.citation.Q2, subject.citation['Q3&4']);
  }));
  let maxTopicPaper = Math.min(...barTopicData.map(subject => {
    return Math.min(subject.paper.Q1, subject.paper.Q2, subject.paper['Q3&4']);
  }));
  function findCategoryAndField(subjectName) {
    for (let category in data.Filter) {
        for (let field in data.Filter[category]) {
            if (data.Filter[category][field].includes(subjectName)) {
                return {
                    category: category,
                    field: field
                }
              }
        }
    }
    return {
      category: "N/A",
      field: "N/A"
    }  // returns null if the subject name isn't found
  }
  const backward = findCategoryAndField(topSubjects[0].name);
  // console.log("category and field:", backward);

  //
  function allQsAreZero(data) {
    return data.every(item => (
      item.citation.name === "" || (
      item.citation.Q1 === 0 &&
      item.citation.Q2 === 0 &&
      item.citation["Q3&4"] === 0 &&
      item.paper.Q1 === 0 &&
      item.paper.Q2 === 0 &&
      item.paper["Q3&4"] === 0
    )));
  }

  function oneQsAreZero(data) {
    if (data && data.hasOwnProperty('citation')) {
      return (
        data.citation.Q1 === 0 &&
        data.citation.Q2 === 0 &&
        data.citation["Q3&4"] === 0 &&
        data.paper.Q1 === 0 &&
        data.paper.Q2 === 0 &&
        data.paper["Q3&4"] === 0
      );
    } else {
      return true;
    }
  }

  const translations = {
    EN: {
     moreInfo1:"More Info 1",
      moreInfo2:"More Info 2",
      moreInfo3:"More Info 3",
      work_experience: "Work Experience",
      IntelliPro: 'IntelliPro | Global Scholar Database',
      Profile: 'Profile',
      EDUCATION_EXPERIENCE: 'EDUCATION EXPERIENCE',
      SCHOLAR_BIO: 'SCHOLAR BIO',
      NA: 'N/A',
      at: 'at',
      ACADEMIC_METRICS: 'ACADEMIC METRICS',
      Field: 'Field',
      Category: 'Category',
      Subject: 'Subject',
      Total: 'Total',
      Publications: 'Publications',
      Other: 'Other',
      Citation: 'Citation',
      Paper: 'Paper',
      Top: 'Top',
      Research_Topics: 'Research Topics',
      hint1:'# of Q1 papers = Publications published on Tie 1 journals or conference proceedings (Top 25% within the subject area)',
      hint2:'# of Q2 papers = Publications published on Tie 2 journals or conference proceedings (Top 50% within the subject area)',
      hint3:'# of Q3 & Q4 papers = Publications published on Tie 3 & 4 journals or conference proceedings (50%-100% within the subject area)',

    },
    CN: {
      moreInfo1:"更多信息 1",
      moreInfo2:"更多信息 2",
      moreInfo3:"更多信息 3",
      work_experience: "工作经历",
      IntelliPro: 'IntelliPro | 全球学者数据库',
      Profile: '资料',
      EDUCATION_EXPERIENCE: '教育经历',
      SCHOLAR_BIO: '学者简介',
      NA: '无',
      at: '在',
      ACADEMIC_METRICS: '学术数据',
      Field: '领域',
      Category: '类别',
      Subject: '学科',
      Total: '总计',
      Publications: '出版物',
      Other: '其他',
      Citation: '引用',
      Paper: '论文',
      Top: '热门',
      Research_Topics: '研究课题',
      hint1:"#Q1论文数量 = 发表在顶级期刊或会议论文集上的出版物（在该学科领域内属于前25%）",
      hint2:"#Q2论文数量 = 发表在二级期刊或会议论文集上的出版物（在该学科领域内属于前50%）",
      hint3:"#Q3 & Q4论文数量 = 发表在三级和四级期刊或会议论文集上的出版物（在该学科领域内属于50%-100%）"
    },
  // You can add more languages here
};
const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };


  const pdfRef = useRef()
  const downloadpdf =()=>{
    const input = pdfRef.current;
    console.log(props.state.photolink)
    // const img = new Image();
    // img.crossOrigin = 'anonymous'; // Set crossOrigin attribute if needed
    // img.src = "https://my-piclogo-bucket.s3.amazonaws.com/testimg.jpg";
    html2canvas(input, { proxy:`${props.state.photolink}` }).then((canvas)=>{
      const dataimg = canvas.toDataURL('image/png');
      const pdf = new jsPdf('p','mm','a4',true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth/imgWidth, pdfHeight/imgHeight)
      const imgX =(pdfWidth-imgWidth*ratio)/2
      const imgY =0


      pdf.addImage(dataimg,'PNG',imgX,imgY,imgWidth*ratio, imgHeight*ratio)
      pdf.save(props.state.name.split("(")[0].trim())
    })
  }

  const chartRef1 = useRef();
  const chartRef2 = useRef();
  const chartRef3 = useRef();
  const chartRef4 = useRef();
  const legendRef1 = useRef();
  const legendRef2 = useRef();

  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  const [link4, setLink4] = useState("");
  const [link5, setLink5] = useState("");
  const [link6, setLink6] = useState("");

  const links = {
    chart1: link1,
    chart2: link2,
    chart3: link3,
    chart4: link4,
    legend1: link5,
    legend2: link6,
  };
  useEffect(() => {
    const input1 = chartRef1.current;
    html2canvas(input1).then((canvas) => {
      setLink1(canvas.toDataURL("image/png"));
    });

    const input2 = chartRef2.current;
    html2canvas(input2).then((canvas) => {
      setLink2(canvas.toDataURL("image/png"));
    });

    const input3 = chartRef3.current;
    html2canvas(input3).then((canvas) => {
      setLink3(canvas.toDataURL("image/png"));
    });

    const input4 = chartRef4.current;
    html2canvas(input4).then((canvas) => {
      setLink4(canvas.toDataURL("image/png"));
    });

    const input5 = legendRef1.current;
    html2canvas(input5).then((canvas) => {
      setLink5(canvas.toDataURL("image/png"));
    });

    const input6 = legendRef2.current;
    html2canvas(input6).then((canvas) => {
      setLink6(canvas.toDataURL("image/png"));
    });
  }, [chartRef1]);
  

  return (
    <>
     {/*<button onClick={downloadpdf}>download</button>*/}
      <DownloadPdf state={props.state} selectedLanguage={props.selectedLanguage} links={links}/>

    <div className={styles.resumePage} ref={pdfRef}>
      <div className={styles.resumePageChild} style={{backgroundImage: `url(${props.state.photolink})`}}/>
     
      {/* First Block Contact Info */}
      <div className={styles.davidSilverParent}>
        <b className={styles.davidSilver}>{props.state.name.split("(")[0].trim()}</b>
       {/* <div className={styles.groupChild} /> */}
        <div className={styles.professorAtUniversityContainer}>
          <b>{props.state.title}</b>
          {props.state.title !== undefined ? (
            <b className={styles.university}> at {getAfterLastComma(props.state.employer)}</b>
          ) : (
            <b className={styles.university}>{getAfterLastComma(props.state.employer)}</b>
          )}
        </div>
      </div>
      <div className={styles.image73Parent}>
        <img className={styles.image73Icon} alt="" src="/image-73@2x.png" />
        {/* <div className={styles.div}>{props.state.phone && props.state.phone.length > 0 ? props.state.phone : "N/A"}</div> */}
        {props.state.phone && props.state.phone.length >0 ? <>
        <div className={styles.div}>{props.state.phone[0]} &nbsp;</div>
        <div className={styles.div}>{props.state.phone[1]}</div></>:<div className={styles.div}>N/A</div>}
        <img className={styles.image74Icon} alt="" src="/image-74@2x.png" />
        <div className={styles.londonUk}>{props.state.location && props.state.location.length > 0 ? props.state.location : "N/A"}</div>
        <div className={styles.moreInfo1Parent}>
          <img className={styles.image75Icon} alt="" src="/image-75@2x.png" />
          {/* <div className={styles.moreInfo1}>{t('moreInfo1')}</div>
          <div className={styles.moreInfo2}>{t('moreInfo2')}</div>
          <div className={styles.moreInfo3}>{t('moreInfo3')}</div>         */}
          {props.state.links && props.state.links.length > 0 ? (
            props.state.links.map((link, index) => (
            <div key={index} className={styles[`moreInfo${index + 1}`]}>
                <a href={link}>{t(`moreInfo${index + 1}`)}</a>
              </div>
            ))
          ) : (
            <div className={styles.moreInfo1}>N/A</div>
          )}
        </div>
      </div>
      <div className={styles.image76Parent}>
        <img className={styles.image76Icon} alt="" src="/image-76@2x.png" />
        {/* <b className={styles.dsilvercsucluk}>{props.state.emails[0]}</b> */}
        {/* <b className={styles.davidsilvergooglecom}>{props.state.emails[1]}</b> */}

        {/* <b className={styles.dsilvercsucluk}>{props.state.emails && props.state.emails.length > 0 ? props.state.emails : "N/A"}</b> */}
        {props.state.emails && props.state.emails.length >0 ? <>
        <b className={styles.dsilvercsucluk}>{props.state.emails[0]}</b>
        <b className={styles.dsilvercsucluk}>{props.state.emails[1]}</b></>:<b className={styles.dsilvercsucluk}>N/A</b>}
        {/* <b className={styles.dsilvercsucluk}>aaaaaa@aaa.aaa</b>
        <b className={styles.dsilvercsucluk}>aaaaaaaa@aaa.aaa</b> */}
      </div>

      {/* Second Block Scholar Bio */}
      <div className={styles.lineParent}>
        <div className={styles.groupInner} />
        <div className={styles.scholarBio}>{t('SCHOLAR_BIO')}</div>
        <div className={styles.davidsWorkFocuses}>
          {/* {displayedBio.length > 0 ? (
            <b>{displayedBio}</b>) : (<b>{t('NA')}</b>)
          } */}
            {displayedBio.length > 0 && displayedBioCn.length !==0 && props.selectedLanguage =='EN' &&(
            <b>{displayedBio}</b>) 
          }
          {displayedBioCn.length > 0 && displayedBio.length !==0 && props.selectedLanguage == 'CN' &&(
            <b>{displayedBioCn}</b>) 
          }

          {displayedBio.length > 0 && displayedBioCn.length ==0 &&(
            <b>{displayedBio}</b>) 
          }
          {displayedBioCn.length > 0 && displayedBio.length ==0 &&(
            <b>{displayedBioCn}</b>) 
          }

          {displayedBio.length == 0 && displayedBioCn.length == 0 &&(t('NA'))}
          {/* {props.state.bio} */}
        </div>
      </div>
      {/* Second Block Work Experience */}
      <div className={styles.WEContainer}>
        <div className={styles.groupParent}>
          <div className={styles.workExperienceParent}>
            <div className={styles.academicMetrics}>{t('work_experience')}</div>
            <div className={styles.lineDiv} />
          </div>
          {/* <div className={styles.div1}>2012-</div>
          <div className={styles.div2}>2005-</div> */}

          {/* ####### */}
          <div className={styles.principalResearchScientist}>   
            {/* {workFormatted.length > 0 ? (
              workFormatted.map(item => (<b>{item}<br></br></b>))) : (
                <b>{t('NA')}</b>)
            } */}
              {workFormatted.length > 0 && workFormattedCn.length>0 && props.selectedLanguage == 'EN'&& (
              workFormatted.map(item => (<b>{item}<br></br></b>))) 
            }
              {workFormatted.length > 0 && workFormattedCn.length>0 && props.selectedLanguage == 'CN' &&(
              workFormattedCn.map(item => (<b>{item}<br></br></b>))) 
            }

            {workFormatted.length > 0 && workFormattedCn.length==0 &&(
              workFormatted.map(item => (<b>{item}<br></br></b>))) 
            }

           {workFormatted.length == 0 && workFormattedCn.length>0 &&(
              workFormattedCn.map(item => (<b>{item}<br></br></b>))) 
            }

                {workFormatted.length == 0 && workFormattedCn.length ==0 && t('NA')}
          </div>
          
        </div>
        {/* Second Block Education Experience */}
        <div className={styles.groupContainer}>
          <div className={styles.educationExperienceParent}>
            <div
              className={styles.educationExperience}
            >{t('EDUCATION_EXPERIENCE')}</div>
            <div className={styles.groupChild1} />
          </div>
          <div className={styles.na}>
            {/* {eduFormatted.length > 0 ? (
              eduFormatted.map(item => (<b>{item}<br></br></b>))) : (
                <b>{t('NA')}</b>)
            } */}
               {eduFormatted.length > 0 && eduFormattedCn.length>0 && props.selectedLanguage == 'EN'&& (
              eduFormatted.map(item => (<b>{item}<br></br></b>))) 
            }
              {eduFormatted.length > 0 && eduFormattedCn.length>0 && props.selectedLanguage == 'CN' &&(
              eduFormattedCn.map(item => (<b>{item}<br></br></b>))) 
            }

            {eduFormatted.length > 0 && eduFormattedCn.length==0 &&(
              eduFormatted.map(item => (<b>{item}<br></br></b>))) 
            }

           {eduFormatted.length == 0 && eduFormattedCn.length>0 &&(
              eduFormattedCn.map(item => (<b>{item}<br></br></b>))) 
            }

                {eduFormatted.length == 0 && eduFormattedCn.length ==0 && t('NA')}
          </div>
          {/* <b className={styles.na}>{props.state.education_experience}</b> */}
        </div>
      </div>

      {/* Third Block Academic Metrics */}
      <div className={styles.academicMetricsParent}>
        <div className={styles.academicMetrics1}>{t('ACADEMIC_METRICS')}</div>
        <img className={styles.groupItem} alt="" src="" />
      </div>      
      {/*<img className={styles.image77Icon} alt="" src="/image-77@2x.png" /> */}

      {/* FirstLine Chart */}
      <div className={styles.frameParent}>
        <div className={styles.physicalScienceWrapper}>
          <b className={styles.physicalScience}>{backward.field}</b>
        </div>
        <div className={styles.fieldWrapper}>
          <b className={styles.field}>
            <span>{t('Field')}</span>
            <span className={styles.span}>{` `}</span>
          </b>
        </div>
      </div>
      {/* SecondLine Chart */}
      <div className={styles.frameGroup}>
        <div className={styles.computerScienceWrapper}>
          <b className={styles.physicalScience}>{backward.category}</b>
        </div>
        <div className={styles.categoryWrapper}>
          <b className={styles.field}>{t('Category')}</b>
        </div>
      </div>

      {/* Subject */}
      <div className={styles.subjectWrapper}>
        <b className={styles.field}>{t('Subject')}</b>
      </div>
      {/* Subject Chart1 */}
      <div className={styles.total171PublicationsParent} ref={chartRef1}>
        <b className={styles.total171PublicationsContainer}>
          <p className={styles.professor}>{t('Total')}</p>
          <p className={styles.p}>{sumOfTotalPapers}</p>
          <p className={styles.professor}>{t('Publications')}</p>
        </b>
        <div className={styles.chartGroup_form_subject_pie}>
            <div className={styles.subjectChart}>
            <PieGenerator data = {pieData}/>
            </div>
            {barSubjectData[0].citation.name!== "" && <>
            <div className={styles.pieChart_text}>
              <div className={styles.ai_color}></div>
              <div >{capitalizeFirstLetter(barSubjectData[0].citation.name)}</div>
            </div> </>}
            {barSubjectData[1].citation.name!== "" && <>
            <div className={styles.pieChart_text}>
            <div className={styles.vision_color}></div>
              <div >{capitalizeFirstLetter(barSubjectData[1].citation.name)}</div>
            </div></>
            }
            {barSubjectData[2].citation.name!== "" && <>
            <div className={styles.pieChart_text}>
            <div className={styles.science_color}></div>
              <div >{capitalizeFirstLetter(barSubjectData[2].citation.name)}</div>
            </div></>}
            {sumOfOtherPapers!== 0 && <>
            <div className={styles.pieChart_text}>
            <div className={styles.other_color}></div>
              <div >{t('Other')}</div>
            </div></>}
          </div>
      </div>
      <div className={styles.SubjectSecondCell} ref={chartRef2}>
        {allQsAreZero(barSubjectData) 
          ? (<b className={styles.barNA}>{t('NA')}</b>) : (
            <div className={styles.citationPaper}>
              <p className={styles.professor}>{t('Citation')}</p>
              <p className={styles.paper}>{t('Paper')}</p>
            </div>
        )}
        
        {/* Insert Chart Here */}
        {(barSubjectData[0].citation.name == "" || oneQsAreZero(barSubjectData[0])) ? (<b></b>) : (
          <div className={styles.subjectc1}>
            <BarGenerator data={barSubjectData[0]} maxCite = {maxCitation} maxPap = {maxPaper}/>
            <p className={styles.chartName1}>{capitalizeFirstLetter(topSubjects[0].name)}</p>
          </div>
        )}
        {(barSubjectData[1].citation.name == "" || oneQsAreZero(barSubjectData[1])) ? (<b></b>) : (
          <div className={styles.subjectc2}>
            <BarGenerator data={barSubjectData[1]} maxCite = {maxCitation} maxPap = {maxPaper}/>
            <p className={styles.chartName2}>{capitalizeFirstLetter(topSubjects[1].name)}</p>
          </div>
        )}
        {(barSubjectData[2].citation.name == "" || oneQsAreZero(barSubjectData[2])) ? (<b></b>) : (
          <div className={styles.subjectc3}>
            <BarGenerator data={barSubjectData[2]} maxCite = {maxCitation} maxPap = {maxPaper}/>
            <p className={styles.chartName3}>{capitalizeFirstLetter(topSubjects[2].name)}</p>
          </div>
        )}

      </div>

      {/* Citation Paper Instructions */}
      <div className={styles.groupDiv} ref={legendRef1}>
        <div className={styles.citationParent}>
          <b className={styles.citation2}>{t('Citation')}</b>
          <div className={styles.q1Parent}>
            <div className={styles.q0}>Q1</div>
            <div className={styles.ellipseDiv} />
          </div>
          <div className={styles.q3Parent}>
            <div className={styles.q1}>Q3&4</div>
            <div className={styles.groupChild2} />
          </div>
          <div className={styles.q2Parent}>
            <div className={styles.q1}>Q2</div>
            <div className={styles.groupChild3} />
          </div>
        </div>
        <b className={styles.paper2}>{t('Paper')}</b>
        <div className={styles.q1Group}>
          <div className={styles.q1}>Q1</div>
          <div className={styles.groupChild4} />
        </div>
        <div className={styles.q3Group}>
          <div className={styles.q1}>Q3&4</div>
          <div className={styles.groupChild5} />
        </div>
        <div className={styles.q2Group}>
          <div className={styles.q1}>Q2</div>
          <div className={styles.groupChild6} />
        </div>
      </div>
      
      {/* TopResearchTopics */}
      <div className={styles.frameContainer}>
        <div className={styles.frameDiv} />
        <div className={styles.topResearchTopicsWrapper}>
          <b className={styles.topResearchTopicsContainer}>
            <p className={styles.professor}>{t('Top')}</p>
            <p className={styles.professor}>{t('Research Topics')}</p>
          </b>
        </div>
      </div>
      <div className={styles.TRTCharts}>
      <div className={styles.TRTCell} ref={chartRef3}>
        {allQsAreZero(barTopicData)
          ? (<b className={styles.barNA2}>{t('NA')}</b>) : (
            (oneQsAreZero(barTopicData[0]) && oneQsAreZero(barSubjectData[1]) && oneQsAreZero(barSubjectData[2]) && oneQsAreZero(barSubjectData[4]) ? (
              <b></b>
            ) : (
              <div className={styles.citationPaper2}>
                <p className={styles.professor}>{t('Citation')}</p>
                <p className={styles.paper}>{t('Paper')}</p>
              </div>
            )))}
        {(barTopicData[0].citation.name == "" || oneQsAreZero(barTopicData[0])) ? (<b></b>) : (
          <div className={styles.trtchart1}>
            <SubjectBarGenerator data={barTopicData[0]} maxCite = {maxTopicCitation} maxPap = {maxTopicPaper}/>
            <p className={styles.chartTwoName}>{capitalizeFirstLetter(topTopics[0].name)}</p>
          </div>
        )}
        {(barTopicData[1].citation.name == "" || oneQsAreZero(barTopicData[1])) ? (<b></b>) : (
          <div className={styles.trtchart2}>
            <SubjectBarGenerator data={barTopicData[1]} maxCite = {maxTopicCitation} maxPap = {maxTopicPaper}/>
            <p className={styles.chartTwoName}>{capitalizeFirstLetter(topTopics[1].name)}</p>
          </div>
        )}
        {(barTopicData[2].citation.name == "" || oneQsAreZero(barTopicData[2])) ? (<b></b>) : (
          <div className={styles.trtchart3}>
            <SubjectBarGenerator data={barTopicData[2]} maxCite = {maxTopicCitation} maxPap = {maxTopicPaper}/>
            <p className={styles.chartTwoName}>{capitalizeFirstLetter(topTopics[2].name)}</p>
          </div>
        )}
        {(barTopicData[3].citation.name == "" || oneQsAreZero(barTopicData[3])) ? (<b></b>) : (
          <div className={styles.trtchart4}>
            <SubjectBarGenerator data={barTopicData[3]} maxCite = {maxTopicCitation} maxPap = {maxTopicPaper}/>
            <p className={styles.chartTwoName}>{capitalizeFirstLetter(topTopics[3].name)}</p>
          </div>
        )}
        
      </div>
      <div className={styles.TRTCelltwo} ref={chartRef4}>
      {allQsAreZero(barTopicData)
          ? (<b className={styles.barNA2}></b>) : (
            ((oneQsAreZero(barTopicData[4]) && oneQsAreZero(barSubjectData[5]) && oneQsAreZero(barSubjectData[6]))||(
              barTopicData[4].citation.name=="" && barTopicData[5].citation.name=="" && barTopicData[6].citation.name==""
            ) ? (
              <b></b>
            ) : (
              <div className={styles.citationPaper3}>
                <p className={styles.professor}>{t('Citation')}</p>
                <p className={styles.paper}>{t('Paper')}</p>
              </div>
            )))}
        {(barTopicData[4].citation.name == "" || oneQsAreZero(barTopicData[4])) ? (<b></b>) : (
          <div className={styles.trtchart5}>
            <SubjectBarGenerator data={barTopicData[4]} maxCite = {maxTopicCitation} maxPap = {maxTopicPaper}/>
            <p className={styles.chartTwoName}>{capitalizeFirstLetter(topTopics[4].name)}</p>
          </div>
        )}
        {(barTopicData[5].citation.name == "" || oneQsAreZero(barTopicData[5])) ? (<b></b>) : (
          <div className={styles.trtchart6}>
            <SubjectBarGenerator data={barTopicData[5]} maxCite = {maxTopicCitation} maxPap = {maxTopicPaper}/>
            <p className={styles.chartTwoName}>{capitalizeFirstLetter(topTopics[5].name)}</p>
          </div>
        )}
        {(barTopicData[6].citation.name == "" || oneQsAreZero(barTopicData[6])) ? (<b></b>) : (
          <div className={styles.trtchart7}>
            <SubjectBarGenerator data={barTopicData[6]} maxCite = {maxTopicCitation} maxPap = {maxTopicPaper}/>
            <p className={styles.chartTwoName}>{capitalizeFirstLetter(topTopics[6].name)}</p>
          </div>
        )}
         
      </div>
      <div className={styles.groupDiv2} ref={legendRef2}>
        <div className={styles.citationParent}>
          <b className={styles.citation2}>{t('Citation')}</b>
          <div className={styles.q1Parent}>
            <div className={styles.q1}>Q1</div>
            <div className={styles.ellipseDiv2} />
          </div>
          <div className={styles.q3Parent}>
            <div className={styles.q1}>Q3&4</div>
            <div className={styles.groupChild2} />
          </div>
          <div className={styles.q2Parent}>
            <div className={styles.q1}>Q2</div>
            <div className={styles.groupChild3} />
          </div>
        </div>
        <b className={styles.paper2}>{t('Paper')}</b>
        <div className={styles.q1Group}>
          <div className={styles.q1}>Q1</div>
          <div className={styles.groupChild4} />
        </div>
        <div className={styles.q3Group}>
          <div className={styles.q1}>Q3&4</div>
          <div className={styles.groupChild5} />

        </div>
        <div className={styles.q2Group}>
          <div className={styles.q1}>Q2</div>
          <div className={styles.groupChild6} />
        </div>
      </div>

      {/* Forth Block #s */}
      <div className={styles.ofQ1PapersContainer}>
        <p className={styles.professor}>
         {t('hint1')}
        </p>
        <p className={styles.professor}>
        {t('hint2')}
        </p>
        <p
          className={styles.professor}
        >{t('hint3')}</p>
      </div>
      {/* Forth Block IntelliPro Img */}
      <img className={styles.icon} alt="" src="/20230626151302-2@2x.png" />
    </div>
    </div>
    </>
  );
};

export default ResumePage;