import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import ResumePage from './ResumePage'
import data from '../fake_data12.json'
import DownloadPdf from './PDFDownload';



function ResumeDownload() {
    const location = useLocation();

    const [resumeData, setResumeData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("resumeData");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
    const { id } = useParams();
    // console.log('id:', id);
    // console.log(resumeData.data)
    console.log(resumeData.language)

    
    return (
        <div>
        {
            // (!resumeData && !resumeData.length === 0) &&<ResumePage state={resumeData[id]} />
            <ResumePage authed={false} state={resumeData.data} selectedLanguage ={resumeData.language}/>
            
        }
        {/* <ResumePage state={resumeData[id]} /> */}
        </div>
        
   
    );
};

export default ResumeDownload;