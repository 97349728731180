import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import ResumePage from '../Download/ResumePage'
import ResumeDetail from './ResumeDetail';
import {fetchResumes} from '../Result/fetch'
import data from '../fake_data12.json'



function ResumeWrapper() {
    const location = useLocation();

    const [resumeData2, setResumeData2] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("resumeData");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
    const [resumeData, setResumeData] = useState();
    const { id,userid } = useParams();
    // console.log('id:', id);
    useEffect(()=>{
        const fetchResumeData = async () =>{
            let ids = {"ids":[userid]};
            const response = await fetchResumes(ids)
            console.log('my response',response);
            setResumeData(response.data[0])
        }
        fetchResumeData();

    },[])

    
    return (
        <div>
        {
            // (!resumeData && !resumeData.length === 0) &&<ResumePage state={resumeData[id]} />
            resumeData && <ResumeDetail authed={false} state={resumeData} selectedLanguage ={resumeData2.language}/> 
            //console.log('result',resumeData)
             
        }
        {/* <ResumePage state={resumeData[id]} /> */}
        </div>
        
   
    );
};

export default ResumeWrapper;