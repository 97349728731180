import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
// ];
const COLORS = ['#377DED', '#94D8FF', '#DDF6FD', '#AEAFB1'];

export default function PieGenerator (props) {
    const data = props.data
    return (
      <PieChart width={220} height={250} >
        <Pie
          data={data}
          cx={117}
          cy={150}
          innerRadius={73}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  }

