import React, { useState,useEffect } from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import { ReactComponent as BackImg } from "./assets/back.svg";
import styles from "./blockAccount.module.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';
import {useParams } from 'react-router-dom';

const BlockAccount = (props) => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate(); // Initialize useNavigate
    const [emailEmpty, setEmailEmpty] = useState(false);
    const formData = new URLSearchParams();
    formData.append("port","3001");
    const [message, setMessage] = useState("");
    const params = useParams();


    const translations = {
    EN: {
        Forget: "Your account has been locked",
        enter: "Your account has been securely blocked",
        enter2: "To reset your passwords, please visit the",
        enter3: "and select forget password to initiate the password reset process",
        email: "Work Email Address",
        sent:"Send",
        newUser:"Please visit",
        signUp:"Login page",
        error1: 'Invalid email address',
        blank: 'Fill in the blank',
        notExist: 'This account does not exist',
        back:'Back to Log In Page'
    },
    CN: {
        Forget: "您的账号已被锁定",
        enter: "输入与您的帐户关联的邮箱地址",
        email: "邮箱",
        sent:"发送",
        newUser: "新用户？",
        signUp:"点击注册",
        error1:"此邮件地址无效",
        blank: '请填写此字段',
        notExist: '此账号不存在',
        back:'返回登录页面'

    },
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (params.token && params.id) {
  //       const requestData = { token: params.token, id: params.id };
  //       const requestOptions = {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify(requestData),
  //       };

  //       try {
  //         const response = await fetch('YOUR_API_URL_HERE', requestOptions);
  //         const data = await response.json();
  //         // Process the fetched data here
  //         console.log('Fetched data:', data);
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [params.token, params.id]);

    const handleBackClick = () => {
        navigate("/login"); // Navigate to /login
    };


  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };
  const handleSignUpClick = () => {
    navigate('/login');
  };

  const SelectedOptionWithSVG = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-100px' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '452px'}} />
      {label==='English' ? (
        <span style={{ paddingTop: '2px' }}>EN</span>
      ) : (
        <span style={{ paddingTop: '2px' }}>CN</span>
      )}
    </div>
  );

  const options2 = [
    { value: 'EN', label: 'English' },
    { value: 'CN', label: '中文(简体)' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

  const handleSelectChange2 = (selectedOption) => {
    setSelectedLanguage(selectedOption.value); // Update
    props.setSelectedLanguage(selectedOption.value); 
    if (selectedOption.value === 'EN') {
      // Language is set to English
    } else if (selectedOption.value === 'CN') {
      // Language is set to Chinese
    }
  }

  const customSelect2 = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: '#377DED',
        border: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none', 
        boxShadow: 'none', 
        minWidth: '130px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display:'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      transition: 'color 0.2s',
      color: '#377DED',
      fontSize: state.isFocused ? '18px' : '16px',
      position: 'relative',
      "&:hover": {
        color: "#2149B1",
        fontSize: 18,
        //borderBottom: "2px solid #2149B1",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Sofia Pro',
      fontSize: 16,
      color: state.isSelected ? '#FFFFFF' : '#377DED',
      cursor: 'pointer',
      textAlign: 'left',
      "&:active": {
        color: '#FFFFFF',
        background: '#377DED',
      },
      "&:focus": {
        outline: 'none',
      },
      display: 'flex',
      marginTop:'8px',
      marginBottom:'8px'
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: "-20px",
      position: 'absolute',
      width:'150px',
      marginLeft:'450px'
    }),
  };

  const PlaceholderImageEN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
        <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '452px' }} />
        <span>EN</span>
    </div>
  );

  const PlaceholderImageCN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
        <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '452px' }} />
        <span>CN</span>
    </div>
  );

    return(
        <div className={props.selectedLanguage==="CN"?styles.forgetPWFormCN:styles.forgetPWForm}>
            {/* <div className={styles.LeftContainer}>
                <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
            </div> */}

            <div className={styles.logo}>
              {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
              {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
            {props.selectedLanguage=='EN' &&  <img src="/backlogin.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/>}
            {props.selectedLanguage=='CN' &&  <img src="/backcn.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/>}

            </div>
            <div className={styles.langDrop}>
                    <Select
                        options={options2}
                        isSearchable={false} // This will disable user input

                        onChange={handleSelectChange2}
                        placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                        components={{
                            SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                        }}
                        styles={customSelect2}
                    />
            </div>
            
            <div className={`${styles.Form} 
            ${(emailEmpty) || (message===t('error1')) || (message===t('notExist')) ? styles.errorGroup : ""}`}>
                
                {/* <div className={styles.backButton} onClick={handleBackClick}>
                  <BackImg className={styles.backImg} />
                </div>            */}
                {props.selectedLanguage==='EN' &&<div>
                <h2>{t('Forget')}</h2>
                <h4 className={styles.message1}>Your account {email} has been securely locked</h4>
                <h4> To reset your password, please visit the<br/> <span className={styles.signupHere} onClick={handleSignUpClick}>Log in page</span> and select 'Forget Password' <br/>to initiate password reset process</h4>

                </div>}

                {props.selectedLanguage==='CN' &&<div>
                <h2>{t('Forget')}</h2>
                <h4 > 您的账号{email} 已被安全锁定</h4>
                <h4> 请访问 <span className={styles.signupHere} onClick={handleSignUpClick}>登录页</span> 并点击“忘记密<br/>码”来重新设定密码</h4>

                </div>}
                
                


                
                {/* <div className={styles.EmailinputField}>
                    <h3>{t('email')}</h3>
                    <input
                        className={styles.input}
                        type="email" // Changed to "email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={ e => e.key ==='Enter'? submit():''}
                    />
                    {message===t('blank') && <p className={styles.errorMessagePW}>{message}</p>}
                    {message===t('error1') && <p className={styles.errorMessagePW}>{message}</p>}
                    {message===t('notExist') && <p className={styles.errorMessagePW}>{message}</p>}
                </div> */}
                 {/* <div className={styles.newUserContainer}>
                    <div className={styles.newUser}>{t('newUser')}</div>
                    {/* <div className={props.selectedLanguage === "CN" ?styles.signupHereCN:styles.signupHere} onClick={handleSignUpClick}>
                        {t('signUp')}
                    </div> */}
        
                
                <button onClick={handleSignUpClick} className={styles.sendButton}>{t('back')}</button>
               
            </div>
        </div>
    )
}

export default BlockAccount;
