import React, { useState } from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import styles from "./loginWelcome.module.css";
import { useNavigate,useParams } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';

function LoginWelcome(props) {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  console.log("log in Language:", props.selectedLanguage);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const params = useParams();


  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  function encodeFormData(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
  }

  const translations = {
    EN: {
     welcome: "Welcome!",
     longin:"Please login to your account",
     Email:"Work Email Address",
     Password:"Password",
     Login_button:"Log In",
     Signup:'Sign Up',
     forget:"Forget Password?",
     newUser:"New User?",
     signUp:"Sign up here",
     error1: 'Invalid email address',
     blank: 'Fill in the blank',
     notExist: 'This account does not exist',
     incorrect: 'Incorrect password',
     verify: 'Please verify your email',
     Errorduringlogin:"Error during login.",


    },
    CN: {
      welcome: "欢迎",
      longin:"登录我的帐户",
      Email:"邮箱",
      Password:"密码",
      Login_button:"登录",
      Signup:'注册',
      forget:"忘记密码？",
      newUser:"还没有账号？",
      signUp:"点击注册",
      error1:"此邮件地址无效",
      blank: '请填写此字段',
      notExist: '此账号不存在',
      incorrect: '密码错误',
      verify: '请验证您的邮件',
      Errorduringlogin:"登录时出错。",

      
    },
    // You can add more languages here
  };


  const handleLogin = () => {

    setMessage("");
    setEmailError(!formData.email.trim());
    setPasswordError(!formData.password.trim());

    if (!formData.email.trim()) {
      setEmailError(true);
      setMessage(t('blank'));
      return;
    } else if (!formData.password.trim()) {
      setPasswordError(true);
      setMessage(t('blank'));
      return;
    } else if (!formData.email.includes("@") || formData.email.length <= 3 || 
                formData.email.split("@")[0].length === 0 || formData.email.split("@")[1].length === 0) {
      setMessage(t('error1'));
      return;
    } else {
      
    }

    if (formData.email !== "" && formData.password !== "") {
      fetch("https://intelliprozenithscholars.com/auth/login/3001", {
        method: "GET",
        headers: {
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error("Request failed with status: " + response.status);
        }
        return response.json();
      })
      .then(csrfdata => {
        console.log(csrfdata)
        setCsrfToken(csrfdata.csrf_token);
        const formBody = encodeFormData(formData);
        console.log("Data we POST:", {
          method: "POST",
          headers: {
            "X-CSRFToken": csrfdata.csrf_token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          credentials: 'include',
          body: formBody
        })
        fetch("https://intelliprozenithscholars.com/auth/login/3001", {
          method: "POST",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          credentials: 'include',
          body: formBody
        })
        .then(response => {
          if (!response.ok) {
            throw new Error("login request failed with status: " + response.status);
          }
          for(let entry of response.headers.entries()) {
            console.log('header', entry);
          }
          return response.json();
        })
        .then(data => {
          console.log(data)
          if (data.success) {
            // Redirect to dashboard or main page
            console.log(document.cookie);
            console.log("Successfully logged in!");
            props.setEmail(formData.email);
            navigate('/home');
          } else {
            // Show error message to the user
            setMessage(data.message);
            if (data.message==="user does not exist.") {
              setMessage('This account does not exist');
            } else if (data.message==="wrong password.") {
              setMessage(t('incorrect'));
            } else if (data.message==="please verify your email."){
              setMessage(t('verify'));
            }else if(data.message=="the account has been block"){
              navigate('/blockAccount');
            }
          }
        })
        .catch(error => {
          console.error("Error during login:", error);
          setMessage(t('Errorduringlogin'));  // Display feedback to the user
        });
      })
      .catch(error => {
        console.error("Error fetching CSRF token:", error);
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };


  const handleSignUpClick = () => {
    if(params.sourcing){
      // console.log(params.sourcing)
      navigate(`/signUp/${params.sourcing}`);
    }else{
      navigate('/signUp')
    }  };

    const handleLoginClick = () => {
      if(params.sourcing){
        // console.log(params.sourcing)
        navigate(`/login/${params.sourcing}`);
      }else{
        navigate('/login')
      }  };

      const handleLanding = () => {
        if(params.sourcing){
          // console.log(params.sourcing)
          navigate(`/landing/${params.sourcing}`);
        }else{
          navigate('/')
        }  };
    
  const handleforgetClick=()=>{
    navigate('/forgetPW');
  }


  const SelectedOptionWithSVG = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px',paddingTop:'7px'}} />
      {label==='English' ? (
        <span style={{ paddingTop: '3px' }}>EN</span>
      ) : (
        <span style={{ paddingTop: '3px' }}>CN</span>
      )}
    </div>
  );

  const options2 = [
    { value: 'EN', label: 'English' },
    { value: 'CN', label: '中文(简体)' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

  const handleSelectChange2 = (selectedOption) => {
    setSelectedLanguage(selectedOption.value); // Update
    props.setSelectedLanguage(selectedOption.value); 
    if (selectedOption.value === 'EN') {
      // Language is set to English
    } else if (selectedOption.value === 'CN') {
      // Language is set to Chinese
    }
  }

  const customSelect2 = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: '#377DED',
        border: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none', 
        boxShadow: 'none', 
        minWidth: '130px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display:'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      transition: 'color 0.2s',
      color: '#377DED',
      fontSize: state.isFocused ? '18px' : '16px',
      position: 'relative',
      "&:hover": {
        color: "#2149B1",
        fontSize: 18,
        //borderBottom: "2px solid #2149B1",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Sofia Pro',
      fontSize: 16,
      color: state.isSelected ? '#FFFFFF' : '#377DED',
      cursor: 'pointer',
      textAlign: 'left',
      "&:active": {
        color: '#FFFFFF',
        background: '#377DED',
      },
      "&:focus": {
        outline: 'none',
      },
      display: 'flex',
      marginTop:'8px',
      marginBottom:'8px'
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: 0,
      position: 'absolute',
    }),
  };

  const PlaceholderImageEN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
        <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
        <span>EN</span>
    </div>
  );

  const PlaceholderImageCN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
        <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
        <span>CN</span>
    </div>
  );

  return (
    <>
    <div className={styles.loginForm}>

      <div className={styles.LeftContainer}>
        {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
        {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
        <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
      </div>

      <div className= {styles.backhome}>
        <img src="/backhome.svg" alt="back" className= {styles.backtohome}  onClick={handleLanding}/>
      </div>
      
      <div className={styles.langDrop}>
          <Select
            options={options2}
            onChange={handleSelectChange2}
            isSearchable={false} // This will disable user input
            placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
            components={{
              SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
            }}
            styles={customSelect2}
          />
      </div>

      {/* c:\Users\zhouy\Downloads\backhome.svg */}

      <div className={props.selectedLanguage==="CN" ? styles.FormCN: styles.Form}>
        <h2>{t('welcome')}</h2>
        {/* <h4 className={props.selectedLanguage==='CN' ? styles.longinCN : styles.longin}>{t('longin')}</h4> */}


          {/* {message && (<p className={styles.errorMessagePW}>{message}</p>)}
          {message && (<p className={styles.errorMessagePW}>{message}</p>)} */}
            <div className={styles.buttondiv}>
                  <button onClick={handleLoginClick} className={styles.Login_button}>
         {t('Login_button')}
                           </button>

                        <button onClick={handleSignUpClick} className={styles.Login_button}>
         {t('Signup')}
                                 </button>
                        </div>
        </div>
        </div>
        <div className="mobile-background">
        </div>
        </>

      
        
  
  );
};

export default LoginWelcome;