import styles from '../GeneralFIlter.module.css';
import React, { useState, useEffect } from "react";

function TotalPublications(props) {
    const [minValue, setMinValue] = useState(props.state[0]);
    const [maxValue, setMaxValue] = useState(props.state[1]);
    const [errorMessage, setErrorMessage] = useState('');
    const [placeholder, setPlaceholder] = useState('max')
    const [minPlaceholder, setMinPlaceholder] = useState('min')

    // useEffect(() => {
    //   props.change([minValue, maxValue]);
    // }, [minValue, maxValue]);

    useEffect(() => {
      if (props.clearAll) {
        setMinValue('');
        setMaxValue('');
      }
    }, [props.clearAll]);
    
  


    useEffect(() => {
      // setMinValue(props.state[0]);
      // setMaxValue(props.state[1]);
      if (minValue < 0 && minValue !== '') {
        setMinValue(0);
      }
      if (maxValue < 0 && maxValue !== '') {
        setMaxValue(0);
      }
      if(maxValue > 100000){
        setMaxValue(99999)
      }
      if(minValue > 100000){
        setMaxValue(99999)
      }

      if (maxValue !== '' && minValue !== '' && parseInt(maxValue) < parseInt(minValue)) {
        setErrorMessage('Please enter a valid value');
      
      }
      else {
        setErrorMessage('');
      }
      if(minValue !== '' && maxValue !== ''  && parseInt(maxValue) > parseInt(minValue)){
        props.change([minValue, maxValue]);

      }else if(minValue !== '' && maxValue !== ''  && parseInt(maxValue) < parseInt(minValue)){
        props.change([null,null ]);

      }
      else if(maxValue == '' && maxValue ==''){
        props.change([null,null])
      }else if(minValue == ''){
        props.change([null,maxValue])
      }else if(maxValue == ''){
        props.change([minValue,null])
      }
    
      console.log(minValue,maxValue)
    }, [minValue, maxValue]);


    // const setMin = (e) => {
    //   const value = parseInt(e.target.value, 10);
    //   if (value <= maxValue){
    //     setMinValue(value);
    //     console.log(minValue, maxValue);
    //   }
    //   else{
    //     setMinValue(maxValue)
    //   }
    // };

   
    // const setMax = (e) => {
    //   const value = parseInt(e.target.value, 10);
    //   if (value >= minValue){
    //     setMaxValue(value);
    //     console.log(minValue, maxValue);
    //   }
    //   else{
    //     setMaxValue(minValue)
    //   }
    // };


    const handleChangeMin = (event) => {
      const inputValue = parseInt(event.target.value);
  
        setMinValue(inputValue);
      
    };


    const handleChangeMax = (event) => {
      const inputValue = parseInt(event.target.value);
  
   
        setMaxValue(inputValue);
      
    };

    const translations = {
    EN: {
      Total: "Total",
      Publications: "Publications",
      publications: "publications",
      errorMessage:'Please enter a valid value'
    },
    CN: {
      Total: "总出版物",
      Publications: "数量",
      publications: "出版物数量",
      errorMessage:'请输入一个合理的值'
      
    },
    // You can add more languages here
  };

  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };

    return(
        <div className={styles.wrapper} id="total_publications">
          <div className={styles.total_div}>
            <div className={styles.non_details}>
              <div className={styles.title_range}>
                {/* <img src="tag.svg" alt="icon"> */}
                <h2>{t('Total')}<br />{t('Publications')}</h2>
              </div>
              <div className={styles.range_block}>
                {/* <input className={styles.mask} type="number" id="tentacles1" name="tentacles" placeholder='min' Value={minValue} onChange={handleChangeMin}/> */}
                <input className={styles.enterNames} type="number"  id="tentacles1" name="tentacles" placeholder={minPlaceholder} onFocus={e=>{setMinPlaceholder('')}} onBlur={e=>{setMinPlaceholder('min')}} value={minValue} onChange={handleChangeMin} />
              </div>
              <div className={styles.separator} id="separator"><span>&nbsp;&nbsp;&nbsp;&nbsp; -- &nbsp;&nbsp;&nbsp;&nbsp;</span></div>
              <div className={styles.range_block}>
                {/* <input className={styles.mask} type="number" id="tentacles2" name="tentacles" placeholder='max' Value={maxValue}  onChange={handleChangeMax}/> */}
                <input className={styles.enterNames} type="number" value={maxValue} id="tentacles2" name="tentacles" placeholder={placeholder} onFocus={e=>{setPlaceholder('')}} onBlur={e=>{setPlaceholder('max')}} onChange={handleChangeMax} />
              </div>
              <div className={styles.separator}><span>&nbsp;&nbsp;&nbsp;&nbsp;{t('publications')}</span></div>  
            </div>
            {errorMessage && <div style={{ color: 'red', fontFamily:'Sofia pro' }} className={styles.total_public_error_msg}>{t('errorMessage')}</div>}
            </div>         
          </div>
    )   
};

export default TotalPublications;

