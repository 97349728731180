
const fetchResumes = (ids) => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/search/filter_get_resume", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(ids)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          reject(error);
        });
    });
  }; 

const fetchTotalNum = (general, subject) => {
  return new Promise((resolve, reject) => {
    fetch("https://intelliprozenithscholars.com/search/filter_total_num", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...general,
        ...subject,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {resolve(data);})
      .catch((error) => {
        console.error("Error:", error);
        reject(error);
      }); 
    });
  };

  const fetchTotalNameNum = (name) => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/search/filter_total_num_by_name", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {resolve(data);})
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        }); 
      });
    };
  export {fetchResumes, fetchTotalNum,fetchTotalNameNum};
  