import React, { useState , useEffect} from "react";
import styles from "./LandingPage.css";
import Select from "react-select";
import { Link } from 'react-router-dom';
import placeholderImg from './setting.svg';
import leftcontentCn from './leftCn.svg'
import leftcontentEn from './leftEn.svg'
import barlistEn from './barEn.svg'
import barlistCn from './barCn.svg'
import barlistEn_hover from './barEn_hover.svg'
import barlistCn_hover from './barCn_hover.svg'
import logoEn from './logoEn.svg'
import logoCn from './logoCn.svg'
import "./LandingPage.css"
import {  useNavigate , useParams } from 'react-router-dom';
import mobilebg from './mobile.svg'

function LandingPage(props) {
  
    const params = useParams();
    const [disableScrolling, setDisableScrolling] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
      if (disableScrolling) {
        document.body.classList.add("disable-scroll");
      } else {
        document.body.classList.remove("disable-scroll");
      }
    
      // Clean up the effect when the component unmounts
      return () => {
        document.body.classList.remove("disable-scroll");
      };
    }, [disableScrolling]);
    useEffect(() => {
      setDisableScrolling(true);
    
      // To re-enable scrolling when the subpage unmounts, you can return a cleanup function
      return () => {
        setDisableScrolling(false);
      };
    }, []);
    
    const t = (key) => {
        return translations[props.selectedLanguage][key] || key;
      };
    const translations = {
        EN: {
            login: "Log In/Sign Up",
            enter:"Sign Up Now",
            term: "Terms Of Use",
            policy: "Privacy Policy",
            optout: "Opt-Out",
            cookie:'Cookie Policy'

        },
        CN: {
            login: "登录/注册",
            enter:"马上进入",
            term: "服务条款",
            policy: "隐私政策",
            optout: "退出选择权",
            cookie:"Cookie政策"
        },
      };
    
      const handleMouseEnter = () => {
        const optionContainer = document.querySelector('.language_Container');
        optionContainer.classList.add('selected-option');
      };
    
      const handleMouseLeave = () => {
        const optionContainer = document.querySelector('.language_Container');
        optionContainer.classList.remove('selected-option');
      };
      
  const SelectedOptionWithSVG = ({ label }) => (
    <div className="language_Container" style={{ height:'100%', display: 'flex', alignItems: 'center', justifyItems:'center', flexDirection:'row'}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img className="selectoption"src={placeholderImg} alt="Placeholder" style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyItems:'center',
        flexDirection:'row',
        width: '25px', 
        height: '25px',
        // marginLeft: '5vh',
        marginRight: '5vh',
        marginTop: '-2.5vh'
        }} />
        {label==='English' ? (
        <span className="language-word" >EN</span>
      ) : (
        <span className="language-word">CN</span>
      )}
    </div>
  );

  const options2 = [
    { value: 'EN', label: 'English' },
    { value: 'CN', label: '中文（简体)' },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage || 'EN'); // Set default language

  const handleSelectChange2 = (selectedOption) => {
    setSelectedLanguage(selectedOption.value); 
    props.setSelectedLanguage(selectedOption.value); 
    const coverLeftDiv = document.querySelector(".Cover_left");
    const bottom1 = document.querySelector(".landing-bottom1");
    const bottom2 = document.querySelector(".landing-bottom2");
    const bottom3 = document.querySelector(".landing-bottom3");
    const enterList = document.querySelector(".enter_list");
    const logo = document.querySelector(".Cover_logo");
    if (selectedOption.value === 'EN') {
      // Language is set to English
        coverLeftDiv.src = leftcontentEn;
        coverLeftDiv.style.height = '100%';
        enterList.src = barlistEn;
        enterList.style.width = '60%';
        logo.src = logoEn;
        bottom1.style.fontSize='1.6vw';
        bottom1.style.marginTop='0px';
        bottom2.style.fontSize='1.6vw';
        bottom2.style.marginTop='0px';
        bottom3.style.fontSize='1.6vw';
        bottom3.style.marginTop='0px';

    } else if (selectedOption.value === 'CN') {
      // Language is set to Chinese
        coverLeftDiv.src = leftcontentCn;
        coverLeftDiv.style.height = '95%';
        enterList.src = barlistCn;
        enterList.style.width = '60%';
        logo.src = logoCn;
        bottom1.style.fontSize='1.5vw';
        bottom1.style.marginTop='-8px';
        bottom2.style.fontSize='1.5vw';
        bottom2.style.marginTop='-8px';
        bottom3.style.fontSize='1.5vw';
        bottom3.style.marginTop='-8px';
    }

  }
  const handleMouseOver = () => {
    const enterList = document.querySelector(".enter_list");

    if (selectedLanguage === 'EN') {
      enterList.src = barlistEn_hover; // Change to the Chinese version when hovering
    } else {
      enterList.src = barlistCn_hover; // Change to the English version when hovering
    }
  };
  const handleMouseOut = () => {
    const enterList = document.querySelector(".enter_list");
    enterList.src = selectedLanguage === 'EN' ? barlistEn : barlistCn; // Reset to the appropriate version when not hovering
  };

  const customSelect3 = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: '#377DED',
        border: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none', 
        boxShadow: 'none', 
        minWidth: '130px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display:'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      transition: 'color 0.2s',
      color: '#377DED',
      fontSize: state.isFocused ? '18px' : '16px',
      position: 'relative',
      "&:hover": {
        color: "#2149B1",
        fontSize: 18,
        //borderBottom: "2px solid #2149B1",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Sofia Pro',
      fontSize: 20,
      color: state.isSelected ? '#FFFFFF' : '#377DED',
      cursor: 'pointer',
      textAlign: 'left',
      "&:active": {
        color: '#FFFFFF',
        background: '#377DED',
      },
      "&:focus": {
        outline: 'none',
      },
      display: 'flex',
      marginTop:'10px',
      marginBottom:'10px'
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: 0,
      position: 'absolute',
      width: '20vh'
    }),
  };

  const PlaceholderImageEN = () => (
    <div className="language_Container2" style={{display: 'flex', alignItems: 'center' , justifyItems:'center', flexDirection:'row'}}>
        <img className="placeholderEn" src={placeholderImg} alt="Placeholder" style={{
        display: 'flex', 
        alignItems: 'center', 
        justifyItems:'center',
        width: '25px', 
        height: '25px',
        marginLeft: '-1vh',
            }} />
        <span className="language-word1">EN</span>
    </div>
  );

  const PlaceholderImageCN = () => (
    <div className="language_Container2" style={{display: 'flex', alignItems: 'center' , justifyItems:'center', flexDirection:'row'}}>
        <img className="placeholderCn" src={placeholderImg} alt="Placeholder" style={{
        display: 'flex', 
        alignItems: 'center', 
        justifyItems:'center',
        width: '25px', 
        height: '25px',
        marginLeft: '-1vh',
            }} />
        <span className="language-word1">CN</span>
    </div>
  );

  const handleLogin=()=>{
      if(params.sourcing){
        console.log(params.sourcing)
        navigate(`/loginwelcome/${params.sourcing}`);
      }else{
        console.log(params)
        navigate('/loginwelcome')
      }
  }

  const handleSignup =()=>{
    if(params.sourcing){
      console.log(params.sourcing)
      navigate(`/signUp/${params.sourcing}`);
    }else{
      console.log(params)
      navigate('/signUp')
    }
  }
  const handle_term=()=>{
    localStorage.setItem('selectedLanguage', props.selectedLanguage)
    window.open('/TermOfUse')
  }
  const handle_privacy=()=>{
    localStorage.setItem('selectedLanguage', props.selectedLanguage)
    window.open('/privacy')
  }
  const handle_optout=()=>{
    localStorage.setItem('selectedLanguage', props.selectedLanguage)
    window.open('/optout')
  }

  const handle_cookie=()=>{
    localStorage.setItem('selectedLanguage', props.selectedLanguage)
    window.open('/cookie')
  }

    return (
      <div>
        <div className="mobile-background">
        </div>
        <div className="Coverbackground-wrapper" >
            <div className="Coverbackground" >
                
                <div className="Cover_list">
                        {props.selectedLanguage == 'EN' && <img className="Cover_logo" src = {logoEn}></img>}
                        {props.selectedLanguage == 'CN' && <img className="Cover_logo" src = {logoCn}></img>}

                        <div className="Cover_login_signup" onClick={handleLogin}>
                        {t('login')}
                        </div>
                            <Select
                                options={options2}
                                onChange={handleSelectChange2}
                                isSearchable={false} // This will disable user input
                                placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                                components={{
                                    SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                                }}
                                styles={customSelect3}
                            >
                            </Select>
                </div>
                <div className="Cover_center">
                {props.selectedLanguage == 'EN' && <img className="Cover_left" src = {leftcontentEn} ></img> }
                {props.selectedLanguage == 'CN' && <img className="Cover_left" src = {leftcontentCn} ></img> }

                    <div className="Cover_right"></div>
                </div>
                <div className="enter_container">
                    <div className="enter_text">

                   {props.selectedLanguage == 'EN'&& <img className="enter_list" src={barlistEn} onClick={handleSignup} alt="Bar List" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}/> }
                   {props.selectedLanguage == 'CN'&& <img className="enter_list" src={barlistCn} onClick={handleSignup} alt="Bar List" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}/> }

                      
                    </div>
                </div>
                <div className="bottom-container">
                  <div className="bottom-list">
                  <div className="landing-bottom1" onClick={handle_term}>
                  {t('term')}
                  </div>
                  <div className="delimiter">|</div>
                  <div className="landing-bottom2" onClick={handle_privacy}>
                      {t('policy')}
                  </div>
                  <div className="delimiter">|</div>
                  <div className="landing-bottom3" onClick={handle_optout}>
                  {t('optout')}
                  </div>
                  <div className="delimiter">|</div>
                  <div className={props.selectedLanguage=='EN'?"landing-bottom4":"landing-bottom4-cn"} onClick={handle_cookie}>
                  {t('cookie')}
                  </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    )
}
export default LandingPage;
