import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate,Navigate} from 'react-router-dom';
import { fetchProfile } from '../Home/fetch';

const PrivateRoute2 = () => {
  const [auth, setAuth] = useState();
  const navigate = useNavigate(); // 获取 navigate 函数


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data2 = await fetchProfile();
        console.log('login status /auth', data2);

        // Check if data2.email exists and localStorage has resumeData
        if (data2.email && localStorage.getItem('resumeData')) {
          setAuth(true);
          console.log('set true');
        } else if(data2.email && !localStorage.getItem('resumeData')){
            navigate('/home');

        }else {
          setAuth(false);
          console.log('set false');
        }
      } catch (error) {
        console.error('Promise rejected:', error);
        setAuth(false);
      }
    };
    fetchData();
  }, []);

  console.log('private route');
  console.log(auth);
  if (auth === undefined) return null;

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute2;
