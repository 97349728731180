import styles from "./SubjectFilter.module.css";
import { useEffect, useState } from "react";
import ResearchTopic from "./ResearchTopic";
import data from "../../DKDH_Filter.json";
import Modal from "./Modal";
import React, { useRef } from "react";
import hint from "../GeneralFilter/asset/hint-icon.png";
import translationCN from "./mapping_dict.json"

import { ReactComponent as TabIcon } from "./Vector 1.svg";
import { ReactComponent as TabIcon1 } from "./Vector 4.svg";
import { ReactComponent as TabIcon2 } from "./Vector 2.svg";

function SubjectFilter(props) {
  const [tags, setTags] = useState([]);
  const [showSub, setShowSub] = useState(props.state);
  const [show, setShow] = useState(true);
  const [showCategory, setShowCategory] = useState(true);
  const [showSubject, setShowSubject] = useState(true);
  const [dropDown, setDropDown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("any");
  const [researchTopic, setResearchTopic] = useState([]);
  const [filterBy, setFilterBy] = useState("Subject Field");
  const [expanded, setExpanded] = useState(false);
  const [toggleState, setToggleState] = useState(1);
  const [showMessage, setShowMessage] = useState(false);
  const [showMessage2, setShowMessage2] = useState(false);
  const [topicMessage, setTopicMessage] = useState(false);


  const filteredTags = tags.filter(tag => tag !== "N/A");



  const handleMouseEnter = () => {
    setShowMessage(true);
  };

  const selectedStyle = {
    fontSize: "27px",
  };

  const handleMouseLeave = () => {
    setShowMessage(false);
  };

  
  const handleMouseEnter2 = () => {
    console.log("Mouse entered");
    if(checkButtonDisabled){
      setShowMessage2(true);
    }
  };

  const handleMouseLeave2 = () => {
    console.log("Mouse leave");
    if(checkButtonDisabled){
      setShowMessage2(false);
    }
  };

  useEffect(() => {
    setShowSub(props.state);
  }, [props.state]);
  useEffect(() => {
    props.setState(showSub);
  }, [showSub]);

  function handleResearch(newValue,message) {
    setResearchTopic(newValue);
    setTopicMessage(message)
  }

  const removeTag = (tagToRemove) => {
    setResearchTopic(researchTopic.filter((tag) => tag !== tagToRemove));
    // if (fieldItems[tagToRemove]){
    //     addField(tagToRemove);
    // }
    // if (categoryItems[tagToRemove]){
    //     addCategory(tagToRemove);
    // }
    if (subjectItems[tagToRemove]) {
      addSubject(tagToRemove);
    }
  };
  const handleOpen = () => {
    // setDropDown(true);
    setDropDown(!dropDown);

  };
  const handleSelection = (option) => {
    setSelectedOption(option);
    save()
    setDropDown(false);
  };
  const translations = {
    EN: {
      academicFilter: "Academic Filter",
      clearAll: "Clear all",
      cancel: "Cancel",
      save: "Save",
      searchPlaceholder: "Search by scholar's research field(academic field, research category, subject.... )",
      any: "Any",
      all: "All",
      anyhint:"Has any of the selected subjects/topics",
      allhint:"Has all of the selected subjects/topics",
      subjectField: "Subject Field",
      researchTopic: "Research Topic",
      Field: "Field",
      Category: "Category",
      showmore: "show more",
      showless: "show less",
      advancedfilters: "Advanced Filters",
      hint:"We measure the academic proficiency of a scholar based on the average number of citations their published papers receive in a specific academic field and the quantity of papers they have published in authoritative journals within that field.",
      hint2:"You must select at least one subject",
      onlyShown: "Only Shown",
      subject: "Subject",
    },
    CN: {
      academicFilter: "学术领域筛选栏",
      clearAll: "清空",
      cancel: "取消",
      save: "保存",
      searchPlaceholder: "按学者的研究领域进行搜索（学术领域、研究类别、课题...）",
      any: "任意",
      all: "全部",
      anyhint:"包含已选学科/课题中的任意一项",
      allhint:"包含已选择的学科/课题",
      subjectField: "主题领域",
      researchTopic: "研究课题",
      Field: "领域",
      Category: "学科类别",
      showmore: "显示更多",
      showless: "收起",
      advancedfilters: "高级筛选",
      hint:"我们基于一名学者在某一学术领域内所发表的论文的平均被引用次数，以及该领域内在权威期刊上发表论文的数量这两个标准来衡量该学者的学术水平的。",
      hint2:"您必须至少选择一个学科。",
      onlyShown: "仅显示",
      subject: "学科",
    },
  // You can add more languages here
};
const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };


  const [fields, setFields] = useState([
    "Life Sciences",
    "Physical Sciences",
     "Social Sciences",
     "Health Sciences",
  ]);
  
  

  const [fieldItems, setFieldItems] = useState({});
  const [savedFieldItems, setSavedFieldItems] = useState({});
  function getCategory(data, fields) {
    let arr = [];
    fields.forEach((field) => {
      arr = [...arr, ...Object.keys(data.Filter[field])];
    });
    return arr;
  }
  function getSubject(data, categories) {
    // console.log(categories)
    let arr = [];
    // console.log(fields);
    categories.forEach((category) => {
      for (let field of fields) {
        if (data.Filter[field][category]) {
          arr = [...arr, ...Object.values(data.Filter[field][category])];
        }
      }
    });
    // console.log(arr)
    return arr;
  }

  const [savedResearchTopic, setSavedResearchTopic] = useState([]);
  const [categories, setCategories] = useState(getCategory(data, fields));
  const [categoryItems, setCategoryItems] = useState({});
  const [savedCategoryItems, setSavedCategoryItems] = useState({});
  const [subjects, setSubjects] = useState(getSubject(data, categories));
  const [subjectItems, setSubjectItems] = useState({});
  const [savedSubjectItems, setSavedSubjectItems] = useState({});
  const [onlyShown, setOnlyShown] = useState({});
  const [savedOnlyShown, setSavedOnlyShown] = useState({});

  // useEffect(()=>{
  //   if(props.filter){
  //     console.log(props.filter)
  //     const data = JSON.parse(props.filter).subject_filter;
  //     console.log('filter',data)
  //     setTags([...data.subject, ...data.topic, ...data.onlyshown])
  //     setResearchTopic([...data.topic])
  //     setSubjectItems(data.subject.reduce((acc, subject) => {
  //       acc[subject] = true;
  //       return acc;
  //     }, {}));
  //     const mainCategoriesSet = new Set();
  //     const subCategoriesSet = new Set();

  //   data.subject.forEach(target => {
  //     const result = findCategoryReverse(target);
  //     if (result) {
  //       mainCategoriesSet.add(result.mainCategory);
  //       subCategoriesSet.add(result.subCategory);
  //     }
  //   });
  //     console.log('category',mainCategoriesSet, subCategoriesSet);

  //     setCategoryItems(Array.from(subCategoriesSet).reduce((acc, category) => {
  //       acc[category] = true;
  //       return acc;
  //     }, {}));
  //     setFieldItems(Array.from(mainCategoriesSet).reduce((acc, category) => {
  //       acc[category] = true;
  //       return acc;
  //     }, {}));
      

  //   }

  // },[])

  const findCategoryReverse = (target) => {
    for (const mainCategory in data.Filter) {
      console.log('filter loop')
      const subCategories = data.Filter[mainCategory];
      for (const subCategory in subCategories) {
        if (subCategories[subCategory].includes(target)) {
          return {
            mainCategory,
            subCategory
          };
        }
      }
    }
    return null;
  };


  const checkAllTagsEmpty = () => {
    if (researchTopic.length !== 0) {
      return false;
    }
    if (areAllValueFalse(subjectItems)) {
      return true;
    }
    return false;
  };
  const checkAllTagsEmptyForClearAll = () => {
    if (researchTopic.length !== 0) {
      return false;
    }
    if (
      areAllValueFalse(subjectItems) &&
      areAllValueFalse(fieldItems) &&
      areAllValueFalse(categoryItems) &&
      areAllValueFalse(onlyShown)
    ) {
      return true;
    }
    return false;
  };
  const checkButtonDisabled = checkAllTagsEmpty();
  const checkClearAllButtonDisabled = checkAllTagsEmptyForClearAll();
  const topNScholars = [
    {
      id: "top5",
      name: "Top 5% scholars",
      value: "200, 0",
      description: "Top 200 scholars in the selected fields/subjects",
    },
    {
      id: "top5to10",
      name: "5% - 10% scholars",
      value: "500, 200",
      description: "200 - 500 scholars in the selected fields/subjects",
    },
    {
      id: "top10to25",
      name: "10% - 25% scholars",
      value: "1000, 500",
      description: "500 - 1000 scholars in the selected fields/subjects",
    },
    {
      id: "top25to50",
      name: "25% - 50% scholars",
      value: "2000, 1000",
      description: "1000 - 2000 scholars in the selected fields/subjects",
    },
    {
      id: "top50to75",
      name: "50% - 75% scholars",
      value: "5000, 2000",
      description: "2000 - 5000 scholars in the selected fields/subjects",
    },
    {
      id: "last25",
      name: "Last 25% scholars",
      value: "5600, 5000",
      description: "After 5000 scholars in the selected fields/subjects",
    },
  ];

  const topNScholarsCN = [
    {
      id: "top5",
      name: "前5%的学者",
      value: "200, 0",
      description: "在所选领域/学科中排名前200的学者",
    },
    {
      id: "top5to10",
      name: "5% - 10%的学者",
      value: "500, 200",
      description: "在所选领域/学科中排名200至500的学者",
    },
    {
      id: "top10to25",
      name: "10% - 25%的学者",
      value: "1000, 500",
      description: "在所选领域/学科中排名500至1000的学者",
    },
    {
      id: "top25to50",
      name: "25% - 50%的学者",
      value: "2000, 1000",
      description: "在所选领域/学科中排名1000至2000的学者",
    },
    {
      id: "top50to75",
      name: "50% - 75%的学者",
      value: "5000, 2000",
      description: "在所选领域/学科中排名2000至5000的学者",
    },
    {
      id: "last25",
      name: "后25%的学者",
      value: "5600, 5000",
      description: "在所选领域/学科中排名5000以外的学者",
    },
  ];
  const addOnlyShown = (e) => {
    const updatedOnlyShown = {};
    if (!onlyShown[e]) {
      updatedOnlyShown[e] = true;
    }
    // Set the rest of the options to false to ensure only one option is selected
    for (const option in onlyShown) {
      if (option !== e) {
        updatedOnlyShown[option] = false;
      }
    }
    // Set the updated onlyShown state
    setOnlyShown(updatedOnlyShown);
  };
  
  function areAllValueFalse(obj) {
    for (let key in obj) {
      if (obj[key]) {
        return false;
      }
    }
    return true;
  }

  const displayTags = expanded ? filteredTags : calculateTags(tags);
  // const remainingTags = tags.slice(displayTags.length, tags.length);
  function calculateTags(tags) {
    let totalLength = 0;
    let displayedTags = [];
    if(props.selectedLanguage =='EN'){
      for (let i = 0; i < tags.length; i++) {
        if (tags[i] !== "N/A") { // Skip "N/A" when calculating
          const tagLength = tags[i].length;
          let displayedTagsLength = displayedTags.length ? displayedTags.length : 0;
          if (totalLength + tagLength + displayedTagsLength * 2.65 <= 98) {
            // console.log(totalLength + tagLength + displayedTagsLength * 2.65 )

            displayedTags.push(tags[i]);
            totalLength = totalLength + tagLength + displayedTagsLength * 2.65;

          } else {
            break;
          }
        }
      }
    }
    if (props.selectedLanguage == 'CN') {
      for (let i = 0; i < tags.length; i++) {
        console.log(tags[i]);
        if (tags[i] !== "N/A") { // Skip "N/A" when calculating
          let tagname = translationCN[tags[i]] ? translationCN[tags[i]] : tags[i];
          const tagLength = tagname.length;
          console.log('CN',tagLength)
          let displayedTagsLength = displayedTags.length ? displayedTags.length : 0;
          if (totalLength + tagLength + displayedTagsLength * 2.65 <= 85) {
            //  console.log(totalLength + tagLength + displayedTagsLength * 2.65 )
            displayedTags.push(tags[i]);
            totalLength = totalLength + tagLength + displayedTagsLength * 2.65;

          } else {

            break;
          }
        }

      }
    }
    

    return displayedTags;
  }

  // useEffect(() => {
  //     props.change({
  //         subject_filter: {
  //             type: selectedOption,
  //             // topic: (areAllValueFalse(subjectItems)) ? subjects : Object.keys(subjectItems),
  //             topic: tags ? tags : subjects,
  //             onlyshown: {
  //                 upper:onlyShown[0],
  //                 lower:onlyShown[1]
  //             }
  //         }
  //     })
  // }, [onlyShown, subjectItems, selectedOption])
  // useEffect(() => {
  //     setTags([...researchTopic, ...Object.keys(subjectItems)]);
  //   }, [researchTopic, fieldItems, categoryItems, subjectItems]);
  // function getSubject(data, field, parentClass) {
  //     const parent = data[field].find(item => item.className === parentClass);
  //     return parent ? parent.children.map(child => child.className) : [];
  // }============================================
  // useEffect(() => {
  //   if (areAllValueFalse(fieldItems)) {
  //     setCategories(getCategory(data, fields));
  //   } else {
  //     setCategories(getCategory(data, Object.keys(fieldItems)));
  //     if (areAllValueFalse(categoryItems)) {
  //       setSubjects(
  //         getSubject(data, getCategory(data, Object.keys(fieldItems)))
  //       );
  //     }
  //   }
  // }, [fieldItems]);

  // useEffect(() => {
  //   if (areAllValueFalse(categoryItems)) {
  //     setSubjects(getSubject(data, categories));
  //   } else {
  //     setSubjects(getSubject(data, Object.keys(categoryItems)));
  //   }
  // }, [categoryItems]);
  
  useEffect(() => {
      if (Object.keys(fieldItems).length === 0){
        setCategories([])
        setTimeout(() => {
          setCategories(getCategory(data, fields));
        }, 1);
      }
      else {
        setCategories(getCategory(data, Object.keys(fieldItems)));
        setSubjects(getSubject(data, Object.keys(categoryItems)));
      }
    }, [fieldItems, categoryItems,subjectItems]);

  const addField = (field) => {
    
    if (fieldItems[field]) {
      let toDel = {}
      for (let sub of subjects) {
          for ( let each of Object.values(data.Filter[field]) ){
            if (each.includes(sub)){
              toDel[sub] = true
            }
          }
        }
      let newsub = {}
      for (let s in subjectItems) {
        if ( s in toDel) {}
        else { newsub[s] = true}
      }
      setSubjectItems(newsub)
      let delcat = {}
      for (let category of categories) {
        if (data.Filter[field][category] && categoryItems[category]) {
          delcat[category] = true
        }
      }
      let newcat = {}
      for (let c in categoryItems) {
        if ( c in delcat) {}
        else { newcat[c] = true}
      }
      setCategoryItems(newcat)

      const { [field]: _, ...rest } = fieldItems;
      setFieldItems(rest);
      
      setSubjects(getSubject(data, Object.keys(categoryItems)))
    } else {
      setFieldItems({ ...fieldItems, [field]: true });
    }
  };

  const addCategory = (category) => {
    if (categoryItems[category]) {
      let todel = {}
      for (let sub of subjects) {
        for (let fld of fields){
          if (data.Filter[fld][category] && Object.values(data.Filter[fld][category]).includes(sub) ){
            todel[sub] = true
          }  
        }
      }
      let newsub = {}
      for (let s in subjectItems) {
        if ( s in todel) {}
        else { newsub[s] = true}
      }
      setSubjectItems(newsub)

      const { [category]: _, ...rest } = categoryItems;
      setCategoryItems(rest);
    } else {
      if (areAllValueFalse(fieldItems)) {
        for (let field of fields) {
          if (data.Filter[field][category]) {
            addField(field);
          }
        }
      }
      setCategoryItems({ ...categoryItems, [category]: true });
    }
  };

  const addSubject = (subject) => {
    if (subjectItems[subject]) {
      const { [subject]: _, ...rest } = subjectItems;
      setSubjectItems(rest);
    } else {
      setSubjectItems({ ...subjectItems, [subject]: true });
    }
  };
  function cancel() {

    setResearchTopic(savedResearchTopic);
    setCategoryItems(savedCategoryItems);
    setFieldItems(savedFieldItems);
    setSubjectItems(savedSubjectItems);
    setOnlyShown(savedOnlyShown);
    setShowSub(!showSub);
  }
  // The new state needs to match the original state. The original state is an empty object {}, so we can't simply set it to false. This is because not all properties exist in your current state.
  function clear() {
    setResearchTopic([]);
    // let newFieldItems = {};
    // Object.keys(fieldItems).forEach((key) => {
    //   newFieldItems[key] = false;
    // });
    // setFieldItems(newFieldItems);
    setFieldItems({});

    // let newCategoryItems = {};
    // Object.keys(categoryItems).forEach((key) => {
    //   newCategoryItems[key] = false;
    // });
    // setCategoryItems(newCategoryItems);
    setCategoryItems({});
    // let newSubjectItems = {};
    // Object.keys(subjectItems).forEach((key) => {
    //   newSubjectItems[key] = false;
    // });
    // setSubjectItems(newSubjectItems);
    setSubjectItems({});
    setFields([])
    setTimeout(() => {
      setFields([
        "Life Sciences",
        "Physical Sciences",
         "Social Sciences",
         "Health Sciences",
      ])
    }, 1);
    setTimeout(() => {
      setCategories(getCategory(data, fields));
    }, 10);
   
    setSavedFieldItems({})
    setSavedCategoryItems({})
    setSavedSubjectItems({})
    
    let newOnlyShown = {};
    Object.keys(onlyShown).forEach((key) => {
      newOnlyShown[key] = false;
    });
    setOnlyShown(newOnlyShown);
    // setOnlyShown({});
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };
  function clearAll() {
    handleOpenModal();
  }
  // clear all 
  function handleOptionSelected() {
    clear();
    setTags([]);
    props.change({
      subject_filter: {
        type: selectedOption,
        subject: [],
        topic: [],
        onlyshown: [],
      },
    });
    handleCloseModal();
  }
  const scrollSmoothTo = (position) => {
    if ('scrollBehavior' in document.documentElement.style) {
        window.scrollTo({
            top: position,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo(0, position);
    }
};
  const save = () => {
    if(checkButtonDisabled === true) {
      return
    }
    setSavedResearchTopic(researchTopic);
    // setSavedFieldItems(fieldItems);
    setSavedFieldItems(JSON.parse(JSON.stringify(fieldItems)));
    // setSavedCategoryItems(categoryItems);
    setSavedCategoryItems(JSON.parse(JSON.stringify(categoryItems)));
    // setSavedSubjectItems(subjectItems);
    setSavedSubjectItems(JSON.parse(JSON.stringify(subjectItems)));
    scrollSmoothTo(0)
    setSavedOnlyShown(onlyShown);
    let subjectTags = [];
    Object.keys(subjectItems).forEach((key) => {
      if (subjectItems[key]) {
        subjectTags = [...subjectTags, ...[key]];
      }
    } );

    //   if (filterBy === 'Subject Field'){
    //     Object.keys(subjectItems).forEach(key => {
    //         if (subjectItems[key]) {subjectTags = [...subjectTags, ...[key]]}
    //     });
    //     setTags(subjectTags);
    //   }
    //   else{
    //     setTags(researchTopic);
    //   }
    let scholarTags = [];
    let scholarRange = [];
    Object.keys(onlyShown).forEach((key) => {
      if (onlyShown[key]) {
        let [upper, lower] = key.split(",");
        scholarRange = [
          ...scholarRange,
          ...[{ upper: parseInt(upper), lower: parseInt(lower) }],
        ];
        scholarTags = [
          ...scholarTags,
          ...[
            parseInt(upper) === 200 && parseInt(lower) === 0
              ? "Top 200"
              : "Top " + lower + " ~ " + upper,
          ],
        ];
      }
    });
    setTags([...subjectTags, ...researchTopic, ...scholarTags]);
    //   console.log(scholarRange);
    setShowSub(false);
    props.change({
      subject_filter: {
        type: selectedOption,
        // topic: (areAllValueFalse(subjectItems)) ? subjects : Object.keys(subjectItems),
        subject: subjectTags ? subjectTags : subjects,
        topic: researchTopic,
        onlyshown: scholarRange,
      },
    });


  };

  function onfilterChange(e) {
    setFilterBy(e.target.value);
  }

  const anyAllStop = (event) => {
    event.stopPropagation(); // This prevents the event from bubbling up to the parent
    console.log("Any/All clicked");
  };

  

  return (
    <>
      
      {/* <div className={styles.space_top}></div> */}
      <div className={styles.total} id="Academic_Search_total">
        <div
          onClick={() => setShowSub(!showSub)}
          className={styles.Academic_Search}
        >
          <div onClick={anyAllStop} className={styles.dropdown}>
            <button onClick={handleOpen} className={styles.dropbtn}>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                {selectedOption === "any" ? t('any') : t('all')}
              </span>
              <span className={styles.down_ward_triangle_All_Any}>▼</span>
            </button>
            {dropDown && (
              <div id="myDropdown" class={styles.dropdown_content}>
                <button
                  className={styles.All_Any}
                  onClick={() => handleSelection("any")}
                >
                 {t('any')}
                 <br />
                  <span className={styles.All_Any_content}>
                   {t('anyhint')}{" "}
                  </span>
                </button>
                <div className={styles.All_Any_split} />
                <button
                  className={styles.All_Any}
                  onClick={() => handleSelection("all")}
                >
                  {t('all')}
                  <br />
                  <span className={styles.All_Any_content}>
                    {t('allhint')}{" "}
                  </span>
                </button>
              </div>
            )}
          </div>
          <ul className={styles.Academic_Search_Input}>
            {displayTags.map((tag, index) => (
              <li key={index}>
                 {props.selectedLanguage === 'CN' && translationCN[tag] ? translationCN[tag] : tag}
                {/* <i onClick={() => removeTag(tag)}>
                        <span className={styles.close}>✖</span>
                        </i> */}
              </li>
            ))}

            {filteredTags.length > displayTags.length && (
              <span className={styles.more_button}>
                +{filteredTags.length - displayTags.length}
              </span>
            )}
            <input
              className={styles.inputSentence}
              type="text"
              placeholder={
                filteredTags.length
                  ? ""
                  : t('searchPlaceholder')
              }
              readOnly
            />
          </ul>
          <div
            className={styles.collapsible}
            name="control_openclose_academic"
            id="collapsible_academic"
          >
            <div className={styles.Academic_Search_image}></div>
            <p>{t('academicFilter')}</p>    
          </div>
        </div>
        {showSub && (
          <>
            <div className={styles.content_input}>
              <div className={styles.wrapper}>
                <div className={styles.filter_by}>
                  {/* <h2>Filter By: </h2> */}

                  <div className={styles.radio}>
                    <label
                      htmlFor="Subject Field"
                      style={filterBy === "Subject Field" ? selectedStyle : {}}
                    >
                      {/* <label htmlFor="Subject Field" style={filterBy === "Subject Field" ? selectedStyle : {}}> */}
                     {t('subjectField')}
                      <input
                        id="Subject Field"
                        type="radio"
                        value="Subject Field"
                        checked={filterBy === "Subject Field"}
                        onChange={onfilterChange}
                      />
                    </label>
                  </div>

                  <div className={styles.radio}>
                    <label
                      htmlFor="Research Topic"
                      style={filterBy === "Research Topic" ? selectedStyle : {}}
                    >
                      {t('researchTopic')}
                      <input
                        id="Research Topic"
                        type="radio"
                        value="Research Topic"
                        checked={filterBy === "Research Topic"}
                        onChange={onfilterChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {researchTopic.length > 0 ? <div className={props.selectedLanguage==='CN'? styles.Fieldline2CN : styles.Fieldline2 }></div>: <></>}
              {!areAllValueFalse(subjectItems) ? <div className={props.selectedLanguage==='CN'? styles.FieldlineCN :styles.Fieldline }></div> : <></>}
              {filterBy === "Research Topic" && (
                <ResearchTopic change={handleResearch} state={researchTopic} selectedLanguage={props.selectedLanguage} message={topicMessage}/>
              )}
              {/* <div className={styles.wrapper}>
                        <div className={styles.choose_below}>Or choose from the subject fields below</div>
                    </div> */}

              {filterBy === "Subject Field" && (
                <>
                  {/* <TabIcon className={styles.tabIcon}/>
                        <TabIcon2 className={styles.tabIcon2}/> */}
                  {/* <div className={styles.Fieldline}></div> */}

                  {/* <TabIcon1 className={styles.tabIcon1}/> */}

                  <div className={styles.allContent}>
                    <div className={styles.wrapper}>
                      <div className={styles.title_checkbox}>
                        <h2>{t('Field')}</h2>
                      </div>

                      <div className={styles.Field}>
                        <ul>
                          {fields.map((field) => (
                            <li key={field}>
                              <input
                                name={field}
                                onClick={() => addField(field)}
                                type="checkbox"
                                checked={fieldItems[field]}
                              />
                              {props.selectedLanguage==='CN'? translationCN[field]: field}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    {/* 0. Space Between */}
                    <div className={styles.devide_line}></div>

                    {/* 2. Category */}
                    <div className={styles.wrapper}>
                      <div className={styles.title_checkbox}>
                        {/* <img src="tag.svg" alt="icon"/> */}
                        <h2>{t('Category')}</h2>
                      </div>

                      <div className={styles.Category}>
                        <ul>
                          {categories.slice(0, 8).map((category) => (
                            <li key={category}>
                              <input
                                type="checkbox"
                                id={category}
                                name={category}
                                onClick={() => addCategory(category)}
                                checked={categoryItems[category]}
                                onChange={e => {}}
                              />
                              {/* <label htmlFor={category}>{category}</label> */}
                               <label htmlFor={category}>{props.selectedLanguage==='CN' ? translationCN[category]: category}</label>
                            </li>
                          ))
                          }
                          {showCategory && (
                            <div className={styles.btn_more}>
                              <button
                                onClick={() => setShowCategory(!showCategory)}
                                className={styles.btn_checkbox_more}
                              >
                                {t('showmore')}
                              </button>
                            </div>
                          )}
                          {!showCategory && (
                            <>
                              {/* <TabIcon1 className={styles.tabIcon1}/> */}
                              {categories.slice(8).map((category) => (
                                <li key={category}>
                                  <input
                                    type="checkbox"
                                    id={category}
                                    name={category}
                                    onClick={() => addCategory(category)}
                                    checked={categoryItems[category]}
                                  />
                                  {/* <label htmlFor={category}>{category}</label> */}
                                  <label htmlFor={category}>{props.selectedLanguage==='CN' ? translationCN[category]: category}</label>
                                </li>
                              ))}
                            </>
                          )}
                          {!showCategory && (
                            <div className={styles.btn_more}>
                              <button
                                onClick={() => setShowCategory(!showCategory)}
                                className={styles.btn_checkbox_more}
                              >
                                {t('showless')}
                              </button>
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>

                    {/* 0. Space Between */}
                    {/* <div className={styles.devide_line}></div> */}

                    {/* 3. Subject*/}
                    {!areAllValueFalse(categoryItems) && (
                      <>
                      <div className={styles.devide_line}></div> 
                      <div className={styles.wrapper}>
                        <div className={styles.title_checkbox}>
                          {/* <img src="tag.svg" alt="icon"/> */}
                          <h2>{t('subject')}</h2>
                        </div>
                        <div className={styles.Category}>
                          <ul>
                            {subjects.slice(0, 8).map((subject) => (
                
                              <li key={subject}>
                                <input
                                  type="checkbox"
                                  id={subject}
                                  name={subject}
                                  onClick={() => addSubject(subject)}
                                  checked={subjectItems[subject]}
                                />
                                <label htmlFor={subject}>{props.selectedLanguage==='CN' ? translationCN[subject]: subject}</label>
                              </li>
                            ))}
                            {showSubject && (
                              <div className={styles.btn_more}>
                                <button
                                  onClick={() => setShowSubject(!showSubject)}
                                  className={styles.btn_checkbox_more}
                                >
                                  {t('showmore')}
                                </button>
                              </div>
                            )}
                            {!showSubject && (
                              <>
                                {subjects.slice(8).map((subject) => (
                                  <li key={subject}>
                                    <input
                                      type="checkbox"
                                      id={subject}
                                      name={subject}
                                      onClick={() => addSubject(subject)}
                                      checked={subjectItems[subject]}
                                    />
                                    <label htmlFor={subject}>{props.selectedLanguage==='CN' ? translationCN[subject]: subject}</label>

                                  </li>
                                ))}
                              </>
                            )}
                            {!showSubject && (
                              <div className={styles.btn_more}>
                                <button
                                  onClick={() => setShowSubject(!showSubject)}
                                  className={styles.btn_checkbox_more}
                                >
                                  {t('showless')}
                                </button>
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {/* 5. Advanced Filters*/}
              <div className={styles.wrapper}>
                <div className={props.selectedLanguage === 'CN' ? styles.title_checkbox_advance_CN :styles.title_checkbox_advance}>
                  {/* <img src="tag.svg" alt="icon"/> */}
                  <button onClick={() => setShow(!show)} className="">
                    <h2>{t('advancedfilters')}</h2>
                    {show ? (
                      <div className={props.selectedLanguage === 'CN' ? styles.advance_filter_plus_CN :styles.advance_filter_plus}>+</div>
                    ) : (
                      <div className={props.selectedLanguage === 'CN' ? styles.advance_filter_plus_CN :styles.advance_filter_plus}>-</div>
                    )}
                  </button>
                  <div
                    className={styles.hint_img}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img src={hint} style={{ width: "1.4rem" }} alt="icon" />
                    {showMessage && (
                      <div className={styles.hint_msg}>
                        {t('hint')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* 6. Subject*/}
              {!show && (
                <>
                  <div className={styles.wrapper}>
                    <div className={styles.title_checkbox}>
                      {/* <img src="tag.svg" alt="icon"/> */}
                      <h2>{t('onlyShown')}</h2>
                    </div>
                    <div className={styles.Show}>
                      {props.selectedLanguage=='EN' &&
                      <ul>
                        {topNScholars.map((top) => (
                          <li className={styles.labels} key={top.id}>
                            <input
                              type="checkbox"
                              id={top.id}
                              name={top.name}
                              onClick={() => addOnlyShown(top.value)}
                              checked={onlyShown[top.value]}
                            />
                            <label htmlFor={top.id}>{top.description}</label>
                          </li>
                        ))}
                        {/* <li className={styles.labels}><input type="checkbox" name='Top 5% scholars' onClick={()=>addOnlyShown([0, 0.05])}/>Top 5% scholars in the selected fields/subjects</li>
                                <li className={styles.labels}><input type="checkbox" name='5% - 10% scholars' onClick={()=>addOnlyShown([0.05, 0.1])}/>5% - 10% scholars in the selected fields/subjects</li>
                                <li className={styles.labels}><input type="checkbox" name='10% - 25% scholars' onClick={()=>addOnlyShown([0.1, 0.25])}/>10% - 25% scholars in the selected fields/subjects</li>

                                <li className={styles.labels}><input type="checkbox" name='25% - 50% scholars' onClick={()=>addOnlyShown([0.25, 0.5])}/>25% - 50% scholars in the selected fields/subjects</li>
                                <li className={styles.labels}><input type="checkbox" name='50% - 75% scholars' onClick={()=>addOnlyShown([0.5, 0.75])}/>50% - 75% scholars in the selected fields/subjects</li>
                                <li className={styles.labels}><input type="checkbox" name='Last 25% scholars' onClick={()=>addOnlyShown([0.75, 1])}/>Last 25% scholars in the selected fields/subjects</li> */}
                      </ul>}


                      {props.selectedLanguage=='CN' &&
                      <ul>
                        {topNScholarsCN.map((top) => (
                          <li className={styles.labels} key={top.id}>
                            <input
                              type="checkbox"
                              id={top.id}
                              name={top.name}
                              onClick={() => addOnlyShown(top.value)}
                              checked={onlyShown[top.value]}
                            />
                            <label htmlFor={top.id}>{top.description}</label>
                          </li>
                        ))}
                        {/* <li className={styles.labels}><input type="checkbox" name='Top 5% scholars' onClick={()=>addOnlyShown([0, 0.05])}/>Top 5% scholars in the selected fields/subjects</li>
                                <li className={styles.labels}><input type="checkbox" name='5% - 10% scholars' onClick={()=>addOnlyShown([0.05, 0.1])}/>5% - 10% scholars in the selected fields/subjects</li>
                                <li className={styles.labels}><input type="checkbox" name='10% - 25% scholars' onClick={()=>addOnlyShown([0.1, 0.25])}/>10% - 25% scholars in the selected fields/subjects</li>

                                <li className={styles.labels}><input type="checkbox" name='25% - 50% scholars' onClick={()=>addOnlyShown([0.25, 0.5])}/>25% - 50% scholars in the selected fields/subjects</li>
                                <li className={styles.labels}><input type="checkbox" name='50% - 75% scholars' onClick={()=>addOnlyShown([0.5, 0.75])}/>50% - 75% scholars in the selected fields/subjects</li>
                                <li className={styles.labels}><input type="checkbox" name='Last 25% scholars' onClick={()=>addOnlyShown([0.75, 1])}/>Last 25% scholars in the selected fields/subjects</li> */}
                      </ul>}

                    </div>
                  </div>
                </>
              )}

             {props.selectedLanguage=='EN' && <div className={styles.submit_clear_section}>
                <div
                  className={
                    checkClearAllButtonDisabled
                      ? styles.clear_all_button_disabled
                      : styles.clearAbutton
                  }
                >
                  <button
                    onClick={clearAll}
                    disabled={checkClearAllButtonDisabled}
                  >
                    {t("clearAll")}
                  </button>
                </div>
                <Modal
                  isOpen={modalIsOpen}
                  onClose={handleCloseModal}
                  onOptionSelected={handleOptionSelected}
                  selectedLanguage={props.selectedLanguage}
                />
                
                <div className={styles.clear_all_button}>
                  <button onClick={cancel}>{t('cancel')}</button>
                </div>
                
                <div
                  className={
                    checkButtonDisabled
                      ? styles.save_button_disabled
                      : styles.save_button
                  }
                >
                  <button onClick={save}   onMouseEnter={handleMouseEnter2}
                                          onMouseLeave={handleMouseLeave2} >
                    {t("save")}
                  </button>
                  {showMessage2 && filterBy === "Subject Field" &&(
                      <div className={styles.hint_msg2}>
                        {t('hint2')}
                      </div>
                    )}
                </div>
              </div>}

              { props.selectedLanguage=='CN' &&
                 <div className={styles.submit_clear_section}>
                 <div
                   className={
                     checkClearAllButtonDisabled
                       ? styles.clear_all_button_disabledCN
                       : styles.clearAbuttonCN
                   }
                 >
                   <button
                     onClick={clearAll}
                     disabled={checkClearAllButtonDisabled}
                   >
                     {t("clearAll")}
                   </button>
                 </div>
                 <Modal
                   isOpen={modalIsOpen}
                   onClose={handleCloseModal}
                   onOptionSelected={handleOptionSelected}
                   selectedLanguage={props.selectedLanguage}
                 />
                 
                 <div className={styles.clear_all_button}>
                   <button onClick={cancel}>{t('cancel')}</button>
                 </div>
                 
                 <div
                   className={
                     checkButtonDisabled
                       ? styles.save_button_disabled
                       : styles.save_button
                   }
                 >
                   <button onClick={save} onMouseEnter={handleMouseEnter2}
                                          onMouseLeave={handleMouseLeave2}>
                     {t("save")}
                   </button>
                   {showMessage2 && filterBy === "Subject Field" &&(
                      <div className={styles.hint_msg2}>
                        {t('hint2')}
                      </div>
                    )}
                 </div>
               </div>
              }
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SubjectFilter;