import React, { useRef, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import html2canvas from 'html2canvas';
import data from '../../DKDH_Filter.json'
import { PDFDownloadLink, Document, Page, Text, View, Image, Link, StyleSheet, Font } from '@react-pdf/renderer';
import font from "./font/Sofia Pro Medium Az.otf";
import lightFont from "./font/Sofia Pro Light Az.otf";
import lightItalicFont from "./font/Sofia Pro Light Italic Az.otf";
import boldFont from "./font/Sofia Pro Bold Az.otf";
import yahei from "./font/chinese.msyh.ttf";



const getAfterLastComma = (str) => {
    const lastCommaIndex = str.lastIndexOf(', ');
    if (lastCommaIndex !== -1) {
        return str.substring(lastCommaIndex + 2);
    }
    return str;
};

Font.register({family: "Microsoft Yahei", fonts: [
    {src: yahei},
    {src: yahei, fontWeight: "light"},
    {src: yahei, fontWeight: "light", fontStyle: "italic"},
    {src: yahei, fontWeight: "bold"},
]});

Font.register({family: "Sofia Pro", fonts: [
    {src: font},
    {src: lightFont, fontWeight: "light"},
    {src: lightItalicFont, fontWeight: "light", fontStyle: "italic"},
    {src: boldFont, fontWeight: "bold"},
]});

const styles = StyleSheet.create({
    mainPage: {
        padding: "2% 6% 0% 6%",
        fontFamily: "Sofia Pro",
    },
    headerSection: {
        display: "flex",
        flexDirection: "row",
    },
    headerImageContainer: {
        height:"67px",
        width: "67px",
        borderRadius: "50%",
        margin: "0 1% 0 0",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#0770dd",
    },
    headerImage: {
        height:"65px",
        width: "65px",
        objectFit: "cover",
        borderRadius: "50%",
    },
    headerName: {
        fontSize: "24px",
        marginTop: "1%",
    },
    headerTitle: {
        marginTop:"1%",
        fontSize: "12px",
        color: "#0770dd",
    },
    headerTitleUniversity: {
        display: "inline-block",
        fontWeight: "light",
        fontStyle: "italic",
    },
    headerContactInfo: {
        display: "flex",
        flexDirection: "row",
        marginTop: "1%",
    },
    infoContainer: {
        display: "flex",
        flexDirection: "row",
    },
    infoImage: {
        width: "15px"
    },
    infoText: {
        margin: "2px 8px 2px 3px",
        fontSize: "10px",
    },
    infoLink: {
        margin: "2px 8px 2px 3px",
        fontSize: "10px",
    },
    infoLinkCN: {
        margin: "2px 8px 2px 3px",
        fontSize: "10px",
        fontFamily: "Microsoft Yahei",
    },
    bodyDescription: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
    },
    bodyWorkEduExperience: {
        marginRight: "30px",
        width: "210px",
    },
    bodyTitle1: {
        position: "relative",
        zIndex: "1",
        backgroundColor: "white",
        color: "#0770dd",
        fontSize: "16px",
        fontWeight: "bold",
        width: "150px",
    },
    bodyTitle2: {
        position: "relative",
        zIndex: "1",
        backgroundColor: "white",
        color: "#0770dd",
        fontSize: "16px",
        fontWeight: "bold",
        width: "190px",
    },
    bodyTitle3: {
        position: "relative",
        zIndex: "1",
        backgroundColor: "white",
        color: "#0770dd",
        fontSize: "16px",
        fontWeight: "bold",
        width: "110px",
    },
    bodyTitle4: {
        position: "relative",
        zIndex: "1",
        backgroundColor: "white",
        color: "#0770dd",
        fontSize: "16px",
        fontWeight: "bold",
        width: "160px",
    },
    bodyTitleCN: {
        position: "relative",
        zIndex: "1",
        backgroundColor: "white",
        color: "#0770dd",
        fontSize: "12px",
        fontWeight: "bold",
        fontFamily: "Microsoft Yahei",
        width: "60px",
    },
    bodyLine1: {
        position: "absolute",
        width: "210px",
        top: "5px",
        height: "1px",
        backgroundColor: "#0770dd",
        zIndex: "2",
    },
    bodyLine2: {
        position: "absolute",
        width: "290px",
        top: "5px",
        height: "1px",
        backgroundColor: "#0770dd",
        zIndex: "2",
    },
    bodyLine3: {
        position: "absolute",
        width: "525px",
        top: "5px",
        height: "1px",
        backgroundColor: "#0770dd",
        zIndex: "2",
    },
    bodyTexts: {
        marginTop: "8px",
        marginBottom: "20px",
    },
    bodyText1: {
        fontSize: "8px",
        marginTop: "5px",
    },
    bodyText2: {
        fontSize: "8px",
        marginTop: "5px",
        lineHeight: "1.3px",
        width: "290px",
        fontWeight: "light",
    },
    bodyTable: {
        marginTop: "10px",
        fontSize: "10px",
    },
    bodyTableRow: {
        display: "flex",
        flexDirection: "row",
    },
    bodyTableEntry1: {
        width: "100px",
        height: "28px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        borderBottomWidth: "0",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "10px",
        zIndex: "3",
    },
    bodyTableEntry1CN: {
        width: "100px",
        height: "28px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        borderBottomWidth: "0",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "10px",
        zIndex: "3",
        fontFamily: "Microsoft Yahei",
    },
    bodyTableEntry2: {
        width: "430px",
        height: "28px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "25px",
        zIndex: "3",
    },
    bodyTableEntry3: {
        width: "100px",
        height: "162.4px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        borderBottomWidth: "0",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "10px",
        zIndex: "3",
    },
    bodyTableEntry3CN: {
        width: "100px",
        height: "162.4px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        borderBottomWidth: "0",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "10px",
        zIndex: "3",
        fontFamily: "Microsoft Yahei",
    },
    bodyTableEntry4: {
        width: "105.2px",
        height: "162.4px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bodyTableEntry5: {
        width: "325.8px",
        height: "162.4px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bodyTableEntry6: {
        width: "100px",
        height: "230px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "10px",
    },
    bodyTableEntry6CN: {
        width: "100px",
        height: "230px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "10px",
        fontFamily: "Microsoft Yahei",
    },
    bodyTableEntry7: {
        width: "430px",
        height: "230px",
        borderWidth: "1px",
        borderColor: "#aeb9c9",
        borderStyle: "solid",
        borderLeftWidth: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bodyChart1: {
        width: "103px",
        height: "158px",
        objectFit: "cover",
    },
    bodyChart2: {
        width: "300px",
        height: "163px",
        objectFit: "cover",
        position: "relative",
        zIndex: "2",
    },
    bodyChart3: {
        width: "424px",
        height: "110px",
        objectFit: "cover",
        position: "relative",
        zIndex: "2",
    },
    bodyChart4: {
        width: "424px",
        height: "110px",
        objectFit: "cover",
        position: "relative",
        zIndex: "2",
    },
    bodyLegend1: {
        width: "200px",
        position: "absolute",
        zIndex: "1",
        top: "140px",
        left: "60px",
    },
    bodyLegend2: {
        width: "200px",
        position: "absolute",
        zIndex: "1",
        top: "215px",
        left: "112px",
    },
    bodyHints: {
        marginTop: "8px",
        fontSize: "8px",
        fontWeight: "light",
    },
    bodyHintsCN: {
        marginTop: "8px",
        fontFamily: "Microsoft Yahei",
        fontSize: "6px",
        fontWeight: "light",
    },
    bodyHint: {
        marginTop: "3px"
,   },
    footer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "8px",
    },
    footerImage: {
        height: "25px",
    }
})

const MyDocument = (props) => {
    const translations = {
        EN: {
            moreInfo1:"More Info 1",
            moreInfo2:"More Info 2",
            moreInfo3:"More Info 3",
            work_experience: "WORK EXPERIENCE",
            IntelliPro: 'IntelliPro | Global Scholar Database',
            Profile: 'Profile',
            EDUCATION_EXPERIENCE: 'EDUCATION EXPERIENCE',
            SCHOLAR_BIO: 'SCHOLAR BIO',
            NA: 'N/A',
            at: 'at',
            ACADEMIC_METRICS: 'ACADEMIC METRICS',
            Field: 'Field',
            Category: 'Category',
            Subject: 'Subject',
            Total: 'Total',
            Publications: 'Publications',
            Other: 'Other',
            Citation: 'Citation',
            Paper: 'Paper',
            Top: 'Top',
            Research_Topics: 'Research Topics',
            hint1:'# of Q1 papers = Publications published on Tie 1 journals or conference proceedings (Top 25% within the subject area)',
            hint2:'# of Q2 papers = Publications published on Tie 2 journals or conference proceedings (Top 50% within the subject area)',
            hint3:'# of Q3 & Q4 papers = Publications published on Tie 3 & 4 journals or conference proceedings (50%-100% within the subject area)',

        },

        CN: {
            moreInfo1:"更多信息 1",
            moreInfo2:"更多信息 2",
            moreInfo3:"更多信息 3",
            work_experience: "工作经历",
            IntelliPro: 'IntelliPro | 全球学者数据库',
            Profile: '资料',
            EDUCATION_EXPERIENCE: '教育经历',
            SCHOLAR_BIO: '学者简介',
            NA: '无',
            at: '在',
            ACADEMIC_METRICS: '学术数据',
            Field: '领域',
            Category: '类别',
            Subject: '学科',
            Total: '总计',
            Publications: '出版物',
            Other: '其他',
            Citation: '引用',
            Paper: '论文',
            Top: '热门',
            Research_Topics: '研究课题',
            hint1:"#Q1论文数量 = 发表在顶级期刊或会议论文集上的出版物（在该学科领域内属于前25%）",
            hint2:"#Q2论文数量 = 发表在二级期刊或会议论文集上的出版物（在该学科领域内属于前50%）",
            hint3:"#Q3 & Q4论文数量 = 发表在三级和四级期刊或会议论文集上的出版物（在该学科领域内属于50%-100%）"
        },
        // You can add more languages here
    };

    const t = (key) => {
        return translations[props.selectedLanguage][key] || key;
    };

    // const processStr = (bio) => {
    //     let combinedStr = bio.join('\n');

    //     let words = combinedStr.split(/ /).filter(word => word);

    //     if (words.length > 100) {
    //         return words.slice(0, 100).join(' ') + "...";
    //     }
    //     return combinedStr;
    // };
    const processStr = (bio,lang) => {
        let combinedStr = bio.join('\n');
        let words=[]
        if(lang =='en'){
          words = combinedStr.split(/ /).filter(word => word);
    
        }
            
        if (words.length > 90 && lang=='en') {
          return words.slice(0, 90).join(' ') + "...";
        }
    
        if (combinedStr.length> 300 && lang=='cn') {
          return combinedStr.slice(0, 300)+ "...";
        }
    
        return combinedStr;
      };

    // let bioArray = [];
    // let displayedBio = "";
    // if (props.state && props.state.bio && props.state.bio.trim() !== ""){
    //     bioArray = props.state.bio.split('<br>');
    //     bioArray = bioArray.filter(item => item.trim() !== '');
    //     displayedBio = processStr(bioArray);
    // }
    let bioArray = [];
    let displayedBio = "";
    let displayedBioCn = "";
    if (props.state && props.state.bio && props.state.bio.trim() !== ""){
      bioArray = props.state.bio.split('<br>');
      bioArray = bioArray.filter(item => item.trim() !== '');
      displayedBio = processStr(bioArray,'en');
    }
  
    if (props.state && props.state.bio_zh && props.state.bio_zh.trim() !== ""){
      bioArray = props.state.bio_zh.split('<br>');
      bioArray = bioArray.filter(item => item.trim() !== '');
      displayedBioCn = processStr(bioArray,'cn');
    }

    const splitToLines = (str) => {
        const words = str.split(/\t|\n| |<br>/);

        let totalLines = [];
        let currentLine = [];
        let lencnt = 0;
        words.forEach(word => {
            if (currentLine.length < 5 && lencnt + word.length < 38) {
                currentLine.push(word);
                lencnt += word.length;
            } else {
                totalLines.push(currentLine.join(' '));
                currentLine = [word];
                lencnt = 0;
            }
            // console.log(lencnt)
        });
        if (currentLine.length > 0 ){
            totalLines.push(currentLine.join(' '));
        }
        //console.log(totalLines);
        return totalLines;

    }

    const experienceFormat = (arr, maxLineNum) =>{
        let lines = [];
        let currentLine = [];
        arr.forEach(exp => {
                currentLine = splitToLines(exp);
                if (currentLine.length) lines=[...lines, ...currentLine];
            }
        );
        // console.log(lines);
        // Limit to 3 lines
        if (lines.length > maxLineNum) {
            lines = lines.slice(0, maxLineNum);
            const lastLine = lines[lines.length - 1];
            lines[lines.length - 1] = lastLine + '...';
        }
        return lines;
    }

    // let eduArray = [];
    // let eduFormatted = [];
    // if (props.state && props.state.education_experience && props.state.education_experience.trim() !== ""){
    //     eduArray = props.state.education_experience.split(/<br>|\n/);
    //     eduArray = eduArray.filter(item => item.trim() !== '');
    //     eduFormatted = experienceFormat(eduArray.slice(0, 2), 3);
    //     // console.log(eduFormatted)
    //     // console.log(eduArray);
    // }

    // let workArray = [];
    // let workFormatted = [];
    // if (props.state && props.state.work_experience  && props.state.work_experience.length){
    //     workArray = props.state.work_experience;
    //     // console.log(props.state.work_experience)
    //     workArray = workArray.filter(item => item.trim() !== '');
    //     workFormatted = experienceFormat(workArray.slice(0, 2), 4);
    //     // console.log(workFormatted);
    // }
  let eduArray = [];
  let eduFormatted = [];
  let eduFormattedCn =[];
  if (props.state && props.state.education_experience && props.state.education_experience.trim() !== ""){
    eduArray = props.state.education_experience.split(/<br>|\n/);
    eduArray = eduArray.filter(item => item.trim() !== '');
    eduFormatted = experienceFormat(eduArray.slice(0, 2), 3);
    // console.log(eduFormatted)
    // console.log(eduArray);
  }
  if (
    props.state &&
    props.state.education_experience_zh &&
    props.state.education_experience_zh.trim() !== ""
  ) {
    eduArray = props.state.education_experience_zh.split(/<br>|\n/);
    eduArray = eduArray.filter((item) => item.trim() !== "");
    eduFormattedCn = experienceFormat(eduArray.slice(0, 2), 3);
  }
  
  

  let workArray = [];
  let workFormatted = [];
  let workFormattedCn = [];
  if (props.state && props.state.work_experience  && props.state.work_experience.length){
    workArray = props.state.work_experience;
    // console.log(props.state.work_experience)
    workArray = workArray.filter(item => item.trim() !== '');
    workFormatted = experienceFormat(workArray.slice(0, 2), 4);
    // console.log(workFormatted);
  }

  if (props.state && props.state.work_experience_zh  && props.state.work_experience_zh.length){
    workArray = props.state.work_experience_zh;
    workArray = workArray.filter(item => item.trim() !== '');
    workFormattedCn = experienceFormat(workArray.slice(0, 2), 4);
    // console.log(workFormatted);
  }

    let subjects = []
    if(props.state && props.state.subject){
        subjects = Object.entries(props.state.subject.paper).map(([subjectName, quartiles]) => {
            const totalPapers = Object.values(quartiles).reduce((sum, value) => sum + value, 0);
            return {
                name: subjectName,
                value: totalPapers,
            };
        });
    }

    // const sumOfTotalPapers = subjects.reduce((sum, subject) => sum + subject.value, 0);
    const topSubjects = subjects.sort((a, b) => b.value - a.value);

    function findCategoryAndField(subjectName) {
        for (let category in data.Filter) {
            for (let field in data.Filter[category]) {
                if (data.Filter[category][field].includes(subjectName)) {
                    return {
                        category: category,
                        field: field
                    }
                }
            }
        }
        return {
            category: "N/A",
            field: "N/A"
        }  // returns null if the subject name isn't found
    }
    const backward = findCategoryAndField(topSubjects[0].name);

    return (
        <Document>
            <Page size="A4" style={styles.mainPage}>
                <View style={styles.headerSection} id={"headerSection"}>
                    <View style={styles.headerImageContainer}>
                        <Image src={props.state.photolink} style={styles.headerImage}/>
                    </View>

                    <View>
                        <Text style={styles.headerName}>{
                            props.state.name.split("(")[0].trim()}
                        </Text>

                        <Text style={styles.headerTitle}>
                            {props.state.title}
                            {props.state.title !== undefined ? (
                                <Text style={styles.headerTitleUniversity}> at {getAfterLastComma(props.state.employer)}</Text>
                            ) : (
                                <Text style={styles.headerTitleUniversity}>{getAfterLastComma(props.state.employer)}</Text>
                            )}
                        </Text>

                        <View style={styles.headerContactInfo}>
                            <View style={styles.infoContainer}>
                                <Image src={"/image-73@2x.png"} style={styles.infoImage}/>
                                {
                                    props.state.phone && props.state.phone.length > 0 ?
                                        <Text style={styles.infoText}>{
                                            props.state.phone[0]} &nbsp; {props.state.phone[1]}
                                        </Text> : <Text style={styles.infoText}>N/A</Text>
                                }
                            </View>

                            <View style={styles.infoContainer}>
                                <Image src={"/image-74@2x.png"} style={styles.infoImage}/>
                                <Text style={styles.infoText}>
                                    {props.state.location && props.state.location.length > 0 ? props.state.location : "N/A"}
                                </Text>
                            </View>

                            <View style={styles.infoContainer}>
                                <Image src={"/image-75@2x.png"} style={styles.infoImage}/>
                                {props.state.links && props.state.links.length > 0 ? (
                                    props.state.links.map((link, index) => (
                                        <Link src={link} style={props.selectedLanguage === "CN" ? styles.infoLinkCN : styles.infoLink}>
                                            {t(`moreInfo${index + 1}`)}
                                        </Link>
                                    ))
                                ) : (
                                    <Text style={styles.infoLink}>N/A</Text>
                                )}
                            </View>
                        </View>

                        <View style={styles.infoContainer}>
                            <Image src={"/image-76@2x.png"} style={styles.infoImage}/>
                            {
                                props.state.emails && props.state.emails.length > 0 ?
                                    <Text style={styles.infoText}>{
                                        props.state.emails[0]} &nbsp; {props.state.emails[1]}
                                    </Text> : <Text style={styles.infoText}>N/A</Text>
                            }
                        </View>
                    </View>
                </View>

                <View style={styles.bodyDescription}>
                    <View style={styles.bodyWorkEduExperience}>
                        <View>
                            <Text style={props.selectedLanguage === "CN" ? styles.bodyTitleCN : styles.bodyTitle1}>{t("work_experience")}</Text>
                            <View style={styles.bodyLine1}></View>

                            <View style={styles.bodyTexts}>
                                {/* {workFormatted.length > 0 ? (
                                    workFormatted.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) : (
                                    <Text style={styles.bodyText1}>{t('NA')}</Text>)
                                } */}
                                   {workFormatted.length > 0 && workFormattedCn.length>0 && props.selectedLanguage == 'EN'&& (
                                        workFormatted.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) 
                                        }
                                        {workFormatted.length > 0 && workFormattedCn.length>0 && props.selectedLanguage == 'CN' &&(
                                        workFormattedCn.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) 
                                        }

                                        {workFormatted.length > 0 && workFormattedCn.length==0 &&(
                                        workFormatted.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) 
                                        }

                                    {workFormatted.length == 0 && workFormattedCn.length>0 &&(
                                        workFormattedCn.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) 
                                        }

                                            {workFormatted.length == 0 && workFormattedCn.length ==0 && ( <Text style={styles.bodyText1}>{t('NA')}</Text>)}            
                            </View>
                        </View>

                        <View>
                            <Text style={props.selectedLanguage === "CN" ? styles.bodyTitleCN : styles.bodyTitle2}>{t("EDUCATION_EXPERIENCE")}</Text>
                            <View style={styles.bodyLine1}></View>

                            <View style={styles.bodyTexts}>
                                {/* {eduFormatted.length > 0 ? (
                                    eduFormatted.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) : (
                                    <Text style={styles.bodyText1}>{t('NA')}</Text>)
                                } */}
                                 {eduFormatted.length > 0 && eduFormattedCn.length>0 && props.selectedLanguage == 'EN'&& (
                                    eduFormatted.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) 
                                }
                                {eduFormatted.length > 0 && eduFormattedCn.length>0 && props.selectedLanguage == 'CN' &&(
                                eduFormattedCn.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) 
                                }

                                {eduFormatted.length > 0 && eduFormattedCn.length==0 &&(
                                eduFormatted.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) 
                                }

                               {eduFormatted.length == 0 && eduFormattedCn.length>0 &&(
                                eduFormattedCn.map(item => (<Text style={styles.bodyText1}>{item}</Text>))) 
                                }

                                {eduFormatted.length == 0 && eduFormattedCn.length ==0 && ( <Text style={styles.bodyText1}>{t('NA')}</Text>)}
                            </View>
                        </View>
                    </View>

                    <View>
                        <Text style={props.selectedLanguage === "CN" ? styles.bodyTitleCN : styles.bodyTitle3}>{t("SCHOLAR_BIO")}</Text>
                        <View style={styles.bodyLine2}></View>

                        <View style={styles.bodyTexts}>
                            {displayedBio.length > 0 && displayedBioCn.length !==0 && props.selectedLanguage =='EN' &&(
                                <Text style={styles.bodyText2}>{displayedBio}</Text>) 
                            }
                             {displayedBioCn.length > 0 && displayedBio.length !==0 && props.selectedLanguage == 'CN' &&(
                                <Text style={styles.bodyText2}>{displayedBioCn}</Text>) 
                             }
                            {displayedBio.length > 0 && displayedBioCn.length ==0 &&(
                               <Text style={styles.bodyText2}>{displayedBio}</Text> ) 
                            }
                            {displayedBioCn.length > 0 && displayedBio.length ==0 &&(
                                 <Text style={styles.bodyText2}>{displayedBioCn}</Text>) 
                            }
                            {displayedBio.length == 0 && displayedBioCn.length == 0 && (<Text style={styles.bodyText2}>{t('NA')}</Text>)}


                            {/* {props.state.bio} */}
                        </View>
                    </View>
                </View>

                <View>
                    <Text style={props.selectedLanguage === "CN" ? styles.bodyTitleCN : styles.bodyTitle4}>{t("ACADEMIC_METRICS")}</Text>
                    <View style={styles.bodyLine3}></View>

                    <View style={styles.bodyTable}>
                        <View style={styles.bodyTableRow}>
                            <View style={props.selectedLanguage === "CN" ? styles.bodyTableEntry1CN : styles.bodyTableEntry1}>
                                <Text>{t("Field")}</Text>
                            </View>

                            <View style={styles.bodyTableEntry2}>
                                <Text>{backward.field}</Text>
                            </View>
                        </View>

                        <View style={styles.bodyTableRow}>
                            <View style={props.selectedLanguage === "CN" ? styles.bodyTableEntry1CN : styles.bodyTableEntry1}>
                                <Text>{t("Category")}</Text>
                            </View>

                            <View style={styles.bodyTableEntry2}>
                                <Text>{backward.category}</Text>
                            </View>
                        </View>

                        <View style={styles.bodyTableRow}>
                            <View style={props.selectedLanguage === "CN" ? styles.bodyTableEntry3CN : styles.bodyTableEntry3}>
                                <Text>{t("Subject")}</Text>
                            </View>

                            <View style={styles.bodyTableEntry4}>
                                {props.links.chart1 ?
                                    <Image style={styles.bodyChart1} src={props.links.chart1}/> :
                                    <Text>{t('NA')}</Text>
                                }
                            </View>

                            <View style={styles.bodyTableEntry5}>
                                <Image style={styles.bodyChart2} src={props.links.chart2}/>
                                <Image style={styles.bodyLegend1} src={props.links.legend1}/>
                            </View>
                        </View>

                        <View style={styles.bodyTableRow}>
                            <View style={props.selectedLanguage === "CN" ? styles.bodyTableEntry6CN : styles.bodyTableEntry6}>
                                <Text>{t("Top")}</Text>
                                <Text>{t("Research_Topics")}</Text>
                            </View>

                            <View style={styles.bodyTableEntry7}>
                                <Image style={styles.bodyChart3} src={props.links.chart3}/>
                                <Image style={styles.bodyChart4} src={props.links.chart4}/>
                                <Image style={styles.bodyLegend2} src={props.links.legend2}/>
                            </View>
                        </View>
                    </View>

                    <View style={props.selectedLanguage === "CN" ? styles.bodyHintsCN : styles.bodyHints}>
                        <Text style={styles.bodyHint}>{t('hint1')}</Text>
                        <Text style={styles.bodyHint}>{t('hint2')}</Text>
                        <Text style={styles.bodyHint}>{t('hint3')}</Text>
                    </View>
                </View>

                <View style={styles.footer}>
                    <Image style={styles.footerImage} src={"/20230626151302-2@2x.png"}/>
                </View>
            </Page>
        </Document>
    );
};

const DownloadPdf = (props) => {
    return (
        <div>
            <PDFDownloadLink document={<MyDocument state={props.state} selectedLanguage={props.selectedLanguage} links={props.links}/>} fileName={`${props.state.name.split("(")[0].trim()}.pdf`}>
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download PDF'
        }
      </PDFDownloadLink>
    </div>
  );
};

export default DownloadPdf;
