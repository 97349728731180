import React from "react";
import styles from "../utils/footerstyle.module.css"
import { Link } from "react-router-dom";

function Footer(props) {
    const handle_help=()=>{
        localStorage.setItem('selectedLanguage', props.selectedLanguage)
        window.open('/helperPage')
      }
    return(
        <div className={styles.footer}>
            <div className={styles.footerLeft}>
                {props.selectedLanguage =='EN' &&  <img src="/new_logo.svg" className={styles.footerLogo}/>}
                {props.selectedLanguage =='CN' &&  <img src="/new_logo_cn.svg" className={styles.footerLogo}/>}

            {props.selectedLanguage == 'EN' && <p className={styles.rights}>© 2024 IntelliPro. All Rights Reserved.</p>}
            {props.selectedLanguage == 'CN' && <p className={styles.rights}>© 2024 英特力普 保留所有权利</p>}

            </div>
            <div  className={styles.footerRight} onClick={handle_help}>
                {props.selectedLanguage=='EN'&&'Help & User Guide'}
                {props.selectedLanguage=='CN'&&'帮助和用户指南'}

                </div>
        </div>
    )

}

export default Footer;

