import React, { useState } from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import { ReactComponent as BackImg } from "./assets/back.svg";
import styles from "./opt.module.css";
import {  useNavigate , useParams } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';

const Optout = (props) => {
  const defaultLanguage = localStorage.getItem('selectedLanguage')

      const SelectedOptionWithSVG = ({ label }) => (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-20px' }}>
          <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px'}} />
          {label==='English' ? (
            <span style={{ paddingTop: '2px' }}>EN</span>
          ) : (
            <span style={{ paddingTop: '2px' }}>CN</span>
          )}
        </div>
      );
    
      const options2 = [
        { value: 'EN', label: 'English' },
        { value: 'CN', label: '中文(简体)' },
      ];
    
      const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage); // Set default language
    
      const handleSelectChange2 = (selectedOption) => {
        setSelectedLanguage(selectedOption.value); // Update
        props.setSelectedLanguage(selectedOption.value); 
        if (selectedOption.value === 'EN') {
          // Language is set to English
        } else if (selectedOption.value === 'CN') {
          // Language is set to Chinese
        }
      }
    
      const customSelect2 = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: 'Sofia Pro',
            fontSize: 16,
            color: '#377DED',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            border: 'none', 
            boxShadow: 'none', 
            minWidth: '120px',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display:'none',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          transition: 'color 0.2s',
          color: '#377DED',
          fontSize: state.isFocused ? '18px' : '16px',
          position: 'relative',
          "&:hover": {
            color: "#2149B1",
            fontSize: 18,
            //borderBottom: "2px solid #2149B1",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          fontFamily: 'Sofia Pro',
          fontSize: 16,
          color: state.isSelected ? '#FFFFFF' : '#377DED',
          cursor: 'pointer',
          textAlign: 'left',
          "&:active": {
            color: '#FFFFFF',
            background: '#377DED',
          },
          "&:focus": {
            outline: 'none',
          },
          display: 'flex',
          marginTop:'8px',
          marginBottom:'8px'
        }),
        menu: (provided) => ({
          ...provided,
          border: 'none',
          borderRadius: '10px',
          boxShadow: 'none',
          marginTop: 0,
          position: 'absolute',
          width:'130px',
          marginLeft:'0px'
        }),
      };
    
// <<<<<<< HEAD
//         const PlaceholderImageEN = () => (
//             <div style={{display: 'flex', alignItems: 'center' }}>
//             <span style={{ marginRight: '5px' }}>English</span>
//             <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '5px' }} />
//             </div>
//         );

//         const PlaceholderImageCN = () => (
//             <div style={{display: 'flex', alignItems: 'center' }}>
//             <span style={{ marginRight: '10px' }}>Chinese (中文)</span>
//             <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '0px' }} />
//             </div>
//         );
//         const handleSignUpClick = () => {
//             navigate('/signup');
//         };
// =======
      const PlaceholderImageEN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>EN</span>
        </div>
      );
    
      const PlaceholderImageCN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
            <span>CN</span>
        </div>
      );


      const handlesurvey=() => {
        window.open('https://intelliprogroup.larksuite.com/share/base/form/shrusXdsTEULNEHdYxymcsozUld', '_blank');

    }

    const handlesurveyCN=() => {
        window.open('https://intelliprogroup.larksuite.com/share/base/form/shrusAWetAT93Nn9u0n0M7uQVjb', '_blank');

    }

    return (
        <div className={props.selectedLanguage==="CN"?styles.newPWFormCN:styles.newPWForm}>
            {/* <div className={styles.LeftContainer}>
                    <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
            </div> */}

            <div className={styles.logo}>
              {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
              {selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
            </div>
            
            <div className={styles.langDrop}>
                    <Select
                        options={options2}
                        onChange={handleSelectChange2}
                        isSearchable={false} // This will disable user input
                        placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                        components={{
                            SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                        }}
                        styles={customSelect2}
                    />
            </div>

            <div className={styles.Form}>
            {selectedLanguage =='EN' &&  <div className={styles.optContainer}>
            <div className={styles.opttitle}>
                    <p className={styles.subtitle}>Opt-out</p>  
                    <p className={styles.line}></p>
                </div>

                <div className={styles.firstP}>
                    <p className={styles.normalopt}>In California and various other states, legislation affords individuals specific rights concerning their personal information. These rights encompass the ability to access, rectify, erase, and/or opt out of the "sale" or "sharing" of their personal information. "Sale" pertains to the divulgence of personal information to third parties in exchange for monetary compensation or other valuable considerations. "Sharing" pertains to the dissemination of personal information to third parties for the purposes of targeted advertising.</p>
                    <p className={styles.normalopt}>For our members, the option to opt out of sharing can be accessed by clicking the designated link. In addition to these mechanisms, in the United States, we will automatically honor your opt-out preference with respect to sharing if we receive a Global Privacy Control signal that reflects your desire to opt out.</p>
                    <p className={styles.normalopt}>Under the California Consumer Privacy Act (CCPA), we are obligated to disclose instances of personal information sharing. We may share your personal information, including data related to your interactions with our website, with our partners for the purpose of promoting our services on alternate platforms. For our members, we may also share your personal information with third-party platforms to personalize the advertisements presented to you when utilizing the services of said third-party platforms.</p>
                    <button className={styles.editButton} onClick={handlesurvey}>Procced to Opt-out</button>

                </div>
                
            </div>
            }

            {selectedLanguage=='CN' &&  <div className={styles.optContainer}>
            <div className={styles.opttitle}>
            <p className={styles.subtitle}>选择退出权</p>  

                    <p className={styles.line}></p>
                </div>

                <div className={styles.firstP}>
                <p className={styles.normalopt}>在加利福尼亚和其他一些州，法规赋予个人关于其个人信息的特定权利。这些权利包括访问、更正、删除以及选择退出"销售"或"分享"他们的个人信息。 "销售" 涉及将个人信息披露给第三方以换取货币补偿或其他有价值的考虑。 "分享" 涉及将个人信息传播给第三方，以进行定向广告。</p>
                    <p className={styles.normalopt}>对于我们的会员，选择退出分享的选项可通过点击指定的链接来实现。此外，在美国，如果我们收到反映您选择退出意愿的全球隐私控制信号，我们将自动尊重您关于分享的选择。</p>
                    <p className={styles.normalopt}>根据加利福尼亚消费者隐私法（CCPA），我们有义务披露个人信息分享的情况。我们可能会与我们的合作伙伴分享您的个人信息，包括与我们网站的互动相关的数据，以促进我们的服务在其他平台上的推广。对于我们的会员，当您使用第三方平台的服务时，我们还可能与第三方平台分享您的个人信息，以个性化呈现给您的广告。</p>
                    <button className={styles.editButton} onClick={handlesurveyCN}>选择退出</button>

                </div>
            
            </div>}

               </div>
        </div>
    );
}

export default Optout;