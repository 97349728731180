import {React, useRef, useEffect} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import fontData from './Sofia Pro Medium Az-normal';
import ResumePage from '../ResumePage/ResumePage';
import styles from './Result.module.css';

const doc = new jsPDF();

function GeneratePdf (){
  const componentRef = useRef(null);
  const thumbnailRef = useRef(null);
  const figLeafRef = useRef(null);

  useEffect(() => {
    if (componentRef.current && thumbnailRef.current) {
      html2canvas(componentRef.current).then((canvas) => {
        const ctx = canvas.getContext('2d');

        // Create a new canvas to be used as the thumbnail.
        const thumbnailCanvas = document.createElement('canvas');
        thumbnailCanvas.width = canvas.width / 10;  // Change these values to adjust the size of the thumbnail.
        thumbnailCanvas.height = canvas.height / 10;

        // Draw the original canvas to the thumbnail canvas, scaling it down.
        thumbnailCanvas.getContext('2d').drawImage(canvas, 0, 0, thumbnailCanvas.width, thumbnailCanvas.height);

        // Add the thumbnail canvas to the DOM.
        thumbnailRef.current.innerHTML = '';
        thumbnailRef.current.appendChild(thumbnailCanvas);
		componentRef.current.style.display = 'none';
		figLeafRef.current.style.display = 'none';
      });
    }
  }, []);

    const generatePdfRef = useRef(null);

	const handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: [1400, 2200],
			unit: 'px',
		});

		// // Adding the fonts.
		// doc.setFont('Inter-Regular', 'normal');
		var font = fontData; // Base64-encoded string representing the font.

		doc.addFileToVFS('Sofia Pro Medium Az-normal.ttf', font);
		doc.addFont('Sofia Pro Medium Az-normal.ttf', 'Sofia Pro Medium Az', 'normal');

		doc.setFont('Sofia Pro Medium Az'); // Sets the custom font.
	
		doc.html(generatePdfRef.current, {
			async callback(doc) {
				await doc.save('document');
			},
		});
	};

	return (
		<div style = {{width: '100%', height: '100%' }}>
			{/* <button onClick={handleGeneratePdf} ref={thumbnailRef}></button> */}
			<div className={styles.figLeaf} ref={figLeafRef}> 
				<div class={styles.loading}>
    				Loading...
  			</div></div>
			<div ref={componentRef} className ={styles.originResume}>
				<ResumePage/>
			</div>
			<button className = {styles.thumbnail} ref={thumbnailRef}></button>
			
			
			{/* <div ref={generatePdfRef}>
                <ResumePage/>
			</div> */}
		</div>
	);
};

export default GeneratePdf;