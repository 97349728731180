import React, { useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import styles from "./wechat_form.module.css";
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';


function WeChat_form(props){
    const navigate = useNavigate();
    const [nameError, setNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [invalidName, setInvalidName] = useState(false);
    const [invalidComp, setInvalidComp] = useState(false);
    const [invalidLengthComp, setInvalidLengthComp] = useState(false);
    const [invalidLengthJob, setInvalidLengthJob] = useState(false);
    const [invalidJob, setInvalidJob] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidLengthPW, setInvalidLengthPW] = useState(false);
    const [invalidCPassword, setInvalidCPassword] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [TOUError, setTOUError] = useState(false);


    const [csrfToken, setCsrfToken] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        title: '',
        company: '',
        agree_to_terms: false,
        port: '3001'
    });
    const [message, setMessage] = useState('');

    function encodeFormData(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");

    setNameError(!formData.name.trim());
    setCompanyError(!formData.company.trim());
    setTitleError(!formData.title.trim());
    setEmailError(!formData.email.trim());
    setTOUError(!formData.agree_to_terms);

    setInvalidName(/[^a-zA-Z\s]/.test(formData.name));
    setInvalidComp(/[^a-zA-Z\s0-9\-.,]/.test(formData.company));
    setInvalidLengthComp(formData.company.length > 40);
    setInvalidLengthJob(formData.title.length > 25);
    setInvalidJob(/[^a-zA-Z\s0-9\-.,]/.test(formData.title));
    setInvalidEmail((formData.email.trim()) && (!formData.email.includes("@") || formData.email.length <= 3 || 
    formData.email.split("@")[0].length === 0 || formData.email.split("@")[1].length === 0));
    
  
    if (!formData.name.trim()) {
      setNameError(true);
      setMessage("Fill in the blank");
      return;
    } else if (!formData.company.trim()) {
      setCompanyError(true);
      setMessage("Fill in the blank");
      return;
    } else if (!formData.title.trim()) {
      setTitleError(true);
      setMessage("Fill in the blank");
      return;
    } else if (!formData.email.trim()) {
      setEmailError(true);
      setMessage("Fill in the blank");
      return;
    }else if (!formData.agree_to_terms) {
      // console.log("Please agree to the terms of use.");
      setTOUError(true);
      setMessage("You must agree with terms");  // Display feedback to the user
      return;
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      setInvalidName(true);
      setMessage("Invaild name");
      return;
    } else if (formData.company.length > 40) {
      setInvalidLengthComp(true);
      setMessage("The company's name must not exceed 40 characters");
      return;
    // The judging conditions of valid company name may be modified later
    } else if (/[^a-zA-Z\s0-9\-.,]/.test(formData.company)) {
      setInvalidComp(true);
      setMessage("Invalid company name");
      return;
    } else if (formData.title.length > 25) {
      setInvalidLengthJob(true);
      setMessage("The job title's name must not exceed 25 characters");
      return;
    // The judging conditions of valid job title may be modified later
    } else if (/[^a-zA-Z\s0-9\-.,]/.test(formData.title)) {
      setInvalidJob(true);
      setMessage("Invalid job title");
      return;
    // The judging conditions of valid email may be modified later
    } else if (!formData.email.includes("@") || formData.email.length <= 3 || 
        formData.email.split("@")[0].length === 0 || formData.email.split("@")[1].length === 0) {
      setInvalidEmail(true);
      setMessage("Invalid email address");
      return;
    } ;

    console.log(JSON.stringify(formData))
    // Send the registration data to your backend
    fetch("https://intelliprozenithscholars.com/auth/register", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("Request failed with status: " + response.status);
      }
      return response.json();
    })
    .then(csrfdata => {
      console.log(csrfdata)
      setCsrfToken(csrfdata.csrf_token);
      const formBody = encodeFormData(formData);
      console.log("Data we POST:", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfdata.csrf_token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: formBody
      })
      fetch("https://intelliprozenithscholars.com/auth/register/", {
        method: "POST",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: formBody
      })
      .then(response => {
        if (!response.ok) {
          throw new Error("Registration request failed with status: " + response.status);
        }
        return response.json();
      })
      .then(data => {
        console.log("data: ", data);
        if (data.success) {
          console.log(data.message);
          setMessage(data.message);  // Display success message to the user
          navigate('/linkSent', { state: { formData: formData } });
          // Redirect to another page or show a success message, etc.
        } else {
          // Handle error messages sent by the server
          if (data.message.email) {
            setMessage(data.message.email[0]);
            setEmailError(true)
          } else {
            setMessage(data.message);
          }
        }
      })
      .catch(error => {
        console.error("Error during registration:", error);
        setMessage("Error during registration.");  // Display feedback to the user
      });
    })
    .catch(error => {
      console.error("Error fetching CSRF token:", error);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    console.log('agree_to_terms', checked)
    setFormData(prevData => ({
      ...prevData,
      agree_to_terms: checked,
    }));
  };

  const translations = {
    EN: {
     Sign_Up: "Sign Up",
     Full_Name: "FULL NAME*",
      Company: "COMPANY*",
      Job_Title: "JOB TITLE*",
      Email_Address: "WORK EMAIL*",
      Agree_to_terms: "I agree to the terms of use",
      Already_have_an_account: "Already have an account?",
      Continue: "Continue",
      agree_to_terms: "*I have read and agree to the ",
      terms: "Terms and Conditions",

    },
    CN: {
      Sign_Up: "注册",
      Full_Name: "姓名 *",
      Company: "公司 *",
      Job_Title: "职位 *",
      Email_Address: "邮箱地址 *",
      Agree_to_terms: "我已阅读并同意",
      Already_have_an_account: "已有账号？",
      Continue: "创建账号",
      agree_to_terms: "我已阅读并同意",
      terms: "使用条款.",

    },
    // You can add more languages here
  };
  
const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };

  const SelectedOptionWithSVG = ({ label }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px'}} />
      {label==='English' ? (
        <span style={{ paddingTop: '2px' }}>EN</span>
      ) : (
        <span style={{ paddingTop: '2px' }}>CN</span>
      )}
    </div>
  );

  const options2 = [
    { value: 'EN', label: 'English' },
    { value: 'CN', label: '中文(简体)' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

  const handleSelectChange2 = (selectedOption) => {
    setSelectedLanguage(selectedOption.value); // Update
    props.setSelectedLanguage(selectedOption.value); 
    if (selectedOption.value === 'EN') {
      // Language is set to English
    } else if (selectedOption.value === 'CN') {
      // Language is set to Chinese
    }
  }

  const customSelect2 = {
    control: (provided, state) => ({
        ...provided,
        fontFamily: 'Sofia Pro',
        fontSize: 16,
        color: '#377DED',
        border: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none', 
        boxShadow: 'none', 
        minWidth: '130px',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display:'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      transition: 'color 0.2s',
      color: '#377DED',
      fontSize: state.isFocused ? '18px' : '16px',
      position: 'relative',
      "&:hover": {
        color: "#2149B1",
        fontSize: 18,
        //borderBottom: "2px solid #2149B1",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Sofia Pro',
      fontSize: 16,
      color: state.isSelected ? '#FFFFFF' : '#377DED',
      cursor: 'pointer',
      textAlign: 'left',
      "&:active": {
        color: '#FFFFFF',
        background: '#377DED',
      },
      "&:focus": {
        outline: 'none',
      },
      display: 'flex',
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: 0,
      position: 'absolute',
    }),
  };

  const PlaceholderImageEN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
      <span>EN</span>
    </div>
  );

  const PlaceholderImageCN = () => (
    <div style={{display: 'flex', alignItems: 'center' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '10px', marginLeft: '10px' }} />
      <span>CN</span>
    </div>
  );
  const handleSignUpClick = () => {
    navigate('/Login');
  };
  const handleTermsClick = () => {
    localStorage.setItem('selectedLanguage', props.selectedLanguage)
    window.open('/TermOfUse')
  };
  const handleSentLinkClick = () => {
    navigate('/linkSent');
  };

  const isFormValid = () => {
    return (
        !nameError &&
        !invalidName &&
        !emailError &&
        !invalidEmail &&
        !titleError &&
        !invalidLengthJob &&
        !invalidJob &&
        !companyError &&
        !invalidLengthComp &&
        !invalidComp &&
        formData.agree_to_terms  // Ensure the checkbox is checked
    );
};


    
return(
    <div className={styles.container}>
        <div className={styles.LeftContainer}>
            <img src="/hero.png" alt="bgi" className={styles.BackgroundImg} />
        </div>
        <div className={styles.RightContainer}>
        <h2>More about you...</h2>


           {/* Full Name */}
            <div className={`${styles.formGroup} 
            ${(nameError) || (invalidName) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l1CN:styles.l1}>{t('Full_Name')}</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
        
            {(nameError) && <p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidName) && <p className={styles.errorMessagePW}>Invaild name</p>}
            </div>

            {/* Work Email Address */}
            <div className={`${styles.formGroup} 
            ${(emailError) || (invalidEmail) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l4CN:styles.l4}>{t('Email_Address')}</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            {(emailError) && <p className={styles.errorMessagePW}>{message}</p>}
            {(invalidEmail) && <p className={styles.errorMessagePW}>Invalid email address</p>}

            {/* Job Title */}
            <div className={`${styles.formGroup} 
            ${(titleError) || (invalidLengthJob) || (invalidJob) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l3CN:styles.l3}>{t('Job_Title')}</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            
            {(titleError) && <p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidLengthJob) && <p className={styles.errorMessagePW}>The job title's name must not exceed 25 characters</p>}
            {(invalidJob) && <p className={styles.errorMessagePW}>Invalid job title</p>}

            {/* Company */}
            <div className={`${styles.formGroup} 
            ${(companyError) || (invalidLengthComp) || (invalidComp) ? styles.errorGroup : ""}`}>
              <label className={props.selectedLanguage==="CN"?styles.l2CN:styles.l2}>{t('Company')}</label>
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
            </div>
            {(companyError) && <p className={styles.errorMessagePW}>Fill in the blank</p>}
            {(invalidLengthComp) && <p className={styles.errorMessagePW}>The company's name must not exceed 40 characters</p>}
            {(invalidComp) &&  <p className={styles.errorMessagePW}>Invalid company name</p>}

            <div className={props.selectedLanguage==="CN" ?styles.formGroup2CN:styles.formGroup2}>
              <label className={props.selectedLanguage==="CN"?styles.l7CN:styles.l7}>
              <input
                type="checkbox"
                name = "agree_to_terms"
                checked={formData.agree_to_terms}
                onChange={handleCheckboxChange}
              />
              {t('agree_to_terms')}<a onClick={handleTermsClick} className={styles.AgreeToTerms}>{t('terms')}</a>
              </label>
            </div>

            {(TOUError) && <p className={styles.errorMessagePW}>You must agree with terms</p>}

            <button 
                type="continue" 
                className={
                    props.selectedLanguage === "CN" 
                        ? isFormValid() ? styles.ccbuttonCN : styles.ccbuttonCNDisabled
                        : isFormValid() ? styles.ccbutton : styles.ccbuttonDisabled
                }
                disabled={!isFormValid()}
            >
                {t("Contiue")}
            </button>


        </div>
    </div>
)
}
export default WeChat_form;