import { useEffect, useState } from "react";
import styles from "./GeneralFIlter.module.css";
import LocationComponent from "./Child/Location";
import JobTitleComponent from "./Child/JobTitle";
import EmployerComponent from "./Child/Employer";
// import AwardComponent from "./Child/Award";
import TotalPublications from "./Child/TotalPublications";
import PublishedonComponent from "./Child/Publishedon";
import OnlyShowComponent from "./Child/OnlyShow";
import Modal from "./Modal";
import translation from "./mapping_dict.json"
import { findAllByAltText } from "@testing-library/react";
// import { addDefault_jobtitle } from '../../js/collapsible2'

function GeneralFilter(props) {
  const [tags, setTags] = useState([]);
  const [showGen, setShowGen] = useState(props.state);
  const [clearAllTotal,setClearAllTotal] = useState(false);

  const filteredTags = tags.filter(tag => tag !== "N/A");

  useEffect(() => {
    setShowGen(props.state);
  }, [props.state]);
  useEffect(() => {
    props.setState(showGen);
  }, [showGen]);

 

  const translationsCN = {
      'Stanford University':"斯坦福大学",
      'Google':"谷歌",
      'MIT':"麻省理工学院",
     'Meta':"Meta 元搜索引擎",
      'QS Global 200':"QS全球大学排名200",
       'Principal Scientist':"首席科学家",
      'Lecturer':"讲师",
      'UK': "英国",
      'USA': "美国",
      'United Kingdom': "英国",
      'United States': "美国",
      'Belgium': "比利时",
      'China': "中国",
      'Italy': "意大利",
      'Singapore': "新加坡",
      'Germany': "德国",
      'ICVR':"ICVR 国际计算机视觉会议",
      'AAAI':"AAAI 人工智能与人工智能应用大会",
      'JAMA':"JAMA 美国医学会杂志",
      'ICCV':"ICCV 国际计算机视觉大会",
      'Nature':"Nature 《自然》杂志",

  }


  const [expanded, setExpanded] = useState(false);
  const [savedLocation, setSavedLocation] = useState([]);
  const [location, setLocation] = useState([]);
  const [locationMessage, setLocationMessage] = useState(false)
  const [jobMessage, setJobMessage] = useState(false)
  const [empMessage, setEmpMessage] = useState(false)
  const [pubMessage, setPubMessage] = useState(false)


  function handleLocation(newValue,message) {
    setLocation(newValue);
    setLocationMessage(message)
    setJobMessage(false)
    setEmpMessage(false)
    setPubMessage(false)
  }
  const [savedJobTitle, setSavedJobTitle] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);

  function handleJob(newValue,message) {
    setJobTitle(newValue);
    setJobMessage(message)
    setLocationMessage(false)
    setEmpMessage(false)
    setPubMessage(false)
  }
  const [savedEmployer, setSavedEmployer] = useState([]);
  const [employer, setEmployer] = useState([]);
  function handleEmp(newValue,message) {
    setEmployer(newValue);
    setLocationMessage(false)
    setJobMessage(false)
    setEmpMessage(message)
    setPubMessage(false)
  }
  // const [savedAward, setSavedAward] = useState([]);
  // const [award, setAward] = useState([]);
  // function handleAward(newValue){
  //   setAward(newValue);
  // }
  const [savedPublishedon, setSavedPublishedon] = useState([]);
  const [publishedon, setPublishedon] = useState([]); //publishedOn
  function handlePubon(newValue,message) {
    setPublishedon(newValue);
    setLocationMessage(false)
    setJobMessage(false)
    setEmpMessage(false)
    setPubMessage(message)
  }
  const [savedTotalPublications, setSavedTotalPublications] = useState([
    null,
    null,
  ]);
  const [totalPublications, setTotalPublications] = useState([null, null]);
  function handleTotal(newValue) {
    setTotalPublications(newValue);
    setLocationMessage(false)
    setJobMessage(false)
    setEmpMessage(false)
    setPubMessage(false)
  }
  const [savedOnlyShow, setSavedOnlyShow] = useState({
    "Email address": false,
    "Phone number": false,
    "ORCID profile link": false,
    "Google Scholar Link": false,
  });
  const [onlyShow, setOnlyShow] = useState({
    "Email address": false,
    "Phone number": false,
    "ORCID profile link": false,
    "Google Scholar Link": false,
  });

  function handleOnlyShow(newValue) {
    setOnlyShow(newValue);
    setLocationMessage(false)
    setJobMessage(false)
    setEmpMessage(false)
    setPubMessage(false)
  }

  // useEffect(()=>{
  //   if(props.filter){
  //     console.log(props.filter)
  //     const data = JSON.parse(props.filter).general_filter;
  //     console.log('filter general',data)
  //     setTags([...data.location, ...data.job_title, ...data.employer, ...data.published_on])     
  //     setLocation([...data.location])
  //     setJobTitle([...data.job_title])
  //     setEmployer([...data.employer])
  //     setPublishedon([...data.published_on])
  //   }

  // },[])

  const displayTags = expanded ? filteredTags : calculateTags(tags);
  // const remainingTags = tags.slice(displayTags.length, tags.length);

  function calculateTags(tags) {
    let totalLength = 0;
    let displayedTags = [];

    if(props.selectedLanguage =='EN'){
      for (let i = 0; i < tags.length; i++) {
        if (tags[i] !== "N/A") { // Skip "N/A" when calculating
          const tagLength = tags[i].length;
          let displayedTagsLength = displayedTags.length ? displayedTags.length : 0;
          if (totalLength + tagLength + displayedTagsLength * 2.65 <= 165) {
            console.log(totalLength + tagLength + displayedTagsLength * 2.65 )

            displayedTags.push(tags[i]);
            totalLength = totalLength + tagLength + displayedTagsLength * 2.65;

          } else {
            break;
          }
        }
      }
    }
    if (props.selectedLanguage == 'CN') {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i] !== "N/A") { // Skip "N/A" when calculating
          let tagname = translationsCN[tags[i]] ? translationsCN[tags[i]] : tags[i];
          const tagLength = tagname.length;
          console.log('CN',tagLength)
          let displayedTagsLength = displayedTags.length ? displayedTags.length : 0;
          if (totalLength + tagLength + displayedTagsLength * 2.65 <= 100) {
            console.log(totalLength + tagLength + displayedTagsLength * 2.65 )
            displayedTags.push(tags[i]);
            totalLength = totalLength + tagLength + displayedTagsLength * 2.65;

          } else {

            break;
          }
        }

      }
    }
    

    return displayedTags;
  }

  const translations = {
    EN: {
      generalFilter: "General Filter",
      clearAll: "Clear all",
      cancel: "Cancel",
      save: "Save",
      searchPlaceholder: "Search by scholar's personal basic information (location, job title, employer...)"
    },
    CN: {
      generalFilter: "基础信息筛选栏",
      clearAll: "清空",
      cancel: "取消",
      save: "保存",
       searchPlaceholder: "按学者的基本信息进行搜索（地点、职位、雇主...）"
    },
  // You can add more languages here
};

  // useEffect(() => {
  //   setTags([...location, ...jobTitle, ...employer, ...award, ...publishedon]);
  //   props.change({
  //     general_filter: {
  //       location: location,
  //       job_title: jobTitle,
  //       employer: employer,
  //       award_membership: award,
  //       total_publications: {
  //         "min": totalPublications[0],
  //         "max": totalPublications[1]
  //       },
  //       published_on: publishedon,
  //       only_with: {
  //           "email": onlyShow["Email address"],
  //           "phone": onlyShow["Phone number"],
  //           "ORCID": onlyShow["ORCID profile link"],
  //           "gs_link": onlyShow["Google Scholar Link"]
  //       }
  //   }
  //   })
  // }, [location, jobTitle, employer, award, publishedon, totalPublications, onlyShow]);
  // const removeTag = (tagToRemove) => {
  //   setLocation(location.filter(tag => tag !== tagToRemove));
  //   setJobTitle(jobTitle.filter(tag => tag !== tagToRemove));
  //   setEmployer(employer.filter(tag => tag !== tagToRemove));
  //   setAward(award.filter(tag => tag !== tagToRemove));
  //   setPublishedon(publishedon.filter(tag => tag !== tagToRemove));
  // }

  const scrollSmoothTo = (position) => {
    if ('scrollBehavior' in document.documentElement.style) {
        window.scrollTo({
            top: position,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo(0, position);
    }
};
  function cancel() {
    setLocation(savedLocation);
    setJobTitle(savedJobTitle);
    setEmployer(savedEmployer);
    // setAward(savedAward);
    setPublishedon(savedPublishedon);
    setOnlyShow(savedOnlyShow);
    setTotalPublications(savedTotalPublications);
    setShowGen(!showGen);
    setLocationMessage(false)
    setJobMessage(false)
    setEmpMessage(false)
    setPubMessage(false)
    
  }

  function clear() {
    var newValue = [];
    setLocation(newValue);
    setJobTitle(newValue);
    setEmployer(newValue);
    // setAward(newValue);
    setPublishedon(newValue);
    setLocationMessage(false)
    setJobMessage(false)
    setEmpMessage(false)
    setPubMessage(false)
    setClearAllTotal(true)
    setOnlyShow({
      "Email address": false,
      "Phone number": false,
      "ORCID profile link": false,
      "Google Scholar Link": false,
    });
    // setTotalPublications(['cancel','cancel']);//does mutch ['', '']
    setTotalPublications([null,null ]); //does mutch
  }
  const save = () => {
    setSavedLocation(location);
    setSavedJobTitle(jobTitle);
    setSavedEmployer(employer);
    // setSavedAward(award);
    setSavedPublishedon(publishedon);
    setSavedOnlyShow(onlyShow);
    setSavedTotalPublications(totalPublications);
    setShowGen(!showGen);
    scrollSmoothTo(0)
    setClearAllTotal(false)
    setLocationMessage(false)
    setJobMessage(false)
    setEmpMessage(false)
    setPubMessage(false)

    setTags([...location, ...jobTitle, ...employer, ...publishedon]);
    props.change({
      general_filter: {
        location: location,
        job_title: jobTitle,
        employer: employer,
        // award_membership: award,
        total_publications: {
          min: totalPublications[0],
          max: totalPublications[1],
        },
        published_on: publishedon,
        only_with: {
          email: onlyShow["Email address"],
          phone: onlyShow["Phone number"],
          ORCID: onlyShow["ORCID profile link"],
          gs_link: onlyShow["Google Scholar Link"],
        },
      },
    });
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };
  function clearAll() {
    handleOpenModal();
  }
  const handleOptionSelected = async () => {
    clear();
    setTags([]);
    props.change({
      general_filter: {
        location: [],
        job_title: [],
        employer: [],
        total_publications: { min: null, max: null },
        published_on: [],
        only_with: { email: false, phone: false, ORCID: false, gs_link: false },
      },
    });
    handleCloseModal();
  };
  const checkAllTagsEmpty = () => {
    if (
      location.length !== 0 ||
      jobTitle.length !== 0 ||
      employer.length !== 0 ||
      publishedon.length !== 0
    ) {
      return false;
    }
    if ( (totalPublications[0] !== null && totalPublications[1] !== null) &&(totalPublications[0] !== '' && totalPublications[1] !== '')) {
      console.log(totalPublications)
      return false;
    }
    if (
      onlyShow["Email address"] !== false ||
      onlyShow["Phone number"] !== false ||
      onlyShow["ORCID profile link"] !== false ||
      onlyShow["Google Scholar Link"] !== false
    ) {
    
      return false;
    }

    return true;
  };


  const checkButtonDisabled = checkAllTagsEmpty();

  const t = (key) => {
  // Check if translations exist for the selected language and the key
  if (translations[props.selectedLanguage] && translations[props.selectedLanguage][key]) {
    return translations[props.selectedLanguage][key];
  } else {
    // If there is no translation, return the original key
    return key;
  }
};


  return (
    
    <div className={styles.total} id="General_Search_total">
      <div
        className={styles.General_Search}
        onClick={() => setShowGen(!showGen)}
      >
        <ul className={styles.General_Search_Input}>
          {displayTags.map((tag, index) => (
            <li key={index}>
               {props.selectedLanguage === 'CN' && translationsCN[tag] ? translationsCN[tag] : tag}
              {/* <i onClick={() => removeTag(tag)}>
                      <span className={styles.close}>✖</span>
                    </i> */}
            </li>
          ))}
          {filteredTags.length > displayTags.length && (
            <span className={styles.more_button}>
              +{filteredTags.length - displayTags.length}
            </span>
          )}

          <input
            className={styles.inputSentence}
            type="text"
            placeholder={
              filteredTags.length
                ? ""
                : t('searchPlaceholder')
            }
            readOnly
          />
        </ul>
        {/* <button className={styles.collapsible} name="control_openclose"><div className={styles.General_Search_image} /><p>General Filter</p></button> */}
        <div className={props.selectedLanguage === 'CN' ? styles.collapsibleCN :styles.collapsible} name="control_openclose">
          <div className={styles.General_Search_image} />
          <p>{t('generalFilter')}</p>
        </div>
      </div>
      {showGen && (
        <>
          <div className={styles.content_input}>
            <LocationComponent change={handleLocation} state={location} selectedLanguage={props.selectedLanguage} message={locationMessage}/>
            <JobTitleComponent change={handleJob} state={jobTitle} selectedLanguage={props.selectedLanguage} message={jobMessage}/>
            <EmployerComponent change={handleEmp} state={employer} selectedLanguage={props.selectedLanguage} message={empMessage}/>
            {/* <AwardComponent change = {handleAward}  state = {award}/> */}
            <TotalPublications change={handleTotal} state={totalPublications} selectedLanguage={props.selectedLanguage} clearAll={clearAllTotal}/>
            <PublishedonComponent change={handlePubon} state={publishedon} selectedLanguage={props.selectedLanguage} message={pubMessage}/>
            <OnlyShowComponent change={handleOnlyShow} state={onlyShow} selectedLanguage={props.selectedLanguage}/>

            {props.selectedLanguage=='EN' && 
             <div className={styles.submit_clear_section}>
             <div
               className={
                 checkButtonDisabled
                   ? styles.clear_all_button_disabled
                   : styles.clearAbutton
               }
             >
                                        {console.log('checkbutton',checkButtonDisabled)}

               <button
                 onClick={clearAll}
                 className={styles.clearAllButtonWord}
                 disabled={checkButtonDisabled}
               >
                 {t('clearAll')}
               </button>
             </div>
             <Modal
               isOpen={modalIsOpen}
               onClose={handleCloseModal}
               onOptionSelected={handleOptionSelected}
               selectedLanguage={props.selectedLanguage}
             />
             {/* wrong className */}
             <div className={styles.clear_all_button}>
               <button onClick={cancel}>{t('cancel')}</button>
             </div>
             <div
               className={
                 checkButtonDisabled
                   ? styles.save_button_disabled
                   : styles.save_button
               }
             >
               <button onClick={save} disabled={checkButtonDisabled}>
                 {t('save')}
               </button>
             </div>
             </div>
              }

{props.selectedLanguage=='CN' && 
             <div className={styles.submit_clear_section}>
             <div
               className={
                 checkButtonDisabled
                   ? styles.clear_all_button_disabledCN
                   : styles.clearAbuttonCN
               }
             >
                          {console.log('checkbutton',checkButtonDisabled)}

               <button
                 onClick={clearAll}
                 className={styles.clearAllButtonWord}
                 disabled={checkButtonDisabled}
               >
                 {t('clearAll')}
               </button>
             </div>
             <Modal
               isOpen={modalIsOpen}
               onClose={handleCloseModal}
               onOptionSelected={handleOptionSelected}
               selectedLanguage={props.selectedLanguage}
             />
             {/* wrong className */}
             <div className={styles.clear_all_button}>
               <button onClick={cancel}>{t('cancel')}</button>
             </div>
             <div
               className={
                 checkButtonDisabled
                   ? styles.save_button_disabled
                   : styles.save_button
               }
             >
               <button onClick={save} disabled={checkButtonDisabled}>
                 {t('save')}
               </button>
             </div>
             </div>
              }
           
            
          </div>
        </>
      )}
    </div>
  );
}

export default GeneralFilter;
