import React, { useEffect, useState } from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import styles from "./linkSent.module.css";
import { useLocation } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';
import { useNavigate } from 'react-router-dom';

const LinkSentPWPage = (props) => {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const location = useLocation();
    const formData = new URLSearchParams();
    formData.append("port","3001");
    const [countdown, setCountdown] = useState(60);
    const [isResending, setIsResending] = useState(false);
    const  email  = location.state.email;
    console.log(email);
    const fetchresend = () =>{
      fetch("https://intelliprozenithscholars.com/auth/register", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
        })
        .then(response => {
        if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
        }
        return response.json();
        })
        .then(csrfdata => {

            const token = csrfdata.csrfToken; 
            
            fetch(`https://intelliprozenithscholars.com/auth/verifyEmail/${email}`, {
                method: 'POST',
                headers: { 
                'X-CSRFToken': token,
                'Content-Type': 'application/x-www-form-urlencoded' 
                },
                credentials: 'include',
                body: formData.toString()
            })
            .then(response => {
                if (!response.ok) {
                  throw new Error("Verify email request failed with status: " + response.status);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (data.success) {
                    console.log(data.message);
                }
                else{
                    setMessage(data.message); 
                }
            })
            .catch(error =>{
                console.error("Error during resend:", error);
                setMessage("Error during resend.");  // Display feedback to the user
            });
        })
        .catch(error => {
            console.error("Error fetching CSRF token:", error);
        })
    };
    const resendEmail = () => {
        fetchresend();
        setIsResending(true);
        setCountdown(60);

        // Simulate the email sending process
        setTimeout(() => {
            setIsResending(false);
        }, 2000);
    };

    useEffect(() => {
        if (countdown > 0 && !isResending) {
            const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(interval);
        }
    }, [countdown, isResending]);

    const translations = {
        EN: {
            send: "Sent!",
            text: "A verification link has been sent to your email",
            resend: "Resend Link",
            resending: "Resending...",
        },
        CN: {
            send: "邮件已发送！",
            text: "验证邮件已发送到邮箱地址",
            resend: "重新发送",
            resending: "重新发送中",
        },
      };
    
      const t = (key) => {
        return translations[props.selectedLanguage][key] || key;
      };
    
      const SelectedOptionWithSVG = ({ label }) => (
        // <div style={{ display: 'flex', alignItems: 'center', marginTop: '-27px' }}>
        //   <span style={{ marginRight: '5px' }}>{label}</span>
        //   <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '0px' }} />
        // </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-10px' }}>
      <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '7px', marginRight: '10px', marginLeft: '12px'}} />
      {label==='English' ? (
        <span style={{ paddingTop: '-5px' }}>EN</span>
      ) : (
        <span style={{ paddingTop: '-5px' }}>CN</span>
      )}
    </div>
      );
    
      const options2 = [
        { value: 'EN', label: 'English' },
        { value: 'CN', label: '中文(简体)' },
      ];
    
      const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language
    
      const handleSelectChange2 = (selectedOption) => {
        setSelectedLanguage(selectedOption.value); // Update
        props.setSelectedLanguage(selectedOption.value); 
        if (selectedOption.value === 'EN') {
          // Language is set to English
        } else if (selectedOption.value === 'CN') {
          // Language is set to Chinese
        }
      }
    
      const customSelect2 = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: 'Sofia Pro',
            fontSize: 16,
            color: '#377DED',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            border: 'none', 
            boxShadow: 'none', 
            minWidth: '130px',
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          display:'none',
        }),
        placeholder: (provided, state) => ({
          ...provided,
          transition: 'color 0.2s',
          color: '#377DED',
          fontSize: state.isFocused ? '18px' : '16px',
          position: 'relative',
          "&:hover": {
            color: "#2149B1",
            fontSize: 18,
            //borderBottom: "2px solid #2149B1",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          fontFamily: 'Sofia Pro',
          fontSize: 16,
          color: state.isSelected ? '#FFFFFF' : '#377DED',
          cursor: 'pointer',
          textAlign: 'left',
          "&:active": {
            color: '#FFFFFF',
            background: '#377DED',
          },
          "&:focus": {
            outline: 'none',
          },
          display: 'flex',
          marginTop:'8px',
          marginBottom:'8px'
        }),
        menu: (provided) => ({
          ...provided,
          border: 'none',
          borderRadius: '10px',
          boxShadow: 'none',
          marginTop: 0,
          position: 'absolute',
          width:'150px',
          marginLeft:'10px'

        }),
      };
    
      // const PlaceholderImageEN = () => (
      //   <div style={{display: 'flex', alignItems: 'center' }}>
      //     <span style={{ marginRight: '5px' }}>English</span>
      //     <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '5px' }} />
      //   </div>
      // );
    
      // const PlaceholderImageCN = () => (
      //   <div style={{display: 'flex', alignItems: 'center' }}>
      //     <span style={{ marginRight: '5px' }}>Chinese (中文)</span>
      //     <img src={placeholderImg} alt="Placeholder" style={{ width: '25px', height: '25px', marginBottom: '0px' }} />
      //   </div>
      //);

      const PlaceholderImageEN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '5px', marginRight: '10px', marginLeft: '10px' }} />
            <span>EN</span>
        </div>
      );
    
      const PlaceholderImageCN = () => (
        <div style={{display: 'flex', alignItems: 'center' }}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '20px', height: '20px', marginBottom: '5px', marginRight: '10px', marginLeft: '10px' }} />
            <span>CN</span>
        </div>
      );
    

return (
    <div className={styles.totalContainer}>
        {/* <div className={styles.LeftContainer}>
          <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
          <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />
        </div> */}

        {/* <div className= {styles.backhome}>
          <img src="/backhome.svg" alt="back" className= {styles.backtohome}  onClick={e=>{navigate("/home")}}/>
        </div> */}
        <div className={styles.logo}>
          {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
          {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
           {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
          {/* <img src="/backlogin.svg" alt="back" className= {styles.backtohome2}  onClick={e=>{navigate("/home")}}/> */}
          {/* <img src="/backlogin.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/> */}
          {props.selectedLanguage=='EN' &&  <img src="/backlogin.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/>}
            {props.selectedLanguage=='CN' &&  <img src="/backcn.svg" alt="logo" className= {styles.backlogin}  onClick={e=>{navigate("/login")}}/>}
        </div>

        <div className={styles.langDrop}>
            <Select
              options={options2}
              onChange={handleSelectChange2}
              isSearchable={false} // This will disable user input
              placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
              components={{
                SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
              }}
              styles={customSelect2}
            />
        </div>
      
      <div className={styles.rightContainerPW}>
        <img className={styles.sentImg} alt="" src="/sent.png" />
        <h2 className={styles.sentWord}>{t('send')}</h2>
        <h3 className={styles.sentcontent}>{t('text')}</h3>
        <h3 className={styles.email}>{email}</h3>

        <button
          className={`${styles.resendButton} ${
            countdown === 0 && !isResending ? styles.active : styles.disabled
          }`}
          onClick={resendEmail}
          disabled={isResending || countdown > 0}
        >
          {isResending ? t('resending') : countdown > 0 ? `Resend link in ${countdown}s` : t('resend')}
        </button>

      </div>
    </div>
);
};

export default LinkSentPWPage;