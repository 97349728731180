import React,{useState,useEffect} from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import styles from "./WechatQR.module.css";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';
import QRCode from 'qrcode.react';
import { nanoid } from 'nanoid'
import { fetchEmail,fetchState } from "../Home/fetch";
import refresh from "../LoginPage/assets/wechat_refresh.png"
import check from "../LoginPage/assets/wechat_checked.png"


function WeChat_QR(props){
    const [termsPop, setTermsPop] = useState(false);
    const [privaPop, setPrivaPop] = useState(false);
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();
    // const appid = 'wxe3732215530c0b3a'
    const appid = 'wx3a830d19d85a3f79'
    const uniq_state = nanoid()
    // const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=https://intelliprozenithscholars.com/auth/wechatlogin&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
    const [url,setUrl] = useState()
    const [token,setToken] =useState('')
    const [hasUrl,setHasUrl] = useState(false)
    const [isLoggedIn,setIsLoggedIn] = useState(false)

    const fetchQrcode = async () => {
      try {
        const response = await fetch("http://54.69.200.235/getQrcode/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: 'include',
          body: JSON.stringify({
          })
        });
        if (!response.ok) {
          throw new Error("Request failed with status: " + response.status);
        }
        const data = await response.json();
        console.log(data);
        // 将获取到的 URL 设置为组件状态
        setUrl(data.url);
        setToken(data.state);
        setHasUrl(true)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      
      // 调用异步函数
      fetchQrcode();
    }, []);
    
   

  //   useEffect(() => {
  //     let interval;
  //     let counter = 0;
      
  //     const fetchData = () => {
  //         fetchState(token)
  //             .then((data) => {
  //                 console.log("login status", data);
  //                 console.log("token", token);
  //                 if (data.state == token && token !=='') {
  //                     console.log('get wechat login')
  //                     setToken('')
  //                     setIsLoggedIn(true)
  //                     setTimeout(() => {
  //                     navigate('/home'); // 在5秒后跳转
  //                       }, 3000);
  //                   clearInterval(interval); // Stop the 
                      
  //                 } else {
  //                   console.log('fail')
  //                 }
  //             })
  //             .catch((error) => {
  //                 console.error("Promise rejected:", error);
  //             });

  //         counter++;
  //         if (counter >= 40) {
  //             clearInterval(interval); // 达到 60 秒时停止调用
  //             setReload(true)
  //         }
  //     };

  //     interval = setInterval(fetchData, 1500); // 每秒调用 fetchEmail

  //     return () => clearInterval(interval); // 在组件卸载时清除定时器
  // }, [token]);


  useEffect(() => {
    let interval;
    let counter = 0;
  
    const fetchData = () => {
      const formData = new URLSearchParams();
      formData.append('state',token)
      console.log(formData.toString())
      fetch("http://54.69.200.235/checkToken/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        credentials: 'include',
        body: formData.toString(),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("login status", data.message);
          if (data.message =='Success') {
            console.log('get wechat login');
            setToken('');
            setIsLoggedIn(true);
            setTimeout(() => {
              navigate('/home'); // 在3秒后跳转
            }, 3000);
            clearInterval(interval); // Stop the 
          } else {
            console.log('fail');
          }
        })
        .catch((error) => {
          console.error("Promise rejected:", error);
        });
  
      counter++;
      if (counter >= 30) {
        clearInterval(interval); // 达到 60 秒时停止调用
        setReload(true);
      }
    };
  
    interval = setInterval(fetchData, 2500); // 每秒调用 fetchEmail
  
    return () => clearInterval(interval); // 在组件卸载时清除定时器
  }, [token]);
  

  
  const handleRefresh = () => {
    setUrl(''); // Clear the current URL
    setHasUrl(false)
    setReload(false);
    fetchQrcode()
  };

    const handleLogin =()=>{
    fetch("https://intelliprozenithscholars.com/auth/register", {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              
            })
          })
          .then(response => {
            if (!response.ok) {
              throw new Error("Request failed with status: " + response.status);
            }
            return response.json();
          }).catch(err => {
            console.log(err)
          })
    }
    
return(
    <div className={styles.container}>
        <div className={styles.LeftContainer}>
        <img src="/LogoLeft.png" alt="bgi" className={styles.BackgroundImg} />
        </div>
        <div className={styles.RightContainer}>
          {/* <img src="/QRcode.png" alt="bgi" className={styles.QRIcon} /> */}
          {!reload && url && !isLoggedIn ? (
              <div className={styles.qr}>
            <QRCode
                value={url}
                  size={320} // 二维码的大小
                fgColor="#000000" // 二维码的颜色
            />
          <div className={styles.qrbtm}>
      <div>By scanning the QR code, you agree to the</div>
      <div className={styles.qrbtm2}>
        <div className={styles.terms} onClick={e => { setTermsPop(true) }}>Terms and Conditions</div>
        <div className={styles.and}> and </div>
        <div className={styles.privacy} onClick={e => { setPrivaPop(true) }}>Privacy Policy</div>
      </div>
    </div>
  </div>
) : reload && hasUrl ? (
  <div className={styles.qr}>
    <img src="/QR_welcome.png" alt="bgi" className={styles.QRIcon} />
    <img src={refresh} alt="bgi" className={styles.checkedIcon} onClick={handleRefresh} />
   
    <div className={styles.qrbtm}>
      <div>The QR code is expired</div>
      <div className={styles.qrbtm2}>
        <div className={styles.and}>Please</div>
        <div className={styles.and}> refresh </div>
        <div className={styles.and}>The QR code</div>
      </div>
    </div>
  </div>
) :isLoggedIn ? (
  <div className={styles.qr}>
        <img src="/QR_welcome.png" alt="bgi" className={styles.QRIcon} />
        <img src={check} alt="bgi" className={styles.checkedIcon} />
      <div className={styles.qrbtm}>
      <div>By scanning the QR code, you agree to the</div>
      <div className={styles.qrbtm2}>
        <div className={styles.terms} onClick={e => { setTermsPop(true) }}>Terms and Conditions</div>
        <div className={styles.and}> and </div>
        <div className={styles.privacy} onClick={e => { setPrivaPop(true) }}>Privacy Policy</div>
      </div>
    </div>
  </div>
) : null}

            
           
        </div>
        
        {termsPop && (
          <div className={styles.layer}>
            <div className={styles.pop}>
              <div className={styles.topbar}>
              <img src="/close.svg" className={styles.crossIcon} onClick={e=>{setTermsPop(false)}} alt="crossIcon" />
                Terms and Condition
              </div>
              <div className={styles.termswrap}>
                <h3>Welcome to Intellipro Zenith Scholars.</h3>
                <h4>Zenith Scholars provides website functionality and other products and services to meet your data searching needs on Zenith Scholars, including but not limited to searching, querying global academic publications from scholars, and accessing salary data from different IT industries worldwide. By using Zenith Scholars, you agree to be bound by the following conditions of use, and members of your household and others who use any service in connection with your account agree to these conditions on their behalf.</h4>
                <h3>Please read these conditions carefully.</h3>
                <h4>We provide various Zenith Scholars services, and other additional terms may sometimes apply. When you use the Zenith Scholars service (for example, our academic publication search, salary data queries, etc.), you will also be bound by the guidelines, terms, and agreements (Terms of Service) applicable to that service. In the event of any inconsistency between these Conditions of Use and the Terms of Service, the Terms of Service shall prevail.</h4>
                <h3>Telecommunication</h3>
                <h4>You may communicate with us electronically when you use the Zenith Scholars Services, or send us emails, text messages, and other communications from your desktop computer or mobile device. You agree to receive communications from us electronically, such as emails, text messages, mobile device notifications, or notices and messages on this site or other Zenith Scholars services, and you may retain a copy of these communications for your future reference. You agree that all agreements, notices, disclosures, and other communications that we provide to you satisfy the legal requirement of written notice.</h4>
                <h3>Copyright</h3>
                <h4>All content contained in the Zenith Scholars service, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Zenith Scholars or its content suppliers and is protected by the United States and protected by international copyright laws. The compilation of all content contained in or made available through the Zenith Scholars Services is the exclusive property of Zenith Scholars and is protected by United States and international copyright laws.</h4>
                <h3>Trademark</h3>
                <h4>Please see a non-exhaustive list of Zenith Scholars trademarks. In addition, graphics, logos, page headers, button icons, scripts, and service names contained in or available through the Zenith Scholars Services are trademarks or trade dress of Zenith Scholars in the United States and other countries. Zenith Scholars's trademarks and trade dress may not be used in connection with any product or service of Zenith Scholars, in a manner likely to confuse customers, or in a manner that disparages or disparages Zenith Scholars. All other non-Zenith Scholars trademarks appearing on the Zenith Scholars Services are the property of their respective owners and may or may not be related to Zenith Scholars.</h4>
                <h3>Licensing and Access</h3>
                <h4>Subject to compliance with these Conditions of Use and any Terms of Service, and payment of any applicable fees, Zenith Scholars or its content providers grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal non-commercial use of Zenith Scholars services. This license does not include resale or commercial use of any Zenith Scholars service or its content; collection and use of any academic publications, salary data, or any other content; use of any third party downloading, copying or other use of your account information for the benefit of a third party; or using data mining, robots or similar data gathering and extraction tools. All rights not expressly granted to you in these Conditions of Use or any Terms of Service are reserved and reserved by Zenith Scholars or its licensors, suppliers, publishers, rights holders or other content providers. None of the Zenith Scholars Services, or any portion thereof, may be copied, duplicated, duplicated, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written permission of Zenith Scholars. You may not use any meta tags or any other (hidden text) utilizing Zenith Scholars's name or trademarks without the express written permission of Zenith Scholars (hidden text). You may not abuse the Zenith Scholars service, and may only use the Zenith Scholars service in a manner permitted by law. The license granted by Zenith Scholars will terminate if you fail to comply with these Conditions of Use or any of the Terms of Service.</h4>

              </div>

            </div>
          </div>
        )}
        {privaPop && (
          <div className={styles.layer}>
            <div className={styles.pop}>
              <div className={styles.topbar}>
              <img src="/close.svg" className={styles.crossIcon} onClick={e=>{setPrivaPop(false)}} alt="crossIcon" />
                Privacy Policy
              </div>
              <div className={styles.termswrap}>
                <h3>Welcome to Intellipro Zenith Scholars.</h3>
                <h4>Zenith Scholars provides website functionality and other products and services to meet your data searching needs on Zenith Scholars, including but not limited to searching, querying global academic publications from scholars, and accessing salary data from different IT industries worldwide. By using Zenith Scholars, you agree to be bound by the following conditions of use, and members of your household and others who use any service in connection with your account agree to these conditions on their behalf.</h4>
                <h3>Please read these conditions carefully.</h3>
                <h4>We provide various Zenith Scholars services, and other additional terms may sometimes apply. When you use the Zenith Scholars service (for example, our academic publication search, salary data queries, etc.), you will also be bound by the guidelines, terms, and agreements (Terms of Service) applicable to that service. In the event of any inconsistency between these Conditions of Use and the Terms of Service, the Terms of Service shall prevail.</h4>
                <h3>Telecommunication</h3>
                <h4>You may communicate with us electronically when you use the Zenith Scholars Services, or send us emails, text messages, and other communications from your desktop computer or mobile device. You agree to receive communications from us electronically, such as emails, text messages, mobile device notifications, or notices and messages on this site or other Zenith Scholars services, and you may retain a copy of these communications for your future reference. You agree that all agreements, notices, disclosures, and other communications that we provide to you satisfy the legal requirement of written notice.</h4>
                <h3>Copyright</h3>
                <h4>All content contained in the Zenith Scholars service, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Zenith Scholars or its content suppliers and is protected by the United States and protected by international copyright laws. The compilation of all content contained in or made available through the Zenith Scholars Services is the exclusive property of Zenith Scholars and is protected by United States and international copyright laws.</h4>
                <h3>Trademark</h3>
                <h4>Please see a non-exhaustive list of Zenith Scholars trademarks. In addition, graphics, logos, page headers, button icons, scripts, and service names contained in or available through the Zenith Scholars Services are trademarks or trade dress of Zenith Scholars in the United States and other countries. Zenith Scholars's trademarks and trade dress may not be used in connection with any product or service of Zenith Scholars, in a manner likely to confuse customers, or in a manner that disparages or disparages Zenith Scholars. All other non-Zenith Scholars trademarks appearing on the Zenith Scholars Services are the property of their respective owners and may or may not be related to Zenith Scholars.</h4>
                <h3>Licensing and Access</h3>
                <h4>Subject to compliance with these Conditions of Use and any Terms of Service, and payment of any applicable fees, Zenith Scholars or its content providers grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal non-commercial use of Zenith Scholars services. This license does not include resale or commercial use of any Zenith Scholars service or its content; collection and use of any academic publications, salary data, or any other content; use of any third party downloading, copying or other use of your account information for the benefit of a third party; or using data mining, robots or similar data gathering and extraction tools. All rights not expressly granted to you in these Conditions of Use or any Terms of Service are reserved and reserved by Zenith Scholars or its licensors, suppliers, publishers, rights holders or other content providers. None of the Zenith Scholars Services, or any portion thereof, may be copied, duplicated, duplicated, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written permission of Zenith Scholars. You may not use any meta tags or any other (hidden text) utilizing Zenith Scholars's name or trademarks without the express written permission of Zenith Scholars (hidden text). You may not abuse the Zenith Scholars service, and may only use the Zenith Scholars service in a manner permitted by law. The license granted by Zenith Scholars will terminate if you fail to comply with these Conditions of Use or any of the Terms of Service.</h4>

              </div>

            </div>
          </div>
        )}



    </div>
)
}
export default WeChat_QR;