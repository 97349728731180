
const fetchEmail = () => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/auth/get_profile_from_cookie_dkdh/", {
        method: "GET",
        headers: {
        },
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          reject(error);
        });
    });
  }; 


  const fetchProfile = () => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/auth/get_profile_from_cookie_dkdh/", {
        method: "GET",
        headers: {
        },
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          reject(error);
        });
    });
  }; 

  const getToken = async () => {
    try {
      const url = "https://intelliprozenithscholars.com/auth/register";
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  
const fetchState = () => {
  return new Promise((resolve, reject) => {
    fetch("http://54.69.200.235/getState/", {
      method: "POST",
      headers: {
      },
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed with status: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.error("Error fetching suggestions:", error);
        reject(error);
      });
  });
}; 
  export {fetchEmail,getToken, fetchState,fetchProfile};
  