import styles from "../ResumeDetail.module.css";
import PieGenerator from "../chart/PieGenerator";
import BarGenerator from "../chart/BarGenerator";
import data from "../../../DKDH_Filter.json";
import SubjectBarGenerator from "../chart/SubjectBarGenerator";
import translateCN from '../../SubjectFilter/mapping_dict.json'
import OpenAI from 'openai';
import { useEffect,useState } from "react";



const AcademicMatrics = (props) => {
  const [labelCn, setLabelCn] = useState([])
  const subjects = Object.entries(props.state.subject.paper).map(
    ([subjectName, quartiles]) => {
      const totalPapers = Object.values(quartiles).reduce(
        (sum, value) => sum + value,
        0
      );
      return {
        name: subjectName,
        value: totalPapers,
      };
    }
  );
  const topSubjects = subjects.sort((a, b) => b.value - a.value);
  const sumOfOtherPapers = topSubjects
    .slice(3)
    .reduce((sum, subject) => sum + subject.value, 0);
  const pieData = [
    ...topSubjects.slice(0, 3),
    ...[
      {
        name: "Others",
        value: sumOfOtherPapers,
      },
    ],
  ];
  const barSubjectData = topSubjects.slice(0, 3).map((subject) => ({
    paper: {
      name: subject.name,
      Q1: -props.state.subject.paper[subject.name].Q1,
      Q2: -props.state.subject.paper[subject.name].Q2,
      "Q3&4": -props.state.subject.paper[subject.name]["Q3&4"],
    },
    citation: {
      name: subject.name,
      Q1: props.state.subject.citation[subject.name].Q1,
      Q2: props.state.subject.citation[subject.name].Q2,
      "Q3&4": props.state.subject.citation[subject.name]["Q3&4"],
    },
  }));
  while (topSubjects.length < 3) {
    topSubjects.push({
      name: "",
      value: 0,
    });
  }
  while (barSubjectData.length < 3) {
    barSubjectData.push({
      paper: {
        name: "",
        Q1: 0,
        Q2: 0,
        "Q3&4": 0,
      },
      citation: {
        name: "",
        Q1: 0,
        Q2: 0,
        "Q3&4": 0,
      },
    });
  }

  const topics = Object.entries(props.state["top_research_topics"].paper).map(
    ([subjectName, quartiles]) => {
      const totalPapers = Object.values(quartiles).reduce(
        (sum, value) => sum + value,
        0
      );
      return {
        name: subjectName,
        value: totalPapers,
      };
    }
  );
  const sumOfTotalPapers = subjects.reduce(
    (sum, subject) => sum + subject.value,
    0
  );
  const topTopics = topics.sort((a, b) => b.value - a.value);

  const barTopicData = topTopics.slice(0, 7).map((subject) => ({
    paper: {
      name: subject.name,
      Q1: -props.state["top_research_topics"].paper[subject.name].Q1,
      Q2: -props.state["top_research_topics"].paper[subject.name].Q2,
      "Q3&4": -props.state["top_research_topics"].paper[subject.name]["Q3&4"],
    },
    citation: {
      name: subject.name,
      Q1: props.state["top_research_topics"].citation[subject.name].Q1,
      Q2: props.state["top_research_topics"].citation[subject.name].Q2,
      "Q3&4": props.state["top_research_topics"].citation[subject.name]["Q3&4"],
    },
  }));
  while (topTopics.length < 7) {
    topTopics.push({
      name: "",
      value: 0,
    });
  }
  while (barTopicData.length < 7) {
    barTopicData.push({
      paper: {
        name: "",
        Q1: 0,
        Q2: 0,
        "Q3&4": 0,
      },
      citation: {
        name: "",
        Q1: 0,
        Q2: 0,
        "Q3&4": 0,
      },
    });
  }
  let maxCitation = Math.max(
    ...barSubjectData.map((subject) => {
      return Math.max(
        subject.citation.Q1,
        subject.citation.Q2,
        subject.citation["Q3&4"]
      );
    })
  );
  let maxPaper = Math.min(
    ...barSubjectData.map((subject) => {
      return Math.min(
        subject.paper.Q1,
        subject.paper.Q2,
        subject.paper["Q3&4"]
      );
    })
  );
  let maxTopicCitation = Math.max(
    ...barTopicData.map((subject) => {
      return Math.max(
        subject.citation.Q1,
        subject.citation.Q2,
        subject.citation["Q3&4"]
      );
    })
  );

  let maxTopicPaper = Math.min(
    ...barTopicData.map((subject) => {
      return Math.min(
        subject.paper.Q1,
        subject.paper.Q2,
        subject.paper["Q3&4"]
      );
    })
  );
  function findCategoryAndField(subjectName) {
    for (let category in data.Filter) {
      for (let field in data.Filter[category]) {
        if (data.Filter[category][field].includes(subjectName)) {
          return {
            category: category,
            field: field,
          };
        }
      }
    }
    return {
      category: "N/A",
      field: "N/A",
    }; // returns null if the subject name isn't found
  }
  const backward = findCategoryAndField(topSubjects[0].name);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const translations = {
    EN: {
     ACADEMIC_MATRICS: 'ACADEMIC MATRICS',
     Field: 'Field',
     Category: 'Category',
      IntelliPro: 'IntelliPro | Global Scholar Database',
      Subject: 'Subject',
      Total: 'Total',
      Publications: 'Publications',
      Citation: 'Citation',
      Top_Research_Topics: 'Top Research Topics',
      Paper: 'Paper',
      Other: 'Other',
      hint1:' # of Q1 papers = Publications published on Tie 1 journals or conference proceedings (Top 25% within the subject area)',
      hint2:' # of Q2 papers = Publications published on Tie 2 journals or conference proceedings (Top 50% within the subject area)',
      hint3:'# of Q3 & Q4 papers = Publications published on Tie 3 & 4 journals or conference proceedings (50%-100% within the subject area)',

      NA: 'N/A',
      at: 'at',
    },
    CN: {
      ACADEMIC_MATRICS: '学术数据',
      Field: '领域',
      Category: '类别',
      Subject: '学科',
      Total: '总共',
      Publications: '篇出版物',
      Citation: '引用',
      Top_Research_Topics: '热门研究课题',
      
      Paper: '论文数量',
      Other: '其他',
      hint1:' "#Q1论文数量 = 发表在顶级期刊或会议论文集上的出版物（在该学科领域内属于前25%）"',
      hint2:' "#Q2论文数量 = 发表在二级期刊或会议论文集上的出版物（在该学科领域内属于前50%）"',
      hint3:'"#Q3 & Q4论文数量 = 发表在三级和四级期刊或会议论文集上的出版物（在该学科领域内属于50%-100%）"',

      NA: '无',
      at: '在',

      
    },
  // You can add more languages here
};

const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };

  
  const generateText = async (text) => {
    const apiKey = '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'

    try {

        const openai = new OpenAI({
            apiKey, dangerouslyAllowBrowser:true// This is also the default, can be omitted
          });   
          const prompt = `Translate the following English text to simplified Chinese: "${text}"`;
     
          const completion = await openai.completions.create({
            model: "gpt-3.5-turbo-instruct",
            prompt: prompt,
            max_tokens: 500,
          });
          return completion.choices[0].text;
          
      } catch (error) {
        console.error('Error:', error);
      }
  };

  const processCnlabel = async (labelArr) => {
    try {
      const translatedList = await Promise.all(
        labelArr.map(async (work) => {
          const translated = await generateText(work.name);
          return translated; 
        })
      );
      setLabelCn(translatedList);
    } catch (error) {
      console.error('Error translating:', error);
      // Handle error if translation fails
      setLabelCn([])
    }
  };

  useEffect(()=>{
    if(topTopics.length>0 && props.selectedLanguage == 'CN'){
      processCnlabel(topTopics)
    }
  },[])
  

  return (
    <div className={styles.chartGroup}>
      <div className={styles.matrics_title}>
        {t('ACADEMIC_MATRICS')}
        <div className={styles.line}></div>
      </div>
      <div className={styles.chartGroup_form}>
        <div className={styles.chartGroup_form_field}>
          <div className={styles.chartGroup_form_name}>{t('Field')}</div>
          {props.selectedLanguage == 'EN' && <div className={styles.chartGroup_form_infor}>{backward.field}</div>}
          {props.selectedLanguage == 'CN' && <div className={styles.chartGroup_form_infor}>{translateCN[backward.field]}</div>}

        </div>

        <div className={styles.chartGroup_form_category}>
          <div className={styles.chartGroup_form_name}>{t('Category')}</div>
          {props.selectedLanguage == 'EN' && <div className={styles.chartGroup_form_infor}>
            {backward.category}
          </div>}
          {props.selectedLanguage == 'CN' &&<div className={styles.chartGroup_form_infor}>
            { translateCN[backward.category]}
          </div>}
        </div>

        <div className={styles.chartGroup_form_subject}>
          <div className={styles.chartGroup_form_name}>{t('Subject')}</div>
          <div className={styles.chartGroup_form_subject_pie}>
            <div className={styles.total171PublicationsContainer}>
              <p className={styles.professor}>{t('Total')}</p>
              <p className={styles.p}>{sumOfTotalPapers}</p>
              <p className={styles.professor}>{t('Publications')}</p>
            </div>
            <PieGenerator data={pieData} />
            {barSubjectData[0].citation.name !== "" && (
              <>
                <div className={styles.pieChart_text}>
                  <div className={styles.ai_color}></div>
                  <div>
                  {props.selectedLanguage == 'EN' ? capitalizeFirstLetter(barSubjectData[0].citation.name): translateCN[barSubjectData[0].citation.name]}
                  </div>
                </div>{" "}
              </>
            )}
            {barSubjectData[1].citation.name !== "" && (
              <>
                <div className={styles.pieChart_text}>
                  <div className={styles.vision_color}></div>
                  <div>
                  {props.selectedLanguage == 'EN' ? capitalizeFirstLetter(barSubjectData[1].citation.name): translateCN[barSubjectData[1].citation.name]}
                  </div>
                </div>
              </>
            )}
            {barSubjectData[2].citation.name !== "" && (
              <>
                <div className={styles.pieChart_text}>
                  <div className={styles.science_color}></div>
                  <div>
                    {props.selectedLanguage == 'EN' ? capitalizeFirstLetter(barSubjectData[2].citation.name): translateCN[barSubjectData[2].citation.name]}
                  </div>
                </div>
              </>
            )}
            {sumOfOtherPapers !== 0 && (
              <>
                <div className={styles.pieChart_text}>
                  <div className={styles.other_color}></div>
                  <div>{t('Other')}</div>
                </div>
              </>
            )}
          </div>

          <div className={styles.chartGroup_form_subject_table}>
            <div className={styles.chartGroup_form_subject_table_chart}>
              {barSubjectData[0].citation.name === "" &&
              barSubjectData[1].citation.name === "" &&
              barSubjectData[2].citation.name === "" ? (
                <div>{t('NA')}</div>
              ) : (
                <div className={styles.chartGroup_form_subject_text}>
                  <div className={styles.citationPaper}>
                    <p className={styles.professor}>{t('Citation')}</p>
                    <p className={styles.paper}>{t('Paper')}</p>
                  </div>
                </div>
              )}

              {barSubjectData[0].citation.name !== "" && (
                <div className={styles.chartGroup_form_subject_table_each}>
                  <BarGenerator
                    data={barSubjectData[0]}
                    maxCite={maxCitation}
                    maxPap={maxPaper}
                  />
                  <p className={styles.chartName}>
                    {props.selectedLanguage =='EN' ? capitalizeFirstLetter(topSubjects[0].name): translateCN[topSubjects[0].name]}
                  </p>
                </div>
              )}
              {barSubjectData[1].citation.name !== "" && (
                <div className={styles.chartGroup_form_subject_table_each}>
                  <BarGenerator
                    data={barSubjectData[1]}
                    maxCite={maxCitation}
                    maxPap={maxPaper}
                  />
                  <p className={styles.chartName}>
                  {props.selectedLanguage =='EN' ? capitalizeFirstLetter(topSubjects[1].name): translateCN[topSubjects[1].name]}
                  </p>
                </div>
              )}
              {barSubjectData[2].citation.name !== "" && (
                <div className={styles.chartGroup_form_subject_table_each}>
                  <BarGenerator
                    data={barSubjectData[2]}
                    maxCite={maxCitation}
                    maxPap={maxPaper}
                  />
                  <p className={styles.chartName}>
                  {props.selectedLanguage =='EN' ? capitalizeFirstLetter(topSubjects[2].name): translateCN[topSubjects[2].name]}
                  </p>
                </div>
              )}
            </div>
            <div className={styles.groupDiv}>
              <div className={styles.citationParent}>
                <b className={styles.citation2}>{t('Citation')}</b>
                <div className={props.selectedLanguage==="CN"?styles.q1ParentCN:styles.q1Parent}>
                  <div className={styles.q1}>Q1</div>
                  <div className={styles.ellipseDiv} />
                </div>
                <div className={props.selectedLanguage==="CN"?styles.q3ParentCN:styles.q3Parent}>
                  <div className={styles.q1}>Q3&4</div>
                  <div className={styles.groupChild2} />
                </div>
                <div className={props.selectedLanguage==="CN"?styles.q2ParentCN:styles.q2Parent}>
                  <div className={styles.q1}>Q2</div>
                  <div className={styles.groupChild3} />
                </div>
              </div>
              <b className={props.selectedLanguage==="CN"?styles.paper2CN:styles.paper2}>{t('Paper')}</b>
              <div className={styles.q1Group}>
                <div className={styles.q1}>Q1</div>
                <div className={styles.groupChild4} />
              </div>
              <div className={styles.q3Group}>
                <div className={styles.q1}>Q3&4</div>
                <div className={styles.groupChild5} />
              </div>
              <div className={styles.q2Group}>
                <div className={styles.q1}>Q2</div>
                <div className={styles.groupChild6} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.chartGroup_form_topResearch}>
        <div className={styles.chartGroup_form_name_last_child}>
          <p>{t('Top_Research_Topics')}</p>
        </div>
        <div className={styles.chartGroup_form_topResearch_box}>
          <div className={styles.chartGroup_form_topResearch_infor}>
            {barTopicData[0].citation.name === "" &&
            barTopicData[1].citation.name === "" &&
            barTopicData[2].citation.name === "" &&
            barTopicData[3].citation.name === "" ? (
              <div style={{ marginTop: "1rem" }}>{""}</div>
            ) : (
              <div className={styles.chartGroup_form_subject_text}>
                <div className={styles.citationPaper2}>
                  <p className={styles.professor}>{t('Citation')}</p>
                  <p className={styles.paper}>{t('Paper')}</p>
                </div>
              </div>
            )}
            {barTopicData[0].citation.name !== "" && (
              <div className={styles.chartGroup_form_subject_table_each}>
                <SubjectBarGenerator
                  data={barTopicData[0]}
                  maxCite={maxTopicCitation}
                  maxPap={maxTopicPaper}
                />
                <p className={styles.chartTwoName}>
                  {props.selectedLanguage == 'EN' && capitalizeFirstLetter(topTopics[0].name)}
                  {props.selectedLanguage=='CN' &&labelCn !==0 && labelCn[0]}
                </p>
              </div>
            )}{" "}
            {barTopicData[1].citation.name !== "" && (
              <div className={styles.chartGroup_form_subject_table_each}>
                <SubjectBarGenerator
                  data={barTopicData[1]}
                  maxCite={maxTopicCitation}
                  maxPap={maxTopicPaper}
                />
                <p className={styles.chartTwoName}>
                {props.selectedLanguage == 'EN' && capitalizeFirstLetter(topTopics[1].name)}
                  {props.selectedLanguage=='CN' &&labelCn !==0 && labelCn[1]}                </p>
              </div>
            )}{" "}
            {barTopicData[2].citation.name !== "" && (
              <div className={styles.chartGroup_form_subject_table_each}>
                <SubjectBarGenerator
                  data={barTopicData[2]}
                  maxCite={maxTopicCitation}
                  maxPap={maxTopicPaper}
                />
                <p className={styles.chartTwoName}>
                {props.selectedLanguage == 'EN' && capitalizeFirstLetter(topTopics[2].name)}
                  {props.selectedLanguage=='CN' &&labelCn !==0 && labelCn[2]}                </p>
              </div>
            )}{" "}
            {barTopicData[3].citation.name !== "" && (
              <div className={styles.chartGroup_form_subject_table_each}>
                <SubjectBarGenerator
                  data={barTopicData[3]}
                  maxCite={maxTopicCitation}
                  maxPap={maxTopicPaper}
                />
                <p className={styles.chartTwoName}>
                {props.selectedLanguage == 'EN' && capitalizeFirstLetter(topTopics[3].name)}
                  {props.selectedLanguage=='CN' &&labelCn !==0 && labelCn[3]}                </p>
              </div>
            )}
          </div>
          <div className={styles.chartGroup_form_topResearch_infor}>
            {barTopicData[4].citation.name === "" &&
            barTopicData[5].citation.name === "" &&
            barTopicData[6].citation.name === "" ? (
              <div style={{ marginTop: "1rem" }}>{t('NA')}</div>
            ) : (
              <div className={styles.chartGroup_form_subject_text}>
                <div className={styles.citationPaper3}>
                  <p className={styles.professor}>{t('Citation')}</p>
                  <p className={styles.paper}>{t('Paper')}</p>
                </div>
              </div>
            )}

            {barTopicData[4].citation.name !== "" && (
              <div className={styles.chartGroup_form_subject_table_each}>
                <SubjectBarGenerator
                  data={barTopicData[4]}
                  maxCite={maxTopicCitation}
                  maxPap={maxTopicPaper}
                />
                <p className={styles.chartTwoName}>
                {props.selectedLanguage == 'EN' && capitalizeFirstLetter(topTopics[4].name)}
                  {props.selectedLanguage=='CN' &&labelCn !==0 && labelCn[4]}                </p>
              </div>
            )}
            {barTopicData[5].citation.name !== "" && (
              <div className={styles.chartGroup_form_subject_table_each}>
                <SubjectBarGenerator
                  data={barTopicData[5]}
                  maxCite={maxTopicCitation}
                  maxPap={maxTopicPaper}
                />
                <p className={styles.chartTwoName}>
                {props.selectedLanguage == 'EN' && capitalizeFirstLetter(topTopics[5].name)}
                  {props.selectedLanguage=='CN' &&labelCn !==0 && labelCn[5]}                </p>
              </div>
            )}
            {barTopicData[6].citation.name !== "" && (
              <div className={styles.chartGroup_form_subject_table_each}>
                <SubjectBarGenerator
                  data={barTopicData[6]}
                  maxCite={maxTopicCitation}
                  maxPap={maxTopicPaper}
                />
                <p className={styles.chartTwoName}>
                {props.selectedLanguage == 'EN' && capitalizeFirstLetter(topTopics[6].name)}
                  {props.selectedLanguage=='CN' &&labelCn !==0 && labelCn[6]}                </p>
              </div>
            )}
          </div>
          <div className={styles.groupDiv2}>
            <div className={styles.citationParent}>
              <b className={styles.citation2}>{t('Citation')}</b>
              <div className={props.selectedLanguage==="CN"?styles.q1ParentCN:styles.q1Parent}>
                  <div className={styles.q1}>Q1</div>
                  <div className={styles.ellipseDiv} />
                </div>
                <div className={props.selectedLanguage==="CN"?styles.q3ParentCN:styles.q3Parent}>
                  <div className={styles.q1}>Q3&4</div>
                  <div className={styles.groupChild2} />
                </div>
                <div className={props.selectedLanguage==="CN"?styles.q2ParentCN:styles.q2Parent}>
                  <div className={styles.q1}>Q2</div>
                  <div className={styles.groupChild3} />
                </div>
            </div>
             <b className={props.selectedLanguage==="CN"?styles.paper2CN:styles.paper2}>{t('Paper')}</b>
            <div className={styles.q1Group}>
              <div className={styles.q1}>Q1</div>
              <div className={styles.groupChild4} />
            </div>
            <div className={styles.q3Group}>
              <div className={styles.q1}>Q3&4</div>
              <div className={styles.groupChild5} />
            </div>
            <div className={styles.q2Group}>
              <div className={styles.q1}>Q2</div>
              <div className={styles.groupChild6} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.ofQ1PapersContainer}>
        <p className={styles.professor}>
          {t('hint1')}
        </p>
        <p className={styles.professor}>
          {t('hint2')}
        </p>
        <p
          className={styles.professor}
        >
          {t('hint3')}
        </p>
      </div>
    </div>
  );
};

export default AcademicMatrics;
