import React, { useState, useEffect } from "react";
import styles from '../collapsible.module.css'

import { ReactComponent as TabIcon } from './Vector 3.svg';
import { ReactComponent as TabIcon2 } from './Vector 5.svg';


import { fetchSuggestionTag } from "../Filters/fetch";


function  ResearchTopic(props) {
  const [tags, setTags] = useState(props.state);
  const [inputValue, setInputValue] = useState('');
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [dropdownTags, setDropdownTags] = useState([])
  const [isVisible, setIsVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false)

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const translationsCN = {
    'Nanotechnology':'纳米技术',
    'Face Recognition':'面部识别',
    'Reinforcement Learning': '强化学习',
    'Atlas':'阿尔塔斯（Atlas）',
    'Neural Network':'神经网络',
    'Internet of Things':'物联网',
  }

  const maxTags = 10;
  var l = 0;

  useEffect(() => {
    let message = true
    if(tags.length < maxTags){
      setErrorMessage(false)
      message = false
    }
    props.change(tags,message)
    }, [tags]);
  
  useEffect(() => {
    setTags(props.state);
  }, [props.state]);

  useEffect(() => {
    // Fetch suggestions when the input value changes
    if (inputValue.length > 0) {
      fetchSuggestionTag(inputValue).then((data) => {
        console.log(data.association_words); 
        const truncatedSuggestions = data.association_words.map((word) =>
        word.length > 50 ? word.slice(0, 50) + "..." : word
      );
        setSuggestedTags(data.association_words)
        setDropdownTags(truncatedSuggestions);
        setIsVisible(true)
      })
      .catch((error) => {
        console.error("Promise rejected:", error); 
      });
    
    } else {
      setSuggestedTags([]); // Clear suggestions if input is empty
    }
  }, [inputValue]);

  const countTags = () => {
    l = tags.length;
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const addDefault = (tag) => {
    if (tag.length > 1 && !tags.includes(tag)){
        if (tags.length < maxTags) {
            setTags([...tags, tag]);
            setInputValue(''); 
            setErrorMessage(false)
        }else{
          setErrorMessage(true)
        }
    }
  };

  const addTag = (e) => {
    if (e.key === "Enter") {
      const newTag = e.target.value.trim();
      if (newTag.length > 1 && !tags.includes(newTag)) {
        if (tags.length < maxTags) {
          if (suggestedTags.length === 0) {
            if (!tags.includes("N/A")) {
              setTags([...tags, "N/A"]);
            }
          } else {
            setTags([...tags, newTag]);
          }
          setInputValue('');
        }
      }
    }
  };
  
  const addPullDown = (index) => {
    if (suggestedTags[index] ) {
      const tag=suggestedTags[index]
      if (tag.length > 1 && !tags.includes(tag)) {
        if (tags.length < maxTags) {
          setTags([...tags, tag]);
          setErrorMessage(false)
        }else{
          setErrorMessage(true)
        }
      }
    }
    setInputValue('');
    setIsVisible(false);
  };  

  const clearAll = () => {
    setTags([]);
  };
  const getHighlightedText = (text, query) => {
    const startIndex = text.toLowerCase().indexOf(query.toLowerCase());
    if (startIndex === -1) {
      return text;
    }

    const endIndex = startIndex + query.length;
    return (
      <>
        {text.slice(0, startIndex)}
        <span className={styles.matched}>{text.slice(startIndex, endIndex)}</span>
        {text.slice(endIndex)}
      </>
    );
  };


  const translations = {
    EN: {
      Research_Topic: "Research Topic",
      clear: "Clear",
      Nanotechnology: 'Nanotechnology',
      Face_Recognition: 'Face Recognition',
      Reinforcement_Learning: 'Reinforcement Learning',
      Atlas: 'Atlas',
      Neural_Network: 'Neural Network',
      Internet_Of_Things: 'Internet Of Things',
      placeholder: 'Enter an institute/company name...',
      message:'No more than 10 research topics'
    },
    CN: {
      Research_Topic: "研究课题",
      clear: "删除",
      Nanotechnology: '纳米技术',
      Face_Recognition: '面部识别',
      Reinforcement_Learning: '强化学习',
      Atlas: '阿尔塔斯（Atlas）',
      Neural_Network: '神经网络',
      Internet_Of_Things: '物联网',
      placeholder: '输入研究机构/公司名称...',
      message:'至多输入10个研究课题'

      
    },
  // You can add more languages here
};
const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };

  return(      
    <div>
     
     <div className={styles.wrapper} id="researchtopic">
     
     {/* {props.state.length > 0 ? <div className={styles.Fieldline2}></div>: <></>} */}
        <div className={styles.non_details}>
            <div className={styles.title_checkbox}>
            {/* <img src="tag.svg" alt="icon"/> */}
            {/* <TabIcon2 className={styles.tabIcon2}/>
            <TabIcon className={styles.tabIcon}/> */}
                <h2>{t('Research_Topic')}</h2>
            </div>
            <div className={styles.non_title}>
              <div className={props.selectedLanguage === 'CN' ? styles.cncontent : styles.content}>
                <div className={styles.content_li}>
                  <ul>
                    {tags.map((tag, index) => (
                    <li key={index}> 
                      
                         {props.selectedLanguage === 'CN' && translationsCN[tag] ? translationsCN[tag] : tag}

                        <i onClick={() => removeTag(tag)}>
                        <span className={styles.close}>✖</span>
                        </i>
                    </li>
                    ))}
                    <input className={styles.enterNames}
                        placeholder={tags.length ? "" : t('placeholder')}
                        type="text"
                        spellcheck="false"
                        value = {inputValue}
                        onChange={handleInputChange}
                        // onKeyUp={addTag}
                    />
                  </ul>
                </div>
                <div>
                  <button onClick={clearAll}>
                    {t('clear')}
                    {/* <span className={styles.down_ward_triangle}>▼</span> */}
                  </button>
                  </div>
                </div>
            </div>
        </div>
        <div className={styles.details}>
            <ul>
                {['Nanotechnology', 'Face Recognition', 'Reinforcement Learning', 'Atlas','Neural Network', 'Internet of Things'].map(
                    (country) => (
                    <li onClick={() => addDefault(country)}>
                        <i>
                        <span className={styles.close}>+</span>
                        </i>
                        {props.selectedLanguage === 'CN' ? translationsCN[country] : country}
                    </li>
                    )
                )}
           </ul>
        </div>
    </div>
    {errorMessage && props.message && <div className={styles.errorMessage}>{t('message')}</div>}

    {suggestedTags && isVisible  && inputValue.length>0? (
      <ul className={styles.suggestions}>
        {dropdownTags.length === 0 && tags.length < maxTags && inputValue.length > 0 && (
          <li onClick={() => addPullDown(null)}>N/A</li>
        )}
        {dropdownTags.map((country, index) => (
          <li onClick={() => addPullDown(index)}>
            {getHighlightedText(country, inputValue)}
          </li>
        ))}
      </ul>    
    ) : (
      <div></div>
    )}

    </div> 
  );
};

export default ResearchTopic;