import React from "react";
import { ReactComponent as BackgroundImg } from "./assets/background.svg";
import styles from "./Wechat_welcome.module.css";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import placeholderImg from '../Home/language_icon.svg';


function WeChat_welcome(props){
    const navigate = useNavigate();
    
return(
    <div className={styles.container}>
        <div className={styles.LeftContainer}>
            <img src="/hero.png" alt="bgi" className={styles.BackgroundImg} />
        </div>
        <div className={styles.RightContainer}>
        <h2>Welcome to ZenithScholars</h2>
        <h3>You have successfully signed up.</h3>
         <img src="/QR_welcome.png" alt="bgi" className={styles.QRIcon} />
         <img src="/wechat_checked.png" alt="bgi" className={styles.checkedIcon} />

        </div>
    </div>
)
}
export default WeChat_welcome;