import React from "react";
import {
  BarChart,
  Bar,
  YAxis,
  LabelList
} from "recharts";

// const data1 = [
//   {
//     name: "Page A",
//     Q1: 4000,
//     Q2: 2400,
//     Q3: 2400
//   },
//   {
//     name: "Page B",
//     Q1: 3000,
//     Q2: 18,
//     Q3: 2210
//   },
//   {
//     name: "Page C",
//     Q1: 2000,
//     Q2: 100,
//     Q3: 2290
//   }
// ];


// const data2 = [
//     {
//       name: "Page A",
//       Q1: -17,
//       Q2: -20,
//       Q3: -40
//     },
    // {
    //   name: "Page B",
    //   Q1: -30,
    //   Q2: -18,
    //   Q3: -10
    // },
    // {
    //   name: "Page C",
    //   Q1: -2,
    //   Q2: -3,
    //   Q3: -5
    // }
  // ];

export default function SubjectBarGenerator(props) {
  const data1 = [props.data.citation]
  const data2 = [props.data.paper]
  // console.log(data1)
  // console.log(data2)
  return (
    <div>
    <BarChart
      width={190}
      height={150}
      data={data1}
      margin={{
        top: -5,
        right:0,
        left: 0,
        bottom: 6
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" /> */}
      <YAxis
        domain={[0, props.maxCite * 1.2]} // Set the YAxis domain to [0, maxValue]
        hide // Hide the Y-axis
      />
      {/* <Tooltip />
      <Legend /> */}
       <Bar dataKey="Q1" fill="#377DED" barSize={100}>
        {/* Using LabelList component to render the top labels */}
        <LabelList
          dataKey="Q1"
          position="top"
          formatter={(value) => Math.abs(value)}
          fontSize={18}
        />
      </Bar>
    
      <Bar dataKey="Q2" fill="#94D8FF" barSize={100}>
        {/* Using LabelList component to render the top labels */}
        <LabelList
          dataKey="Q2"
          position="top"
          formatter={(value) => Math.abs(value)}
          fontSize={18}
        />
      </Bar>
      <Bar dataKey="Q3&4" fill="#DDF6FD" barSize={100}>
        {/* Using LabelList component to render the top labels */}
        <LabelList
          dataKey="Q3&4"
          position="top"
          formatter={(value) => Math.abs(value)}
          fontSize={18}
        />
      </Bar>
     
    </BarChart>
    <BarChart
      width={190}
      height={90}
      data={data2}
      margin={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" /> */}
      <YAxis
        domain={[props.maxPap * 5, 0]} // Set the YAxis domain to [0, maxValue]
        hide // Hide the Y-axis
      />
      {/* <Tooltip />
      <Legend /> */}
        <Bar dataKey="Q1" fill="#5C5D5F" barSize={100}>
        {/* Using LabelList component to render the top labels */}
        <LabelList
          dataKey="Q1"
          position="top"
          formatter={(value) => Math.abs(value)}
          fontSize={18}
        />
      </Bar>
    
      <Bar dataKey="Q2" fill="#AEAFB1" barSize={100}>
        {/* Using LabelList component to render the top labels */}
        <LabelList
          dataKey="Q2"
          position="top"
          formatter={(value) => Math.abs(value)}
          fontSize={18}
        />
      </Bar>
      <Bar dataKey="Q3&4" fill="#E8E8E8" barSize={100}>
        {/* Using LabelList component to render the top labels */}
        <LabelList
          dataKey="Q3&4"
          position="top"
          formatter={(value) => Math.abs(value)}
          fontSize={18}
        />
      </Bar>
    
    </BarChart>

    
    </div>
  );
}
