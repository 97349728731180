import './App.css';
import { useEffect, useState } from "react";
import { Routes, Route, useLocation, Link, Navigate} from 'react-router-dom';

import Home from './component/Home/Home';
import LandingPage from './component/LandingPage/LandingPage';
import ResumeWrapper from './component/ResumePage/ResumeWrapper';
import WelcomePage from './component/LoginPage/welcome';
import SignInPage from './component/LoginPage/signIn';
import SignUpPage from './component/LoginPage/signUp';
import LinkSentPage from './component/LoginPage/linkSent';
import SuccessPage from './component/LoginPage/success';
import SuccessPWPage from './component/LoginPage/successPW';
import ForgetPWPage from './component/LoginPage/forgetPassword';
import NewPWPage from './component/LoginPage/newPassword';
import LogedHome from './component/Home/LogedHome';
import LinkSentPWPage from './component/LoginPage/linkSentPW';
import TermOfUse from './component/LoginPage/termOfUse';
import Privacy from  './component/LoginPage/privacy';
import FailPage from './component/LoginPage/fail';
import Profile from './component/Home/profile';
import WeChat_login from './component/LoginPage/WeChat.jsx';
import WeChat_QR from './component/LoginPage/WechatQRcode.jsx';
import WeChat_welcome from './component/LoginPage/Wechat_welcome';
import WeChat_form from './component/LoginPage/wechat_form';
import WeChat_logedIn from './component/LoginPage/Wechatlogin_successfully';
import PrivateRoute from './component/utils/PrivateRoute';
import PrivateRoute2 from './component/utils/PrivateRouter2.js';
import PrivateRoute3 from './component/utils/PrivateRouter3.js';
import BlockAccount from './component/LoginPage/blockAccount';
import LoginWelcome from './component/LoginPage/loginWelcome';
import Opt_out from './component/LoginPage/optout';
import Optout from './component/LoginPage/optpout2.jsx';
import CookieNotice from './component/utils/cookieNotice'
import Cookie from './component/LoginPage/cookie.jsx';
import { fetchEmail } from './component/Home/fetch';
import ResumeDownload from './component/Download/ResumeDownload.jsx';
import DownloadPdf from './component/Download/PDFDownload.jsx';
import HelperPage from './component/Home/helperPage.jsx';
import GptTranslate from './component/utils/GptTest.jsx';

const App = () => {
  const [resumeData, setResumeData] = useState([]);
  
  function setData(resumesResponsed){
    // console.log(resumesResponsed);
    setResumeData(resumesResponsed['resumes']);
  }
  useEffect(() => {
    // console.log('resumes Data changed:', resumeData);
  }, [resumeData]); 
  const [email, setEmail] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("EN");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();


   // const [auth,setAuth] = useState(false); // determine if authorized, from context or however you're doing it

  // useEffect(()=>{
  //   console.log('call use effect')
  //   fetchEmail().then((data) => {
  //     console.log("login status", data);
  //     if (data.email){
  //       setIsAuthenticated(true);
  //       console.log('set true')
  //     }
  //     else{
  //       setIsAuthenticated(false)
  //       console.log('set false')
  //     }
  // })
  // .catch((error) => {
  //     console.error("Promise rejected:", error);
  // });
  // },[]);

  return (
        <div>
            <CookieNotice selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage} />
            <Routes>
                <Route path="/" element={<Navigate to="/landing" />} />

                <Route path="/landing" element={<LandingPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                {/* <Route path="/landing" element={<LandingPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} /> */}
                <Route path="landing/:sourcing" element={<LandingPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />

                {/* <Route path="/home" element={<Home set = {setData} setEmail={setEmail} email={email} selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} /> */}
                {/* <PrivateRoute
               path="/home"
               element={<Home set={setData} setEmail={setEmail} email={email} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}
               isAuthenticated={isAuthenticated} // pass the isAuthenticated value
             /> */}

                <Route exact path='/home' element={<PrivateRoute />}>
                    <Route exact path='/home'
                           element={<Home set={setData} setEmail={setEmail} email={email} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}
                    />
                </Route>

                <Route exact path='/home/:filter' element={<PrivateRoute />}>
                    <Route exact path='/home/:filter'
                           element={<Home set={setData} setEmail={setEmail} email={email} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}
                    />
                </Route>

                <Route exact='/profile' element={<PrivateRoute />}>
                    <Route exact path="/profile" element={<Profile selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                </Route>

                <Route exact='/resume/:id' element={<PrivateRoute2 />}>
                    <Route path="/resume/:id/:userid" element={<ResumeWrapper mydata={resumeData}/>} />
                </Route>

                <Route exact='/resume/download/:id' element={<PrivateRoute3 />}>
                    <Route path="/resume/download/:id/:userid" element={<ResumeDownload mydata={resumeData}/>} />
                </Route>
                <Route path="/welcome" element={<WelcomePage setEmail={setEmail} selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/login" element={<SignInPage setEmail={setEmail} selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/login/:sourcing" element={<SignInPage setEmail={setEmail} selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/loginwelcome" element={<LoginWelcome setEmail={setEmail} selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/loginwelcome/:sourcing" element={<LoginWelcome setEmail={setEmail} selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />

                <Route path="/signUp" element={<SignUpPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/signUp/:sourcing" element={<SignUpPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/linkSent" element={<LinkSentPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/success" element={<SuccessPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/successPW" element={<SuccessPWPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/forgetPW" element={<ForgetPWPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/change-password/:id/:token" element={<NewPWPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                {/* <Route path="/logedHome" element={<LogedHome selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} /> */}
                <Route path="/linkSentPW" element={<LinkSentPWPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/termOfUse" element={<TermOfUse selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/privacy" element={<Privacy selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                {/* <Route path="/optout" element={<Opt_out selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} /> */}
                <Route path="/optout" element={<Optout selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/cookie" element={<Cookie selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />

                <Route path="/helperPage" element={<HelperPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />

                <Route path="/fail/:email" element={<FailPage selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/wechat" element={<WeChat_login selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/wechatQR" element={<WeChat_QR selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/wechatWelcome" element={<WeChat_welcome selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/wechatForm" element={<WeChat_form selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/wechatLogedIn" element={<WeChat_logedIn selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />
                <Route path="/blockAccount" element={<BlockAccount selectedLanguage = {selectedLanguage} setSelectedLanguage = {setSelectedLanguage}/>} />

                <Route path='/gpt' element={<GptTranslate />}></Route>
            </Routes>
        </div>
        
    
    
  );
}

export default App;
