import React, { useState, useEffect } from "react";
import styles from '../GeneralFIlter.module.css';
import { fetchSuggestionVenue } from "../../Filters/fetch";

function PublishedonComponent(props) {
  const [tags, setTags] = useState(props.state);
  const [inputValue, setInputValue] = useState('');
  const [suggestedVenues, setSuggestedVenues] = useState([]);
  const [dropdownVenues, setDropdownVenues] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [tagLength, setTagLength] = useState(0)

  const [isVisible, setIsVisible] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const translationsCN = {
      'ICVR':"ICVR 国际计算机视觉会议",
      'AAAI':"AAAI 人工智能与人工智能应用大会",
      'JAMA':"JAMA 美国医学会杂志",
      'ICCV':"ICCV 国际计算机视觉大会",
      'Nature':"Nature 《自然》杂志",
  }

  const maxTags = 10;
  var l = 0;

  useEffect(() => {
    let message = true
    if(tags.length < maxTags){
      setErrorMessage(false)
      message = false
    }
    props.change(tags,message)
  }, [tags,tagLength]);

  useEffect(() => {
    setTags(props.state);
  }, [props.state]);

  useEffect(() => {
    // Fetch suggestions when the input value changes
    if (inputValue.length > 0) {
      fetchSuggestionVenue(inputValue).then((data) => {
        console.log(data.association_words); 
        const truncatedSuggestions = data.association_words.map((word) =>
        word.length > 50 ? word.slice(0, 50) + "..." : word
      );
        setSuggestedVenues(data.association_words)
        setDropdownVenues(truncatedSuggestions); 
        setIsVisible(true)
      })
      .catch((error) => {
        console.error("Promise rejected:", error); 
      });
    
    } else {
      setSuggestedVenues([]); // Clear suggestions if input is empty
    }
  }, [inputValue]);


  const countTags = () => {
    l = tags.length;
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const addDefault = (tag) => {
    if (tag.length > 1 && !tags.includes(tag)){
        if (tags.length < maxTags) {
            setTags([...tags, tag]);
            setInputValue(''); 
            setErrorMessage(false)
        }else{
          setErrorMessage(true)
        }
        setTagLength(tagLength+1)
    }
  };

  const addTag = (e) => {
    // if(e.key === "Enter") {
    //   const newTag = e.target.value.trim();
    //   if(newTag.length > 1 && !tags.includes(newTag)) {
    //     if(tags.length < maxTags) {
    //       setTags([...tags, newTag]);
    //       setInputValue(''); 
    //     }
    //   }
    // }
  };

  const addPullDown = (index) => {
    if (suggestedVenues[index]) {
      const tag=suggestedVenues[index]
      if (tag.length > 1 && !tags.includes(tag)) {
        if (tags.length < maxTags) {
          setTags([...tags, tag]);
          setErrorMessage(false)
        }else{
          setErrorMessage(true)
        }
        setTagLength(tagLength+1)

      }
    }
    setInputValue('');
    setIsVisible(false);
  }; 

  const clearAll = () => {
    setTags([]);
  };

  const getHighlightedText = (text, query) => {
    const startIndex = text.toLowerCase().indexOf(query.toLowerCase());
    if (startIndex === -1) {
      return text;
    }

    const endIndex = startIndex + query.length;
    return (
      <>
        {text.slice(0, startIndex)}
        <span className={styles.matched}>{text.slice(startIndex, endIndex)}</span>
        {text.slice(endIndex)}
      </>
    );
  };

  const translations = {
    EN: {
      publish:"Published on",
      clear:"Clear",
      enter:"Enter journal/conference processing...",
      message:'No more than 10 journel/conference processings'
    },
    CN: {
      publish:"发表于",
      clear:"删除",
      enter:"输入期刊/会议名称...",
      message:"至多输入10个期刊/会议名称"
    },
    // You can add more languages here
  };

  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };

  return (
    <div>
    <div className={styles.wrapper} id="publishedon">
      <div className={styles.non_details}>
        <div className={styles.title_pub}>
         <h2>{t('publish')}</h2>
        </div>
        <div className={styles.nontitle}>
          <div className={props.selectedLanguage === 'CN' ? styles.contentCN:styles.content}>
            <div className={styles.content_li}>
              <ul>
                {tags.map((tag, index) => (
                  <li key={index}> 
                     {props.selectedLanguage === 'CN' && translationsCN[tag] ? translationsCN[tag] : tag}
                    <i onClick={() => removeTag(tag)}>
                      <span className={styles.close}>✖</span>
                    </i>
                  </li>
                ))}
                <input className={styles.enterNames}
                placeholder={tags.length ? "" : t('enter')}
                type="text"
                spellcheck="false"
                value = {inputValue}
                onChange={handleInputChange}
                onKeyUp={addTag}
                />
              </ul>
            </div>
            <div>
              <button onClick={clearAll} className={props.selectedLanguage==='CN'?styles.ClearTriCN1:styles.ClearTri}>
                {t('clear')}
                {/* <span className={styles.down_ward_triangle}>▼</span> */}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.details}>
        <ul>
          {['ICVR', 'AAAI', 'JAMA', 'ICCV', 'Nature'].map(
            (country) => (
              <li onClick={() => addDefault(country)}>
                <i>
                  <span className={styles.close}>+</span>
                </i>
                {props.selectedLanguage === 'CN' ? translationsCN[country] : country}
              </li>
            )
          )}
        </ul>
      </div>
      
    </div>
    {errorMessage && props.message && <div className={props.selectedLanguage == 'EN'?styles.errorMessage2:styles.errorMessageCN}>{t('message')}</div>}

    {suggestedVenues && isVisible && inputValue.length >0 ? (
      <ul className={styles.suggestions}>
        {dropdownVenues.length === 0 && tags.length < maxTags && inputValue.length > 0 && (
          <li onClick={() => addPullDown(null)}>N/A</li>
        )}
        {dropdownVenues.map((country, index) => (
          <li onClick={() => addPullDown(index)}>
            {getHighlightedText(country, inputValue)}
          </li>
        ))}
      </ul>    
    ) : (
      <div></div>
    )}

    </div>
  );
};

export default PublishedonComponent;