const fetchSuggestionLocation = (inputValue) => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/search/association", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ type: "city", content: inputValue })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          reject(error);
        });
    });
  }; 
  
  const fetchSuggestionPosition = (inputValue) => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/search/association", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ type: "position", content: inputValue })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          reject(error);
        });
    });
  }; 

  
  const fetchSuggestionEmployer = (inputValue) => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/search/association", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ type: "employer", content: inputValue })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          reject(error);
        });
    });
  }; 


  const fetchSuggestionVenue = (inputValue) => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/search/association", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ type: "venue", content: inputValue })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          reject(error);
        });
    });
  }; 
  
  const fetchSuggestionTag = (inputValue) => {
    return new Promise((resolve, reject) => {
      fetch("https://intelliprozenithscholars.com/search/association", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ type: "tags", content: inputValue })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed with status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          reject(error);
        });
    });
  }; 
  


  export{
    fetchSuggestionLocation,
    fetchSuggestionPosition,
    fetchSuggestionEmployer,
    fetchSuggestionVenue,
    fetchSuggestionTag
  }