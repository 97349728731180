import "../../App.css";
import { useEffect, useState,useRef } from "react";
import headshot from "./headshot.svg";
import hint from "./hint.svg";
import IntelliProIcon from "../IntelliProIcon/IntelliProIcon";
// import styles from "./profile.module.css";
import styles from "./logedhome.module.css";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import React from "react";
import {fetchEmail, fetchProfile, getToken} from './fetch'


import Select from "react-select";
import placeholderImg from './language_icon.svg';
import { ReactComponent as CopyIcon } from "./copy.svg";
import { ReactComponent as SuccessIcon} from "./Success.svg";
import { ReactComponent as HoverCopyIcon }from './copyHover.svg'; // Hover state copy icon
import { ReactComponent as ActiveCopyIcon}from './copy_pressed.svg'; // Active (pressed) state copy icon
import checkicon from "./check.png";

function ProfileSetting (props) {
    const navigate = useNavigate();
    const [page, setPage] = useState('showprofile');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNew, setConfirmNew] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('1');
    const [initial,setInitial] = useState('')
    const [company, setCompany] = useState('1');
    const [jobTitle, setJobTitle] = useState('1');
    const [showPw1, setShowPw1] = useState(false);
    const [showPw2, setShowPw2] = useState(false);
    const [showPw3, setShowPw3] = useState(false);
    const [errMsg1, setErrMsg1] = useState('');
    const [errMsg2, setErrMsg2] = useState('');
    const [errMsg3, setErrMsg3] = useState('');
    const [errMsg4, setErrMsg4] = useState('');

    const [errMsg1CN, setErrMsg1CN] = useState('');
    const [errMsg2CN, setErrMsg2CN] = useState('');
    const [errMsg3CN, setErrMsg3CN] = useState('');
    const [errMsg4CN, setErrMsg4CN] = useState('');

    const [linkCopied, setLinkCopied] = useState(false);
    const [referralLink, setReferralLink] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false); // Add this state to track modal status
    const [isOutModalOpen, setIsOutModalOpen] = useState(false); // Add this state to track modal status
    const [isHintModalOpen, setIsHintModalOpen] = useState(false); // Add this state to track modal status
    const [isHovered, setIsHovered] = useState(false);//for Copy Icon
    const [isActive, setIsActive] = useState(false);//for Copy Icon
    const [isLoggedIn, setIsLoggedIn] = useState(true); // Add this state to track login status
    const [allEmpty,setAllEmpty] = useState(false);

    const [showPasswordChangeSuccessPopup, setShowPasswordChangeSuccessPopup] = useState(false);

    

    // const PlaceholderImage = () => (
    //     <div style={{display: 'flex', alignItems: 'center', color: "black", fontSize:"22px", marginLeft:"2px" }}>
    //       <img src={placeholderImg} alt="Placeholder" style={{  height: '33px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px'}} />
    //       <span className={styles.langp}>EN</span>
    //     </div>
    //   );

    const PlaceholderImageEN = () => (
        <div style={{display: 'flex', alignItems: 'center', marginLeft:"2px"}}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '28px', height: '28px',  marginBottom: '0px', marginRight: '10px', marginLeft: '12px' }} />
            <span style={{fontSize:'19px'}}>EN</span>
        </div>
      );
    
      const PlaceholderImageCN = () => (
        <div style={{display: 'flex', alignItems: 'center', marginLeft:"2px"}}>
            <img src={placeholderImg} alt="Placeholder" style={{ width: '28px', height: '28px',  marginBottom: '0px', marginRight: '10px', marginLeft: '12px' }} />
            <span style={{fontSize:'19px'}}>CN</span>

        </div>
      );
    const editProfilePage = () => {
        setPage('editprofile')
        
    }
    const showProfilePage = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNew('');
        // setFullName();
        // setCompany('');
        // setJobTitle('');
        setErrMsg1("")
        setErrMsg2("")
        setErrMsg3("")
        setErrMsg4("")
        setErrMsg1CN("")
        setErrMsg2CN("")
        setErrMsg3CN("")
        setErrMsg4CN("")
        fetchProfile().then((data) => {
            console.log("login status", data);
            setEmail(data.email);
            setFullName(data.name);
            // const initials = data.name.split(' ').map((n) => n[0]).join('').toUpperCase();
            // setInitial(initials)
            setCompany(data.company);
            setJobTitle(data.title);
            fetchReferralLink(data.email);
            
        })
        .catch((error) => {
            console.error("Promise rejected:", error);
        });
        setPage('showprofile')

    }

    const changePasswordPage = () => {
        setPage('changepassword')
        
       
    }
    const handleChange = (e) => {
        // console.log(e.target.name)

        if (e.target.name === "oldPw"){
            setOldPassword(e.target.value)
        }
        else if (e.target.name === "newPw"){
            setNewPassword(e.target.value)
        }
        else if (e.target.name === "confirm" ) {
            setConfirmNew(e.target.value)
        }
        else if (e.target.name === "fullName" ) {
            setFullName(e.target.value)
        }
        else if (e.target.name === "company" ) {
            setCompany(e.target.value)
        }
        else if (e.target.name === "jobTitle" ) {
            setJobTitle(e.target.value)
        }
        
    }
    const submit = async() => {

        if(oldPassword !==""){
            setErrMsg1("")
            setErrMsg1CN("")

        }
        if(newPassword !==""){
            setErrMsg2("")
            setErrMsg2CN("")

        }
        if (confirmNew!==""){
            setErrMsg3("")
            setErrMsg3CN("")

        }
        if(oldPassword ==="" || newPassword ==="" || confirmNew===""){
            if(oldPassword ===""){
                setErrMsg1("Please fill in the blank!")
                setErrMsg1CN("请输入字段")

            }
            if(newPassword ===""){
                setErrMsg2("Please fill in the blank!")
                setErrMsg2CN("请输入字段")

            }
            if (confirmNew===""){
                setErrMsg3("Please fill in the blank!")
                setErrMsg3CN("请输入字段")

            }
            return
        }else if (newPassword.length<8){
            setErrMsg2("Password must be at least 8 characters")
            setErrMsg2CN("密码必须至少8个字符")  
            return 
        }
        else if(newPassword !== confirmNew){
            setErrMsg2("Password does not match!")
            setErrMsg3("Password does not match!")
            setErrMsg2CN("密码不一致")
            setErrMsg3CN("密码不一致")       
            return
        }
        else{
            // console.log(oldPassword)
            // console.log(newPassword)
            // console.log(confirmNew)
            try {
          const tokenData = await getToken;
          const token = tokenData.csrfToken;
    
          const response = await fetch("https://intelliprozenithscholars.com/auth/changePWDInLogin/", {
            method: "POST",
            headers: {
              "X-CSRFToken": token,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            credentials: "include",
            // body: formData.toString()
            body: JSON.stringify({
              old_password: oldPassword,
              password: newPassword,
              port: '3001'
            }),
          });
          const data = await response.json();
          
        //   console.log(data)
          if (data.message == "successfully reset password") {
            
            setIsLoggedIn(true); // Set login status to false
            Cookies.remove('user_id');
            setShowPasswordChangeSuccessPopup(true)
            // history.push( {
            //   pathname: '/resend-link',
            //   state: { formData }});
          } else {
            // setError( data.message ||'Registration failed.');
            if(data.message =='wrong password'){
                setErrMsg1("Wrong old password!")
                setErrMsg1CN("旧密码错误")
            }else{
                setErrMsg2("Same as the old password!")
                setErrMsg3("Same as the old password!")
                setErrMsg2CN("与旧密码相同")
                setErrMsg3CN("与旧密码相同")
            }
              
          }
        } catch (error) {
          setErrMsg1("An error occurred. Please try again later.");
        }
            // setErrMsg4("Your new password has been changed")
        }
        
    }


    

    const fetchReferralLink = async (email) => {
        try {
          const response = await fetch(`https://intelliprozenithscholars.com/auth/get_referral_link/?email=${email}`);
          if (response.ok) {
            const data = await response.json();
            if (data.status === 'success') {
              setReferralLink('https://paradx.net/dkdh_referral?'+data.referral_link);
            } else {
              console.error('Error fetching referral link:', data.status);
            }
          } else {
            console.error('Error fetching referral link:', response.status);
          }
        } catch (error) {
          console.error('Error fetching referral link:', error);
        }
      };    
    useEffect(()=>{
        fetchProfile().then((data) => {
          console.log("login status", data);
          setEmail(data.email);
          setFullName(data.name);
          const initials = data.name.split(' ').map((n) => n[0]).join('').toUpperCase();
          setInitial(initials)
          setCompany(data.company);
          setJobTitle(data.title);
          fetchReferralLink(data.email);
          
      })
      .catch((error) => {
          console.error("Promise rejected:", error);
      });
      },[email]);

    
    // const submitProfile = () => {
    //     console.log('full:',fullName)
    //     console.log('comp:', company)
    //     console.log('job:',jobTitle)
    // }

    const submitProfile = async (e) => {
        e.preventDefault();
        console.log(email)
        console.log(fullName)
        console.log(company)
        console.log(jobTitle)

        setErrMsg1("")
        setErrMsg2("")
        setErrMsg3("")
        setErrMsg4("")
        setErrMsg1CN("")
        setErrMsg2CN("")
        setErrMsg3CN("")
        setErrMsg4CN("")
        
        if((/[^a-zA-Z\s]/.test(fullName)) || (company.length > 40) || (/[^a-zA-Z\s0-9\-.,]/.test(company)) || (jobTitle.length > 25) ||(/[^a-zA-Z\s0-9\-.,]/.test(jobTitle))){
            if (/[^a-zA-Z\s]/.test(fullName)) {
                setErrMsg1('Invalid Name')
                setErrMsg1CN('姓名格式不正确')
            
            } 
            if (company.length > 40) {
                setErrMsg2("The company's name must not exceed 40 characters")
                setErrMsg2CN('公司名称长度不能超过40个字符')
                
               
              // The judging conditions of valid company name may be modified later
              } 
              if (/[^a-zA-Z\s0-9\-.,]/.test(company)) {
    
                setErrMsg2('Invalid Company Name')
                setErrMsg2CN('公司名称格式不正确')
            
            
              } 
              if (jobTitle.length > 25) {
    
                setErrMsg3("The job title must not exceed 25 characters")
                setErrMsg3CN('职位名称长度不能超过25个字符')
                   
              // The judging conditions of valid job title may be modified later
              } 
              if (/[^a-zA-Z\s0-9\-.,]/.test(jobTitle)) {
                setErrMsg3('Invalid Job Title')
                setErrMsg3CN('职位名称格式不正确')  
              }

              return
           
        }

       
        try {
          const tokenData = await getToken;
          const token = tokenData.csrfToken;
    
          const response = await fetch("https://intelliprozenithscholars.com/auth/user_info_update/", {
            method: "POST",
            headers: {
              "X-CSRFToken": token,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            credentials: "include",
            // body: formData.toString()
            body: JSON.stringify({
              email: email,
              new_name:fullName,
              new_company:company,
              new_title:jobTitle,
              port: '3001'
            }),
          });
          const data = await response.json();
    
          if (data.message == "Successfully updated profile information") {
            
            setPage('showprofile')
             const initials = fullName.split(' ').map((n) => n[0]).join('').toUpperCase();
             setInitial(initials)

            // history.push( {
            //   pathname: '/resend-link',
            //   state: { formData }});
          } else {
            // setError( data.message ||'Registration failed.');
          }
        } catch (error) {
          setErrMsg1("An error occurred. Please try again later.");
        }
      };
    const toggleHintModal = () => {
        setIsHintModalOpen(!isHintModalOpen);
    }
    const toggleModal = () => {
        console.log('togle !!!!!!!!!!!!')
        setIsModalOpen(!isModalOpen);
    }
    const toggleoutModal = () => {
        setIsOutModalOpen(!isOutModalOpen);
    }
    const options2 = [
        { value: 'EN', label: 'English' },
        { value: 'CN', label: '中文(简体)' },
      ]
    const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage); // Set default language

    const handleSelectChange2 = (selectedOption) => {
        setSelectedLanguage(selectedOption.value); // Update
        props.setSelectedLanguage(selectedOption.value); 
        if (selectedOption.value === 'EN') {
            // Language is set to English
        } else if (selectedOption.value === 'CN') {
            // Language is set to Chinese
        }
    }
    const SelectedOptionWithSVG = ({ label }) => (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-25px' }}>
          <img src={placeholderImg} alt="Placeholder" style={{   height: '26px', marginBottom: '0px', marginRight: '10px', marginLeft: '12px',paddingTop:"7px"}} />
          {label==='English' ? (
            <span className={styles.langp}>EN</span>
          ) : (
            <span className={styles.langp}>CN</span>
          )}
        </div>
      );
    const customSelect2 = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: 'Sofia Pro',
            fontSize: 16,
            color: '#377DED',
            background: 'transparent',
            outline: 'none',
            border: 'none', 
            boxShadow: 'none', 
            minWidth: '130px',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display:'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            transition: 'color 0.2s',
            color: '#377DED',
            fontSize: state.isFocused ? '18px' : '16px',
            position: 'relative',
            "&:hover": {
            color: "#1052BC",
            fontSize: 18,
            //borderBottom: "2px solid #2149B1",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: 'Sofia Pro',
            fontSize: 16,
            color: state.isSelected ? '#FFFFFF' : '#377DED',
            cursor: 'pointer',
            textAlign: 'left',
            "&:active": {
            color: '#FFFFFF',
            background: '#377DED',
            },
            "&:focus": {
            outline: 'none',
            },
            display: 'flex',
            marginTop:'8px',
            marginBottom:'8px'
        }),
        menu: (provided) => ({
            ...provided,
            border: 'none',
            borderRadius: '10px',
            boxShadow: 'none',
            marginTop: 5,
            position: 'absolute',
        }),
    };
    // Create a reference to the modal content to close the reffreal link modal if clicked outside
    const modalContentRef = useRef(null);
    const modalOutContentRef = useRef(null);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleClick = () => {
        copyLink();
        setIsActive(true);
    };
    const copyLink = () => {
        navigator.clipboard.writeText(referralLink);
        console.log("Referral link copied to clipboard");
        setLinkCopied(true); // Set linkCopied to true when the link is copied
    };
    const toggleLoggout = () => {
        if (isLoggedIn) {
          handleLogout();
        } else {
          handleLogin();
        }
    };
    // Function to handle logout
    const handleLogout = () => {
        // Logic to logout user goes here   
        setIsLoggedIn(false); // Set login status to false
        Cookies.remove('user_id');
        Cookies.remove('sessionid');
        Cookies.remove('csrftoken');
        toggleoutModal();
  
        console.log("User logged out successfully");
        // Redirect to the home page
        navigate('/login');
    };
    const handleLogin = () => {
        // Logic to authenticate user goes here
       
        setIsLoggedIn(!isLoggedIn); // Set login status to true
        console.log("User logged in successfully");
          // Navigate to the new URL
        // navigate('/logedHome');
    };
    const handleClickOutside = (e) => {
        if (isModalOpen && modalContentRef.current && !modalContentRef.current.contains(e.target)) {
          toggleModal();
          setLinkCopied(false);
          setIsHovered(false);
          setIsActive(false);
        }
        if (isOutModalOpen && modalOutContentRef.current && !modalOutContentRef.current.contains(e.target)) {
          toggleoutModal();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
        // Cleanup function to remove the event listener when the component is unmounted
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isModalOpen]);
    
      // Add an event listener to the document when the modal is open
    useEffect(() => {
        if (isOutModalOpen) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
        // Cleanup function to remove the event listener when the component is unmounted
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOutModalOpen]);
    // <button className={styles.homeButton} onClick={e => navigate('/')} >
    //     <img src="/homeIcon.svg" className={styles.homeIcon} alt="homeIcon" />
    // </button>
         
    
    return (
        <>
            <div className={styles.topbar}>
                {/* <button onClick={handleLogin}>Login</button> */}
                {props.selectedLanguage =='EN' && <div className={styles.area}>
              {/* LogoutModal */}
              {isOutModalOpen && (
                  <div className={styles.profileMenu} ref={modalOutContentRef}>
                      <div  className={styles.settingmodal} onClick={e=>navigate('/profile')}>
                        <h3>Settings</h3>
                      </div>

                      <div className={`${styles.Logoutmodal} ${isHovered ? styles.logoutModalHover : ''} ${isActive ? styles.logoutModalClick : ''}`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={toggleLoggout}
                      >
                        <h3>Log out</h3>
                      </div>
                  </div>
              )} 
              <div className={styles.hint_modal}>
                  {isHintModalOpen && (
                      <div className={styles.Hintmodal}>
                        
                        “Click to obtain your exclusive referral link！”
                        
                        <h4>
                        For inquiries regarding the collection and utilization of personal data, please refer to our Privacy Policy and Terms of Use.

                        </h4>
                      </div>
                  )}
              </div>
              {/* <referralLinkModal/> */}
              <div className={styles.refer_modal}>
                      {isModalOpen && (
                        <div className={linkCopied ? styles.modalSmall : styles.modalNormal}>
                            <div className={styles.modalContent} ref={modalContentRef}>
                            <div className={styles.linkContainer}>
                                {linkCopied ? (
                                <>
                                    <div className={styles.successMessageContainer}>
                                      <div className={styles.successMessage}>Link Copied Successfully!</div>
                                      <SuccessIcon className={styles.successIcon} /> {/* Replace with the actual success icon */}
                                    </div>
                                    
                                </>
                                ) : (
                                <>
                                  <a href={referralLink} target="_blank" rel="noopener noreferrer" className={styles.referralLink}>{referralLink}</a>
                                    <div
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                      >
                                        {isHovered ? <HoverCopyIcon /> : isActive ? <ActiveCopyIcon /> : <CopyIcon />}
                                        
                                      </div>
                                </>
                                )}
                            </div>
                            </div>
                        </div>
                    )}
                
              </div>
          
          </div>}
          {props.selectedLanguage =='CN' && <div className={styles.area}>
              {/* LogoutModal */}
              {isOutModalOpen && (
                  <div className={styles.profileMenu1} ref={modalOutContentRef}>
                      <div  className={styles.settingmodal} onClick={e=>navigate('/profile')}>
                        <h3>设置</h3>
                      </div>

                      <div className={`${styles.Logoutmodal} ${isHovered ? styles.logoutModalHover : ''} ${isActive ? styles.logoutModalClick : ''}`}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={toggleLoggout}
                      >
                        <h3>退出登录</h3>
                      </div>
                  </div>
              )} 
              <div className={styles.hint_modal}>
                  {isHintModalOpen && (
                      <div className={styles.Hintmodal}>
                        
                        “点击获取您的专属的推广注册链接！”
                        
                        <h4>
                        关于个人数据的收集和使用等疑问，请参考privacy policy和term of use。
                        </h4>
                      </div>
                  )}
              </div>
              {/* <referralLinkModal/> */}
              <div className={styles.refer_modal}>
                      {isModalOpen && (
                        <div className={linkCopied ? styles.modalSmall : styles.modalNormal}>
                            <div className={styles.modalContent} ref={modalContentRef}>
                            <div className={styles.linkContainer}>
                                {linkCopied ? (
                                <>
                                    <div className={styles.successMessageContainer}>
                                      <div className={styles.successMessage}>成功复制链接!</div>
                                      <SuccessIcon className={styles.successIcon} /> {/* Replace with the actual success icon */}
                                    </div>
                                    
                                </>
                                ) : (
                                <>
                                  <a href={referralLink} target="_blank" rel="noopener noreferrer" className={styles.referralLink}>{referralLink}</a>
                                    <div
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                      >
                                        {isHovered ? <HoverCopyIcon /> : isActive ? <ActiveCopyIcon /> : <CopyIcon />}
                                        
                                      </div>
                                </>
                                )}
                            </div>
                            </div>
                        </div>
                    )}
                
              </div>
          
          </div>}

                {!isLoggedIn && <div className="navBar">        
                    <button className={styles.buttonSign} onClick={() => navigate('/login')}>
                    Sign Up/Log in
                    </button>

                    <div className={styles.langDrop}>
                    <Select
                        options={options2}
                        onChange={handleSelectChange2}
                        isSearchable={false} // This will disable user input
                        placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                        components={{
                        SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                        }}
                        styles={customSelect2}
                    />
                    </div>
                </div>}

                {isLoggedIn && <div className="navBar"  style={{width:'94%'}}>
                {/* <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  /> */}
                <button className={styles.homeButton} >
                    {/* <img src="/new_logo.svg" className={styles.homeIcon} alt="homeIcon" /> */}
                    {props.selectedLanguage == 'EN' && <img src="/new_logo.svg" alt="logo" className= {styles.newlogo}  />}
                     {props.selectedLanguage == 'CN' &&   <img src="/new_logo_cn.svg" alt="logo" className= {styles.newlogo}  />}
                </button>
                    <img src={hint} alt="Hint" className= {styles.hintIcon} onMouseEnter={() => setIsHintModalOpen(true)}
                onMouseLeave={() => setIsHintModalOpen(false)}/>
                           { props.selectedLanguage=='EN' && <div className={styles.getRefer} onClick={toggleModal}>Get My Referral Link!</div>} 
                          { props.selectedLanguage=='CN' && <div className={styles.getRefer} onClick={toggleModal}>获取我的推荐链接</div>} 
                    {/* <img src={headshot} alt="User Headshot" className={styles.userHeadshot} onClick={toggleoutModal} /> */}
                    <div className={styles.avatar}  onClick={toggleoutModal}>
                           <span className="initials"  >{initial}</span>
                       </div>
                    <div className={styles.langDrop2}>
                    <Select
                        options={options2}
                        onChange={handleSelectChange2}
                        isSearchable={false} // This will disable user input
                        placeholder={props.selectedLanguage==='EN' ? <PlaceholderImageEN /> : <PlaceholderImageCN />}
                        components={{
                        SingleValue: ({data}) => <SelectedOptionWithSVG label={data.label} />,
                        }}
                        styles={customSelect2}
                    />
                    </div> 
                </div>
                }

            </div>
        {props.selectedLanguage=='EN' &&
        <>

            <div className={styles.home}>
                <div className={styles.container}>
                    <div>
                        <p className={styles.topBox}>
                        Settings
                        </p>
                        <img src="/backhome.svg" alt="back" className= {styles.backtohome}  onClick={e=>{navigate("/home")}}/>

                        <div class={styles.line}></div>
                    </div>
                    <div className={styles.left} >
                        {/* <div className={styles.avatarBox}>
                            <div className={styles.avatar} ></div>
                        </div> */}
                        <div className={styles.menuBox}>
                            <button className={page!=="changepassword"?styles.selectedOption:styles.optionButton} onClick={showProfilePage}>My Profile</button>
                            <div className={styles.separate}></div>
                            <button className={page==="changepassword"?styles.selectedOption:styles.optionButton} onClick={changePasswordPage}>Password</button>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.mainBox}>
                            <div className={styles.mainBoxTop}>
                                <div className={styles.title}>{page!=="changepassword"?"My Profile":"Change Password"}</div>
                                <div className={styles.email}>  {page!=="changepassword"?email:""}</div>
                            </div>
                            {page === 'showprofile'&& 
                                <div className={styles.infoContainer}>
                                    <div className={styles.infoBox}>
                                        <div className={styles.infoLeft}>Full Name</div>
                                        <div className={styles.info}>{fullName}</div>
                                    </div>
                                    <div className={styles.infoSeparate}></div>
                                    <div className={styles.infoBox}>
                                        <div className={styles.infoLeft}>Company</div>
                                        <div className={styles.info}>{company}</div>
                                    </div>
                                    <div className={styles.infoSeparate}></div>
                                    <div className={styles.infoBox}>
                                        <div className={styles.infoLeft3}>&nbsp;Job Title&nbsp;&nbsp;</div>
                                        <div className={styles.info}>{jobTitle}</div>
                                    </div>
                                    <div className={styles.infoSeparate}></div>
                                    <div className={styles.infoBox}>
                                        <button className={styles.editButton} onClick={editProfilePage}>Edit Profile</button>
                                    </div>
                                </div>
                            }
                            {page === 'editprofile'&& 
                                <div className={styles.editContainer}>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>Full Name</div>
                                        <input name="fullName" className={styles.input} onChange={handleChange} placeholder={fullName}></input>
                                    </div>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>Company</div>
                                        <input name="company" className={styles.input} onChange={handleChange} placeholder={company}></input>
                                    </div>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>Job Title</div>
                                        <input name="jobTitle" className={styles.input} onChange={handleChange} placeholder={jobTitle}></input>
                                    </div>
                                    <div className={styles.buttonBox}>
                                        <button className={styles.cancelButton} onClick={showProfilePage}>Cancel</button>
                                        <button
                                        onClick={submitProfile}
                                              className={styles.saveButton} >
                                        Save Changes</button>
                                    </div>
                                </div>
                            }
                            {page === 'changepassword'&& 
                                <div className={styles.editContainer}>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>Old Password</div>
                                        <div className={styles.inputContainer}>
                                            <input name="oldPw" className={styles.input} onChange={handleChange} type={showPw1?"text":"password"}/>
                                            <button className={styles.toggleButton} onClick={() => setShowPw1(!showPw1)}>
                                                <img src={showPw1?"/openEye.svg":"/closeEye.svg"} alt="Show" className={styles.eyes} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>New Password</div>
                                        <div className={styles.inputContainer}>
                                            <input name="newPw" className={styles.input} onChange={handleChange} type={showPw2?"text":"password"}/>
                                            <button className={styles.toggleButton} onClick={() => setShowPw2(!showPw2)}>
                                                <img src={showPw2?"/openEye.svg":"/closeEye.svg"} alt="Show" className={styles.eyes} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>Confirm New Password</div>
                                        <div className={styles.inputContainer}>
                                            <input name="confirm" className={styles.input} onChange={handleChange} type={showPw3?"text":"password"}/>
                                            <button className={styles.toggleButton} onClick={() => setShowPw3(!showPw3)}>
                                                <img src={showPw3?"/openEye.svg":"/closeEye.svg"} alt="Show" className={styles.eyes} />
                                            </button>
                                        </div>

                                    </div>
                                    <div className={styles.buttonBox}>
                                        <button className={styles.cancelButton} onClick={showProfilePage}>Cancel</button>
                                        <button className={styles.saveButton} onClick={submit}>Save</button>
                                    </div>
                                    
                                </div>
                            }



                 {showPasswordChangeSuccessPopup&&(
                   <div className={styles.layer}>
                    <div className={styles.setuppop}>
                        <img src={checkicon} className={styles.remindicon}></img>
                     <div className={styles.remindwords}>Password Updated</div>
                       <div className={styles.remind3}>Please login again with your new password </div>

                       <div className={styles.box5}>
                       <button className={styles.setupPopBtn} onClick={e=>{ navigate("/login") }}>Go to login page</button>
                      </div>
                       </div>
                   </div>
                  )}  
                        </div>
                        <div className={styles.errMsg1}>{errMsg1}</div>
                        <div className={styles.errMsg2}>{errMsg2}</div>
                        <div className={styles.errMsg3}>{errMsg3}</div>
                        {/*   */}
                        {errMsg4 && (
                            <div className={styles.errMsg4}>
                                <img src="/changePWsuccess.svg" className={styles.PWsuccessIcon} alt="successIcon" />
                                {errMsg4}
                            </div>
                        )}
                    </div>
                </div>

            </div>
            </>}

            {props.selectedLanguage ==='CN' && 
            <>
            
            <div className={styles.home}>
                <div className={styles.container}>
                    <div>
                        <p className={styles.topBox}>
                        设置
                        </p>
                        <img src="/backhome.svg" alt="back" className= {styles.backtohome}  onClick={e=>{navigate("/home")}}/>

                        <div class={styles.line}></div>
                    </div>
                    <div className={styles.left} >
                        {/* <div className={styles.avatarBox}>
                            <div className={styles.avatar} ></div>
                        </div> */}
                        <div className={styles.menuBox}>
                            <button className={page!=="changepassword"?styles.selectedOption:styles.optionButton} onClick={showProfilePage}>个人信息</button>
                            <div className={styles.separate}></div>
                            <button className={page==="changepassword"?styles.selectedOption:styles.optionButton} onClick={changePasswordPage}>密码</button>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.mainBox}>
                            <div className={styles.mainBoxTop}>
                                <div className={styles.title}>{page!=="changepassword"?"个人信息":"更改密码"}</div>
                                <div className={styles.email}>  {page!=="changepassword"?email:""}</div>
                            </div>
                            {page === 'showprofile'&& 
                                <div className={styles.infoContainer}>
                                    <div className={styles.infoBox}>
                                        <div className={styles.infoLeft}>姓名</div>
                                        <div className={styles.info}>{fullName}</div>
                                    </div>
                                    <div className={styles.infoSeparate}></div>
                                    <div className={styles.infoBox}>
                                        <div className={styles.infoLeft}>公司</div>
                                        <div className={styles.info}>{company}</div>
                                    </div>
                                    <div className={styles.infoSeparate}></div>
                                    <div className={styles.infoBox}>
                                        <div className={styles.infoLeft3}>职位</div>
                                        <div className={styles.info}>{jobTitle}</div>
                                    </div>
                                    <div className={styles.infoSeparate}></div>
                                    <div className={styles.infoBox}>
                                        <button className={styles.editButton} onClick={editProfilePage}>更新信息</button>
                                    </div>
                                </div>
                            }
                            {page === 'editprofile'&& 
                                <div className={styles.editContainer}>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>姓名</div>
                                        <input name="fullName" className={styles.input} onChange={handleChange} placeholder={fullName}></input>
                                    </div>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>公司</div>
                                        <input name="company" className={styles.input} onChange={handleChange} placeholder={company}></input>
                                    </div>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>职位</div>
                                        <input name="jobTitle" className={styles.input} onChange={handleChange} placeholder={jobTitle}></input>
                                    </div>
                                    <div className={styles.buttonBox}>
                                        <button className={styles.cancelButton} onClick={showProfilePage}>取消</button>
                                        <button
                                        onClick={submitProfile}
                                              className={styles.saveButton} >
                                        保存</button>
                                    </div>
                                </div>
                            }
                            {page === 'changepassword'&& 
                                <div className={styles.editContainer}>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>旧密码</div>
                                        <div className={styles.inputContainer}>
                                            <input name="oldPw" className={styles.input} onChange={handleChange} type={showPw1?"text":"password"}/>
                                            <button className={styles.toggleButton} onClick={() => setShowPw1(!showPw1)}>
                                                <img src={showPw1?"/openEye.svg":"/closeEye.svg"} alt="Show" className={styles.eyes} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>新密码</div>
                                        <div className={styles.inputContainer}>
                                            <input name="newPw" className={styles.input} onChange={handleChange} type={showPw2?"text":"password"}/>
                                            <button className={styles.toggleButton} onClick={() => setShowPw2(!showPw2)}>
                                                <img src={showPw2?"/openEye.svg":"/closeEye.svg"} alt="Show" className={styles.eyes} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.editBox}>
                                        <div className={styles.infoLeft}>确认新密码</div>
                                        <div className={styles.inputContainer}>
                                            <input name="confirm" className={styles.input} onChange={handleChange} type={showPw3?"text":"password"}/>
                                            <button className={styles.toggleButton} onClick={() => setShowPw3(!showPw3)}>
                                                <img src={showPw3?"/openEye.svg":"/closeEye.svg"} alt="Show" className={styles.eyes} />
                                            </button>
                                        </div>

                                    </div>
                                    <div className={styles.buttonBox}>
                                        <button className={styles.cancelButton} onClick={showProfilePage}>取消</button>
                                        <button className={styles.saveButton} onClick={submit}>保存</button>
                                    </div>
                                    
                                </div>
                            }



                 {showPasswordChangeSuccessPopup&&(
                   <div className={styles.layer}>
                    <div className={styles.setuppop}>
                        <img src={checkicon} className={styles.remindicon}></img>
                     <div className={styles.remindwords}>密码已更新</div>
                       <div className={styles.remind3}>请用您的新密码重新登录</div>

                       <div className={styles.box5}>
                       <button className={styles.setupPopBtn} onClick={e=>{ navigate("/login") }}>访问登录页</button>
                      </div>
                       </div>
                   </div>
                  )}  
                        </div>
                        <div className={styles.errMsg1}>{errMsg1CN}</div>
                        <div className={styles.errMsg2}>{errMsg2CN}</div>
                        <div className={styles.errMsg3}>{errMsg3CN}</div>
                        {/*   */}
                        {errMsg4 && (
                            <div className={styles.errMsg4}>
                                <img src="/changePWsuccess.svg" className={styles.PWsuccessIcon} alt="successIcon" />
                                {errMsg4CN}
                            </div>
                        )}
                    </div>
                </div>

            </div>
            </>}
        
        </>


        

    );
}

export default ProfileSetting;