import React, { useState, useEffect } from "react";
import styles from '../GeneralFIlter.module.css'
import { fetchSuggestionLocation } from "../../Filters/fetch";

function LocationComponent(props) {
  const [tags, setTags] = useState(props.state);
  const [inputValue, setInputValue] = useState('');
  const [suggestedCountries, setSuggestedCountries] = useState([]);
  const [dropdownCountries, setDropdownCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [tagLength, setTagLength] = useState(0)
  const [isVisible, setIsVisible] = useState(true);
  const translationsCN = {
    'United Kingdom': "英国",
    'Belgium': "比利时",
    'China': "中国",
    'Italy': "意大利",
    'Singapore': "新加坡",
    'Germany': "德国",
    // 'USA': "美国",
}


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const maxTags = 10;
  var l = 0;

  useEffect(() => {
    let message = true
    if(tags.length < maxTags){
      setErrorMessage(false)
      message = false
    }
    props.change(tags,message);

  }, [tags,tagLength]);

  
  useEffect(() => {
    setTags(props.state);
  }, [props.state]);

 

  useEffect(() => {
    // Fetch suggestions when the input value changes
    if (inputValue.length > 0) {
      fetchSuggestionLocation(inputValue).then((data) => {
        console.log(data.association_words); 
        const truncatedSuggestions = data.association_words.map((word) =>
        word.length > 50 ? word.slice(0, 50) + "..." : word
      );
        setSuggestedCountries(data.association_words)
        setDropdownCountries(truncatedSuggestions);
        setIsVisible(true)
      })
      .catch((error) => {
        console.error("Promise rejected:", error); 
      });
    
    } else {
      setSuggestedCountries([]); // Clear suggestions if input is empty
    }
  }, [inputValue]);

  const countTags = () => {
    l = tags.length;
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const addDefault = (tag) => {
    if (tag.length > 1 && !tags.includes(tag)){
        if (tags.length < maxTags) {
            setTags([...tags, tag]);
            setInputValue(''); 
            setErrorMessage(false)
        }else{
          setErrorMessage(true)
        }
        setTagLength(tagLength+1)
    }
  };

  const addPullDown = (index) => {
    if (suggestedCountries[index]) {
      const tag=suggestedCountries[index]
      if (tag.length > 1 && !tags.includes(tag)) {
        if (tags.length < maxTags) {
          setTags([...tags, tag]);
          setErrorMessage(false)
        }else{
          setErrorMessage(true)
        }
        setTagLength(tagLength+1)
      }
    }
    setInputValue('');
    setIsVisible(false);
  };  

  const addTag = (e) => {
    if(e.key === "Enter") {
      const newTag = e.target.value.trim();
      if(newTag.length > 1 && !tags.includes(newTag)) {
        if (tags.length < maxTags) {
          if (suggestedCountries.length === 0) {
            if (!tags.includes("N/A")) {
              setTags([...tags, "N/A"]);
            }
            setInputValue('');
          } else {
            setTags([...tags, newTag]);
            setInputValue('');
          }
        }
      }
    }
  };

  const clearAll = () => {
    setTags([]);
  };

  const getHighlightedText = (text, query) => {
    const startIndex = text.toLowerCase().indexOf(query.toLowerCase());
    if (startIndex === -1) {
      return text;
    }

    const endIndex = startIndex + query.length;
    return (
      <>
        {text.slice(0, startIndex)}
        <span className={styles.matched}>{text.slice(startIndex, endIndex)}</span>
        {text.slice(endIndex)}
      </>
    );
  };

  const translations = {
    EN: {
      location: "Location",
       clear:"Clear",
       enter:"Enter a country name...",
       message:'No more than 10 country names'
    },
    CN: {
      location: "地点",
      clear:"删除",
      enter:" 输入国家名称...",
      message:'至多输入10个国家名称'
    },
    // You can add more languages here
  };

  const t = (key) => {
    return translations[props.selectedLanguage][key] || key;
  };
  return (
    <div>
    <div className={styles.wrapper} id="location">
      <div className={styles.non_details}>
        <div className={styles.title}>
         <h2>{t('location')}</h2>
        </div>
        <div className={styles.nontitle}>
          <div className={styles.content}>
            <div className={styles.content_li}>
              <ul>
                {tags.map((tag, index) => (
                  <li key={index}> 
                    {props.selectedLanguage === 'CN' && translationsCN[tag] ? translationsCN[tag] : tag}
                    <i onClick={() => removeTag(tag)}>
                      <span className={styles.close}>✖</span>
                    </i>
                  </li>
                ))}
                <input className={styles.enterNames}
                placeholder={tags.length ? "" : t("enter")}
                type="text"
                spellcheck="false"
                value = {inputValue}
                onChange={handleInputChange}
                // onKeyUp={addTag}
                />
              </ul>
            </div>
            <div>
              <button onClick={clearAll} className={props.selectedLanguage==='CN'?styles.ClearTriCN:styles.ClearTri}>
                {t('clear')}
                {/* <span className={styles.down_ward_triangle}>▼</span> */}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.details}>
            <ul>
                {Object.keys(translationsCN).map(country => (
                    <li onClick={() => addDefault(country)}>
                        <i>
                            <span className={styles.close}>+</span>
                        </i>
                        {props.selectedLanguage === 'CN' ? translationsCN[country] : country}
                    </li>
                ))}
            </ul>
        </div>
      
    </div>
    {errorMessage && props.message && <div className={props.selectedLanguage == 'EN'?styles.errorMessage:styles.errorMessageCN}>{t('message')}</div>}
  
    {suggestedCountries && isVisible && inputValue.length >0 ? (
      <ul className={styles.suggestions}>
        {dropdownCountries.length === 0 && tags.length < maxTags && inputValue.length > 0 && (
          <li onClick={() => addPullDown(null)}>N/A</li>
        )}
        {dropdownCountries.map((country, index) => (
          <li onClick={() => addPullDown(index)}>
            {getHighlightedText(country, inputValue)}
          </li>
        ))}
      </ul>    
    ) : (
      <div></div>
    )}

    </div>
  );
};

export default LocationComponent;